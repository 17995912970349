import { Dispatch } from "redux";
import { canteenSetTabSelectedContent } from "../../../../features/canteen-slice.feature";
import { canteenContentsNames } from "../../../../features/types/canteen-slice.type";
import { ChangeEvent } from "react";
import { ProductM } from "../../../../models/product.model";
import { generalSetIsLoading, generalSetToast } from "../../../../features/general-slice.feature";
import { ProductCategoryController } from "../../../../controllers/produc-category.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { tabMenuSetProductCategoriesData, tabMenuSetProductForm } from "../../../../features/tab-menu-slice.feature";
import { ProductCategoryI } from "../../../../models/interfaces/product-category.interface";
import { ProductCategoryM } from "../../../../models/product-category.model";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { ProductController } from "../../../../controllers/product.controller";
import { baseUrl } from "../../../../api/api";

export class MenuAddItemService{
    dispatch : Dispatch;
    productCategoryController : ProductCategoryController;
    productController : ProductController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.productCategoryController = new ProductCategoryController();
        this.productController = new ProductController();
    }

    public handleGetCategories(){
        this.dispatch(generalSetIsLoading(true));
        this.productCategoryController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(tabMenuSetProductCategoriesData(data));
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.SUCCESS,
                    text: 'Ocorreu um erro ao obter as catégorias.',
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleCategoriesToOptions(productsCategoriesList? : ProductCategoryI[]){
        const options = productsCategoriesList?.map(productCategory=>(
            {value: productCategory.id?.toString()!, content: productCategory.name!}
        ));
    
        return options;
    }

    public handleMeasureTypesToOptions(){
        const options =[
            {value: 'ml', content: 'Mililitros (ml)'},
            {value: 'g', content: 'Gramas (g)'}
        ];
    
        return options;
    }

    public handleChangeCategoryByValue(
        value: string, 
        productForm : ProductM,
        productsCategoriesList? : ProductCategoryM[],
        
    ){
        const productCategory = productsCategoriesList?.find(productCategory => productCategory.id?.toString() === value);
        this.dispatch(tabMenuSetProductForm({
            ...productForm,
            type : productCategory
        }));
    }

    public handleChangeName(value: string, productForm : ProductM){
        this.dispatch(tabMenuSetProductForm({
            ...productForm,
            name : value
        }));
    }

    public handleChangePrice(value: string, productForm : ProductM){
        this.dispatch(tabMenuSetProductForm({
            ...productForm,
            price : parseFloat(value)
        }));
    }

    public handleChangeAmountInGrams(value: string, productForm : ProductM){
        this.dispatch(tabMenuSetProductForm({
            ...productForm,
            amountInGrams : value
        }));
    }

    public handleChangeBarCode(value: string, productForm : ProductM){
        this.dispatch(tabMenuSetProductForm({
            ...productForm,
            code : value
        }));
    }

    public handleChangeQuantity(value: string, productForm : ProductM){
        this.dispatch(tabMenuSetProductForm({
            ...productForm,
            quantity : parseFloat(value)
        }));
    }

    public resetForm(){
        this.dispatch(tabMenuSetProductForm(ProductM.initial()));
    }

    public handleChangeImage(
        event : ChangeEvent<HTMLInputElement>,
        productForm : ProductM
    ){
        
        const file = event.target.files?.[0];
        
        if (file) {
            const reader = new FileReader();
            
            reader.onloadend = () => {
                console.log('reader')
                if(reader.result){
                    const media = {...productForm.media, content : reader.result.toString(), type: 'base64'};
                    this.dispatch(tabMenuSetProductForm({
                        ...productForm,
                        media : media

                    }));
                }
            };
            reader.readAsDataURL(file);
        }
    }    
    
    public handleCloseAddItemPage(){
        this.dispatch(canteenSetTabSelectedContent(canteenContentsNames.MENU));
    }

    private convertBlobToBase64(blob : Blob){
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    public async convertImageToBase64(product : ProductM){
        try {
            const response = await fetch(`${baseUrl}/${product.media?.content}`);
            const blob = await response.blob();
            const base64 = await this.convertBlobToBase64(blob) as string;
            this.dispatch(tabMenuSetProductForm({
                ...product,
                media : {type : 'base64', content : base64}
            }));
        } catch (error) {
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: 'Erro ao recuperar a imagem.',
            });
        }
    };

    
    private validateProductForm(productForm: ProductM, measureType?: string) {
        const fieldsErrors = [];

        if (!productForm.media?.content) {
            fieldsErrors.push('Foto');
        }
    
        if (!productForm.type?.id) {
            fieldsErrors.push('Categoria');
        }
    
        if (!productForm.name) {
            fieldsErrors.push('Nome');
        }
    
        if (!productForm.price) {
            fieldsErrors.push('Preço');
        }
    
        if (!productForm.amountInGrams) {
            fieldsErrors.push(measureType === 'g' ? 'Peso em Gramas' : 'Volume em Mililitros');
        }

        if (!productForm.quantity) {
            fieldsErrors.push('Quantidade');
        }
    
        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
    
        return true;
    }

    public handleSubmit( productForm : ProductM, measureType? : string){
        if(this.validateProductForm(productForm, measureType)){
            if(productForm.id === -1){
                productForm = {...productForm, amountInGrams : `${productForm.amountInGrams}${measureType}`};
                delete productForm.id;
            }
            this.dispatch(generalSetIsLoading(true));
            this.productController.createAndUpdate(productForm).then(response=>{
                const { status, data } = response;
                if(status === responseStatus.SUCCESS){
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Produto cadastrado com sucesso',
                    });
                    this.dispatch(canteenSetTabSelectedContent(canteenContentsNames.MENU));
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            })
        }

    }
}
