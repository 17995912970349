import React from 'react';
import { LoadingContainer, LoadingContent } from './styles/loading.styled';
import { spiral } from 'ldrs';

export const Loading = () => {
    spiral.register();
    return (
        <LoadingContainer>
            <LoadingContent>
                <l-spiral
                    size={40}
                    speed={0.9}
                    color={'#FF6242'}
                />
            </LoadingContent>
        </LoadingContainer>
    );
};