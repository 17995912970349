import { handleRequest } from "../api/api-request.service";
import { FullMealM } from "./full-meal.model";
import { FullMealI } from "./interfaces/full-meal.interface";
import { StudentScoreI } from "./interfaces/student-score.interface";
import { StudentI } from "./interfaces/student.interface";

export class StudentScoreM implements StudentScoreI {
    id?: number;
    score: number;
    fullMealId: number;
    studentId: number;
    fullMeal?: FullMealI;
    student?: StudentI;
    createdAt?: Date;

    constructor({ id, score, fullMealId, studentId }: StudentScoreI) {
        this.id = id;
        this.score = score;
        this.fullMealId = fullMealId;
        this.studentId = studentId;
    }

    static async getAll(studentId: number){
        return handleRequest(
            `/student-score/by/student/${studentId}`,
            'GET'
        );
    }
}