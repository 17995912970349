import styled from "styled-components";

export const MenuAddItemContainer = styled.section`
    width: 100%;
`;

export const MenuAddItemTitle = styled.div`
    margin: 24px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width: 1400px){
        margin: 18px 0;
        padding-bottom: 12px;
    }
`;

export const MenuAddItemContent = styled.form`
    width: 552px;
    .input-form{
        margin-top: 16px;
    }

    button{
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        padding: 20px 12px;
    }

    .divider{
        height: 16px;
    }

    @media screen and (max-width: 1400px){
        width: 414px;

        .input-form{
            margin-top: 12px;
        }

        button{
            margin-top: 24px;
            padding: 15px 9px;
        }
    }
`;

export const MenuAddItemFotoInput =  styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--grey-color);
    width: 552px;
    height: 412px;
    margin-top: 18px;
    margin-bottom: 36px;
    border-radius: 8px;
    cursor: pointer;

    img{
        width: 250px;
        height: 250px;
        object-fit: cover;
    }

    @media screen and (max-width: 1400px){
        width: 414px;
        height: 309px;
        margin-top: 14px;
        margin-bottom: 27px;
    }
`;