import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { GradeController } from "../../../../controllers/grade.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { studentDetailsSetGradeForm, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { studentContentsNames } from "../../../../features/types/student-slice.type";
import { GradeM } from "../../../../models/grade.model";

export class TabGradesService{
    dispatch : Dispatch;
    gradeController : GradeController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.gradeController = new GradeController();
    }

    public handleOpenAddGrade(){
        this.dispatch(studentDetailsSetGradeForm(GradeM.initial()));
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.ADD_GRADE));
    }

    public handleOpenEditGrade(grade : GradeM){
        this.dispatch(studentDetailsSetGradeForm(grade));
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.ADD_GRADE));        
    }

    public handleGetGrades(setGrades: Function, search? :string) {
        this.dispatch(generalSetIsLoading(true));
        this.gradeController.getAllByInstitute(search).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                setGrades(data);
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }
}
