import { useEffect, useRef, useState } from "react";
import { ActionBack } from "../../components/action-back.component";
import { DatePicker } from "../../components/date-picker.component";
import { DatePickerMultiT } from "../../components/types/datepicker.type";
import { TabFoodAddMenuAddItem, TabFoodAddMenuContainer, TabFoodAddMenuContent, TabFoodAddMenuGeneralInfo, TabFoodAddMenuItem, TabFoodAddMenuListProducts, TabFoodAddMenuMenus, TabFoodAddMenuSelectGradeContainer, TabFoodAddMenuSelectGradeItem, TabFoodAddMenuSelectGradeItems, TabFoodAddMenuTitle, TabFoodRemoveMenuItem } from "./styles/tab-food-add-menu.styled";
import { useDispatch } from "react-redux";
import { TabFoodAddMenuService } from "./services/tab-food-add-menu.service";
import { Input } from "../../components/input.component";
import { useAppSelector } from "../../../hooks";
import { MenuDataT } from "./types/tab-food-add-menu-page.type";
import { GradeM } from "../../../models/grade.model";
import { Table } from "../../components/table.component";
import { dateToString } from "../../../utils/config";
import { FoodTypeM } from "../../../models/food-type.model";
import arrow_up_icon from "../../../assets/icons/arrow-up.svg";
import arrow_down_icon from "../../../assets/icons/arrow-down.svg";
import trash_icon from "../../../assets/icons/trash.svg"
import plus_icon from "../../../assets/icons/plus.svg";
import { SelectConsumableModalPage } from "./select-cosumable-modal.page";
import { TodaysMenuM } from "../../../models/todays-menu.model";
import { baseUrl } from "../../../api/api";
import { ButtonPrimary } from "../../components/button-primary.component";


export const TabFoodAddMenuPage = ()=>{
    const [todaysMenus, setTodaysMenus] = useState<TodaysMenuM[]>([]);
    const [menuData, setMenuData] = useState<MenuDataT>({name : '', grades : []});

    const [datesSearch, setDatesSearch] = useState<DatePickerMultiT>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [gradesData, setGradesData] = useState<{count : number, rows : GradeM[]}>();
    const [selectedTodaysMenuDate, setSelectedTodaysMenuDate] = useState<Date>();
    const theadRef = useRef<HTMLTableSectionElement>(null);
    const dispatch = useDispatch();
    const foodReducer = useAppSelector(state=>state.foodReducer);

    const tabFoodAddMenuService = new TabFoodAddMenuService(dispatch);

    useEffect(() => {
        if (selectedDate) {
            tabFoodAddMenuService.handleSelectedDate(selectedDate, setDatesSearch);
        }
    }, [selectedDate]);

    useEffect(()=>{
        tabFoodAddMenuService.handleGetAllDates(setTodaysMenus, datesSearch);
    }, [datesSearch]);

    useEffect(()=>{
        if(todaysMenus.length > 0){
            tabFoodAddMenuService.handleSearchFoodTypes({count : 0 , rows: todaysMenus}, setSelectedTodaysMenuDate);
        }
    }, [todaysMenus]);

    useEffect(()=>{
        tabFoodAddMenuService.handleSearchGrades(setGradesData);
    }, []);

    return (
        <TabFoodAddMenuContainer>
            { foodReducer.showSelectCosumableModal && <SelectConsumableModalPage/> }
            <ActionBack onClose={tabFoodAddMenuService.handleBack}/>

            <TabFoodAddMenuTitle>
                <h2 className='primaryBlackSemibold24-150'>
                    Criar novo cardápio
                </h2>
           </TabFoodAddMenuTitle>

            <TabFoodAddMenuContent autoComplete="off">
                <TabFoodAddMenuGeneralInfo>
                    <DatePicker
                        label={'Defina os dias deste cardápio'}
                        dates={datesSearch}
                        setDate={setSelectedDate}
                        selectsRange={true}
                    />

                    <Input
                        className='input-form'
                        id='name'
                        name='name'
                        type='text'
                        label='Nome do Cardápio'
                        placeholder='Nome do Cardápio'
                        value={menuData?.name}                    
                        onChange={(value)=>setMenuData({...menuData, name : value})}
                    />

                    <TabFoodAddMenuSelectGradeContainer>
                        <label className="primaryBlackSemibold18-150">Selecione para quais turmas este cardápio está disponível</label>
                        <TabFoodAddMenuSelectGradeItems>
                            { gradesData?.rows?.map(grade=>(
                                <TabFoodAddMenuSelectGradeItem 
                                    className={`primaryBlackRegular18-150 ${menuData.grades?.includes(grade) && 'active'}`}
                                    onClick={()=>{tabFoodAddMenuService.handleChangeSelectadesGrades(grade, setMenuData, menuData)}}
                                >
                                    {grade.name}
                                </TabFoodAddMenuSelectGradeItem>
                            ))}
                        </TabFoodAddMenuSelectGradeItems>
                    </TabFoodAddMenuSelectGradeContainer>
                </TabFoodAddMenuGeneralInfo>

                <TabFoodAddMenuMenus>

                    <label className='primaryBlackSemibold18-150'>Cardápio Semanal</label> 

                    {foodReducer.todaysMenusForm?.rows.map((todayMenu, todayMenuIndex)=>(
                        <Table key={`table-${todayMenuIndex}`}>
                            <thead 
                                className='primaryBlackSemibold20-150' 
                                ref={selectedTodaysMenuDate && dateToString(selectedTodaysMenuDate) === dateToString(todayMenu.date) ? theadRef : null} 
                                onClick={()=>setSelectedTodaysMenuDate(todayMenu.date)} 
                            >
                                <th>
                                    {dateToString(todayMenu.date)}
                                    <span className='primaryBlackNormal18-150 tableSubTitle'>Alimentos</span>
                                </th>
                                <th className="options-column">
                                    <img 
                                        src={
                                            selectedTodaysMenuDate && dateToString(selectedTodaysMenuDate) === dateToString(todayMenu.date) ?
                                            arrow_up_icon : 
                                            arrow_down_icon
                                        } 
                                        alt="Icone de seta"
                                    />
                                </th>
                            </thead>
                            { selectedTodaysMenuDate && dateToString(selectedTodaysMenuDate) === dateToString(todayMenu.date) && 
                                todayMenu.fullmeals?.map((fullMeal, fullMealIndex)=>(
                                    <tbody>
                                        <tr>
                                            <td className="full-line text-start">
                                                <span className="primaryBlackSemibold20-150">{fullMeal.foodType?.name}</span>
                                                <TabFoodAddMenuListProducts>
                                                    { fullMeal.consumables?.map(consumable=>(
                                                        <TabFoodAddMenuItem onClick={()=>tabFoodAddMenuService.handleShowSelectConsumableModal(todayMenuIndex, fullMealIndex)}>
                                                            <div>
                                                                <img src={`${baseUrl}/${consumable.media?.content}`} alt="Imagem do Alimento"/>
                                                                <TabFoodRemoveMenuItem>
                                                                    <img src={trash_icon} alt="Icone de remover"/>
                                                                    <span className="primaryWhiteMedium18-150">Excluir</span>
                                                                </TabFoodRemoveMenuItem>
                                                            </div>
                                                            <span className="primaryBlackMedium20-150">{consumable.name}</span>
                                                            <span className="primaryGreyMedium18-150">{consumable.amountInGrams}g</span>
                                                            <span className="primaryPrimaryMedium18-150">Mudar alimento</span>
                                                        </TabFoodAddMenuItem>
                                                    ))

                                                    }
                                                    <TabFoodAddMenuAddItem onClick={()=>tabFoodAddMenuService.handleShowSelectConsumableModal(todayMenuIndex, fullMealIndex)}>
                                                        <div>
                                                            <img src={plus_icon} alt="Icone de Adicionar"/>
                                                        </div>
                                                        <span className="primaryGreyMedium20-150">Alimento novo</span>
                                                        <span className="primaryPrimaryMedium18-150">Escolher alimento</span>
                                                    </TabFoodAddMenuAddItem>
                                                    
                                                </TabFoodAddMenuListProducts>
                                            </td>                        
                                        </tr>
                                    </tbody>
                                ))
                            }
                        </Table>
                    ))}
                </TabFoodAddMenuMenus>

                <ButtonPrimary 
                    className="button-save"
                    onClick={()=>tabFoodAddMenuService.handleSubmit(menuData, foodReducer.todaysMenusForm.rows)}
                >
                    Salvar novo cardápio
                </ButtonPrimary>

            </TabFoodAddMenuContent>

        </TabFoodAddMenuContainer>


    );
}