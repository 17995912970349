import { handleRequest } from "../api/api-request.service";
import { MediaI } from "./interfaces/media.interface";
import { ProductCategoryI } from "./interfaces/product-category.interface";
import { ProductI } from "./interfaces/product.interface";
import { ResponsibleI } from "./interfaces/responsible.interface";
import { MediaM } from "./media.model";
import { ProductCategoryM } from "./product-category.model";

export class ProductM implements ProductI {
    id?: number;
    name: string;
    amountInGrams?: string;
    price?: number;
    type?: ProductCategoryI;
    code?: string;
    quantity?: number;
    availableCode?: boolean;
    available?: boolean;
    canteenId?: number;
    media?: MediaM;
    blockedBy?: ResponsibleI[];

    constructor({
        id,
        name,
        amountInGrams,
        price,
        type,
        code,
        quantity,
        availableCode,
        available,
        canteenId,
        media,
        blockedBy,
    }: ProductI) {
        this.id = id;
        this.name = name;
        this.amountInGrams = amountInGrams;
        this.price = price;
        this.type = type;
        this.code = code;
        this.quantity = quantity;
        this.availableCode = availableCode;
        this.available = available;
        this.canteenId = canteenId;
        this.media = media;
        this.blockedBy = blockedBy;
    }

    static initial(){
        return new ProductM({
            name : '',
            type : ProductCategoryM.initial(),
            media : MediaM.initial()
        })
    }

    static async getByCategoryName(categoryName : string, search? : string){
        const query = search ? `&name=${search}` : '';

        return handleRequest(
            `product/by/canteen/${1}?type=${categoryName}${query}`,
            'GET'
        );
    }

    static async update(product : ProductM){
        return handleRequest(
            `product/${product.id}`,
            'PUT',
            product
        );
    }

    static async create(product : ProductM){
        return handleRequest(
            `product`,
            'POST',
            product
        );
    }

    static async delete(productId : number){
        return handleRequest(
            `product/${productId}`,
            'DELETE'
        );
    }
}