import React, { useState } from 'react';
import home_icon from '../../assets/icons/home.svg';
import canteen_icon from '../../assets/icons/canteen.svg';
import food_icon from '../../assets/icons/food.svg';
import students_icon from '../../assets/icons/students.svg';
import settings_icon from '../../assets/icons/settings.svg';

import announcement_icon from '../../assets/icons/announcements.svg';
import logo75_75_image from '../../assets/images/logo75x75.png';

import { Link } from 'react-router-dom';
import { SidebarContainer, SidebarContent, SidebarMenu, SidebarMenuItem } from './styles/sidebar.styled';
import { LocalToken } from '../../utils/local-token';
import { LoginUserType } from '../../utils/config';
import { useDispatch } from 'react-redux';
import { generalSetAlertDialog } from '../../features/general-slice.feature';
import { useAppSelector } from '../../hooks';

export const Sidebar = () => {
    const path = window.location.href.split('/')[3];
    const userType = LocalToken.getStoredUserType();
    const [showSidebar, setShowSidebar] = useState(false);
    const dispatch = useDispatch();

    const generalReducer = useAppSelector(state => state.generalReducer);

    const logout = () => {
        dispatch(generalSetAlertDialog({
            isOpen: true,
            title: "Sair",
            text: "Deseja realmente sair do Healthy Puppy?",
            action: {
                text: "Sair",
                onClick: () => {
                    LocalToken.clearStoredToken();
                    window.location.href = '/landing'
                }
            }
        }))
    }

    return (
        <SidebarContainer>
            <SidebarContent className={!showSidebar ? 'close-sidebar' : ''}>
                <h2 className='secondaryPrimarySemibold24-normal'>Healthy Puppy</h2>
                <img className='logo' src={logo75_75_image} alt='Logo Healthy Puppy' />
                <SidebarMenu className='sidebar-menu'>
                    {userType === LoginUserType.TEACHER ?
                        <>
                            {generalReducer.userSchoolWorker?.groups?.find(group=>group.name === "SCHOOL_ADMINISTRATION") ?
                                <ul>
                                    <SidebarMenuItem className='sidebar-menu-item'>
                                        <Link to="/home" className={`primaryBlackSemibold18-150 ${path === 'home' && 'active'}`}>
                                            <img src={home_icon} alt='Icone de Dashboard' />
                                            <span >Dashboard</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='sidebar-menu-item' >
                                        <Link to="/alimentacao" className={`primaryBlackSemibold18-150 ${path === 'alimentacao' && 'active'}`}>
                                            <img src={food_icon} alt='Icone de Alimentação' />
                                            <span >Alimentação</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='sidebar-menu-item' >
                                        <Link to="/cantina" className={`primaryBlackSemibold18-150 ${path === 'cantina' && 'active'}`}>
                                            <img src={canteen_icon} alt='Icone de Cantina' />
                                            <span >Cantina</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='sidebar-menu-item' >
                                        <Link to="/alunos" className={`primaryBlackSemibold18-150 ${path === 'alunos' && 'active'}`}>
                                            <img src={students_icon} alt='Icone de Alunos' />
                                            <span >Alunos</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='sidebar-menu-item'>
                                        <Link to="/comunicados" className={`primaryBlackSemibold18-150 ${path === 'comunicados' && 'active'}`}>
                                            <img src={announcement_icon} alt='Icone de Comunicados' />
                                            <span >Comunicados</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='sidebar-menu-item'>
                                        <Link to="/configuracoes" className={`primaryBlackSemibold18-150 ${path === 'configuracoes' && 'active'}`}>
                                            <img src={settings_icon} alt='Icone de Configurações' />
                                            <span >Configurações</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='logout'>
                                        <Link
                                            onClick={logout}
                                            to={path} className={`primaryBlackSemibold18-150`}>
                                            <span >Sair</span>
                                        </Link>
                                    </SidebarMenuItem>

                                </ul>
                                :
                                <ul>
                                    <SidebarMenuItem className='sidebar-menu-item'>
                                        <Link to="/alimentacao" className={`primaryBlackSemibold18-150 ${path === 'alimentacao' && 'active'}`}>
                                            <img src={food_icon} alt='Icone de Alimentação' />
                                            <span >Alimentação</span>
                                        </Link>
                                    </SidebarMenuItem>

                                    <SidebarMenuItem className='logout'>
                                        <Link
                                            onClick={logout}
                                            to={path} className={`primaryBlackSemibold18-150`}>
                                            <span >Sair</span>
                                        </Link>
                                    </SidebarMenuItem>
                                </ul>
                            }
                        </>
                        :
                        <ul>
                            <SidebarMenuItem className='sidebar-menu-item'>
                                <Link to="/alimentacao" className={`primaryBlackSemibold18-150 ${path === 'alimentacao' && 'active'}`}>
                                    <img src={food_icon} alt='Icone de Alimentação' />
                                    <span >Alimentação</span>
                                </Link>
                            </SidebarMenuItem>

                            <SidebarMenuItem className='logout'>
                                <Link
                                    onClick={logout}
                                    to={path} className={`primaryBlackSemibold18-150`}>
                                    <span >Sair</span>
                                </Link>
                            </SidebarMenuItem>
                        </ul>
                    }
                </SidebarMenu>
            </SidebarContent>
        </SidebarContainer>
    );
};