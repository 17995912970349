import { MediaI } from "./interfaces/media.interface";

export class MediaM implements MediaI {
    id?: number;
    type: string;
    content: string;
    name?: string;

    constructor({ id, type, content, name }: MediaI) {
        this.id = id;
        this.type = type;
        this.content = content;
        this.name = name;
    }

    static initial(){
        return new MediaM({
            type: 'base64',
            content: '',
        });        
    }
}