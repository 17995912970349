import styled from "styled-components";

export const TabFoodAddMenuContainer = styled.div`

`;

export const TabFoodAddMenuContent = styled.form`
    margin-top: 48px;

    .button-save{
        max-width: 300px;
        align-items: center;
        justify-content: center;
        padding: 16px 18px;
    }

    @media screen and (max-width : 1400px){
        margin-top: 36px;

        .button-save{
            max-width: 225px;
        }
    }

`;

export const TabFoodRemoveMenuItem = styled.div`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--grey-opacity-color);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    cursor: pointer;
    width: 120px;
    height: 120px;
    
    img{
        width: 32px !important;
        height: 32px !important;
    }

    @media screen and (max-width : 1400px){
        width: 90px;
        height: 90px;

        img{
            width: 24px !important;
            height: 24px !important;
        }
    }
`

export const TabFoodAddMenuItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    max-width: 140px;
    span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    div{
        img{
            width: 120px;
            height: 120px;
            border-radius: 8px;
            object-fit: cover;
        }

        &:hover{
            div{
                display: flex;
            }
        }
    }

    @media screen and (max-width : 1400px){
        div{
            img{
                width: 90px;
                height: 90px;
            }
        }
    }
`;

export const TabFoodAddMenuAddItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;

    div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border-radius: 8px;
        border: 1px dashed var(--border-color);

        img{
            width: 52px;
            height: 52px;
            filter: grayscale(100%);
        }
    }

    @media screen and (max-width : 1400px){
        div{
            width: 90px;
            height: 90px;

            img{
                width: 39px;
                height: 39px;
            }
        }
        
    }
`;

export const TabFoodAddMenuListProducts = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

export const TabFoodAddMenuMenus = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    thead{
        cursor: pointer;
    }

    .options-column{
        align-items: flex-end;
    } 

    @media screen and (max-width : 1400px) {
        gap: 18px;
    }
`;

export const TabFoodAddMenuGeneralInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 590px;

    @media screen and (max-width : 1400px) {
        width: 443px;
        gap: 18px;
    }
`;

export const TabFoodAddMenuSelectGradeContainer = styled.div`

`

export const TabFoodAddMenuSelectGradeItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 24px 0;

    .active{
        background-color: var(--primary-color);
        color: var(--white-color) !important;
    }
    
    @media screen {
        margin: 18px 0;
        gap: 12px;
    }
`

export const TabFoodAddMenuSelectGradeItem = styled.div`
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    cursor: pointer;

    &:hover{
        background-color: var(--primary-color);
        color: var(--white-color) !important;
    }

    @media screen {
        padding: 2px 12px;
    }
`

export const TabFoodAddMenuTitle = styled.div`
    margin: 12px 0;
    border-bottom: 1px solid var(--border-color);

    h2{
        margin-bottom: 8px;
    }

    @media screen and (max-width: 1400px){
        margin : 9px 0;
        
        h2{
            margin-bottom: 6px;
        }
    }

`;