import styled from "styled-components";

export const TabMenuContainer = styled.section`
    width: 100%;

    .product-info{
        display: flex !important;
        flex-direction: row !important;
        align-items: center;
        width: 100%;

        div{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        img{
            width: 52px;
            margin: 28px;
        }

        
    }

    .full-line{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    @media screen and (max-width: 1400px){
        .product-info {
            div{
                gap: 6px;
            }

            img{
                width: 39px;
                margin: 21px;
            }
        }    
    }
`;

export const TabMenuTitle = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;

    h2{
        margin-right: auto;
        margin-bottom: 16px;
    }

    span{
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border-color);
    }

    @media screen and (max-width: 1400px){
        h2{
            margin-right: auto;
            margin-bottom: 12px;
        }

        span{
            margin-bottom: 24px;
            padding-bottom: 12px;
    }
    }
  
`;

export const TabMenuButtonBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
   

    .info-more{
        position: relative !important;
        margin: 0 0 0 28px !important;
    }

    .add-item{
        width: 220px;
        margin: 0;
    }

    button{
        width: auto;
        margin: 24px 0;
        height: 52px;
        padding: 0 16px;
        
    }

    @media screen and (max-width: 1400px){

        button{
            margin: 18px 0;
        }
        
        .add-item{
            width: 180px;
             margin: 0;
        }

        button{
            height: 39px;
            padding: 0 12px;
        }

        .info-more{
            margin: 0 0 0 21px !important;
        }
    }
`;

export const TabMenuSearchBar = styled.form`
    display: flex;
    width: 100%;
    align-items: flex-end;
    gap: 24px;

    .inputSearch{
        width: 100%;
        input{ 
            margin: 0;
        }
    }

    .input-style{
        border-radius: 4px;
        max-height: 60px;
        padding: 11px 16px;
    }

    @media screen and (max-width : 1400px){
        .input-style{
            max-height: 42px;
        }
    }
`;