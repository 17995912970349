import { Input } from "../../components/input.component";
import { Dropdown } from "../../components/dropdown.component";
import { ButtonPrimary } from "../../components/button-primary.component";
import { useEffect, useState } from "react";
import { InputSugestion, InputSugestions, TabAnnouncementsFormContainer, TabAnnouncementsFormContent, TabAnnouncementsFormFotoInput, TabAnnouncementsFormTitle } from "./styles/tab-announcements-form.styled";

import { useAppSelector } from "../../../hooks";
import { useDispatch } from "react-redux";

import { baseUrl } from '../../../api/api';
import { ButtonSecondary } from "../../components/button-secondary.component";
import { TabAnnouncementsFormService } from "./services/tab-announcements-form.service";
import { TextArea } from "../../components/textarea.component";
import { ActionBack } from "../../components/action-back.component";
import { InputPhoto } from "./styles/tab-announcements.styled";

export const TabAnnouncementsForm = () => {

    const tabannouncementsReducer = useAppSelector(state => state.tabAnnouncementsReducer);
    const generalReducer = useAppSelector(state => state.generalReducer);
    const dispatch = useDispatch();

    const tabAnnouncementsForm = TabAnnouncementsFormService.getInstance(dispatch);
    const [editMode, setEditMode] = useState(false)
    const [search, setSearch] = useState(tabannouncementsReducer.announcementsForm.author?.schoolWorker?.name ?? '');

    const [searchEdit, setSearchEdit] = useState(false);

    useEffect(() => {
        if(generalReducer.userSchoolWorker?.instituteId){
            tabAnnouncementsForm.handleGetSchoolWorkers(generalReducer.userSchoolWorker.instituteId);
        }
    }, [generalReducer.userSchoolWorker]);

    useEffect(() => {
        if (tabannouncementsReducer.announcementsForm.id === -1) {
            tabAnnouncementsForm.convertImageToBase64(tabannouncementsReducer.announcementsForm);
        }
    }, []);

    return (
        <TabAnnouncementsFormContainer>
            <ActionBack onClose={() => tabAnnouncementsForm.handleCloseAddItemPage()} />

            <TabAnnouncementsFormTitle>
                <h2 className='primaryBlackSemibold24-150'>
                    {!tabannouncementsReducer.announcementsForm.id ? "Detalhes do" : tabannouncementsReducer.announcementsForm.id === -1 ? "Duplicar" : "Editar"}{' '}comunicado
                </h2>
            </TabAnnouncementsFormTitle>


            <div className="float-edit-mode-form">
                {tabannouncementsReducer.announcementsForm.id &&
                    <>
                        {
                            editMode &&
                            <ButtonPrimary onClick={() => {
                                tabAnnouncementsForm.handleSubmit(tabannouncementsReducer.announcementsForm)
                                setEditMode(false);
                            }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22ZM15.536 8.464C15.7238 8.27649 15.9783 8.17126 16.2437 8.17144C16.5091 8.17163 16.7635 8.27723 16.951 8.465C17.1385 8.65277 17.2437 8.90734 17.2436 9.17271C17.2434 9.43807 17.1378 9.69249 16.95 9.88L11.297 15.533L11.293 15.537C11.2003 15.6302 11.0902 15.7041 10.9689 15.7546C10.8475 15.8051 10.7174 15.831 10.586 15.831C10.4546 15.831 10.3245 15.8051 10.2031 15.7546C10.0818 15.7041 9.97165 15.6302 9.879 15.537L9.875 15.533L7.05 12.708C6.95449 12.6158 6.87831 12.5054 6.8259 12.3834C6.77349 12.2614 6.7459 12.1302 6.74475 11.9974C6.7436 11.8646 6.7689 11.7329 6.81918 11.61C6.86946 11.4872 6.94371 11.3755 7.0376 11.2816C7.1315 11.1877 7.24315 11.1135 7.36605 11.0632C7.48894 11.0129 7.62062 10.9876 7.7534 10.9887C7.88618 10.9899 8.0174 11.0175 8.1394 11.0699C8.26141 11.1223 8.37175 11.1985 8.464 11.294L10.586 13.415L15.536 8.465V8.464Z" fill="#FEFEFE" />
                                </svg>
                                Salvar informações

                            </ButtonPrimary>
                        }
                        {
                            !editMode &&
                            <ButtonSecondary onClick={() => setEditMode(true)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 17.9999H9.24C9.37161 18.0007 9.50207 17.9755 9.62391 17.9257C9.74574 17.8759 9.85656 17.8026 9.95 17.7099L16.87 10.7799L19.71 7.99994C19.8037 7.90698 19.8781 7.79637 19.9289 7.67452C19.9797 7.55266 20.0058 7.42195 20.0058 7.28994C20.0058 7.15793 19.9797 7.02722 19.9289 6.90536C19.8781 6.7835 19.8037 6.6729 19.71 6.57994L15.47 2.28994C15.377 2.19621 15.2664 2.12182 15.1446 2.07105C15.0227 2.02028 14.892 1.99414 14.76 1.99414C14.628 1.99414 14.4973 2.02028 14.3754 2.07105C14.2536 2.12182 14.143 2.19621 14.05 2.28994L11.23 5.11994L4.29 12.0499C4.19732 12.1434 4.12399 12.2542 4.07423 12.376C4.02446 12.4979 3.99924 12.6283 4 12.7599V16.9999C4 17.2652 4.10536 17.5195 4.29289 17.707C4.48043 17.8946 4.73478 17.9999 5 17.9999ZM14.76 4.40994L17.59 7.23994L16.17 8.65994L13.34 5.82994L14.76 4.40994ZM6 13.1699L11.93 7.23994L14.76 10.0699L8.83 15.9999H6V13.1699ZM21 19.9999H3C2.73478 19.9999 2.48043 20.1053 2.29289 20.2928C2.10536 20.4804 2 20.7347 2 20.9999C2 21.2652 2.10536 21.5195 2.29289 21.707C2.48043 21.8946 2.73478 21.9999 3 21.9999H21C21.2652 21.9999 21.5196 21.8946 21.7071 21.707C21.8946 21.5195 22 21.2652 22 20.9999C22 20.7347 21.8946 20.4804 21.7071 20.2928C21.5196 20.1053 21.2652 19.9999 21 19.9999Z" fill="#FF6242" />
                                </svg>
                                Editar informações

                            </ButtonSecondary>
                        }

                    </>
                }
            </div>

            <TabAnnouncementsFormContent>
                <Input
                    className='input-form'
                    id='title'
                    name='title'
                    type='text'
                    label='Titulo do comunicado'
                    placeholder='Titulo'
                    value={tabannouncementsReducer.announcementsForm.title}
                    onChange={(value) => tabAnnouncementsForm.handleChangeTitle(value, tabannouncementsReducer.announcementsForm)}
                />


                <Input
                    className='input-form'
                    id='authorName'
                    name='authorName'
                    type='text'
                    label='Autor'
                    placeholder='Nome do autor'
                    value={search}
                    onChange={(value) => {
                        setSearchEdit(true);
                        setSearch(value);
                    }}
                />

                <InputSugestions id="authorNameSuggestions" className={`authors-name-suggestions ${search.length >= 3 && searchEdit ? 'show' : ''}`}>

                    {
                        tabannouncementsReducer.authorsData?.rows.filter(function (item, pos, self) {

                            return item.schoolWorker!.name?.toLowerCase().includes(search?.toLowerCase());

                        }).map((user) => {

                            return (

                                <InputSugestion className={`${tabannouncementsReducer.announcementsForm.userId === user.id ? 'select' : ''}`} onClick={(e) => {
                                    tabAnnouncementsForm.handleChangeAuthor(user, tabannouncementsReducer.announcementsForm)
                                    setSearch(user.schoolWorker!.name)
                                    setSearchEdit(false)
                                }} key={"suggestion" + user.id}>
                                    {user.schoolWorker!.name}
                                </InputSugestion>
                            )
                        })
                    }

                </InputSugestions>



                <div className="dropdown-form" >
                    <label className="primaryBlackSemibold18-150 label" htmlFor={''}>
                        Cargo
                    </label>
                    <Dropdown
                        styleClass='input-style'
                        defaultValue={tabannouncementsReducer.announcementsForm.author?.schoolWorker?.office ?? ''}
                        placeholder='Cargo'
                        options={
                            tabannouncementsReducer.authorsData?.rows.filter(function (item, pos, self) {

                                return self.findIndex(e => e.schoolWorker!.office === item.schoolWorker!.office) === pos;

                            }).map((user) => {

                                return { value: user.schoolWorker?.office, content: user.schoolWorker?.office }
                            })
                        }
                        onChange={(value) => { }}

                    />
                </div>


                <TextArea
                    className='input-form'
                    id='content'
                    name='content'
                    label='Conteúdo'
                    placeholder='Conteúdo do comunicado'
                    value={tabannouncementsReducer.announcementsForm.content}
                    onChange={(value) => tabAnnouncementsForm.handleChangeContent(value, tabannouncementsReducer.announcementsForm)}
                />


                <div className="dropdown-form" >
                    <label className="primaryBlackSemibold18-150 label" htmlFor={''}>
                        Status
                    </label>
                    <Dropdown
                        styleClass='input-style'
                        defaultValue={tabannouncementsReducer.announcementsForm.status}
                        options={[{ value: 'active', content: 'Ativo' }, { value: 'no-active', content: "Inativo" }]}
                        onChange={(value) => tabAnnouncementsForm.handleChangeStatus(value, tabannouncementsReducer.announcementsForm)}

                    />
                </div>

                <Input
                    className='input-form'
                    id='authorName'
                    name='authorName'
                    type='text'
                    label='Visualizações'
                    info='O número de visualizações representa quantas pessoas clicaram no comunicado.'
                    placeholder='Nome do autor'
                    disabled={true}
                    value={`${tabannouncementsReducer.announcementsForm.viewCount ?? 0} visualizações`}
                    onChange={(value) => { }}
                />

                <Input
                    className='input-form'
                    id='createdAtDate'
                    name='createdAtDate'
                    type='text'
                    label='Data de criação do comunicado'
                    placeholder='Nome do autor'
                    disabled={true}
                    value={new Date(tabannouncementsReducer.announcementsForm.createdAt ?? Date.now()).toLocaleDateString('en-GB')}
                    onChange={(value) => { }}
                />

                <InputPhoto>
                    <h2 className='primaryBlackSemibold20-120'>Imagem da capa</h2>
                    <label className="photoBanner" htmlFor={"photo-banner"}>
                        <span className='primaryBlackNormal20-120'>Aparece no aplicativo para os pais e na página de comunicados.</span>
                        <input
                            id="photo-banner"
                            type='file'
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => tabAnnouncementsForm.handleChangeImage(e, tabannouncementsReducer.announcementsForm)}
                            hidden
                        />
                        <TabAnnouncementsFormFotoInput>
                            {tabannouncementsReducer.announcementsForm.media?.content ?
                                <img
                                    src={
                                        tabannouncementsReducer.announcementsForm.media.type === 'external_url' ?
                                            `${baseUrl}/${tabannouncementsReducer.announcementsForm.media?.content}` :
                                            tabannouncementsReducer.announcementsForm.media?.content
                                    }
                                    alt='Imagem da capa'
                                /> :
                                <span className='primaryGreySemibold18-150'>Clique aqui para adicionar uma imagem</span>
                            }
                        </TabAnnouncementsFormFotoInput>
                    </label>
                </InputPhoto>
                {
                    !tabannouncementsReducer.announcementsForm.id &&

                    <ButtonPrimary onClick={() => tabAnnouncementsForm.handleSubmit(tabannouncementsReducer.announcementsForm)}>Salvar comunicado</ButtonPrimary>
                }
                {
                    tabannouncementsReducer.announcementsForm.id &&

                    <ButtonSecondary onClick={() => tabAnnouncementsForm.handleRemove(tabannouncementsReducer.announcementsForm)}>Excluir comunicado</ButtonSecondary>
                }
                
            </TabAnnouncementsFormContent>


        </TabAnnouncementsFormContainer>
    )
}