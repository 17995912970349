import * as S from './styled';

import logo_image from '../../../../assets/images/logo146x146.png';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginUserType } from '../../../../utils/config';
import { ButtonPrimary } from '../../../components/button-primary.component';

export const LadingPage = () => {
    const [userType, setUserType] = useState<string | null>(null);
    const navigate = useNavigate();

    return (
        <S.LandingContainer>
            <S.LandingContent>
                <img src={logo_image} alt={"Logo Healthy Puppy"} />
                <h2 className='primaryPrimaryBold30-120'>Bem-vindo ao Healthy Puppy</h2>

                <span className='primaryBlackSemibold24-120'>Escolha o seu acesso</span>

                <S.LandingOptionLogin onClick={() => { setUserType(LoginUserType.STUDENT) }} className={`primaryBlackNormal18-120`} status={userType === LoginUserType.STUDENT ? "active" : "inactive"}>Sou aluno</S.LandingOptionLogin>
                <S.LandingOptionLogin onClick={() => { setUserType(LoginUserType.TEACHER) }} className='primaryBlackNormal18-120' status={userType === LoginUserType.TEACHER ? "active" : "inactive"}>Sou professor</S.LandingOptionLogin>

                <ButtonPrimary onClick={() => { navigate(`/login/${userType}`) }} disabled={!userType}>Continuar</ButtonPrimary>

                {/* <a href="#" className='primaryBlackNormal20-120'>Não tenho uma conta</a> */}

            </S.LandingContent>
        </S.LandingContainer>
    )
}