export class DatePickerService{
    
    constructor(){
        this.filterWeekends = this.filterWeekends.bind(this);
        this.isWeekend = this.isWeekend.bind(this);
    }
    
    handleFocus(id?: string){
        document.getElementById(id || 'date-picker')?.focus();
    }

    handleAddButton(buttonText : string, buttonOnClick : Function){
        setTimeout(() => {
            const datePickerPopper = document.getElementsByClassName('react-datepicker__month')[0];

            const exists = datePickerPopper?.getElementsByClassName('new-button')[0];
            if (!exists) {
                const newButton = document.createElement('button');
                newButton.className='new-button primaryPrimarySemibold16-150-underline';
                newButton.textContent = buttonText;

                newButton.addEventListener('click', ()=>buttonOnClick());

                datePickerPopper?.appendChild(newButton);
            }
        }, 100);
    }
    
    isWeekend(date : Date){
        const day = date.getDay();
        return day === 0 || day === 6;
    };
    
    filterWeekends(date : Date){
        return !this.isWeekend(date);
    };
}
