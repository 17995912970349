import React, { useEffect, useRef, useState } from 'react';
import dots_vertical_icon from '../../assets/icons/dots_vertical.svg';
import { OptionsMenuContainer } from './styles/options-menu.styled';
import { OptionsMenuT } from './types/options-menu.type';
import { OptionsMenuModal } from './options-menu-modal.component';
import { OptionsMenuService } from './services/options-menu.service';

export const OptionsMenu = ({className, options} : OptionsMenuT) => {
    const [showOptions, setShowOptions] = useState(false);
    const optionsMenuRef = useRef(null);

    const optionsMenuService = new OptionsMenuService();

    useEffect(() => {
        optionsMenuService.handleAddEventListner(optionsMenuRef, setShowOptions);
        return () => {
            optionsMenuService.handleRemoveEventListner(optionsMenuRef, setShowOptions);
        };
    }, []);

    return (
        <OptionsMenuContainer className={className} ref={optionsMenuRef}>
            <img src={dots_vertical_icon} onClick={()=>setShowOptions(!showOptions)} alt='Icone optons menu' />
            { showOptions && <OptionsMenuModal options={options}/> }
        </OptionsMenuContainer>
    );
};