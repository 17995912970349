import styled from "styled-components";

export const MobileContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    padding-top: 100px;
    text-align: center;

    p{
        margin-top: 20px;
    }
`;