import { Dispatch } from "redux";
import { foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { foodContentsNames } from "../../../../features/types/food-slice.type";
import { LoginUserType } from "../../../../utils/config";
import { LocalToken } from "../../../../utils/local-token";

export class TabStudentEvaluationSuccessService {
    dispatch : Dispatch;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
    }
   
    public async handleSubmit(userType : string){
        if(userType === LoginUserType.TEACHER){
            this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION));   
        }else{
            this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_MEAL));   
        }    
    }
    
}