import { addDays, endOfWeek, format, parseISO, startOfWeek } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { GradeController } from "../../../../controllers/grade.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { GradeM } from "../../../../models/grade.model";
import { DatePickerMultiT } from "../../../components/types/datepicker.type";
import { FoodTypeController } from "../../../../controllers/food-type.controller";
import { foodSetShowSelectCosumableModal, foodSetTabSelectedContent, foodSetTodaysMenusForm, foodSetTodaysMenusFormData } from "../../../../features/food-slice.feature";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { FullMealM } from "../../../../models/full-meal.model";
import { FoodTypeM } from "../../../../models/food-type.model";
import { MenuDataT } from "../types/tab-food-add-menu-page.type";
import { TodaysMenuController } from "../../../../controllers/todays-menu.controller";
import { foodContentsNames } from "../../../../features/types/food-slice.type";

export class TabFoodAddMenuService{
    dispatch : Dispatch;
    gradeController : GradeController;
    foodTypeController : FoodTypeController;
    todaysMenuController : TodaysMenuController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.gradeController = new GradeController();
        this.foodTypeController = new FoodTypeController();
        this.todaysMenuController = new TodaysMenuController();
        
        this.handleShowSelectConsumableModal = this.handleShowSelectConsumableModal.bind(this);
        this.validateTodayMenu = this.validateTodayMenu.bind(this);
        this.handleSubmit =  this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    private validateTodayMenu(menuData : MenuDataT, todaysMenu : TodaysMenuM[]){
        const fieldsErrors = [];

        if(!menuData.name){
            fieldsErrors.push('Nome');
        }

        if(menuData.grades?.length === 0){
            fieldsErrors.push('Turmas');
        }
   
        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        return true;
    }

    public handleSubmit(menuData : MenuDataT, todaysMenusForm : TodaysMenuM[]){
        if(this.validateTodayMenu(menuData, todaysMenusForm)){
            this.dispatch(generalSetIsLoading(true));

            const newTodaysMenu = JSON.parse(JSON.stringify(todaysMenusForm));
            newTodaysMenu.map((todayMenu : TodaysMenuM)=>{
                todayMenu.menuGrade!.grades = menuData.grades;
                todayMenu.menuGrade!.name = menuData.name;
                newTodaysMenu.push(todayMenu);                
            });
            
            this.todaysMenuController.createAndUpdateList(newTodaysMenu).then(response=>{
                const { status, data } = response;
                if(status === responseStatus.SUCCESS){
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Cardápio Semanal cadastrado com sucesso',
                    });
                    if(!newTodaysMenu[0].id){
                        this.dispatch(foodSetTodaysMenusForm({count: 0, rows : []}));
                        this.dispatch(foodSetTabSelectedContent(foodContentsNames.MENU));
                    }else{
                        this.dispatch(foodSetTodaysMenusForm(data));
                    }
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            })
        }
        

    }

    public handleShowSelectConsumableModal(todaysMenuIndex : number, fullMealIndex: number ){
        this.dispatch(foodSetShowSelectCosumableModal(true));
        this.dispatch(foodSetTodaysMenusFormData({
            todaysMenuIndex,
            fullMealIndex
        }));
    }

    public handleSelectedDate(selectedDate : Date, setDatesSearch : Function){
        let startDate = startOfWeek(new Date(selectedDate));
        startDate = addDays(startDate, 1);
        let endDate = endOfWeek(new Date(selectedDate));
        endDate = addDays(endDate, -1);
        setDatesSearch({ startDate, endDate });
    }

    public handleChangeSelectadesGrades(grade : GradeM, setMenuData : Function, menuData? : MenuDataT){
        if(menuData?.grades){
            const gradeExists = menuData.grades.some((g) => g.id === grade.id);
        
            const updatedGrades = gradeExists
            ?  menuData.grades.filter((g) => g.id !== grade.id)
            : [... menuData.grades, grade];

            setMenuData({
                ...menuData,
                grades : updatedGrades
            });
        }
    }

    public handleGetAllDates(setTodaysMenus : Function, datesSearch? : DatePickerMultiT){
        
        if(datesSearch){
            const {startDate, endDate} = datesSearch;
            const todaysMenuList : TodaysMenuM[] = [];
            let currentDate = new Date(startDate);
            
            while (currentDate <= endDate) {
                const todayMenuItem = TodaysMenuM.initial();
                todayMenuItem.date = new Date(currentDate);
                todayMenuItem.weekday = format(currentDate, 'iiii', { locale: ptBR }).slice(0,3).toUpperCase();
                todaysMenuList.push(todayMenuItem);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            setTodaysMenus(todaysMenuList);
            this.dispatch(foodSetTodaysMenusForm({count: 5, rows : todaysMenuList}))
        }
    }

    public handleBack(){
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.MENU));
    }

    public async handleSearchGrades(setGradesData : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.gradeController.getAllByInstitute().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setGradesData(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async handleSearchFoodTypes(todaysMenusForm : {count : number, rows : TodaysMenuM[]}, setSelectedTodaysMenuDate : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.foodTypeController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                const newTodaysMenus : {count : number, rows : TodaysMenuM[]}  = {count : todaysMenusForm.count, rows: []};
                                
                todaysMenusForm.rows.map((todayMenu=>{
                    data.rows.map((foodType : FoodTypeM) =>{
                        const fullMeal = FullMealM.initial();
                        fullMeal.foodType = foodType;
                        fullMeal.foodTypeId = foodType.id;
                        todayMenu.fullmeals?.push(fullMeal);
                    })
                    newTodaysMenus.rows.push(todayMenu);
                }));

                this.dispatch(foodSetTodaysMenusForm(newTodaysMenus));
                setSelectedTodaysMenuDate(newTodaysMenus.rows[0].date)
                           
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

}