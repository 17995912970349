import { Dispatch } from "redux";
import { settingsSetTabSelectdItem, settingsSetTabSelectedContent } from "../../../../features/settings-slice.feature";
import { settingsTabToContent } from "../../../../features/types/settings-slice.type";

export class HomeService{
    dispatch : Dispatch;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
    }
    
    public handleSetSelectedTab(tabSelectedItem : string){
        this.dispatch(settingsSetTabSelectdItem(tabSelectedItem));
        this.dispatch(settingsSetTabSelectedContent(settingsTabToContent[tabSelectedItem]))
    }
}
