import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { TabAddStudentResponsibleContainer, TabAddStudentResponsibleContent, TabAddStudentResponsibleResponsible, TabAddStudentResponsibleResponsibleContainer } from "./styled/tab-add-student-responsible.styled";
import { Input } from "../../components/input.component";
import { ButtonPrimary } from "../../components/button-primary.component";
import { useEffect, useState } from "react";
import { TabAddStudentResponsiblesService } from "./services/tab-add-student-responsibles.service";
import { UserResponsibleM } from "../../../models/user-responsible.model";
import { formatCPF } from '../../../utils/config';


export const TabAddStudentResponsiblePage = () => {
    const dispatch = useDispatch();
    const studentReducer = useAppSelector(state => state.studentReducer);
    const [inputDisableds, setInputDisables] = useState([true, true]);
    const [passwords, setPasswords] = useState(['', '']);
    const [reload, setReload] = useState(false);
    const [responsiblesData, setResponsiblesData] = useState<{count : number, rows : UserResponsibleM[]}>();

    const tabAddStudentResposibleService = new TabAddStudentResponsiblesService(dispatch);

    useEffect(()=>{
        tabAddStudentResposibleService.handleGetResponsibles(
            studentReducer.studentForm.student.id!, setResponsiblesData, setInputDisables
        );
    }, [reload]);

    return(
        <TabAddStudentResponsibleContainer>
            <h2 className='primaryBlackSemibold28-120'>Informações dos responsáveis</h2> 
            <TabAddStudentResponsibleContent>
                {
                    Array.from({length : 2}).map((_, index : number)=>(
                        <TabAddStudentResponsibleResponsibleContainer>
                            <TabAddStudentResponsibleResponsible>
                                <Input
                                    className='input-form'
                                    id={`cpf-${index}`}
                                    name={`cpf-${index}`}
                                    type='text'
                                    label={`CPF do Responsável ${index+1}`}
                                    placeholder={`CPF do Responsável ${index+1}`}
                                    value={formatCPF(responsiblesData?.rows[index]?.cpf || '')}
                                    onChange={(value) => {tabAddStudentResposibleService.handleChangeCPF(
                                        value, index, setResponsiblesData, setInputDisables, responsiblesData, inputDisableds
                                    )}}
                                />

                                <Input
                                    className='input-form'
                                    id={`name-${index}`}
                                    name={`name-${index}`}
                                    disabled={inputDisableds[index]}
                                    type='text'
                                    label={`Nome do Responsável ${index+1}`}
                                    placeholder={`Nome do Responsável ${index+1}`}
                                    value={responsiblesData?.rows[index]?.responsible?.name}
                                    onChange={(value) => {tabAddStudentResposibleService.handleChangeName(value, index, setResponsiblesData, responsiblesData)}}
                                />

                                <Input
                                    className='input-form'
                                    id={`email-${index}`}
                                    name={`email-${index}`}
                                    disabled={inputDisableds[index]}
                                    type='email'
                                    label={`Email do Responsável ${index+1}`}
                                    placeholder={`Email do Responsável ${index+1}`}
                                    value={responsiblesData?.rows[index]?.email}
                                    onChange={(value) => {tabAddStudentResposibleService.handleChangeEmail(value, index, setResponsiblesData, responsiblesData)}}
                                />

                                <Input
                                    className='input-form'
                                    id={`password-${index}`}
                                    name={`password-${index}`}
                                    disabled
                                    type='text'
                                    label={`Senha gerada para o Responsável ${index+1}`}
                                    placeholder={`Senha gerada para o Responsável ${index+1}`}
                                    value={passwords[index] ? passwords[index] : '******'}
                                    onChange={()=>{}}
                                />

                                <ButtonPrimary
                                    disabled={inputDisableds[index]}
                                    onClick={()=>tabAddStudentResposibleService.handleGeneratePassword(index, passwords, setPasswords)}
                                >
                                    Gerar senha para acesso ao app
                                </ButtonPrimary>
                                
                            </TabAddStudentResponsibleResponsible>
                        </TabAddStudentResponsibleResponsibleContainer>
                    ))
                }

                <ButtonPrimary 
                    className="submitButton"
                    disabled={tabAddStudentResposibleService.handleSaveIsDisables(responsiblesData?.rows)}
                    onClick={()=>{tabAddStudentResposibleService.handleSubmit(studentReducer.studentForm.student, responsiblesData!.rows, passwords, setPasswords, reload, setReload)}}
                >
                    Salvar informações
                </ButtonPrimary>
            </TabAddStudentResponsibleContent>
        </TabAddStudentResponsibleContainer>
    );
}