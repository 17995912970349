import styled from "styled-components";

export const HomeContainer = styled.div`
    padding: 48px;
    width: 100%;

    h2{
        margin-bottom: 24px;
    }

    @media screen and (max-width: 1400px){
        padding: 36px;
        h2{
            margin-bottom: 18px;
        }
    }
`;