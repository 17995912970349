import { StudentM } from "../models/student.model";
import { TodaysMenuM } from "../models/todays-menu.model";

export class TodaysMenuController {

    async getByStudent(student : StudentM){
        return await TodaysMenuM.getByStudent(student);
    }
    
    async getByDateRange(dateStart : Date, dateEnd : Date){
        return await TodaysMenuM.getByDateRange(dateStart, dateEnd);
    }

    async createAndUpdateList(todaysMenus : TodaysMenuM[]){
        if(todaysMenus[0].id){
            return await TodaysMenuM.updateList(todaysMenus);
        }
        return await TodaysMenuM.createList(todaysMenus);
    }
}