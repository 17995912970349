import { Dispatch } from "redux";
import { UserSchoolWorkerController } from "../../../../controllers/user-school-worker.controller";
import { alertInitialValue, generalSetAlertDialog, generalSetIsLoading, generalSetUserSchoolWorker } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { clearCPF, formatCPF, generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { tabAdminSetSchoolWorkerForm, tabAdminSetSchoolWorkersData } from "../../../../features/user-school-worker-slice.feature";
import { UserSchoolWorkerM } from "../../../../models/user-school-worker.model";
import { baseUrl } from "../../../../api/api";
import { SchoolWorkerI } from "../../../../models/interfaces/school-worker.interface";
import { ChangeEvent } from "react";

export class TabAdminService {
    private static instance: TabAdminService | null = null;

    private dispatch: Dispatch;
    private usersController: UserSchoolWorkerController;

    private constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.usersController = new UserSchoolWorkerController();
    }

    public static getInstance(dispatch: Dispatch) {
        if (!TabAdminService.instance) {
            TabAdminService.instance = new TabAdminService(dispatch);
        }
        return TabAdminService.instance;
    }

    public handleGetSchoolWorkers(instituteId: number) {
        this.dispatch(generalSetIsLoading(true));
        this.usersController.getAllByInstitute(instituteId).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(tabAdminSetSchoolWorkersData(data));
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }


    public handleAdd() {
        this.dispatch(generalSetIsLoading(true));
        this.dispatch(tabAdminSetSchoolWorkerForm(UserSchoolWorkerM.initial()));
        this.dispatch(generalSetIsLoading(false));
    }

    private handleDeleteItem(userId : number){
        this.dispatch(generalSetIsLoading(true));
        this.usersController.delete(userId).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                showToast(this.dispatch, {
                    type: toastTypes.SUCCESS,
                    text: 'Administrador excluído com sucesso.',
                });
                this.handleGetSchoolWorkers(1)
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }

        }).finally(()=>{
            this.dispatch(generalSetAlertDialog(alertInitialValue));
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleShowAlertExcluirItem(user : UserSchoolWorkerM){
        this.dispatch(generalSetAlertDialog({
            title : 'Excluir Item',
            text : `O administrador “${user.schoolWorker?.name}” será excluído do sistema. Tem certeza que deseja continuar?`,
            isOpen : true,
            action : {
                text : 'Excluir Administrador',
                onClick : ()=>{
                    this.handleDeleteItem(user.id!);
                    this.dispatch(tabAdminSetSchoolWorkerForm(UserSchoolWorkerM.initial()))
                }
            }
        }));
    }


    public handleEdit(user: UserSchoolWorkerM) {
        this.dispatch(generalSetIsLoading(true));
        this.dispatch(tabAdminSetSchoolWorkerForm({ ...user }));
        this.dispatch(generalSetIsLoading(false));
    }


    public handleChangeName(value: string, usersForm: UserSchoolWorkerM) {

        const schoolWorker: SchoolWorkerI = {
            id: usersForm.schoolWorker!.id,
            office: usersForm.schoolWorker!.office,
            userId: usersForm.schoolWorker!.userId,
            name: value,
            registrationCode: usersForm.schoolWorker!.registrationCode
        }


        const form: UserSchoolWorkerM = {
            ...usersForm,
            schoolWorker: schoolWorker
        }

        this.dispatch(tabAdminSetSchoolWorkerForm(form));
    }

    public handleChangeEmail(value: string, usersForm: UserSchoolWorkerM) {

        this.dispatch(tabAdminSetSchoolWorkerForm({
            ...usersForm,
            email: value
        }));
    }

    public handleChangeCPF(value: string, usersForm: UserSchoolWorkerM) {
        const cleanedValue = value.replace(/\D/g, '');
        this.dispatch(tabAdminSetSchoolWorkerForm({
            ...usersForm,
            cpf: formatCPF(cleanedValue)
        }));
    }

    public handleChangeOffice(value: string, usersForm: UserSchoolWorkerM) {

        const schoolWorker: SchoolWorkerI = {
            id: usersForm.schoolWorker!.id,
            office: value,
            userId: usersForm.schoolWorker!.userId,
            name: usersForm.schoolWorker!.name,
            registrationCode: usersForm.schoolWorker!.registrationCode
        }


        const form: UserSchoolWorkerM = {
            ...usersForm,
            schoolWorker: schoolWorker
        }

        this.dispatch(tabAdminSetSchoolWorkerForm(form));
    }

    public handleChangePassword(value: string, usersForm: UserSchoolWorkerM) {


        const form: UserSchoolWorkerM = {
            ...usersForm,
            newPassword: value
        }

        this.dispatch(tabAdminSetSchoolWorkerForm(form));
    }


    public handleChangeImage(
        event: ChangeEvent<HTMLInputElement>,
        usersForm: UserSchoolWorkerM
    ) {

        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                console.log('reader')
                if (reader.result) {
                    const media = { ...usersForm.profilePic, content: reader.result.toString(), type: 'base64' };
                    this.dispatch(tabAdminSetSchoolWorkerForm({
                        ...usersForm,
                        profilePic: media

                    }));
                }
            };
            reader.readAsDataURL(file);
        }
    }


    private convertBlobToBase64(blob: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };

    public async convertImageToBase64(announcement: UserSchoolWorkerM) {
        try {
            const response = await fetch(`${baseUrl}/${announcement.profilePic?.content}`);
            const blob = await response.blob();
            const base64 = await this.convertBlobToBase64(blob) as string;
            this.dispatch(tabAdminSetSchoolWorkerForm({
                ...announcement,
                profilePic: { type: 'base64', content: base64 }
            }));
        } catch (error) {
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: 'Erro ao recuperar a imagem.',
            });
        }
    };

    private validatePassword(password: string) {

        const rDig = /(?=.*?\d)/;              // deve conter ao menos um dígito
        const rLower = /(?=.*[a-z])/;         // deve conter ao menos uma letra minúscula
        const rUpper = /(?=.*[A-Z])/;          // deve conter ao menos uma letra maiúscula
        const rSpecial = /(?=.*[#$@!%&*?])/;       // deve conter ao menos um caractere especial

        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;

        if (!rDig.test(password)) {
            throw new Error("Senha deve possuir um digito numérico!");
        }
        if (!rUpper.test(password)) {
            throw new Error("Senha deve possuir uma letra maiúscula");
        }
        if (!rLower.test(password)) {
            throw new Error("Senha deve possuir uma letra minúscula");
        }
        if (!rSpecial.test(password)) {
            throw new Error("Senha deve possuir algum caractere especial ($*&@#)");
        }
        if (!(password.length >= 8)) {
            throw new Error("Senha deve ter no minimo 8 caractéres");
        }

        return regex.test(password);
    }

    private validateUserForm(userForm: UserSchoolWorkerM) {
        const fieldsErrors = [];


        if (!userForm.schoolWorker!.name) {
            fieldsErrors.push('Nome');
        }

        if (!userForm.schoolWorker!.office) {
            fieldsErrors.push('Cargo');
        }

        if (!userForm.email) {
            fieldsErrors.push('Email');
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        if (userForm.newPassword && userForm.newPassword.length > 0) {

            try {
                this.validatePassword(userForm.newPassword)

            } catch (error) {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: (error as Error).message,
                });
                return false;
            }
        }

        return true;
    }

    public handleSubmit(userForm: UserSchoolWorkerM) {
        if (this.validateUserForm(userForm)) {
            if (userForm.id === -1) {
                userForm = { ...userForm };
                delete userForm.id;
            }

            if (userForm.newPassword) {
                userForm = { ...userForm, password: userForm.newPassword };
                delete userForm.newPassword;
            }

            if (userForm.groups) {
                userForm = { ...userForm, groups: [] };
                delete userForm.groups;
            }

            if(!userForm.profilePic?.content) {
                userForm = { ...userForm, profilePic: undefined };
                delete userForm.profilePic
            }

            this.dispatch(generalSetIsLoading(true));
            this.usersController.createAndUpdate({...userForm, cpf :  clearCPF(userForm.cpf || '')}).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Usuario atualizado com sucesso',
                    });
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            })
        }

    }


}