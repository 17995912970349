import { handleRequest } from "../api/api-request.service";
import { GroupI } from "./interfaces/group.interface";
import { MediaI } from "./interfaces/media.interface";
import { ResponsibleI } from "./interfaces/responsible.interface";
import { SchoolWorkerI } from "./interfaces/school-worker.interface";
import { UserI } from "./interfaces/user.interface";
import { ResponsibleM } from "./responsible.model";
import { SchoolWorkerM } from "./school-worker.model";

export class UserM implements UserI {
    id?: number;
    login?: string;
    cpf?: string;
    email?: string;
    password: string;
    newPassword?: string;
    instituteId: number;
    profilePic?: MediaI;
    groups?: GroupI[];
    schoolWorker?: SchoolWorkerI;
    responsible?: ResponsibleI;

    constructor({
        id,
        login,
        cpf,
        email,
        password,
        instituteId,
        profilePic,
        groups,
        schoolWorker,
        responsible,
    }: UserI) {
        this.id = id;
        this.login = login;
        this.cpf = cpf;
        this.email = email;
        this.password = password;
        this.instituteId = instituteId;
        this.profilePic = profilePic;
        this.groups = groups;
        this.schoolWorker = schoolWorker;
        this.responsible = responsible;
    }

    static initial() : UserM{
        return new UserM({
            login: '',
            cpf: '',
            email: '',
            password: "",
            instituteId: 0,
            groups: [],
            // student: StudentM.initial(),
            // schoolWorker: SchoolWorkerM.initial(),
            // responsible: ResponsibleM.initial()
        });
    }

    static async getAllByInstitute(instituteId: number, search?: string) {

        const query = `?offset=0&limit=20`;

        return handleRequest(
            `user/by/institute/${instituteId}${query}`,
            'GET'
        )
    }


    static async update(schoolWorker: UserM) {
        return handleRequest(
            `user/${schoolWorker.id}`,
            'PUT',
            schoolWorker
        );
    }

    static async create(schoolWorker: UserM) {
        return handleRequest(
            `user`,
            'POST',
            schoolWorker
        );
    }

    static async getByid(userId: number) {
        return handleRequest(
            `user/${userId}`,
            'GET'
        );
    }

    static async delete(userId: number) {
        return handleRequest(
            `user/${userId}`,
            'DELETE'
        );
    }
}
