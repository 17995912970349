import React from 'react';
import { PageCountT } from './types/page-count.type';
import { PageCountContainer, PageCountItem } from './styles/page-count.styled';

export const PageCount = ({size, current, disabled} : PageCountT) => {
  return (
    <PageCountContainer className='primaryPrimarySemibold14-120'>
        
        {Array.from({length: size}).map((_, index)=>(
            <PageCountItem className={`${index+1 === current && 'active'} ${index+1 === disabled && 'disabled'}`}>
                {index+1}
            </PageCountItem>
        ))}
        
    </PageCountContainer>
  );
};