import React, { useEffect } from 'react';
import { FoodHomeContainer } from './styles/home.styled';
import { TabBar } from '../../components/tab-bar.component';
import { HomeService } from './services/home.service';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { foodContents, foodTabAdmList, foodTabStudentList, foodTabTeatcherList } from '../../../features/types/food-slice.type';
import { LocalToken } from '../../../utils/local-token';
import { LoginUserType } from '../../../utils/config';

export const FoodPage = () => {

    const dispatch = useDispatch();
    const foodReducer = useAppSelector((state) => state.foodReducer);
    const generalReducer = useAppSelector((state) => state.generalReducer);
    const homeService = new HomeService(dispatch, generalReducer.userSchoolWorker);
    const userType = LocalToken.getStoredUserType();

    useEffect(() => {
        if (userType === LoginUserType.TEACHER){
            if(generalReducer.userSchoolWorker?.groups?.find(group=>group.name === "SCHOOL_TEACHER")){
                homeService.handleSetSelectedTab('Cardápio');
            }else{
                homeService.handleSetSelectedTab('Avaliação dos alunos');
            }
            
        }
    }, []);

    return (
        <FoodHomeContainer>
            <h2 className='primaryBlackSemibold28-120'>Alimentação</h2>
            <span className='primaryBlackNormal20-120'>
                {
                    userType === LoginUserType.STUDENT ?
                        'Aqui você pode avaliar a alimentação de hoje' :
                        'Gerencie o cardápio da alimentação geral e avaliações dos alunos'
                }
            </span>

            {userType === LoginUserType.TEACHER ?
                <>

                    {generalReducer.userSchoolWorker?.groups?.find(group => group.name === "SCHOOL_TEACHER") ?
                        <TabBar
                            setSelectedTab={(tabSelectedItem) => homeService.handleSetSelectedTab(tabSelectedItem)}
                            selected={foodReducer.tabSelectedItem}
                            items={foodTabTeatcherList}
                        /> :
                        <TabBar
                            setSelectedTab={(tabSelectedItem) => homeService.handleSetSelectedTab(tabSelectedItem)}
                            selected={foodReducer.tabSelectedItem}
                            items={foodTabAdmList}
                        />
                    }
                </> :
                <TabBar
                    setSelectedTab={(tabSelectedItem) => homeService.handleSetSelectedTab(tabSelectedItem)}
                    selected={foodReducer.tabSelectedItem}
                    items={foodTabStudentList}
                />
            }

            {foodContents[foodReducer.tabSelectedContent]}

        </FoodHomeContainer>
    );
};