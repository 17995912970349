import { SelectConsumableModalContainer, SelectConsumableModalContent, SelectConsumableModalForm, SelectConsumableModalNutritionalCategoryAddButton, SelectConsumableModalNutritionalCategoryContainer, SelectConsumableModalNutritionalCategoryItem, SelectConsumableModalTitle } from "./styles/select-cosumable-modal.styled";
import close_icon from '../../../assets/icons/close.svg';
import { TabBar } from "../../components/tab-bar.component";
import { useAppSelector } from "../../../hooks";
import { useDispatch } from "react-redux";
import { SelectCosumableModalService } from "./services/select-cosumable-modal.service";
import { NutritionalCategoryM } from "../../../models/nutritional-category.model";
import { TabAddNewCosumablePage } from "./tab-add-new-consumable.page";
import { useState } from "react";
import { TabSelectConsumablePage } from "./tab-select-consumable.page";

export const SelectConsumableModalPage = () => {
    const tabItems = ['Criar novo alimento', 'Catálogo de alimentos'];
    const [selectedTabItems, setSelectedTabItems] = useState(tabItems[0]);
    const foodReducer = useAppSelector(state=>state.foodReducer);
    const dispatch = useDispatch();
    const selectConsumableModalService = new SelectCosumableModalService(
        dispatch, foodReducer.consumableForm
    );

    return (
        <SelectConsumableModalContainer>
            <SelectConsumableModalContent>
                <SelectConsumableModalTitle>
                    <h2 className="primaryBlackSemibold24-150">Escolher Alimento</h2>
                    <div className="close-button" onClick={()=>selectConsumableModalService.handleSetShowConsumableModal(false)}>
                        <img src={close_icon} alt="Icone de fechar"/>
                    </div>
                </SelectConsumableModalTitle>

                <TabBar
                    items={tabItems}
                    selected={selectedTabItems}
                    setSelectedTab={(value)=>{setSelectedTabItems(value)}}
                />

                {selectedTabItems === tabItems[0] ?
                    <TabAddNewCosumablePage/>  :
                    <TabSelectConsumablePage/>
                }
            </SelectConsumableModalContent>
        </SelectConsumableModalContainer>
    );
};