import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnnouncementM } from '../models/announcements.model';
import { AnnouncementsDataT, AuthorDataT, TabAnnouncementsStateT } from './types/tab-announcements-slice.type';

const initialState : TabAnnouncementsStateT = {
    announcementsForm: AnnouncementM.initial()
} 

export const announcementsSlice = createSlice({
   name: 'tab-announcements',
   initialState,
   reducers: {
        tabAnnouncementsSetAuthorsData: (state, action : PayloadAction<AuthorDataT>)=>{
            state.authorsData = action.payload;
        },        

        tabAnnouncementsSetAnnouncementsData: (state, action : PayloadAction<AnnouncementsDataT>)=>{
            state.announcementsData = action.payload;
        },

        tabAnnouncementsForm(state, action: PayloadAction<AnnouncementM>) {
            state.announcementsForm = action.payload;
        }
       
   } 
});

export const { 
    tabAnnouncementsSetAuthorsData,
    tabAnnouncementsSetAnnouncementsData,
    tabAnnouncementsForm
} = announcementsSlice.actions;

export const tabAnnouncementsReducer = announcementsSlice.reducer;