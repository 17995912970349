import { Dispatch } from "redux";
import { generalSetToast } from "../features/general-slice.feature";
import { ToastValueT } from "../features/types/general-slice.type";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";


import face_confused_icon from '../assets/icons/face_confused.svg';
import face_happy_icon from '../assets/icons/face_happy.svg';
import face_sad_icon from '../assets/icons/face_sad.svg';
import face_smiling_icon from '../assets/icons/face_smiling.svg';

export const emotions = [
    {
        descriptionConsumable : "não comi", 
        descriptionDrink : "não bebi", 
        icon : face_confused_icon
    },
    {
        descriptionConsumable : "comi pouco", 
        descriptionDrink : "bebi pouco", 
        icon : face_sad_icon
    },
    {
        descriptionConsumable : "comi quase tudo", 
        descriptionDrink : "bebi quase tudo", 
        icon : face_smiling_icon
    },
    {
        descriptionConsumable : "comi tudo",
        descriptionDrink : "bebi tudo", 
        icon : face_happy_icon
    },
];

export const LoginUserType = {
    STUDENT : "STUDENT",
    TEACHER : "TEACHER"
}

export const ordersStatus = {
    IN_PRODUCTION : "Em produção",
    CONCLUDED : "Finalizado",
    CANCELED : "Cancelado"
}

export function dateToString(date : Date) {
    const formattedDate = format(date, "EEEE dd/MM/yyyy", { locale: ptBR });
    const capitalizedDate = formattedDate.replace(
        /^([a-z\u00E0-\u00FC])/, 
        match => match.toUpperCase()
    );

    return capitalizedDate;
}

export function formattedPrice(amountPrice : number, type?: string){
    if(type === 'decimal'){
        return amountPrice.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2 
        });
    }

    return amountPrice.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
    });
}

export function showToast(dispatch : Dispatch, content : ToastValueT){
    dispatch(generalSetToast(content));

    setTimeout(() => {
        dispatch(generalSetToast({ text: "", type: "" }));
    }, 5000);
}

export function generateErrorMessage(fieldsErrors: string[], item?: string) {
    let errorMessage = 'O';

    if (fieldsErrors.length > 1) {
        errorMessage += 's';
    }

    errorMessage += ' campo';

    if (fieldsErrors.length > 1) {
        errorMessage += 's';
    }

    if (fieldsErrors.length > 1) {
        const lastItem = fieldsErrors.pop();
        errorMessage += ` ${fieldsErrors.join(', ')} e ${lastItem} são obrigatórios`;
        fieldsErrors.push(lastItem!);
    } else {
        errorMessage += ` ${fieldsErrors[0]} é obrigatório`;
    }

    if(item){
        errorMessage += ` ${item}`;
    }

    return errorMessage;
}

export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatCPF(cpf: string): string {
    return cpf
        .replace(/^(\d{3})(\d)/, '$1.$2')
        .replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d{1,2})/, '$1.$2.$3-$4')
        .replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})\d+/, '$1.$2.$3-$4');
}

export function clearCPF(cpf:string):string{
    return cpf.replaceAll('.', '').replaceAll('-', '');
}