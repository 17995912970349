import React, { useEffect, useState } from 'react';
import search_icon from '../../../assets/icons/search.svg';
import { Input } from '../../components/input.component';
import { Dropdown } from '../../components/dropdown.component';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { FoodTypeM } from '../../../models/food-type.model';
import { TabStudentEvaluationConsumableButtonBar, TabStudentEvaluationConsumableContainer, TabStudentEvaluationConsumableItem, TabStudentEvaluationConsumableList, TabStudentEvaluationConsumableOptionItem, TabStudentEvaluationConsumableOptions, TabStudentEvaluationConsumableTop } from './styles/tab-student-evaluation-consumable.styled';
import { TabStudentEvaluationConsumableService } from './services/tab-student-evaluation-consumable.service';
import { ActionBack } from '../../components/action-back.component';
import { PageCount } from '../../components/page-count.component';
import arrow_right_icon from '../../../assets/icons/arrow_right.svg';
import { ButtonPrimary } from '../../components/button-primary.component';
import { TodaysMenuM } from '../../../models/todays-menu.model';
import { baseUrl } from '../../../api/api';
import { MealConsumableM } from '../../../models/meal-consumable.model';

export const TabStudentEvaluationConsumablePage = () => {

    const [todayMenu, seTodayMenu] = useState<TodaysMenuM>();
    const [consumables, setConsumables] = useState<MealConsumableM[]>([]);
    const [drinks, setDrinks] = useState<MealConsumableM[]>([]);
   
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);
    const tabFoodStudentEvaluationService = new TabStudentEvaluationConsumableService(dispatch);
    
    useEffect(()=>{
        if(todayMenu){
            tabFoodStudentEvaluationService.handleGetConsumables(todayMenu, setConsumables, setDrinks, foodReducer.evaluationSelectedFoodType);
        }
    }, [todayMenu]);

    useEffect(()=>{
        tabFoodStudentEvaluationService.handleGetTodayMenu(seTodayMenu, foodReducer.evaluationSelectedFoodType, foodReducer.evaluationSelectedStudent);
        console.log(foodReducer.evaluationSelectedStudent)
    }, []);

    return (
        <TabStudentEvaluationConsumableContainer>
            <ActionBack onClose={()=>{tabFoodStudentEvaluationService.handleBack()}}/>
            <TabStudentEvaluationConsumableTop>
                <span className='primaryBlackSemibold24-120'>O que você comeu hoje?</span>
                <PageCount size={5} current={2} disabled={drinks.length === 0 ? 4 : undefined}/>
            </TabStudentEvaluationConsumableTop>
            
            <TabStudentEvaluationConsumableOptions>
                <TabStudentEvaluationConsumableOptionItem className={drinks.length > 0 ? 'middle' : ''}>
                    <span className='primaryBlackMedium24-120'>Alimentos oferecidos</span>
                    <TabStudentEvaluationConsumableList>
                        {
                            consumables.map(consumable=>(
                                <TabStudentEvaluationConsumableItem 
                                    className={foodReducer.evaluationSelectedConsumables.consumables.find(selectedConsumable=>consumable.id === selectedConsumable.mealConsumable.id) ? 'active' : ''}
                                    onClick={()=>tabFoodStudentEvaluationService.handleSelectConsumable(
                                        foodReducer.evaluationSelectedStudent?.id!, consumable, foodReducer.evaluationSelectedConsumables
                                    )}
                                >
                                    <img src={`${baseUrl}/${consumable.media?.content}`} alt="Imagem do alimento"/>
                                    <span className='primaryBlackSemibold20-120'>{consumable.name}</span>
                                </TabStudentEvaluationConsumableItem>
                            ))
                        }
                    </TabStudentEvaluationConsumableList>
                </TabStudentEvaluationConsumableOptionItem>
                {
                    drinks.length > 0 &&
                    <TabStudentEvaluationConsumableOptionItem>
                        <span className='primaryBlackMedium24-120'>Bebidas oferecidas</span>
                        <TabStudentEvaluationConsumableList>
                            {
                                drinks.map(drink=>(
                                    <TabStudentEvaluationConsumableItem 
                                        className={foodReducer.evaluationSelectedConsumables.drinks.find(selectedConsumable=>drink.id === selectedConsumable.mealConsumable.id) ? 'active' : ''}
                                        onClick={()=>tabFoodStudentEvaluationService.handleSelectDrink(
                                            foodReducer.evaluationSelectedStudent?.id!, drink, foodReducer.evaluationSelectedConsumables
                                        )}
                                    >
                                        <img src={`${baseUrl}/${drink.media?.content}`} alt="Imagem do alimento"/>
                                        <span className='primaryBlackSemibold20-120'>{drink.name}</span>
                                    </TabStudentEvaluationConsumableItem>
                                ))
                            }
                        </TabStudentEvaluationConsumableList>
                    </TabStudentEvaluationConsumableOptionItem>
                }
            </TabStudentEvaluationConsumableOptions>

            <TabStudentEvaluationConsumableButtonBar>
                <ButtonPrimary 
                    disabled={!foodReducer.evaluationSelectedFoodType}
                    onClick={tabFoodStudentEvaluationService.handleOpenEvaluationEvaluation}
                >
                    Continuar
                </ButtonPrimary>
            </TabStudentEvaluationConsumableButtonBar>
        </TabStudentEvaluationConsumableContainer>
    );
};