import React, { useEffect, useState } from 'react';
import search_icon from '../../../assets/icons/search.svg';
import plus_icon from '../../../assets/icons/plus.svg';
import { useDispatch } from 'react-redux';
import { Input } from '../../components/input.component';
import { Dropdown } from '../../components/dropdown.component';
import { ButtonPrimary } from '../../components/button-primary.component';
import { Table } from '../../components/table.component';
import { OptionsMenu } from '../../components/options-menu.component';
import { ButtonSecondary } from '../../components/button-secondary.component';
import { TabMenuButtonBar, TabMenuContainer, TabMenuSearchBar, TabMenuTitle } from './styles/tab-menu.styled';
import { ProductCategoryI } from '../../../models/interfaces/product-category.interface';
import { TabMenuService } from './services/tab-menu.service';
import { baseUrl } from '../../../api/api';
import { ButtonSwitch } from '../../components/button-switch.component';
import { useAppSelector } from '../../../hooks';
import { formattedPrice } from '../../../utils/config';
import { AddCategoryModal } from './add-category-modal.page';

export const TabMenuPage = () => {
    const tabMenuReducer = useAppSelector(state => state.tabMenuReducer);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedProductCategory, setSelectedProductCategory] = useState<ProductCategoryI>();
    const dispatch = useDispatch();

    const tabMenuService = TabMenuService.getInstance(dispatch);

    useEffect(()=>{
        tabMenuService.handleGetCategories();
    },[tabMenuService]);

    useEffect(()=>{
        if(!selectedProductCategory && tabMenuReducer.productCategoriesData){
            tabMenuService.handleGetProcductsByCategoryList(
                tabMenuReducer.productCategoriesData.rows, search
            );
        }
    },[tabMenuReducer.productCategoriesData, selectedProductCategory, search]);

    useEffect(()=>{
        if(selectedProductCategory){
            tabMenuService.handleGetProcductsByCategory(
                selectedProductCategory, search
            );
        }
    },[selectedProductCategory, search]);

    return (
        <TabMenuContainer>
            {showAddCategoryModal && <AddCategoryModal handleGetCategories={tabMenuService.handleGetCategories} setShowAddCategoryModal={setShowAddCategoryModal}/>}
            <TabMenuTitle>
                <h2 className='primaryBlackSemibold28-150'>Cardápio</h2> 
                <span className='primaryBlackNormal18-120'>Gerencie por este cardápio todos os itens disponíveis para venda</span>
            </TabMenuTitle>
            <TabMenuSearchBar autoComplete="off">
                <Input
                    className='inputSearch'
                    type='search'
                    name='inputSearch'
                    id='inputSearch'
                    placeholder='Busque pelo nome do item'
                    value={search}
                    onChange={setSearch}
                    prefix={
                        <img src={search_icon} alt="Icone de busca"/>
                    }
                />
                <Dropdown 
                    styleClass='input-style'
                    defaultValue={selectedProductCategory?.id?.toString()}
                    placeholder='Categorias'
                    options={tabMenuService.handleCategoriesToOptions(tabMenuReducer.productCategoriesData?.rows)}
                    onChange={(value)=>setSelectedProductCategory(tabMenuService.handleFindCategoryByValue(value, tabMenuReducer.productCategoriesData?.rows))}
                />
            </TabMenuSearchBar>

            <TabMenuButtonBar>
                <ButtonPrimary onClick={()=>setShowAddCategoryModal(true)}>
                    <img src={plus_icon} alt="Icone de Adicionar"/>
                    Adicionar nova categoria
                </ButtonPrimary>
            </TabMenuButtonBar>
            
            {  tabMenuReducer.productCategoriesData && tabMenuReducer.productCategoriesData.count > 0 ?
                
                tabMenuReducer.productCategoriesData?.rows?.map((category, index)=>{
                const product = tabMenuReducer.productsData?.find(product => product.category === category);
                return product ? (
                    <Table key={`table-product-category-${index}`}>
                        <thead className='primaryBlackSemibold20-150'>
                            <th>
                                {product.category.name}
                                <span className='primaryBlackNormal18-150'>Item</span>
                            </th>
                            
                            <th>
                                <TabMenuButtonBar>
                                    <ButtonSecondary className="add-item" onClick={()=>tabMenuService.handleShowAddItemPage()}>
                                        <img src={plus_icon} alt="Icone de adicionar"/>
                                        Adicionar um item
                                    </ButtonSecondary>
                                    {/* <OptionsMenu options={} className='info-more' /> */}
                                </TabMenuButtonBar>
                            </th>
                        </thead>
                        <tbody>
                            { product.productsList?.count === 0 ?
                                <tr>
                                    <td className='full-line' colSpan={2}>
                                        <span className='primaryBlackNormal20-150'>Nenhum item nesta categoria</span>
                                    </td>
                                </tr> :
                            
                                product.productsList?.rows?.map((product) => (
                                    <tr key={`prosuct-${product.id}`}>
                                        <td className='product-info'>
                                            <img src={`${baseUrl}/${product.media?.content}`} alt={`Imagem do produto ${product.name}`}/>
                                            <div>
                                                <span className='primaryBlackNormal20-150'>
                                                    {product.name}
                                                </span>
                                                <span className='primaryBlackNormal18-150'>
                                                    {product.amountInGrams}g
                                                </span>
                                                <span className='primaryBlackSemibold20-150'>
                                                    {formattedPrice(product.price!)}
                                                </span>
                                            </div>
                                        </td>

                    
                                        <td>
                                            <div className='column-status'>
                                                <ButtonSwitch
                                                    label='Cardápio'
                                                    checked={product.available!}
                                                    onClick={()=>tabMenuService.handleSwitchMenu(product, tabMenuReducer.productCategoriesData!.rows)}
                                                />

                                                <ButtonSwitch
                                                    label='Cód. de Barra'
                                                    checked={product.availableCode!}
                                                    onClick={()=>tabMenuService.handleSwitchBarCode(product, tabMenuReducer.productCategoriesData!.rows)}
                                                />
                                                <OptionsMenu options={tabMenuService.handleGetOptionsProductItem(product,  tabMenuReducer.productCategoriesData!.rows)} className='info-more' />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                        
                    </Table>
                ) : <></>;
            }) : 
            <div className='empty-table mt'>
                <span className='primaryBlackSemibold20-150'>Nenhuma categoria cadastrada</span> 
            </div>
            }
            
        </TabMenuContainer>
    );
};