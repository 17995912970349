import { ModalBackground, ModalBox, ModalHeader, ProfilePic, SectionSeparator, ShowModal, ShowModalContainer, TabSettingsInfoContainer } from "./styles/tab-admin-add-form-styled"

import { KeyboardEventHandler, MouseEventHandler, useState } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../../hooks"
import { ButtonPrimary } from "../../components/button-primary.component"
import { Input } from "../../components/input.component"
import { TabAdminService } from "./services/tab-admin-service"
import { InputSugestion, InputSugestions } from "../announcements/styles/tab-announcements-form.styled"

import logo from "../../../assets/images/logo75x75.png"
import { formatCPF } from "../../../utils/config"

export type ActionModal = {
    onBackground: MouseEventHandler<HTMLDivElement>;
    onKeyEsc: KeyboardEventHandler<HTMLDivElement>;
    onFinish?: Function
}


export const TabAdminAddForm = ({ onBackground, onKeyEsc }: ActionModal) => {


    const tabAdminReducer = useAppSelector(state => state.tabAdminReducer);

    const dispatch = useDispatch();

    const tabAdminService = TabAdminService.getInstance(dispatch);
    const [searchCargo, setSearchCargo] = useState(tabAdminReducer.userForm.schoolWorker?.office ?? '');

    const [searchEdit, setSearchEdit] = useState(false);


    return (
        <ModalBackground onClick={(e) => {
            e.stopPropagation();
            onBackground(e);
        }} onKeyUp={(e) => {
            if (e.code?.toLowerCase().includes('escape')) {
                onKeyEsc(e);
            }
        }}>

            <ModalBox>

                <ShowModalContainer style={{
                }}>
                    <TabSettingsInfoContainer onClick={(e) => e.stopPropagation()} onKeyUp={(e) => {

                    }}>


                        <ModalHeader>
                            Informações do Administrador
                        </ModalHeader>

                        <SectionSeparator />

                        {
                            !tabAdminReducer.userForm.id &&

                            <ShowModal >

                                <ProfilePic className="profilePic" >
                                    {
                                        <img className="upload_img " id="image_file"
                                            src={
                                                ((tabAdminReducer.userForm.profilePic?.content.length ?? 0) > 0 ? tabAdminReducer.userForm.profilePic?.content :
                                                    logo
                                                )

                                            } alt="profile_picture"
                                        />
                                    }
                                    {
                                        <div className='icon '>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-camera-fill" viewBox="0 0 16 16">
                                                <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                            </svg>
                                        </div>
                                    }
                                    <input name="image" type="file" className=""
                                        id="image_file_input" aria-label="Banner"
                                        onChange={(e) => tabAdminService.handleChangeImage(e, tabAdminReducer.userForm)} placeholder="Selecione uma imagem"
                                        accept="image/*" disabled={false} required />
                                </ProfilePic>

                                <Input
                                    className='input-form'
                                    id='name'
                                    name='name'
                                    type='text'
                                    label='Nome do Profissional'
                                    placeholder='Nome do profissional'
                                    value={tabAdminReducer.userForm.schoolWorker?.name ?? ''}
                                    onChange={(value) => tabAdminService.handleChangeName(value, tabAdminReducer.userForm)}
                                />

                                <Input
                                    className='input-form'
                                    id='cpf'
                                    name='cpf'
                                    type='text'
                                    label='CPF do Profissional'
                                    placeholder='CPF do profissional'
                                    value={formatCPF(tabAdminReducer.userForm.cpf ?? '')}
                                    onChange={(value) => tabAdminService.handleChangeCPF(value, tabAdminReducer.userForm)}
                                />

                                <Input
                                    className='input-form'
                                    id='office'
                                    name='office'
                                    type='text'
                                    label='Cargo'
                                    placeholder='Cargo'
                                    value={searchCargo}
                                    onChange={(value) => {

                                        setSearchEdit(true);
                                        setSearchCargo(value);

                                        // tabAdminService.handleChangeOffice(value, tabAdminReducer.userForm)


                                    }}
                                />

                                <InputSugestions id="cargoNameSuggestions" className={`cargo-name-suggestions ${searchCargo.length >= 3 && searchEdit ? 'show' : ''}`}>
                                    {
                                        [
                                            "Administração",
                                            "Secretaria",
                                            "Nutricionista",
                                            "Cantina",
                                            "Professor(a)",
                                        ].filter(function (item, pos, self) {

                                            return item?.toLowerCase().includes(searchCargo?.toLowerCase());

                                        }).map((cargo, i) => {

                                            return (

                                                <InputSugestion className={`${tabAdminReducer.userForm.schoolWorker?.office === cargo ? 'select' : ''}`} onClick={(e) => {
                                                    tabAdminService.handleChangeOffice(cargo, tabAdminReducer.userForm);
                                                    setSearchCargo(cargo)
                                                    setSearchEdit(false)
                                                }} key={"suggestion-" + cargo}>
                                                    {cargo}
                                                </InputSugestion>
                                            )
                                        })
                                    }

                                </InputSugestions>


                                <Input
                                    className='input-form'
                                    id='email'
                                    name='email'
                                    type='email'
                                    label='Email'
                                    placeholder='Email'
                                    value={tabAdminReducer.userForm.email ?? ''}
                                    onChange={(value) => tabAdminService.handleChangeEmail(value, tabAdminReducer.userForm)}
                                />

                                {

                                    <Input
                                        className='input-form'
                                        id='password'
                                        name='password'
                                        type='password'
                                        label='Senha'
                                        placeholder='Para alterar a senha, preencha o campo'
                                        value={tabAdminReducer.userForm.newPassword ?? ''}
                                        onChange={(value) => tabAdminService.handleChangePassword(value, tabAdminReducer.userForm)}
                                    />
                                }

                                <ButtonPrimary className="admin-save-button" onClick={() => {
                                    tabAdminService.handleSubmit(tabAdminReducer.userForm);
                                }}>
                                    Salvar administrador
                                </ButtonPrimary>

                            </ShowModal>
                        }

                    </TabSettingsInfoContainer>
                </ShowModalContainer>

            </ModalBox>

        </ModalBackground>
    )
}