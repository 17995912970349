import { Dispatch } from "redux";
import { UserSchoolWorkerM } from "../../models/user-school-worker.model";
import { AuthController } from "../../controllers/auth.controller";
import { UserSchoolWorkerController } from "../../controllers/user-school-worker.controller";
import { generalSetIsLoading, generalSetUserSchoolWorker } from "../../features/general-slice.feature";
import { responseStatus } from "../../api/api-request.service";
import { LoginUserType, showToast } from "../../utils/config";
import { toastTypes } from "../../features/types/general-slice.type";
import { LocalToken } from "../../utils/local-token";
import { LocalTokenM } from "../../features/types/auth-slice.type";

export class RoutesService{
    dispatch : Dispatch;
    userSchoolWorker? : UserSchoolWorkerM;
    authController : AuthController;
    userSchoolWorkerController : UserSchoolWorkerController;

    constructor(dispatch : Dispatch, userSchoolWorker? : UserSchoolWorkerM){
        this.dispatch = dispatch;
        this.userSchoolWorker = userSchoolWorker;

        this.authController = new AuthController();
        this.userSchoolWorkerController = new UserSchoolWorkerController();
    }

    private async loadUserSchoolWorkerData(userId: string){
        this.dispatch(generalSetIsLoading(true));
        await this.userSchoolWorkerController.getById(userId)
        .then(async response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(generalSetUserSchoolWorker(data));
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: `Token Inválido. Faça login novamente.`,
                });
                setTimeout(()=>{
                    LocalToken.clearStoredToken();
                    window.location.href = '/landing';
                }, 2000);
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async loadAuthenticatedUser(){
        const userType = LocalToken.getStoredUserType();
        if(
            userType === LoginUserType.TEACHER &&
            !this.userSchoolWorker
        ){
            const localToken = new LocalTokenM({
                access : LocalToken.getStoredToken(),
                userType : userType
            });

            this.dispatch(generalSetIsLoading(true));
            await this.authController.verifyToken(localToken)
            .then(async response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    await this.loadUserSchoolWorkerData(data.user.user_id);
                    
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: 'Token Inválido. Faça login novamente.',
                    });
                    LocalToken.clearStoredToken();
                    window.location.href = '/landing';
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }


}