import React, { useEffect, useState } from 'react';
import arrow_left_icon from '../../../assets/icons/arrow_left.svg';
import { useDispatch } from 'react-redux';
import { MenuAddItemContainer, MenuAddItemContent, MenuAddItemFotoInput, MenuAddItemTitle } from './styles/menu-add-item.styled';
import { Select } from '../../components/select.component';
import { Input } from '../../components/input.component';
import { ButtonPrimary } from '../../components/button-primary.component';
import { MenuAddItemService } from './services/menu-add-item.service';
import { useAppSelector } from '../../../hooks';
import { baseUrl } from '../../../api/api';
import { ActionBack } from '../../components/action-back.component';
import { InputPhoto } from '../../components/input-photo.component';

export const MenuAddItemPage = () => {
    const tabMenuReducer = useAppSelector( state=> state.tabMenuReducer);
    const [measureType, setMeasureType] = useState<string>();
    const dispatch = useDispatch();
    const menuAddItemService = new MenuAddItemService(dispatch);

    useEffect(()=>{
        const scrollableElements = document.querySelectorAll('.content');
        scrollableElements.forEach((element) => {
            element.scrollTop = 0;
        });
    },[]);

    useEffect(()=>{
        menuAddItemService.handleGetCategories();
    }, []);

    useEffect(()=>{
        if(tabMenuReducer.productForm.id === -1){
            menuAddItemService.convertImageToBase64(tabMenuReducer.productForm);
        }
    }, []);

    return (
        <MenuAddItemContainer>
            
            <ActionBack onClose={()=>menuAddItemService.handleCloseAddItemPage()}/>

            <MenuAddItemTitle>
                    <h2 className='primaryBlackSemibold24-150'>
                        {!tabMenuReducer.productForm.id ?  "Adicionar" : tabMenuReducer.productForm.id === -1 ? "Duplicar" : "Editar" }{' '} um item
                    </h2>
            </MenuAddItemTitle>

            <MenuAddItemContent autoComplete="off">
                <h2 className='primaryBlackSemibold24-150'>Foto do Item</h2>

                <InputPhoto
                    label="Aparece na listagem e no detalhe do item."
                    srcImage={
                        tabMenuReducer?.productForm?.media?.type === 'external_url' ?
                        `${baseUrl}/${tabMenuReducer.productForm.media?.content}` :
                        tabMenuReducer.productForm.media?.content
                    } 
                    onChange={(e) => menuAddItemService.handleChangeImage(e, tabMenuReducer.productForm)}
                />

                <Select
                    className='input-form'
                    label='Categoria'
                    name='category'
                    id='category'
                    placeholder='Selecione uma categoria'
                    defaultValue={tabMenuReducer.productForm?.type?.id?.toString()}
                    options={menuAddItemService.handleCategoriesToOptions(tabMenuReducer.productCategoriesData?.rows)}                    
                    onChange={(value)=>menuAddItemService.handleChangeCategoryByValue(value, tabMenuReducer.productForm, tabMenuReducer.productCategoriesData?.rows)}
                />

                <Input
                    className='input-form'
                    id='name'
                    name='name'
                    type='text'
                    label='Nome do Item'
                    placeholder='Nome do Item'
                    value={tabMenuReducer.productForm.name}                    
                    onChange={(value)=>menuAddItemService.handleChangeName(value, tabMenuReducer.productForm)}
                />

                <Input
                    className='input-form'
                    id='price'
                    name='price'
                    type='currency'
                    placeholder='Preço'
                    label='Preço'
                    value={tabMenuReducer.productForm.price?.toString()}
                    onChange={(value)=>menuAddItemService.handleChangePrice(value, tabMenuReducer.productForm)}
                />

                <div className="divider"></div>

                <Select
                    className='input-form'
                    label='Tipo de Medida'
                    name='measureType'
                    id='measureType'
                    placeholder='Selecione um tipo de medida'
                    defaultValue={measureType}
                    options={menuAddItemService.handleMeasureTypesToOptions()}                    
                    onChange={(value)=>setMeasureType(value)}
                />

                {measureType && <Input
                    className='input-form'
                    id='amountInGrams'
                    name='amountInGrams'
                    type='number'
                    placeholder={measureType === 'g' ? 'Peso em Gramas' : 'Volume em Mililitros'}
                    label={measureType === 'g' ? 'Peso em Gramas' : 'Volume em Mililitros'}
                    value={tabMenuReducer.productForm.amountInGrams?.toString()}
                    onChange={(value)=>menuAddItemService.handleChangeAmountInGrams(value, tabMenuReducer.productForm)}
                />}

                <Input
                    className='input-form'
                    id='quantity'
                    name='quantity'
                    type='number'
                    label='Quantidade'
                    placeholder='Quantidade'
                    value={tabMenuReducer.productForm.quantity?.toString()}                    
                    onChange={(value)=>menuAddItemService.handleChangeQuantity(value, tabMenuReducer.productForm)}
                />

                <Input
                    className='input-form'
                    id='barCode'
                    name='barCode'
                    type='number'
                    placeholder='Código de Barras'
                    label='Código de Barras'
                    value={tabMenuReducer.productForm.code?.toString()}
                    onChange={(value)=>menuAddItemService.handleChangeBarCode(value, tabMenuReducer.productForm)}
                />

                <ButtonPrimary onClick={()=>menuAddItemService.handleSubmit(tabMenuReducer.productForm, measureType)}>Salvar Item</ButtonPrimary>
            </MenuAddItemContent>

           
        </MenuAddItemContainer>
    );
};
