import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GradeDataT, LoginFormM, LoginStateT, StudentsDataT, LocalTokenM } from './types/auth-slice.type';
import { UserM } from '../models/user.model';

const initialState : LoginStateT = {
    loginForm: LoginFormM.initial(),
    token: LocalTokenM.initial()
} 

export const authSlice = createSlice({
   name: 'login-auth',
   initialState,
   reducers: {
        authSetGradesData: (state, action : PayloadAction<GradeDataT>)=>{
            state.gradesData = action.payload;
        },        

        authSetStudentsData: (state, action : PayloadAction<StudentsDataT>)=>{
            state.studentsData = action.payload;
        },

        authForm(state, action: PayloadAction<LoginFormM>) {
            state.loginForm = action.payload;
        },

        tokenData(state, action: PayloadAction<LocalTokenM>) {
            state.token = action.payload;
        },

        
        userId(state, action: PayloadAction<number>) {
            state.userId = action.payload;
        },

        userData(state, action: PayloadAction<UserM>) {
            state.user = action.payload;
        },
   } 
});

export const { 
    authSetGradesData,
    authSetStudentsData,
    authForm,
    tokenData,
    userId,
    userData
} = authSlice.actions;

export const authReducer = authSlice.reducer;