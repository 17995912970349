import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { responseStatus } from "../../../../api/api-request.service";
import { MealConsumableController } from "../../../../controllers/meal-consumable.controller";
import { FoodTypeController } from "../../../../controllers/food-type.controller";
import { FoodTypeM } from "../../../../models/food-type.model";
import { FoodTodaysMenusFormDataT } from "../../../../features/types/food-slice.type";
import { MealConsumableM } from "../../../../models/meal-consumable.model";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { foodSetShowSelectCosumableModal, foodSetTodaysMenusForm, foodSetTodaysMenusFormData } from "../../../../features/food-slice.feature";

export class TabSelectConsumableService{
    dispatch : Dispatch;
    mealConsumablesController : MealConsumableController;
    foodTypeController : FoodTypeController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.mealConsumablesController = new MealConsumableController();
        this.foodTypeController = new FoodTypeController();
    }

    public handleFoodTypesToOptions(foodTypesList? : FoodTypeM[]){
        const options = foodTypesList?.map(foodType=>(
            {value: foodType.id?.toString()!, content: foodType.name!}
        ));
        
        options?.push({value : '', content : 'Nenhuma'});
        return options;
    }

    public handleFindFoodTypeByValue(value: string, foodTypesList? : FoodTypeM[]){
        return foodTypesList?.find(foodType => foodType.id?.toString() === value);
    }

    public async handleSearchConsumables( setConsumables : Function, foodType?: FoodTypeM, search?: string){
        this.dispatch(generalSetIsLoading(true));
        await this.mealConsumablesController.getAll(foodType, search).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setConsumables(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async handleSearchFoodTypes(setFoodTypes : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.foodTypeController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setFoodTypes(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleSubmit(
        todaysMenusForm: { count: number, rows: TodaysMenuM[] },
        selectedConsumable: MealConsumableM,
        todaysMenusFormData?: FoodTodaysMenusFormDataT,
    ) {
        const { todaysMenuIndex, fullMealIndex, consumableIndex } = todaysMenusFormData!;
        const updatedForm = JSON.parse(JSON.stringify(todaysMenusForm));
        const consumablesUpdated = [...updatedForm.rows[todaysMenuIndex].fullmeals[fullMealIndex].consumables];
    
        if (consumableIndex || consumableIndex === 0) {
            consumablesUpdated[consumableIndex] = selectedConsumable;
        } else {
            consumablesUpdated.push(selectedConsumable);
        }
    
        updatedForm.rows[todaysMenuIndex].fullmeals[fullMealIndex].consumables = consumablesUpdated;
    
        this.dispatch(foodSetTodaysMenusForm(updatedForm));
        this.dispatch(foodSetTodaysMenusFormData(undefined));
        this.dispatch(foodSetShowSelectCosumableModal(false));
    }
    

}