import { InputPhotoContainer } from "./styles/input-photo.style";
import { InputPhotoT } from "./types/input-photo.type";

export const InputPhoto = ({label, srcImage, onChange} : InputPhotoT) => {
    
    return (
        <>
            <label htmlFor={"photo-item"}>
                <span className='primaryBlackNormal20-120'>{label}</span>
                <input 
                    id="photo-item" 
                    type='file' 
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={onChange} 
                    hidden
                />
                <InputPhotoContainer>
                    { srcImage ?
                        <img src={srcImage} alt='Imagem do produto'/> :
                        <span className='primaryGreySemibold18-150'>Clique aqui para adicionar uma imagem</span>
                    }
                </InputPhotoContainer>
            </label>
        </>
    );
};