import React from 'react';
import { TableContainer, TableContent } from './styles/table.styled';
import { TableT } from './types/table.type';

export const Table = ({children, className} : TableT) => {
  return (
    <TableContainer className={className}>
        <TableContent>
            {children}
        </TableContent>
        
    </TableContainer>
  );
};