import { FoodTypeM } from "../models/food-type.model";

export class FoodTypeController {

    async getAll(){
        return await FoodTypeM.getAll();
    }

    async createAndUpdate(category : FoodTypeM){
        if(category.id){
            return await FoodTypeM.update(category);
        }
        return await FoodTypeM.create(category);
    }
    
}