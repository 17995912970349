import styled from "styled-components";

export const AddNutritionalCategoryModalContainer = styled.div`
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-opacity-color);
   
`;

export const AddNutritionalCategoryModalContent = styled.div`
    background-color: var(--white-color);
    width: 532px;
    border: 1px solid var(--grey-light-2-color);
    border-radius: 8px;

    @media screen and (max-width : 1400px){
        width: 399px;
    }
`;

export const AddNutritionalCategoryModalTitle = styled.div`
    padding: 32px 0 16px 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width : 1400px){
        padding: 24px 0 12px 0;
    }

`;

export const AddNutritionalCategoryModalDescription = styled.div`
    margin: 32px 47px;

    @media screen and (max-width : 1400px){
        margin: 24px 35px;
    }
    
`;

export const AddNutritionalCategoryModalActions = styled.div`
    margin: 0 47px;
    button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding:16px;
    }

    @media screen and (max-width : 1400px){
        margin: 0 35px;

        span{
            padding:12px;
        }
    }

`;