import styled from "styled-components";

export const SidebarContainer = styled.div`
    @media screen and (max-width: 900px){
        .close-sidebar{
            width: 50px;

            h2{
                display: none;
            }

            .logo{
                display: block;
                width: 35px;
                margin: 20px 0;
            }

            .sidebar-menu{
                width: 100%;
                ul{
                    width: 100%;

                    .sidebar-menu-item{
                        a{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        span{
                            display: none;
                        }
                    }

                    .logout{
                        
                        a{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: var(--primary-color) !important;
                        }

                        &:hover{
                            a{
                                color: var(--white-color) !important;
                            }
                        }
                    }
                }
            }

            
        }
    }
`;

export const SidebarContent = styled.div`
    width: 250px;
    height: 100vh;
    border-right: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        margin: 46px 0;
    }

    .logo{
        display: none;
    }

    @media screen and (max-width : 1400px){
        width: 200px;

        h2{
            margin: 34px 0;
        }
    }
`;

export const SidebarMenu = styled.nav`
    width: 100%;
    height: 100%;
    
    ul{
        position: relative;
        height: 95%;
        width: 100%;
        .logout{
            width: 100%;
            position: absolute;
            bottom: 10px;
        }
    }

    
`;

export const SidebarMenuItem = styled.li`   
    a{
        width: 100%;
        padding: 16px 32px;
        gap: 12px;
        display: flex;
        align-items: center;
        margin-top: 24px;
        border-radius: 8px;

        &:hover{
            color: var(--white-color) !important;
            background-color: var(--primary-color);

            img{
                filter: brightness(0) invert(1);
            }
        }

        @media screen and (max-width : 1400px){
            padding: 12px 24px;
            gap: 9px;
            margin-top: 18px;
        }
    }

    .active{
        color: var(--white-color) !important;
        background-color: var(--primary-color);

        img{
            filter: brightness(0) invert(1);
        }
    }

    
    
`;