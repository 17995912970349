import styled from "styled-components";

export const GradeStudentsListModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--grey-opacity-color);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const GradeStudentsListModalContent = styled.div`
    height: 96vh;
    width: 654px;
    background-color: var(--white-color);
    border-radius: 8px;
    border: 1px solid #E0E0E0;

    @media screen and (max-width: 1400px){
        width: 490px;
    }
`;

export const GradeStudentsListModalTopBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 43px 16px 43px;
    border-bottom: 1px solid #E0E0E0;

    .close-button{
        cursor: pointer;
    }

    span{
        width: 100%;
        text-align: center;
    }

    @media screen and (max-width: 1400px){
        padding: 23px 32px 12px 32px;
    }
`;

export const GradeStudentsListModalList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 30px 43px;
    overflow-y: scroll;

    @media screen and (max-width: 1400px){
        padding: 23px 32px;
    }
`

export const GradeStudentsListModalListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;

    div{
        gap: 8px;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 1400px){
        height: 32px;
        img{
            width: 18px;
        }
    }
`

export const GradeStudentsListModalListItemInfo = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    background-color: var(--primary-opacity-2-color);
    cursor: pointer;

    @media screen and (max-width: 1400px){
        img{
            width: 18px;
        }
    }
`