import { Dispatch } from "redux";
import { foodSetConsumableForm, foodSetShowAddNutritionalCategoryModal, foodSetShowSelectCosumableModal, foodSetTabSelectedContent, foodSetTodaysMenusForm, foodSetTodaysMenusFormData } from "../../../../features/food-slice.feature";
import { MealConsumableM } from "../../../../models/meal-consumable.model";
import { alertInitialValue, generalSetAlertDialog, generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { ChangeEvent } from "react";
import { NutritionalCategoryM } from "../../../../models/nutritional-category.model";
import { NutritionalCategoryController } from "../../../../controllers/nutritional-category.controller";
import { MealConsumableController } from "../../../../controllers/meal-consumable.controller";
import { FoodTodaysMenusFormDataT, foodContentsNames } from "../../../../features/types/food-slice.type";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { FoodTypeController } from "../../../../controllers/food-type.controller";
import { FoodTypeM } from "../../../../models/food-type.model";

export class TabAddNewConsumableService{
    dispatch : Dispatch;
    consumableForm : MealConsumableM;
    nutritionalCategoryController : NutritionalCategoryController;
    mealConsumableController : MealConsumableController;
    foodTypeController : FoodTypeController;

    constructor(dispatch : Dispatch, consumableForm : MealConsumableM){
        this.dispatch = dispatch;
        this.consumableForm = consumableForm;
        this.nutritionalCategoryController = new NutritionalCategoryController();
        this.mealConsumableController = new MealConsumableController();
        this.foodTypeController = new FoodTypeController();

        this.handleChangeAmountInGrams = this.handleChangeAmountInGrams.bind(this);
        this.handleChangeImage = this.handleChangeImage.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleGetNutritionalCategories = this.handleGetNutritionalCategories.bind(this);
        this.handleSearchFoodTypes = this.handleSearchFoodTypes.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowAlertExcluirItem = this.handleShowAlertExcluirItem.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
    }

    private handleDeleteItem(consumableId : number){
        this.dispatch(generalSetIsLoading(true));
        this.mealConsumableController.delete(consumableId).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(foodSetConsumableForm(MealConsumableM.initial()));
                this.dispatch(foodSetTabSelectedContent(foodContentsNames.FOOD_CATALOG));
                showToast(this.dispatch, {
                    type: toastTypes.SUCCESS,
                    text: 'Alimento excluído com sucesso.',
                });
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }

        }).finally(()=>{
            this.dispatch(generalSetAlertDialog(alertInitialValue));
            this.dispatch(generalSetIsLoading(false));
        });
    }


    public handleShowAlertExcluirItem(consumable : MealConsumableM){
        this.dispatch(generalSetAlertDialog({
            title : 'Excluir Item',
            text : `O item “${consumable.name}” será excluído do sistema. Tem certeza que deseja continuar?`,
            isOpen : true,
            action : {
                text : 'Excluir item',
                onClick : ()=>{
                    this.handleDeleteItem(consumable.id!);
                }
            }
        }));
    }

    public handleChangeCategoryByValue(
        value: string, 
        consumableForm : MealConsumableM,
        foodTypesList? : FoodTypeM[],
        
    ){
        const foodType = foodTypesList?.find(foodType => foodType.id?.toString() === value);
        this.dispatch(foodSetConsumableForm({
            ...consumableForm,
            foodType : foodType!
        }));
    }

    public handleChangeMealTypeByValue(
        value: string, 
        consumableForm : MealConsumableM,
        
    ){
        this.dispatch(foodSetConsumableForm({
            ...consumableForm,
            mealType : value
        }));
    }

    public handleFoodTypesToOptions(foodTypesList? : FoodTypeM[]){
        const options = foodTypesList?.map(foodType=>(
            {value: foodType.id?.toString()!, content: foodType.name!}
        ));
        
        return options;
    }

    public handleMealTypesToOptions(){
        const options = [
            {value: 'consumable', content: 'Comida'},
            {value: 'drink', content: 'Bebida'},

        ];
        return options;
    }

    public async handleSearchFoodTypes(setFoodTypes : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.foodTypeController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setFoodTypes(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleClose(){
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.FOOD_CATALOG));
    }

    public handleSetShowAddNutritionalCategoryModal(status : boolean){
        this.dispatch(foodSetShowAddNutritionalCategoryModal(status));
    }

    public async handleGetNutritionalCategories(setNutritionalCategories: Function){
        this.dispatch(generalSetIsLoading(true));
        await this.nutritionalCategoryController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setNutritionalCategories(data);
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleChangeImage(
        event : ChangeEvent<HTMLInputElement>
    ){
        
        const file = event.target.files?.[0];
        
        if (file) {
            const reader = new FileReader();
            
            reader.onloadend = () => {
                if(reader.result){
                    const media = {...this.consumableForm.media, content : reader.result.toString(), type: 'base64'};
                    this.dispatch(foodSetConsumableForm({
                        ...this.consumableForm,
                        media : media

                    }));
                }
            };
            reader.readAsDataURL(file);
        }
    }    

    public handleChangeName(value : string){
        this.dispatch(foodSetConsumableForm({
            ...this.consumableForm,
            name : value
        }));
    }

    public handleChangeAmountInGrams(value : string){
        this.dispatch(foodSetConsumableForm({
            ...this.consumableForm,
            amountInGrams : parseFloat(value)
        }));
    }


    public selectNutritionalCategories(nutritionalCategorySelected: NutritionalCategoryM) {
        const nutritionalCategoryList = [...this.consumableForm.nutritionalCategories];
        const index = nutritionalCategoryList.findIndex(category => category === nutritionalCategorySelected);
    
        if (index !== -1) {
            nutritionalCategoryList.splice(index, 1);
        } else {
            nutritionalCategoryList.push(nutritionalCategorySelected);
        }

        this.dispatch(foodSetConsumableForm({
            ...this.consumableForm,
            nutritionalCategories : nutritionalCategoryList
        }));
    }

    private validateConsumable(){
        const fieldsErrors = [];

        if(!this.consumableForm.media?.content){
            fieldsErrors.push('Foto');
        }

        if(!this.consumableForm.name){
            fieldsErrors.push('Nome');
        }

        if(!this.consumableForm.amountInGrams){
            fieldsErrors.push('Peso');
        }

        if(this.consumableForm.nutritionalCategories.length === 0){
            fieldsErrors.push('Categoria Nutricional');
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        return true;
    }

    public handleSubmit(
        todaysMenusForm : {count : number, rows : TodaysMenuM[]},
        todaysMenusFormData? : FoodTodaysMenusFormDataT
    ){
        if(this.validateConsumable()){
            this.dispatch(generalSetIsLoading(true));
            this.mealConsumableController.createAndUpdate(
                {...this.consumableForm , foodTypeId : this.consumableForm.foodType.id}
            ).then(response=>{
                const { status, data } = response;
                if(status === responseStatus.SUCCESS){
                    data.media = data.media[0]; 

                    if(todaysMenusFormData){
                        const { todaysMenuIndex, fullMealIndex, consumableIndex } = todaysMenusFormData;
                        const updatedForm = JSON.parse(JSON.stringify(todaysMenusForm));
                        const consumablesUpdated = [...updatedForm.rows[todaysMenuIndex].fullmeals[fullMealIndex].consumables];
                        
                        if(consumableIndex || consumableIndex === 0){
                            consumablesUpdated[consumableIndex] = data;
                        }else{
                            consumablesUpdated.push(data);
                        }
                        updatedForm.rows[todaysMenuIndex].fullmeals[fullMealIndex].consumables = consumablesUpdated;
                
                        this.dispatch(foodSetTodaysMenusForm(updatedForm));
                        this.dispatch(foodSetTodaysMenusFormData(undefined));
                        this.dispatch(foodSetShowSelectCosumableModal(false));
                        this.dispatch(foodSetConsumableForm(MealConsumableM.initial()));
                    }else{
                        if(!this.consumableForm.id){
                            this.dispatch(foodSetTodaysMenusForm({count: 0, rows: []}));
                            this.dispatch(foodSetTabSelectedContent(foodContentsNames.FOOD_CATALOG));
                        }
                    }

                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: `Alimento ${this.consumableForm.id ? "atualizado" : "cadastrado"} com sucesso`,
                    });
                    
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            })
        }

    }
}