import { Dispatch } from "redux";
import { clearCPF, generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { AuthController } from "../../../../controllers/auth.controller";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { ForgoutPasswordT } from "./types";

export class ForgoutPasswordService{
    dispatch : Dispatch;
    authController : AuthController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.authController = new AuthController();
    }

    private validateForgoutPassword(loginForgoutPassword: ForgoutPasswordT) {
        const fieldsErrors = [];

        if (!loginForgoutPassword.cpf) {
            fieldsErrors.push('cpf')
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }

        return true;
    }


    public async submit(loginForgoutPassword : ForgoutPasswordT){
        if (this.validateForgoutPassword(loginForgoutPassword)) {
            this.dispatch(generalSetIsLoading(true));
            this.authController.passwordResetSchooWorker(clearCPF(loginForgoutPassword.cpf!)).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Email de recuperação enviado',
                    });
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }

    }
}