import styled from "styled-components"

export const TabFoodStudentEvaluationContainer = styled.div`

`

export const TabFoodStudentEvaluationTitle = styled.div`

`

export const TabFoodStudentEvaluationSearchBarInputs = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;

    .date-picker-filter{
        input{
            min-height: 55px;
        }
    }

    .inputSearch{
        width: 100%;
    }

    .input-style{
        margin-top: 12px !important;
        min-height: 55px !important;
        border-radius: 4px;
    }

    @media screen and (max-width: 1400px){
        .input-style{
            margin-top: 8px !important;
            min-height: 41px !important;

        }

        .date-picker-filter{
            input{
                min-height: 41px;
            }
        }

    }
`;

export const TabFoodStudentEvaluationSearchBar = styled.form`
    button{
       margin-left: auto;
       margin-top: 12px;
       padding: 12px 16px;
    }

    @media screen and (max-width: 1400px){
        margin-top: 9px;
        padding: 9px 12px;
    }   
`

export const TabFoodStudentEvaluationAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 12px;
    background-color: var(--primary-opacity-2-color);
    border-radius: 8px;

    img{
        width: 24px;
    }

    @media screen and (max-width : 1400px){
        padding: 15px 0;
        img{
            width: 18px;
        }
    }
`;

export const TabFoodStudentEvaluationContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    margin-top: 48px;

    .active{
        background: rgba(15, 192, 0, 0.10) !important;
        color: #578D38 !important;
    }

    .special{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 8px;
        background-color: var(--green-opacity-color);
        border-radius: 8px;
        max-width: 94px;
        @media screen and (max-width: 1400px){
            max-width: 76px;
        }
    
    }

    .border-none{
        border: none;
    }

    .full-padding{
        padding: 24px 27px;
    }

    td{
        justify-content: center;
    }

    @media screen and (max-width : 1400px){
        gap: 36px;
        margin-top: 36px;

        .full-padding{
            padding: 18px 20px;
        }

    }

`