import React, { useState } from 'react';
import { StudentScoreModalActions, StudentScoreModalContainer, StudentScoreModalContent, StudentScoreModalDescription, StudentScoreModalTitle } from './styled/student-score-modal.styled';
import { useDispatch } from 'react-redux';
import { ButtonPrimary } from '../../components/button-primary.component';
import { useAppSelector } from '../../../hooks';
import { Input } from '../../components/input.component';
import { StudentDiseaseM } from '../../../models/student-disease.model';
import { StudentHealthObservationI } from '../../../models/interfaces/student-health-observation.interface';
import { StudentHealthObservationM } from '../../../models/student-health-observation.model';
import { StudentScoreM } from '../../../models/student-score.model';
import moment from 'moment';
import { baseUrl } from '../../../api/api';
import { emotions } from '../../../utils/config';
// import { StudentScoreModalService } from './services/add-disease-modal.service';


export const StudentScoreModal = ({ onClose, studentScore }: { onClose: Function, studentScore?: StudentScoreM }) => {
    return (
        <StudentScoreModalContainer>
            <StudentScoreModalContent>
                <StudentScoreModalTitle>
                    <span className='primaryBlackSemibold18-150'>Avaliação de refeição</span>
                </StudentScoreModalTitle>
                <StudentScoreModalDescription>

                    <div className='container-top'>
                        <div className='info-item'>
                            <span className='title'>Refeição</span>
                            <span>{studentScore?.fullMeal?.foodType?.name}</span>
                        </div>

                        <div className='info-item'>
                            <span className='title'>Data</span>
                            <span>{moment(studentScore?.createdAt).format('DD/MM/yyyy')}</span>
                        </div>

                        <div className='info-item'>
                            <span className='title'>Aluno</span>
                            <span>{studentScore?.student?.name}</span>
                        </div>
                    </div>

                    <div className='container-bottom'>
                        <span className="title">Avaliação de refeição por alimento</span>

                        <div className='food-list'>
                            {studentScore?.fullMeal?.consumables.map(consumable => (
                                <div className='food-item'>
                                    <div className='food-item-left'>
                                        <img src={`${baseUrl}/${consumable.media?.content}`} alt='foto do alimento' />
                                        <span>{consumable.name}</span>
                                    </div>
                                    <div className='food-item-right'>
                                        <div className='active'>
                                            <img src={emotions[(consumable.studentsAcceptances?.find(sa => sa.studentId === studentScore.student?.id && sa.mealConsumableId === consumable.id)?.eatLevel || 1) - 1]?.icon} alt='Icone emotions' />
                                            <span className='primaryBlackSemibold18-120'>{emotions[(consumable.studentsAcceptances?.find(sa => sa.studentId === studentScore.student?.id && sa.mealConsumableId === consumable.id)?.eatLevel || 1) - 1]?.descriptionConsumable}</span>
                                        </div>
                                        <span className='title'>Avaliação</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='container-general'>
                        <span className="title">Avaliação de refeição geral</span>
                        <div className='food-list'>
                            <div className='food-item'>
                                {studentScore?.fullMeal?.consumables.map(consumable => (
                                    <div className='food-item-left'>
                                        <img src={`${baseUrl}/${consumable.media?.content}`} alt='foto do alimento' />
                                        <span>{consumable.name}</span>
                                    </div>
                                ))}

                                <div className='food-item-right'>
                                    <div className='active'>
                                        <img src={emotions[(studentScore?.fullMeal?.studentsAcceptances?.find(sa => sa.studentId === studentScore.student?.id)?.eatLevel || 1) - 1]?.icon} alt='Icone emotions' />
                                        <span className='primaryBlackSemibold18-120'>{emotions[(studentScore?.fullMeal?.studentsAcceptances?.find(sa => sa.studentId === studentScore.student?.id)?.eatLevel || 1) - 1]?.descriptionConsumable}</span>
                                    </div>
                                    <span className='title'>Avaliação</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </StudentScoreModalDescription>
                <StudentScoreModalActions>
                    <ButtonPrimary onClick={() => onClose()}>
                        Concluído
                    </ButtonPrimary>
                </StudentScoreModalActions>
            </StudentScoreModalContent>
        </StudentScoreModalContainer>
    );
};
