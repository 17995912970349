import axios from 'axios';
import { LocalToken } from '../utils/local-token';

export const baseUrl = "https://back.healthypuppy.com.br";

const api = axios.create({
    baseURL: `${baseUrl}/api/v1`
});

api.interceptors.request.use(
    (config) => {
        const token = LocalToken.getStoredToken();
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
