import React from 'react';
import { AlertDialogActions, AlertDialogContainer, AlertDialogContent, AlertDialogDescription, AlertDialogTitle } from './styles/alert-dialog.style';
import { ButtonPrimary } from './button-primary.component';
import { useAppSelector } from '../../hooks';
import { alertInitialValue, generalSetAlertDialog } from '../../features/general-slice.feature';
import { useDispatch } from 'react-redux';


export const AlertDialog = () => {
    const generalReducer = useAppSelector(state=>state.generalReducer);
    const dispatch = useDispatch();
    return (
        <AlertDialogContainer>
            <AlertDialogContent>
                <AlertDialogTitle>
                    <span className='primaryBlackSemibold18-150'>{generalReducer.alertDialog.title}</span>
                </AlertDialogTitle>
                <AlertDialogDescription>
                    <span className='primaryBlackNormal20-150'>
                        {generalReducer.alertDialog.text}
                    </span>
                </AlertDialogDescription>
                <AlertDialogActions>
                    <ButtonPrimary className='primaryWhiteNormal20-150' onClick={()=>generalReducer.alertDialog.action.onClick()}>{generalReducer.alertDialog.action.text}</ButtonPrimary>
                    <span className='primaryBlackNormal20-150' onClick={()=>{dispatch(generalSetAlertDialog(alertInitialValue))}}>Cancelar</span>
                </AlertDialogActions>
            </AlertDialogContent>
        </AlertDialogContainer>
    );
};
