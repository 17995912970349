import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FoodStateT, FoodTodaysMenusFormDataT } from './types/food-slice.type';
import { MealConsumableM } from '../models/meal-consumable.model';
import { TodaysMenuM } from '../models/todays-menu.model';
import { FoodTypeM } from '../models/food-type.model';
import { StudentM } from '../models/student.model';
import { StudentMealAcceptanceM } from '../models/student-meal-acceptance.model';
import { FullMealM } from '../models/full-meal.model';
import { LocalToken } from '../utils/local-token';
import { LoginUserType } from '../utils/config';

const userType = LocalToken.getStoredUserType();

const initialState : FoodStateT = {
    tabSelectedItem : 'Avaliação do alimento',
    tabSelectedContent : 'student-evaluation-meal',
    consumableForm : MealConsumableM.initial(),
    todaysMenusForm : {count : 0, rows : []},
    showSelectCosumableModal: false,
    showAddNutritionalCategoryModal: false,
    evaluationSelectedConsumables: {
        consumables : [],
        drinks : []
    }
} 

export const foodSlice = createSlice({
    name: 'food',
    initialState,
    reducers: {
        foodSetConsumableForm : (state, action : PayloadAction<MealConsumableM>) => {
            state.consumableForm = action.payload;
        },
        foodSetTabSelectedContent: (state, action : PayloadAction<string>)=>{
            state.tabSelectedContent = action.payload;
        },
        foodSetShowSelectCosumableModal : (state, action : PayloadAction<boolean>) => {
            state.showSelectCosumableModal = action.payload
        },
        foodSetShowAddNutritionalCategoryModal : (state, action : PayloadAction<boolean>) => {
            state.showAddNutritionalCategoryModal = action.payload
        },
        foodSetTodaysMenusForm : (state, action : PayloadAction<{count : number, rows : TodaysMenuM[]}>) => {
            state.todaysMenusForm = action.payload
        },
        foodSetTodaysMenusFormData : (state, action : PayloadAction<FoodTodaysMenusFormDataT>) => {
            state.todaysMenusFormData = action.payload
        },
        foodSetTabSelectdItem :  (state, action : PayloadAction<string>) => {
            state.tabSelectedItem = action.payload
        },
        foodSetEvaluationSelectedFoodType :  (state, action : PayloadAction<FoodTypeM>) => {
            state.evaluationSelectedFoodType = action.payload
        },
        foodSetEvaluationSelectedFullMeal :  (state, action : PayloadAction<FullMealM>) => {
            state.evaluationSelectedFullMeal = action.payload
        },
        foodSetEvaluationSelectedStudent :  (state, action : PayloadAction<StudentM>) => {
            state.evaluationSelectedStudent = action.payload
        },
        foodSetEvaluationSelectedConsumables :  (state, action : PayloadAction<{ consumables: StudentMealAcceptanceM[], drinks: StudentMealAcceptanceM[]}>) => {
            state.evaluationSelectedConsumables = action.payload
        },
        
    } 
});

export const { 
    foodSetConsumableForm,
    foodSetShowSelectCosumableModal,
    foodSetTabSelectedContent,
    foodSetShowAddNutritionalCategoryModal,
    foodSetTodaysMenusForm,
    foodSetTodaysMenusFormData,
    foodSetTabSelectdItem,
    foodSetEvaluationSelectedFoodType,
    foodSetEvaluationSelectedStudent,
    foodSetEvaluationSelectedConsumables,
    foodSetEvaluationSelectedFullMeal
} = foodSlice.actions;
export const foodReducer = foodSlice.reducer;