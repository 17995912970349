import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CanteenOrdersDataStateT, CanteenStateT, canteenTabList, canteenTabToContent } from './types/canteen-slice.type';

const initialState : CanteenStateT = {
    tabSelectedItem : 'Pedidos Ativos',
    tabSelectedContent : 'active-orders',
} 

export const canteenSlice = createSlice({
   name: 'canteen',
   initialState,
   reducers: {
        canteenSetTabSelectdItem : (state, action : PayloadAction<string>)=>{
            state.tabSelectedItem = action.payload;
        },
        canteenSetTabSelectedContent: (state, action : PayloadAction<string>)=>{
            state.tabSelectedContent = action.payload;
        },
        canteenSetOrdersData: (state, action : PayloadAction<CanteenOrdersDataStateT>)=>{
            state.ordersData = action.payload;
        },
       
   } 
});

export const { 
    canteenSetTabSelectdItem, 
    canteenSetTabSelectedContent,
    canteenSetOrdersData,
} = canteenSlice.actions;
export const canteenReducer = canteenSlice.reducer;