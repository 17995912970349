import { useEffect, useState } from "react";
import { ButtonPrimary } from "../../components/button-primary.component";
import { Dropdown } from "../../components/dropdown.component";
import { Input } from "../../components/input.component";
import { TabStudentsAction, TabStudentsButtonBar, TabStudentsCanteenPeriod, TabStudentsContainer, TabStudentsContent, TabStudentsSearchBar } from "./styled/tab-students.styled";

import { GradeM } from "../../../models/grade.model";
import { useDispatch } from "react-redux";
import { TabStudentsService } from "./services/tab-students.service";
import { DatePicker } from "../../components/date-picker.component";
import { DatePickerMultiT } from "../../components/types/datepicker.type";
import { Table } from "../../components/table.component";
import { StudentM } from "../../../models/student.model";

import search_icon from '../../../assets/icons/search.svg';
import plus_icon from '../../../assets/icons/plus.svg';
import arrow_right_icon from '../../../assets/icons/arrow_right.svg';
import info_icon from '../../../assets/icons/info.svg';
import { UserStudentM } from "../../../models/user-student.model";

export const TabStudentsPage = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>();
    const [selectedGrade, setSelectedGrade] = useState<GradeM>();
    const [gradesData, setGradesData] = useState<{count: number, rows: GradeM[]}>();
    const [datesSearch, setDatesSearch] = useState<DatePickerMultiT>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [studentsData, setStudentsData] = useState<{count : number, rows: UserStudentM[]}>();

    const tabStudentsService = new TabStudentsService(dispatch);

    useEffect(()=>{
        tabStudentsService.handleSearchStudents(setStudentsData, selectedGrade, search);
    }, [selectedGrade, search]);

    useEffect(()=>{
        tabStudentsService.handleGetGrades(setGradesData);
    }, []);

    useEffect(() => {
        if (selectedDate) {
            tabStudentsService.handleSelectedDate(selectedDate, setDatesSearch);
        }
    }, [selectedDate]);

    return(
        <TabStudentsContainer>
            <TabStudentsSearchBar autoComplete="off">
                <Input
                    className='inputSearch'
                    type='search'
                    name='inputSearch'
                    id='inputSearch'
                    placeholder='Busque pelo nome do item'
                    value={search}
                    onChange={setSearch}
                    prefix={
                        <img src={search_icon} alt="Icone de busca"/>
                    }
                />
                <Dropdown 
                    styleClass='input-style'
                    defaultValue={selectedGrade?.id?.toString() || ' '}
                    placeholder='Categorias'
                    options={tabStudentsService.handleGradesToOptions(gradesData?.rows)}
                    onChange={(value)=>setSelectedGrade(tabStudentsService.handleFindGradeByValue(value, gradesData?.rows))}
                />
            </TabStudentsSearchBar>

            <TabStudentsButtonBar>
                <TabStudentsCanteenPeriod>
                    <span className="primaryBlackMedium18-120">Periodo atual da cantina:</span>
                    <DatePicker
                        dates={datesSearch}
                        setDate={setSelectedDate}
                        selectsRange={true}
                        className={'canteenPeriod'}
                    />
                </TabStudentsCanteenPeriod>
                {/* <label>
                    <img src={info_icon} alt="Icone de Informação" />
                    <span className='infoCard primaryBlackNormal16-120'>
                        Aqui você define o Período de compras da Cantina para todos os alunos.
                    </span>
                </label> */}

                <ButtonPrimary onClick={()=>{tabStudentsService.handleOpenDetailsPage()}}>
                    <img src={plus_icon} alt="Icone de Adicionar"/>
                    Cadastrar novo aluno
                </ButtonPrimary>
            </TabStudentsButtonBar>

            <TabStudentsContent>
                <Table className='border-none'>
                    <thead className='primaryBlackSemibold20-150'>
                        <th>Aluno</th>
                        <th>Turma</th>
                        <th>Responsáveis</th>
                        <th></th>
                    </thead>
                    {studentsData && studentsData.count > 0 ?
                        <tbody className='primaryBlackMedium18-150'>
                            {studentsData?.rows.map((userStudent : UserStudentM)=>(
                                <tr className='full-padding'>
                                    <td>{userStudent.student.name || userStudent.name}</td>
                                    <td>{userStudent.student.grade.name}</td>
                                    <td>
                                        <span>{userStudent.student.parentNameFather}</span>
                                        <span>{userStudent.student.parentNameMother}</span>
                                    </td>
                                    <td>
                                        <TabStudentsAction 
                                            className='primaryPrimarySemibold16-150'
                                            onClick={()=>{tabStudentsService.handleOpenDetailsPage(userStudent)}}
                                        >
                                            Ver informações do aluno
                                            <img src={arrow_right_icon} alt='Icone seta para direita'/>
                                        </TabStudentsAction>
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody> :
                        <tbody>
                            <div className='empty-table mt'>
                                <span className='primaryBlackSemibold20-150'>Nenhum aluno cadastrado</span> 
                            </div>
                        </tbody>
                    }
                </Table>
            </TabStudentsContent>
        </TabStudentsContainer>
    );
}