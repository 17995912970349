import { StudentMealAcceptanceI } from "./interfaces/student-meal-acceptance.interface";
import { MealConsumableM } from "./meal-consumable.model";

export class StudentMealAcceptanceM implements StudentMealAcceptanceI {
    id?: number;
    eatLevel: number;
    mealConsumableId: number;
    mealConsumable: MealConsumableM;
    studentId: number;

    constructor({ id, eatLevel, mealConsumable, mealConsumableId, studentId }: StudentMealAcceptanceI) {
        this.id = id;
        this.eatLevel = eatLevel;
        this.mealConsumableId = mealConsumableId;
        this.mealConsumable = mealConsumable;
        this.studentId = studentId;
    }
}