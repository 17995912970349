import { addMonths, format } from "date-fns";
import { Dispatch } from "redux";
import { PaymentRequestController } from "../../../../controllers/payment-request.controller";
import { PaymentRequestM } from "../../../../models/payment-request.model";
import { alertInitialValue, generalSetAlertDialog, generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { DatePickerMultiT } from "../../../components/types/datepicker.type";
import { StudentM } from "../../../../models/student.model";
import { MediaM } from "../../../../models/media.model";

export class StudentTabCanteenService{
    dispatch : Dispatch;
    paymentRequestController : PaymentRequestController;
    
    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        this.paymentRequestController = new PaymentRequestController();
    }

    public handleChangePayment(paymentRequest : PaymentRequestM, student: StudentM, setPaymentRequestData: Function){
        this.dispatch(generalSetIsLoading(true));
        this.paymentRequestController.createAndUpdate(paymentRequest).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(generalSetAlertDialog(alertInitialValue));
                this.handleGetPaymentRequestByStudentId(student.id!, setPaymentRequestData);
                showToast(this.dispatch, {
                    type: toastTypes.SUCCESS,
                    text: `Boleto ${paymentRequest.id ? 'editado' : 'cadastrado'} com sucesso.`,
                });
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleClickInputFile(){
        document.getElementById('document')?.click();
    }

    public hanldeFileOnChange(
        e : any,
        student : StudentM,
        setPaymentRequestData: Function,
        datesSearch? : DatePickerMultiT,
    ){
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64String = event.target?.result as string;
                console.log(base64String);
                const paymentRequest = PaymentRequestM.initial();
                paymentRequest.periodFrom = datesSearch!.startDate;
                paymentRequest.periodTo = datesSearch!.endDate;
                paymentRequest.students = [student];
                paymentRequest.media = new MediaM({
                    type : 'pdf',
                    content : base64String,
                    name: `${student.name} ${format(new Date(), 'MM-yyyy')}` 
                });

                this.dispatch(generalSetAlertDialog({
                    isOpen: true,
                    title: "Enviar documento agora?",
                    text: '',
                    action: {
                        text: "Enviar agora",
                        onClick: ()=>{
                            this.handleChangePayment(paymentRequest, student, setPaymentRequestData);
                        }
                    }
                }))

            };

            reader.readAsDataURL(file);
            
        }
    }

    public handleSelectedDate(selectedDate : Date | Date[], setDatesSearch : Function){
        if(Array.isArray(selectedDate)){
            selectedDate = selectedDate[0];
        }
        let endDate = new Date(selectedDate);
        endDate = addMonths(endDate, 1);
        setDatesSearch({ startDate : selectedDate, endDate });
    }

    public handleGetPaymentRequestByStudentId(studentId: number, setPaymentRequestsData: Function){
        this.dispatch(generalSetIsLoading(true));
        this.paymentRequestController.getByStudent(studentId).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                setPaymentRequestsData(data)
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

}
