import styled from "styled-components";

export const TabAnnouncementsFormContainer = styled.section`
    width: 100%;
   
    .float-edit-mode-form {
        display: flex;
        position: relative;
        height: 0px;
        top: -18px;
        align-items: end;
        justify-content: end;
        button {
            position: relative;
            /* right: 0; */
            max-width: 215px;
            top: calc(48px + 18px);
            width: auto;
        }
    }
`;

export const TabAnnouncementsFormTitle = styled.div`
    margin: 24px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 32px !important;

    @media screen and (max-width: 1400px){
        margin: 18px 0;
        padding-bottom: 12px;
        margin-bottom: 24px !important;
    }
`;

export const TabAnnouncementsFormContent = styled.div`
    width: 552px !important;

    button{
        width: 100%;
    }
    
    .input-form{
        margin-top: 16px;

        textarea {
            width: 794px;
            height: 443px;
            resize: none;
            
            font-size: 16px !important;
            font-weight: 400 !important;
        }
    }

    .dropdown-form {
        margin-top: 16px;
        margin-bottom: 16px;
        
        .input-style{
            margin-top: 14px !important;
        }
        
    }

    .dropdown-form .input-style{
        width: 100%;
        min-height: 50px;
        border-radius: 4px;
        span{
            font-size: 16px !important;
            font-weight: 400 !important;
        }
    }

    button{
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        padding: 20px 12px;
    }

    @media screen and (max-width: 1400px){
        width: 414px;

        .input-form{
            margin-top: 12px;
        }

        button{
            margin-top: 24px;
            padding: 15px 9px;
        }

        textarea {
            width: 595px;
            height: 332px;
            resize: none;
        }

        .dropdown-form .input-style{
            min-height: 42px;
           
        }
    }
`;

export const TabAnnouncementsFormFotoInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--grey-color);
    width: 450px;
    height: 336px;
    margin-top: 18px;
    margin-bottom: 36px;
    border-radius: 8px;
    cursor: pointer;

    img{
        width: 250px;
        height: 250px;
        object-fit: cover;
    }

    @media screen and (max-width: 1400px){
        width: 337px;
        height: 252px;
        margin-top: 14px;
        margin-bottom: 27px;
    }
`;

export const TabAnnouncementsFormActionBack = styled.button`
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    gap: 16px;

    img{
        width: 24px
    }

    @media screen and (max-width: 1400px){
        gap: 12px;

        img{
            width: 18px
        }
    }
`;



export const InputSugestions = styled.div`
    position: relative;
    width: 100%;
    margin-top: 12px;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid #E4E4E4 !important;
    font-family: Inter;
    overflow: hidden;
    overflow-y: scroll;
    display: none;

    min-height: 48px;
    max-height: 100px;
    /* box-shadow: 0px 0px 2px gray; */

    &.show {
        display: block;
    }

    @media screen and (max-width: 1400px) {
            margin-top: 9px;
            padding: 4px;
    }

`

export const InputSugestion = styled.div`
        
    border-radius: 4px;
    padding: 6px;
    margin: 4px;
    cursor: pointer;
    &:hover {
        background: #E4E4E4;
    }

    &.select {
        background: var(--primary-color);
    }
`


export const SearchContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${props => props.color ? props.color : 'var(--grey-light-2-color)'};
    width: 220px;
    max-height: 48px;
    padding: 8px 12px;
    cursor: pointer;

    @media screen and (max-width: 1400px){
        padding: 6px 9px;
        width: 160px;
        max-height: 36px;
    }
`;