import { configureStore } from "@reduxjs/toolkit";
import { canteenReducer } from "../features/canteen-slice.feature";
import { studentReducer } from "../features/student-slice.feature";
import { foodReducer } from "../features/food-slice.feature";
import { generalReducer } from "../features/general-slice.feature";
import { tabMenuReducer } from "../features/tab-menu-slice.feature";
import { settingsReducer } from "../features/settings-slice.feature";
import { tabAdminReducer } from "../features/user-school-worker-slice.feature";
import { announcementsReducer } from "../features/announcements-slice.feature";
import { tabAnnouncementsReducer } from "../features/tab-announcements-slice.feature";
import { authReducer } from "../features/auth-slice.feature";


export const store = configureStore({
  reducer : {
    canteenReducer,
    foodReducer,
    studentReducer,
    generalReducer,
    tabMenuReducer,


    // Configuração 
    settingsReducer,
    tabAdminReducer,

    // Comunicados
    announcementsReducer,
    tabAnnouncementsReducer,

    // Auth
    authReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;