import React, { useEffect, useState } from 'react';
import { TabAddGradeContainer, TabAddGradeContent, TabAddGradeSearchBar } from "./styled/tab-add-grade.styled";
import { Input } from "../../components/input.component";
import { ButtonPrimary } from '../../components/button-primary.component';
import { TabAddGradesService } from './services/tab-add-grades.service';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { ActionBack } from '../../components/action-back.component';
import { GradeStudentsListModal } from './grede-students-list-modal.page';
import { Select } from '../../components/select.component';
import { UserSchoolWorkerDataT } from '../../../features/types/user-school-worker-slice.type';

export const TabAddGradePage = ()=>{
    const [schoolWorkersData, setSchoolWorkersData] = useState<UserSchoolWorkerDataT>()
    const studentReducer = useAppSelector(state=>state.studentReducer);
    const generalReducer = useAppSelector(state=>state.generalReducer);

    const dispatch = useDispatch();
    const tabAddGradeService = new TabAddGradesService(dispatch);
    
    useEffect(()=>{
        if(generalReducer.userSchoolWorker?.instituteId){
            tabAddGradeService.handleGetSchoolWorkers(generalReducer.userSchoolWorker.instituteId, setSchoolWorkersData);
        }
    }, [generalReducer.userSchoolWorker]);

    return(
        <TabAddGradeContainer>
            {studentReducer.showGradeListStudent && <GradeStudentsListModal onClose={()=>{tabAddGradeService.handleSetShowModal(false)}}/> }
            <ActionBack onClose={()=>tabAddGradeService.handleCloseAddGrade()}/> 
            <TabAddGradeContent autoComplete="off">
                <h2 className='primaryBlackSemibold28-120'>Informações da Turma</h2> 
                <Input
                    className='input-form'
                    type='text'
                    name='name'
                    id='name'
                    label='Nome da turma'
                    placeholder='Nome da turma'
                    value={studentReducer.gradeForm.name}
                    onChange={(value)=>{tabAddGradeService.handleChangeName(value, studentReducer.gradeForm)}}
                />
                
                <Select
                    className="inputClasse"
                    label='Professor Responsável'
                    name='teacher'
                    id='teacher'
                    placeholder='Professor Responsável'
                    defaultValue={studentReducer.gradeForm.schoolWorkers && studentReducer.gradeForm.schoolWorkers[0] ? studentReducer.gradeForm.schoolWorkers[0].id?.toString() : ''}
                    onChange={(value)=>{tabAddGradeService.handleChangeSchoolWorkers(value, studentReducer.gradeForm, schoolWorkersData?.rows!)}}
                    options={schoolWorkersData?.rows.map(userSchoolWorker => { return { value: userSchoolWorker.schoolWorker?.id?.toString() || '', content: userSchoolWorker.schoolWorker?.name } })}
                />

                {studentReducer.gradeForm.id &&
                    <>
                        <Input
                            className='input-form'
                            type='text'
                            name='studentCount'
                            id='studentCount'
                            label='Número de alunos'
                            placeholder='Número de alunos'
                            disabled
                            value={studentReducer.gradeForm.studentCount?.toString()}
                            onChange={(value)=>{}}
                        />

                        <span 
                            className='primaryPrimarySemibold20-150 click'
                            onClick={()=>{tabAddGradeService.handleSetShowModal(true)}}
                        >
                            Ver lista de alunos
                        </span>
                    </>
                }
                    
                <ButtonPrimary
                    disabled={!studentReducer.gradeForm.name}
                    onClick={()=>tabAddGradeService.handleSubmit(
                       studentReducer.gradeForm, generalReducer.userSchoolWorker?.instituteId
                    )}
                >
                   {studentReducer.gradeForm.id ? 'Editar' : 'Salvar'} informações
                </ButtonPrimary>
            </TabAddGradeContent>
        </TabAddGradeContainer>
    );
}