import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { StudentScoreController } from "../../../../controllers/student-score.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";

export class StudentScoreService {
    dispatch: Dispatch;
    studentScoreController: StudentScoreController;

    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.studentScoreController = new StudentScoreController();
    }

    public handleGetByStudentId(studentId?: number, setAvaliationData?: Function) {
        if (studentId) {
            this.dispatch(generalSetIsLoading(true));
            this.studentScoreController.getAll(studentId).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    if(setAvaliationData){
                        setAvaliationData(data);
                    }
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }
}
