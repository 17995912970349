import { FoodTypeM } from "../models/food-type.model";
import { MealConsumableM } from "../models/meal-consumable.model";

export class MealConsumableController {

    async delete(consumableId : number){
        return await MealConsumableM.delete(consumableId);
    }
    
    async getAll(foodType?: FoodTypeM, search?: string){
        return await MealConsumableM.getAll(foodType, search);
    }

    async createAndUpdate(nutritionalCategory : MealConsumableM){
        if(nutritionalCategory.id){
            return await MealConsumableM.update(nutritionalCategory);
        }
        return await MealConsumableM.create(nutritionalCategory);
    }
}