import styled from "styled-components";

export const TabBarContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    margin: 36px 0;

    @media screen and (max-width: 1400px){
        margin: 27px 0;
    }
`;

export const TabBarNav = styled.nav`
    ul{
        display: flex;
        list-style: none;
        gap: 36px;
    }

    .active{
        a{
            color: var(--primary-color) !important;
        }
        border-bottom: 2px solid var(--primary-color);
    }

    @media screen and (max-width: 1400px){
        ul{
            gap: 27px;
        }    
    }
    
`;

export const TabBarItem = styled.li`
    padding: 8px 0;
    cursor: pointer;
    &:hover{
        a{
            color: var(--primary-color) !important;
        }
        border-bottom: 2px solid var(--primary-color);    
    }
    
    @media screen and (max-width: 1400px){
        padding: 6px 0;
    }
`;

export const TabBarLink = styled.a`

`;