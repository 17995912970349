import React from 'react';
import { TabBarContainer, TabBarItem, TabBarLink, TabBarNav } from './styles/tab-bar.styled';
import { TabBarT } from './types/tab-bar.type';

export const TabBar = ({
    selected, 
    items, 
    setSelectedTab
} : TabBarT) => {
    return (
        <TabBarContainer>
            <TabBarNav >
                <ul>
                    {items?.map((item)=>(
                        <TabBarItem 
                            className={selected === item ? 'active' : ''} 
                            onClick={()=>setSelectedTab(item)}
                        >
                            <TabBarLink 
                                href="javascript:void(0);"
                                className={selected === item ? 'primaryPrimaryBold18-150' : 'primaryGreySemibold18-150'}
                            >
                                {item}
                            </TabBarLink>
                        </TabBarItem>
                    ))}
                    
                </ul>
            </TabBarNav>

            
        </TabBarContainer>
    );
};