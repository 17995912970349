import { MenuGradeM } from "../models/menu-grade.modal";
import { DatePickerMultiT } from "../views/components/types/datepicker.type";

export class MenuGradeController {

    async getByInstituteIdAndPeriod(seletedDate : DatePickerMultiT, instituteId: number){
        return await MenuGradeM.getAllByInstituteAndPeriod(
            seletedDate.startDate, seletedDate.endDate, instituteId
        );
    }

}