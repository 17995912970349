import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import dropdown_arrow_icon from '../../assets/icons/dropdown_arrow.svg';
import { DatePikerContainer, DatePikerContent } from './styles/date-picker.styled';
import { DatePickerT } from './types/datepicker.type';
import { useEffect, useRef, useState } from 'react';
import { DatePickerService } from './services/date-picker.service';

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR'); 

export const DatePicker = ({
    id,
    label, 
    date, 
    dates, 
    setDate, 
    buttonText, 
    selectsRange,
    placeholder,
    className,
    acceptWeekend,
    filter,
    dateFormat,
    disabled,
    showMonthYearPicker
} : DatePickerT) => {
    const [isFocus, setIsFocus] = useState(false);
    const datePickerService = new DatePickerService();


    useEffect(() => {
        const datePicker = document.getElementById(id || 'date-picker');
        const handleFocus = () => {
            setIsFocus(true);
        };
        const handleBlur = () => {
            setIsFocus(false);
        };
    
        datePicker?.addEventListener('focus', handleFocus);
        datePicker?.addEventListener('blur', handleBlur);
    
        return () => {
            datePicker?.removeEventListener('focus', handleFocus);
            datePicker?.removeEventListener('blur', handleBlur);
        };
    }, []); 
    
    useEffect(() => {
        if (isFocus && buttonText) {
            datePickerService.handleAddButton(buttonText, setDate ? ()=>setDate(null) : ()=>{});
        }
    }, [isFocus]);

    return (
        <DatePikerContainer className={className}>
            <label className='primaryBlackSemibold18-150'>{label}</label>
            <DatePikerContent className={className}>
                <ReactDatePicker
                    id={id || 'date-picker'}
                    className='date-picker'
                    startDate={dates?.startDate}
                    endDate={dates?.endDate}
                    selected={date}
                    onChange={setDate as any}
                    dateFormat={dateFormat ?? 'dd/MM/yyyy'}
                    placeholderText={placeholder || 'Filtrar por data'}
                    locale='pt-BR'
                    showMonthYearPicker={showMonthYearPicker || false}
                    filterDate={filter ? filter : acceptWeekend ? undefined : datePickerService.filterWeekends}
                    shouldCloseOnSelect={true}
                    selectsRange={selectsRange}
                    disabled={disabled}

                />
                <div className='arrow'>
                    <img src={dropdown_arrow_icon} alt='Icon arrowdown' onClick={()=>datePickerService.handleFocus(id)}/>
                </div>
            </DatePikerContent>
        </DatePikerContainer>
    );
};