import { NutritionalCategoryM } from "../models/nutritional-category.model";

export class NutritionalCategoryController {
    
    async getAll(){
        return await NutritionalCategoryM.getAll();
    }

    async createAndUpdate(nutritionalCategory : NutritionalCategoryM){
        if(nutritionalCategory.id){
            return await NutritionalCategoryM.update(nutritionalCategory);
        }
        return await NutritionalCategoryM.create(nutritionalCategory);
    }
}