import React, { useState, useRef, useEffect } from 'react';
import dropdown_arrow_icon from '../../assets/icons/dropdown_arrow.svg';
import dropdown_arrow_primary_icon from '../../assets/icons/dropdown_arrow_primary.svg';
import { DropDownContent, DropDownOption, DropDownOptions, DropdownContainer } from './styles/dropdown.styled';
import { DropdownT } from './types/dropdown.type';
import { DropdownService } from './services/dropdown.service';

export const Dropdown = ({
    defaultValue,
    onChange,
    className,
    containerClassName,
    styleClass, 
    color,
    options,
    placeholder,
    optionsClassName
} : DropdownT) => {

    const [showOptions, setShowOptions] = useState(false);
    const dropdownRef = useRef(null);

    const dropdownService = new DropdownService();

    useEffect(() => {
        dropdownService.handleAddEventListner(dropdownRef, setShowOptions);
        return () => {
            dropdownService.handleRemoveEventListner(dropdownRef, setShowOptions);
        };
    }, []);

    return (
        <DropdownContainer className={containerClassName} ref={dropdownRef} onClick={()=>setShowOptions(!showOptions)}>
            <DropDownContent className={styleClass} color={color}>
                <span className={`primaryBlackSemibold18-150 ${className}`}>
                    { defaultValue ? 
                        options?.find(option=>option.value === defaultValue)?.content :
                        placeholder
                    }
                </span>
                <img src={styleClass === 'input-style' ? dropdown_arrow_primary_icon : dropdown_arrow_icon} alt='Icon arrowdown'/>
            </DropDownContent>
            { options && showOptions && 
                <DropDownOptions className={styleClass === 'input-style' ? `${styleClass} ${optionsClassName}` : optionsClassName}>
                    { options?.map((option, index)=>(
                        <DropDownOption 
                            key={index} 
                            className='primaryBlackSemibold18-150'
                            onClick={onChange ? ()=>onChange(option.value!) : ()=>{}}
                        >
                            {option?.content}
                        </DropDownOption>
                    ))}
                </DropDownOptions>
            }
        </DropdownContainer>
    );
};
