import styled from "styled-components";

export const ActionBackContainer = styled.button`
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: flex;
    gap: 16px;

    img{
        width: 24px
    }

    @media screen and (max-width: 1400px){
        gap: 12px;

        img{
            width: 18px
        }
    }
`;