import styled from "styled-components";

export const TabGradeContainer = styled.div`
    
`;


export const TabGradeSearchBar = styled.form`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    align-items: flex-end;

    .addGrade{
        width: 350px;
        height: 52px;
        align-items: center;
        justify-content: center;
    }

    .inputSearch{
        width: 100%;
    }



    .input-style{
        margin-top: 12px !important;
        min-height: 50px !important;
    }

    button: {
        min-width: 250px;
    }

    

    @media screen and (max-width: 1400px){
        .input-style{
            margin-top: 8px !important;
            min-height: 41px !important;

        }

        .addGrade{
            width: 250px;
            height: 42px;
        }

    }

`;

export const TabGradeCanteenPeriod = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 12px;
    margin-top: 12px;
    width: auto !important;

    border: 1px solid var(--border-color);
    border-radius: 8px;

    .canteenPeriod{
        width: 150px;
    }

    input{
        border: none !important;
        padding: 0 !important;
        max-width: 150px !important;
    }
    
    img, label{
        display: none;
    }
`;

export const TabGradeCard = styled.div`
    width: 359px;
    height: 149px;
    padding: 24px 16px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;

    .students{
        display: flex;
        gap: 8px;
        align-items: center;
        img{
            width: 24px;
        }
    }

    @media screen and (max-width: 1400px){
        width: 261px;
        height: 112px;
        padding: 18px 12px;
    }   
`;

export const TabGradeContent = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
`;

export const TabGradeAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 12px;
    background-color: var(--primary-opacity-2-color);
    border-radius: 8px;

    img{
        width: 24px;
    }

    @media screen and (max-width : 1400px){
        padding: 15px 0;
        img{
            width: 18px;
        }
    }
`;

export const TabGradeButtonBar = styled.div`
    display: flex;
    button{
        margin-left: auto;
        margin-top: 12px;
    }
`;