import { UserSchoolWorkerM } from "../models/user-school-worker.model";

export class UserSchoolWorkerController {

    async getAllByInstitute(instituteId: number, search?: string) {
        return await UserSchoolWorkerM.getAllByInstitute(instituteId, search);
    }

    async getById( userId: string) {
        return await UserSchoolWorkerM.getById(userId);
    }

    async delete(userId: number) {
        return await UserSchoolWorkerM.delete(userId);
    }

    async updateDataWithoutMedia(schoolWorker: UserSchoolWorkerM) {
        delete schoolWorker.profilePic;
        return await UserSchoolWorkerM.update(schoolWorker);
    }

    async createAndUpdate(schoolWorker: UserSchoolWorkerM) {
        if (schoolWorker.id) {
            return await UserSchoolWorkerM.update({
                ...schoolWorker,
            });
        }
        return await UserSchoolWorkerM.create({
            ...schoolWorker,
            instituteId: 1,
        });
    }
}