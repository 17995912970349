import { handleRequest } from "../api/api-request.service";
import { StudentDiseaseI } from "./interfaces/student-disease.interface";

export class StudentDiseaseM implements StudentDiseaseI {
    id?: number;
    disease: string;
    studentNoteId?: number;

    constructor({ id, disease, studentNoteId }: StudentDiseaseI) {
        this.id = id;
        this.disease = disease;
        this.studentNoteId = studentNoteId;
    }
    
    static initial(){
        return new StudentDiseaseM({
            disease: '',
        });
    }

}