import { Dispatch } from "redux";
import { ProductCategoryController } from "../../../../controllers/produc-category.controller";
import { ProductCategoryM } from "../../../../models/product-category.model";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { tabMenuSetProductCategoriesData } from "../../../../features/tab-menu-slice.feature";

export class AddCategoryModalService{
    dispatch : Dispatch;
    productCategoryController : ProductCategoryController;
    category : ProductCategoryM;
    setCategory : Function;
    setShowAddCategoryModal : Function;
    handleGetCategories : Function;

    constructor(
        dispatch : Dispatch, 
        category : ProductCategoryM, 
        setCategory : Function,
        setShowAddCategoryModal : Function,
        handleGetCategories : Function

    ){
        this.dispatch = dispatch;
        this.productCategoryController = new ProductCategoryController();
        this.category = category;
        this.setCategory = setCategory;
        this.setShowAddCategoryModal = setShowAddCategoryModal;
        this.handleGetCategories = handleGetCategories;

        this.handleChangeName = this.handleChangeName.bind(this);
        this.validateProductCategory = this.validateProductCategory.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public handleChangeName(value : string){
        this.setCategory({
            ...this.category,
            name : value
        })
    }

    private validateProductCategory(){
        const fieldsErrors = [];
        if(!this.category.name){
            fieldsErrors.push('Nome');
        }
        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        return true;
    }

    public handleSubmit(){
        if(this.validateProductCategory()){
            this.dispatch(generalSetIsLoading(true));
            this.productCategoryController.createAndUpdate(this.category).then(response=>{
                const { status, data } = response;
                if(status === responseStatus.SUCCESS){
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Categoria cadastrada com sucesso',
                    });
                    this.setShowAddCategoryModal(false);
                    this.handleGetCategories();
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            })
        }

    }
}