import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { GradeController } from "../../../../controllers/grade.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { studentDetailsSetGradeForm, studentDetailsSetShowGradeListStudent, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { studentContentsNames } from "../../../../features/types/student-slice.type";
import { GradeM } from "../../../../models/grade.model";
import { SchoolWorkerI } from "../../../../models/interfaces/school-worker.interface";
import { UserSchoolWorkerController } from "../../../../controllers/user-school-worker.controller";
import { UserSchoolWorkerM } from "../../../../models/user-school-worker.model";

export class TabAddGradesService{
    dispatch : Dispatch;
    gradeController : GradeController;
    userSchoolWorkerConstroller : UserSchoolWorkerController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.gradeController = new GradeController();
        this.userSchoolWorkerConstroller = new UserSchoolWorkerController();
    }

    public async handleGetSchoolWorkers(instituteId: number, setShoolWorkersData : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.userSchoolWorkerConstroller.getAllByInstitute(instituteId).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setShoolWorkersData(data);
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleCloseAddGrade(){
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.GRADES));
    }

    public handleSetShowModal(isShow : boolean){
        this.dispatch(studentDetailsSetShowGradeListStudent(isShow));
    }

    public handleChangeName(value : string, grade: GradeM){
        this.dispatch(studentDetailsSetGradeForm({
            ...grade,
            name : value
        }));
    }

    public handleChangeSchoolWorkers(value: string, grade: GradeM, schoolWorkersList : UserSchoolWorkerM[]){
        const schoolWorker = schoolWorkersList.find(userSchoolWorker=> userSchoolWorker.schoolWorker?.id?.toString() === value);
        this.dispatch(studentDetailsSetGradeForm({
            ...grade,
            schoolWorkers : [schoolWorker?.schoolWorker!]
        }));
    }

    private validateGradeForm(grade: GradeM){
        const fieldsErrors = [];

        if(!grade.name){
            fieldsErrors.push('Nome');
        }

        if(grade.schoolWorkers?.length === 0){
            fieldsErrors.push('Professor Responsável');
        }
   
        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        return true;
    }

    public handleSubmit(grade: GradeM, instituteId?: number) {
        if(this.validateGradeForm(grade) && instituteId){
            this.dispatch(generalSetIsLoading(true));
            this.gradeController.createAndUpdate({...grade, instituteId : instituteId}).then(response => {
                console.log(grade)
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    this.handleCloseAddGrade();
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Turma cadastrada com sucesso.',
                    });
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }
}
