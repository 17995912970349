import { CardBody, CardUser, ProfilePic, SVGIcon, SectionSeparator, TabListContainer, TabListUserSection, TabMainContainer, TabSettingsButtonBar, TabSettingsInfoContainer, TabSubtitle, UserInfoContainer } from "./styles/tab-admin-styled";
import { Input } from "../../components/input.component"
import { ButtonPrimary } from "../../components/button-primary.component"
import { ButtonSecondary } from "../../components/button-secondary.component"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { TabAdminService } from "./services/tab-admin-service"
import { useAppSelector } from "../../../hooks"
import { baseUrl } from "../../../api/api"
import { TabAdminAddForm } from "./tab-admin-add-form.page"
import { InputSugestion, InputSugestions } from "../announcements/styles/tab-announcements-form.styled"
import { formatCPF } from "../../../utils/config"

import plus_icon from '../../../assets/icons/plus.svg';
import logo from "../../../assets/images/logo75x75.png"


export const TabAdmin = () => {
    const tabAdminReducer = useAppSelector(state => state.tabAdminReducer);
    const generalReducer = useAppSelector(state => state.generalReducer);

    const [editMode, setEditMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchCargo, setSearchCargo] = useState(tabAdminReducer.userForm.schoolWorker?.office ?? '');
    const [searchEdit, setSearchEdit] = useState(false);

    const dispatch = useDispatch();
    const tabAdminService = TabAdminService.getInstance(dispatch);

    useEffect(() => {
        console.log(generalReducer.userSchoolWorker)
        if (generalReducer.userSchoolWorker?.id) {
            tabAdminService.handleGetSchoolWorkers(generalReducer.userSchoolWorker.instituteId);
        }
    }, [tabAdminService, generalReducer.userSchoolWorker]);

    return (
        <TabMainContainer>
            <TabListContainer>

                <TabSubtitle>Administradores</TabSubtitle>

                <TabListUserSection>
                    {
                        tabAdminReducer.usersData && tabAdminReducer.usersData?.rows?.map((user, index) => {

                            const selected = tabAdminReducer.userForm?.id === user.id;

                            return (
                                <CardUser key={'card-' + user.id} className={`${selected && 'selected'}`} onClick={(e) => {
                                    tabAdminService.handleEdit(user);
                                    setSearchCargo(user.schoolWorker!.office)
                                    setSearchEdit(false);
                                    setEditMode(false);
                                }}>
                                    <img src={logo} alt="" />
                                    <CardBody>
                                        <span>{user.schoolWorker?.name}</span>
                                        <span>{user.schoolWorker?.office}</span>
                                    </CardBody>
                                    <SVGIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </SVGIcon>
                                </CardUser>
                            )
                        })
                    }
                </TabListUserSection>
            </TabListContainer>
            <SectionSeparator />

            <TabSettingsInfoContainer>

                <TabSettingsButtonBar>
                    <ButtonPrimary onClick={() => {
                        tabAdminService.handleAdd();
                        setShowModal(!showModal)
                    }}>
                        <img src={plus_icon} alt="Icone de Adicionar" />
                        Adicionar novo administrador
                    </ButtonPrimary>
                </TabSettingsButtonBar>

                {
                    tabAdminReducer.userForm.id &&


                    <UserInfoContainer >
                        <TabSubtitle>Informações do Administrador</TabSubtitle>

                        <ProfilePic className="profilePic" >
                            {
                                <img className="upload_img " id="image_file"
                                    src={
                                        tabAdminReducer.userForm.profilePic?.type === 'external_url' ?
                                            `${baseUrl}/${tabAdminReducer.userForm.profilePic?.content}`
                                            : (
                                                tabAdminReducer.userForm.profilePic?.content ??
                                                logo
                                            )
                                    } alt="profile_picture"
                                />
                            }
                            {
                                editMode &&
                                <div className='icon '>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-camera-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                        <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                    </svg>
                                </div>
                            }
                            <input name="image" type="file" className=""
                                id="image_file_input" aria-label="Banner"
                                onChange={(e) => tabAdminService.handleChangeImage(e, tabAdminReducer.userForm)} placeholder="Selecione uma imagem"
                                accept="image/*" disabled={!editMode} required />
                        </ProfilePic>





                        <Input
                            disabled={!editMode}
                            className='input-form'
                            id='name'
                            name='name'
                            type='text'
                            label='Nome do Profissional'
                            placeholder='Nome do profissional'
                            value={tabAdminReducer.userForm.schoolWorker?.name ?? ''}
                            onChange={(value) => tabAdminService.handleChangeName(value, tabAdminReducer.userForm)}
                        />

                        <Input
                            className='input-form'
                            id='cpf'
                            name='cpf'
                            type='text'
                            label='CPF do Profissional'
                            placeholder='CPF do profissional'
                            value={formatCPF(tabAdminReducer.userForm.cpf ?? '')}
                            onChange={(value) => tabAdminService.handleChangeCPF(value, tabAdminReducer.userForm)}
                        />

                        <Input
                            disabled={!editMode}
                            className='input-form'
                            id='office'
                            name='office'
                            type='text'
                            label='Cargo'
                            placeholder='Cargo'
                            value={searchCargo}
                            onChange={(value) => {

                                setSearchEdit(true);
                                setSearchCargo(value);

                                // tabAdminService.handleChangeOffice(value, tabAdminReducer.userForm)


                            }}
                        />

                        <InputSugestions id="cargoNameSuggestions" className={`cargo-name-suggestions ${searchCargo.length >= 3 && searchEdit ? 'show' : ''}`}>
                            {
                                [
                                    "Administração",
                                    "Secretaria",
                                    "Nutricionista",
                                    "Cantina",
                                    "Professor(a)",

                                ].filter(function (item, pos, self) {

                                    return item?.toLowerCase().includes(searchCargo?.toLowerCase());

                                }).map((cargo, i) => {

                                    return (

                                        <InputSugestion className={`${tabAdminReducer.userForm.schoolWorker?.office === cargo ? 'select' : ''}`} onClick={(e) => {
                                            tabAdminService.handleChangeOffice(cargo, tabAdminReducer.userForm);
                                            setSearchCargo(cargo)
                                            setSearchEdit(false)
                                        }} key={"suggestion-" + cargo}>
                                            {cargo}
                                        </InputSugestion>
                                    )
                                })
                            }

                        </InputSugestions>

                        <Input
                            disabled={!editMode}
                            className='input-form'
                            id='email'
                            name='email'
                            type='email'
                            label='Email'
                            placeholder='Email'
                            value={tabAdminReducer.userForm.email ?? ''}
                            onChange={(value) => tabAdminService.handleChangeEmail(value, tabAdminReducer.userForm)}
                        />

                        {
                            editMode &&

                            <Input
                                disabled={!editMode}
                                className='input-form'
                                id='password'
                                name='password'
                                type='password'
                                label='Senha'
                                placeholder='Para alterar a senha, preencha o campo'
                                value={tabAdminReducer.userForm.newPassword ?? ''}
                                onChange={(value) => tabAdminService.handleChangePassword(value, tabAdminReducer.userForm)}
                            />
                        }
                        {
                            !editMode &&
                            <>
                            <ButtonPrimary className="admin-edit-button" onClick={() => setEditMode(true)} >Editar informações</ButtonPrimary>

                            <ButtonSecondary className="admin-save-button" onClick={() => {
                                tabAdminService.handleShowAlertExcluirItem(tabAdminReducer.userForm);
                            }}>
                                Excluir administrador
                            </ButtonSecondary>
                            </>
                        }

                        {editMode &&
                            <>
                                <ButtonPrimary className="admin-save-button" onClick={() => {
                                    tabAdminService.handleSubmit(tabAdminReducer.userForm);
                                    setEditMode(false)
                                }}>
                                    Salvar edições
                                </ButtonPrimary>
                            </>
                        }

                    </UserInfoContainer>
                }

            </TabSettingsInfoContainer>

            {
                showModal &&
                <TabAdminAddForm onKeyEsc={() => { setShowModal(!showModal) }} onBackground={() => { setShowModal(!showModal) }} onFinish={() => {
                    setShowModal(!showModal)
                }} />
            }

        </TabMainContainer>
    )
}