import { Dispatch } from "redux";
import { alertInitialValue, generalSetAlertDialog, generalSetIsLoading } from "../../../../features/general-slice.feature";
import { StudentController } from "../../../../controllers/student.controller";
import { StudentMonitoringController } from "../../../../controllers/student-monitoring.controller";
import { STATUS_CODES } from "http";
import { HttpStatusCode } from "axios";
import { responseStatus } from "../../../../api/api-request.service";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { UserStudentM } from "../../../../models/user-student.model";
import { StudentMonitoringM } from "../../../../models/student-monitoring.model";
import { differenceInMonths, differenceInYears, endOfDay } from "date-fns";
import { StudentHealthyObservationController } from "../../../../controllers/student-healthy-observation.controller";
import { StudentDiseaseM } from "../../../../models/student-disease.model";
import { StudentHealthObservationI } from "../../../../models/interfaces/student-health-observation.interface";
import { StudentHealthObservationM } from "../../../../models/student-health-observation.model";

export class TabAddStudentHealthyService{
    dispatch : Dispatch;
    studentMonitoringController : StudentMonitoringController;
    studentHealthyObservationController : StudentHealthyObservationController;
    
    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;

        this.studentMonitoringController = new StudentMonitoringController();
        this.studentHealthyObservationController = new StudentHealthyObservationController();
    }

    public handleGetStudentMonitoringDate(setSelectedDate: Function) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // January is 0, so adding 1
        let newMonth;
        if (currentMonth <= 6) {
            newMonth = 3; 
        } else {
            newMonth = 9; 
        }

        const newDate = new Date(currentDate.getFullYear(), newMonth - 1, currentDate.getDate());
        setSelectedDate(newDate);
    }

    public changeWeight(value : string, studentMonitoring: StudentMonitoringM, setStudentMonitoring : Function){
        setStudentMonitoring({
            ...studentMonitoring,
            weight : value ? parseFloat(value) : undefined
        })
    }

    public changeHeight(value : string, studentMonitoring: StudentMonitoringM, setStudentMonitoring : Function){
        setStudentMonitoring({
            ...studentMonitoring,
            height : parseFloat(value)
        })
    }


    public handleCalcAge(selectedDate: Date, userStudent : UserStudentM, studentMonitoring: StudentMonitoringM, setStudentMonitoring : Function){
        const birthDate = new Date(userStudent.student.birthDate!);
        const currentDate = selectedDate;

        const ageInYears = differenceInYears(currentDate, birthDate);

        const months = differenceInMonths(currentDate, birthDate) % 12;

        studentMonitoring.age = ageInYears;
        studentMonitoring.months = months;
        studentMonitoring.completeAge = `${ageInYears} anos e ${months} meses`;

        if (setStudentMonitoring) {
            setStudentMonitoring(studentMonitoring);
        }

        return `${ageInYears} anos e ${months} meses`;

    }

    private async handleDeleteSubmit(
        studentHealthyObservation: StudentHealthObservationM,
        refresh: Function
    ){
        this.dispatch(generalSetIsLoading(true));
        await this.studentHealthyObservationController.createAndUpdate(studentHealthyObservation).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(generalSetAlertDialog(alertInitialValue));
                refresh();
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    };

    private handleDeleteDoenca(index : number, studentHealthObservation : StudentHealthObservationM, refresh: Function){
        const diseases = studentHealthObservation.diseases?.filter((_, diseasesIndex)=>diseasesIndex !== index);
        this.handleDeleteSubmit({...studentHealthObservation, diseases : diseases}, refresh);
    }

    private handleDeleteAllergy(index : number, studentHealthObservation : StudentHealthObservationM, refresh: Function){
        const allergies = studentHealthObservation.allergies?.filter((_, allergyIndex)=>allergyIndex !== index);
        this.handleDeleteSubmit({...studentHealthObservation, allergies : allergies}, refresh);
    }

    public handleShowAlertExcluirDesease(index : number, studentHealthObservation : StudentHealthObservationM, refresh: Function){
        this.dispatch(generalSetAlertDialog({
            title : 'Excluir Doença',
            text : `O item “${studentHealthObservation.diseases![index].disease}” será excluído do aluno. Tem certeza que deseja continuar?`,
            isOpen : true,
            action : {
                text : 'Excluir Doença',
                onClick : ()=>{
                    this.handleDeleteDoenca(index, studentHealthObservation, refresh);
                }
            }
        }));
    }

    public handleShowAlertExcluirAllergy(index : number, studentHealthObservation : StudentHealthObservationM, refresh: Function){
        this.dispatch(generalSetAlertDialog({
            title : 'Excluir Alergia',
            text : `O item “${studentHealthObservation.allergies![index].allergie}” será excluído do aluno. Tem certeza que deseja continuar?`,
            isOpen : true,
            action : {
                text : 'Excluir Alergia',
                onClick : ()=>{
                    this.handleDeleteAllergy(index, studentHealthObservation, refresh);
                }
            }
        }));
    }

    public handleCalcIMC(studentMonitoring: StudentMonitoringM, setStudentMonitoring : Function){
        const weight = studentMonitoring.weight! / 100; 
        const height = studentMonitoring.height! / 100;    
        
        if (weight > 0 && height > 0) {
            const imc = weight / (height * height);

            if (setStudentMonitoring) {
                setStudentMonitoring({
                    ...studentMonitoring,
                    imc : imc.toFixed(2)
                });
            }
        }else{
            if (setStudentMonitoring) {
                setStudentMonitoring({
                    ...studentMonitoring,
                    imc : undefined
                });
            }
        }
    }

    public async handleGetStudentHealhtyObservationsData(
        studentId: number,
        setStudentHealthyObjectData : Function
    ){
        this.dispatch(generalSetIsLoading(true));
        await this.studentHealthyObservationController.getAll(studentId).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                if(data.count !== 0){
                    setStudentHealthyObjectData(data);
                }else{
                    setStudentHealthyObjectData({count: 0, rows : [StudentHealthObservationM.initial(studentId)]} );
                }
                     
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        })
    }

    public async handleGetStudentMonitoringByDate(
        studentId: number,
        setStudentMonitoring : Function,
        selectedDate : Date
    ){
        const month = selectedDate.getMonth() + 1; 
        let startDate: Date;
        let endDate: Date;

        if (month >= 1 && month <= 6) { 
            startDate = new Date(selectedDate.getFullYear(), 0, 1); 
            endDate = new Date(selectedDate.getFullYear(), 5, 31); 
        } else { 
            startDate = new Date(selectedDate.getFullYear(), 6, 1); 
            endDate = new Date(selectedDate.getFullYear(), 11, 31); 
        }

        this.dispatch(generalSetIsLoading(true));
        
        await this.studentMonitoringController.getAll(studentId, startDate, endDate).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                if(data.count > 0){
                    setStudentMonitoring(data.rows[0]);  
                }else{
                    setStudentMonitoring(StudentMonitoringM.initial());  
                }
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        })
    }


    public async handleGetStudentMonitoringData(
        studentId: number,
        setStudentMonitoringData : Function
    ){
        this.dispatch(generalSetIsLoading(true));
        await this.studentMonitoringController.getAll(studentId).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setStudentMonitoringData(data);        
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        })
    }

    private validateStudentMonitoring(studentMonitoring : StudentMonitoringM){
        const fieldsErrors = [];

        if (!studentMonitoring.weight) {
            fieldsErrors.push('Peso')
        }

        if (!studentMonitoring.height) {
            fieldsErrors.push('Altura')
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }

        return true;
    }

    public async submit(refresh: boolean, setRefresh: Function, studentMonitoring : StudentMonitoringM, selectedDate: Date, studentId : number){
        if(this.validateStudentMonitoring(studentMonitoring)){
            this.dispatch(generalSetIsLoading(true));
            
            await this.studentMonitoringController.createAndUpdate({
                ...studentMonitoring, 
                registerDate : selectedDate,
                studentId : studentId
            })
            .then((response)=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: `Avaliação semestral inserida com sucesso`,
                    });
                    setTimeout(()=>{
                        setRefresh(!refresh);
                    }, 200);
                                            
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    })
                }
                
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            })
        }
    }

}
