import React, { useEffect, useRef, useState } from 'react';
import dropdown_arrow_primary_icon from '../../assets/icons/dropdown_arrow_primary.svg';
import { SelectContainer, SelectContent, SelectOption, SelectOptions } from './styles/select.styled';
import { SelectT } from './types/select.type';
import { SelectService } from './services/select.service';

export const Select = ({
    defaultValue,
    onChange,
    className,
    options,
    placeholder,
    name,
    id,
    label
} : SelectT) => {

    const [showOptions, setShowOptions] = useState(false);
    const selectRef = useRef(null);

    const selectService = new SelectService();

    useEffect(() => {
        selectService.handleAddEventListner(selectRef, setShowOptions);
        return () => {
            selectService.handleRemoveEventListner(selectRef, setShowOptions);
        };
    }, []);


    return (
        <SelectContainer ref={selectRef} onClick={()=>setShowOptions(!showOptions)}>
            <label className='label primaryBlackSemibold18-150'>{label}</label>
            <SelectContent className={showOptions ? 'focus' : ''}>
                <span className={`primaryBlackRegular18-150 ${className}`}>
                    { defaultValue ? 
                        options?.find(option=>option.value === defaultValue)?.content :
                        placeholder
                    }
                </span>
                <img src={ dropdown_arrow_primary_icon } alt='Icon arrowdown'/>
            </SelectContent>
            { options && showOptions && 
                <SelectOptions>
                    { options?.map((option, index)=>(
                        <SelectOption 
                            key={index} 
                            className='primaryBlackSemibold18-150'
                            onClick={onChange ? ()=>onChange(option.value!) : ()=>{}}
                        >
                            {option?.content}
                        </SelectOption>
                    ))}
                </SelectOptions>
            }
        </SelectContainer>
    );
};