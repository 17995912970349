import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { GradeController } from "../../../../controllers/grade.controller";
import { GradeI } from "../../../../models/interfaces/grade.interface";
import { GradeM } from "../../../../models/grade.model";
import { StudentController } from "../../../../controllers/student.controller";
import { StudentM } from "../../../../models/student.model";
import { foodSetEvaluationSelectedStudent, foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { foodContentsNames } from "../../../../features/types/food-slice.type";
import { FoodTypeController } from "../../../../controllers/food-type.controller";
import { FoodTypeI } from "../../../../models/interfaces/food-type.interface";
import { FoodTypeM } from "../../../../models/food-type.model";

export class TabFoodStudentEvaluationService {
    dispatch : Dispatch;
    gradeController : GradeController;
    studentController : StudentController;
    foodTypeController : FoodTypeController;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        this.gradeController = new GradeController();
        this.studentController = new StudentController();
        this.foodTypeController = new FoodTypeController();

        this.handleOpenEvaluation = this.handleOpenEvaluation.bind(this);
    }

    public handleOpenEvaluation(selectedStudent: StudentM){
        this.dispatch(foodSetEvaluationSelectedStudent(selectedStudent));
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_MEAL));
    }

    public handleGradesToOptions(grades? : GradeI[]){
        const options = grades?.map(grade=>(
            {value: grade.id?.toString()!, content: grade.name!}
        ));

        options?.push(
            {value : 'all', content: 'Todas'}
        );
    
        return options;
    }

    public handleFoodTypesToOptions(foodTypes? : FoodTypeI[]){
        const options = foodTypes?.map(foodType=>(
            {value: foodType.id?.toString()!, content: foodType.name!}
        ));    
        return options;
    }

    public async handleSearchGrades(setGradesData : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.gradeController.getAllByInstitute().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setGradesData(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }
    
    public async handleSearchFoodTypes(setFoodTypesData : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.foodTypeController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setFoodTypesData(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async handleSearchStudents(setStudentsData : Function, selectedGrade? : GradeM, search?: string, selectedFoodType?: FoodTypeM, selectedDate?: Date){
        this.dispatch(generalSetIsLoading(true));
        await this.studentController.getAll(selectedGrade, search, selectedFoodType, selectedDate).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                const students : StudentM[] = [];
                data.rows.map((row : any)=>{
                    students.push(row.student)
                });
                setStudentsData({count : data.count, rows : students});                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleFindGradeByValue(value: string, gradeList? : GradeM[]){
        return gradeList?.find(grade => grade.id?.toString() === value);
    }

    public handleFindFoodTypeByValue(value: string, foodTypeList? : FoodTypeM[]){
        return foodTypeList?.find(foodType => foodType.id?.toString() === value);
    }
}