import { Dispatch } from "redux";
import { OrderController } from "../../../../controllers/order.controller";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { ordersStatus } from "../../../../utils/config";
import { canteenSetOrdersData } from "../../../../features/canteen-slice.feature";

export class TabOrdersCompletedService{
    dispatch : Dispatch;
    orderController : OrderController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.orderController = new OrderController;
    }

    public handleSearch(dateSearch : Date){
        this.dispatch(generalSetIsLoading(true));
        this.orderController.getByCanteenId(1, dateSearch, ordersStatus.CONCLUDED, 0).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(canteenSetOrdersData(data));
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
        
    }
}

