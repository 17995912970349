import { Provider, useDispatch } from "react-redux";
import RoutesApp from "./views/routes/Routes";
import { store } from "./store";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <RoutesApp/>
      </div>
    </Provider>
  );
}

export default App;
