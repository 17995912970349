import { Dispatch } from "redux";
import { studentSetTabSelectdItem, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { studentTabToContent } from "../../../../features/types/student-slice.type";

export class StudentHomeService{
    dispatch : Dispatch;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
    }
    
    public handleSetSelectedTab(tabSelectedItem : string){
        this.dispatch(studentSetTabSelectdItem(tabSelectedItem));
        this.dispatch(studentSetTabSelectedContent(studentTabToContent[tabSelectedItem]))
    }
}
