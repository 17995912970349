import { MenuAddItemPage } from "../../views/modules/canteen/menu-add-item.page";
import { TabActiveOrdersPage } from "../../views/modules/canteen/tab-active-orders.page";
import { TabOrdersCompletedPage } from "../../views/modules/canteen/tab-orders-completed.page";
import { TabMenuPage } from "../../views/modules/canteen/tab-menu.page";
import { OrderI } from "../../models/interfaces/order.interface";

const tabsNames = {
    ACTIVE_ORDERS : 'Pedidos Ativos', 
    ORDERS_COMPLETED : 'Pedidos Finalizados',
    MENU : "Cardápio"
}

export const canteenContentsNames = {
    ACTIVE_ORDERS : 'active-orders', 
    ORDERS_COMPLETED : 'orders-completed',
    MENU : "menu",
    MENU_ADD_ITEM: 'menu-add-item'
}

export const canteenTabToContent = {
    [tabsNames.ACTIVE_ORDERS] : canteenContentsNames.ACTIVE_ORDERS,
    [tabsNames.ORDERS_COMPLETED] : canteenContentsNames.ORDERS_COMPLETED,
    [tabsNames.MENU] : canteenContentsNames.MENU
}

export const canteenTabList = Object.values(tabsNames);

export const canteenContents = {
    [canteenContentsNames.ACTIVE_ORDERS] : <TabActiveOrdersPage/>,
    [canteenContentsNames.ORDERS_COMPLETED] : <TabOrdersCompletedPage/>,
    [canteenContentsNames.MENU] : <TabMenuPage/>,
    [canteenContentsNames.MENU_ADD_ITEM] : <MenuAddItemPage/>
}

export type CanteenOrdersDataStateT = {
    count : number,
    rows : OrderI[]
}

export type CanteenStateT = {
    tabSelectedItem : string,
    tabSelectedContent : string,
    ordersData?: CanteenOrdersDataStateT
}