import styled from "styled-components";

export const DasboardHomeContainer = styled.div`
    padding: 48px;
    width: 100%;

    @media screen and (max-width: 1400px){
        padding: 36px;        
    }

    @media screen and (max-width: 900px){
        padding: 36px 16px;        
    }
    
`

export const DasboardHomeContentAnnouncement = styled.div`
    width: 350px;
    height: 550px;
    position: relative;
   
    border: 1px solid var(--border-color);
    border-radius: 8px;

    @media screen and (max-width: 1400px){
        width: 327px; 
        height: 450px;
    }
`;

export const DasboardHomeContentMenu = styled.div`
    flex: 1;
   
    .input-year-style{
        width: 220px;
        padding: 16px 12px;
        background-color: transparent;
        border: 1px solid var(--border-color);
        border-radius: 4px;
    }

    tbody{
        height: 450px;
        overflow: scroll !important;
    }

    .table{
        height: 550px;
        position: relative;
    }

    thead{
        height: 80px;
    }

    .title-column{
        width: 20%;
        justify-content: center;
    }

    .filter-column{
        position: absolute;
        right: 20px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 70%;
        align-items: center;
        justify-content: flex-end;

        label{
            display: none;
        }
    }

    @media screen and (max-width: 1400px){
        .input-year-style{
            width: 180px;
        }

        tbody{
            height: 380px;
        }

        .filter-column{
            right: 15px;
        }

        thead{
            height: 65px;
        }

        .table{
            height: 450px;
            min-width: 450px;
        }
    }

    @media screen and (max-width: 900px){
        thead{
            height: 140px;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .filter-column{
            top: 40px;
            flex-wrap: wrap;

            .input-year-style{
                width: 100%;
            }
        }

        .table{
            height: auto !important;
        }
        
        tbody{
            height: auto !important;
        }
    }

    @media screen and (max-width: 540px){
        .table{ 
            min-width: 100%;
        }
    }


`;

export const DasboardHomeContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const DasboardHomeHeader = styled.div`
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 24px;
    margin-bottom: 24px;
    h2{
        margin-bottom: 24px;
    }

    @media screen and (max-width: 1400px){
        padding-bottom: 18px;
        margin-bottom: 18px;
        h2{
            margin-bottom: 18px;
        }
    }
`;

export const TableOrdersHead = styled.div`
    justify-content: space-between;
    width: 100%;
    display: flex;
    padding: 20px;
    align-items: center;
    position: absolute;
    top: 0;
    flex-wrap: wrap;
`;

export const DasboardHomeContentOrders = styled.div`
    position: relative;
    width: 100%;

    thead{
        height: 160px;
        align-items: flex-end;
    };

    tbody{
        display: flex;
        min-height: 150px !important;
        justify-content: center;
        flex-direction: column;
    }

    @media screen and (max-width: 900px){
        thead{
            flex-wrap: wrap !important;
            height: 150px;
            min-width: calc(100vw - 100px) !important;
        }  
        
        tbody{
            width: 100% !important;
            min-width: calc(100vw - 100px) !important;
        }


        .date-picker{
            width: 130%;
        }

    }

`;

export const DasboardHomeContentRow = styled.div`
    display: flex;
    width: 100%;
    gap: 36px;
    flex-wrap: wrap;

    @media screen and (max-width: 900px){
        gap: 0;
        margin-bottom: 32px;
    }
`;

export const DashboardHomeGridItem = styled.div`
    padding: 16px 0;

    img{
        height: 172px;
        width: 100%;
        object-fit: cover;
        margin-top: 16px;
    }

    .title{
        margin: 16px;
    }

    @media screen and (max-width: 1400px){
        img{
            height: 129px;
        }
    }
`;


export const DashboardHomeGridItemBody = styled.div`
    box-sizing: border-box;
    button{
        position: absolute;
        bottom: 0px;
        width: calc(100% - 32px);
        align-items: center;
        justify-content: center;
        padding: 16px;
        margin: 16px;
        box-sizing: border-box;
    }
`;

export const DashboardHomeGridItemSection = styled.div`
    padding: 16px;
    .card-date-label{
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        gap: 10px;
        img{
            width: 16px;
            height: 16px;
            margin: 0;
        }
    }

    
`;

export const DashboardHomeGridItemContent = styled.div`
    padding: 0 16px;
    max-height: 90px; 
    div{
        display: block;
        overflow : hidden;
        text-overflow: ellipsis;
        max-height: 96px; 
        display: -webkit-box;
        overflow : hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; 
 
    }
    
    @media screen and (max-width : 1400px){
        max-height: 70px;    
    }
`;