import { Dispatch } from "redux";
import { OrderController } from "../../../../controllers/order.controller";
import { canteenSetOrdersData } from "../../../../features/canteen-slice.feature";
import { generalReload, generalSetIsLoading } from "../../../../features/general-slice.feature";
import { OrderI } from "../../../../models/interfaces/order.interface";
import { responseStatus } from "../../../../api/api-request.service";
import { ordersStatus } from "../../../../utils/config";

export class TabActiveOrdersService{
    dispatch : Dispatch;
    orderController : OrderController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.orderController = new OrderController;
    }

    public handleSearch(dateSearch : Date | null){
        this.dispatch(generalSetIsLoading(true));
        this.orderController.getByCanteenId(1, dateSearch, ordersStatus.IN_PRODUCTION, 0).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(canteenSetOrdersData(data));
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
        
    }
    
    public handleChangeStatus(status : string, orderData : OrderI){
        this.dispatch(generalSetIsLoading(true));
        this.orderController.update({...orderData, orderStatus : status }).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(canteenSetOrdersData(data));
                this.dispatch(generalReload());
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    } 
}

