import React from 'react';
import { ButtonSwitchConteiner, ButtonSwitchContent, ButtonSwitchToggle } from './styles/button-switch.styled';
import { ButtonSwitchT } from './types/button-switch.component';

export const ButtonSwitch = ({label, checked, disabled, onClick, className} : ButtonSwitchT) => {
  return (
    <ButtonSwitchConteiner className={`primaryBlackNormal16-150 ${className}`}>
        <label>{label}</label>

        <ButtonSwitchContent checked={checked} onClick={onClick}>
            <ButtonSwitchToggle checked={checked}/>
        </ButtonSwitchContent>
    </ButtonSwitchConteiner>
  );
};