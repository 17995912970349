import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { TabStudentEvaluationEvaluationService } from './services/tab-student-evaluation-evaluation.service';
import { ActionBack } from '../../components/action-back.component';
import { ButtonPrimary } from '../../components/button-primary.component';
import { TodaysMenuM } from '../../../models/todays-menu.model';
import { baseUrl } from '../../../api/api';
import { PageCount } from '../../components/page-count.component';
import arrow_hight_icon from '../../../assets/icons/arrow_right.svg';
import { TabStudentEvaluationEvaluationButtonBar, TabStudentEvaluationEvaluationContainer, TabStudentEvaluationEvaluationContent, TabStudentEvaluationEvaluationEmotions, TabStudentEvaluationEvaluationEmotionsItem, TabStudentEvaluationEvaluationTop } from './styles/tab-student-evaluation-evaluation.styled';
import { emotions } from '../../../utils/config';

export const TabStudentEvaluationEvaluationPage = () => {

    const [consumableIndex, setConsumableIndex] = useState<number>(0);
    const [drinkIndex, setDrinkIndex] = useState<number>(0);
    
   
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);
    const [currentPageCount, setCurrentPageCount] = useState(0);
    const tabFoodStudentEvaluationService = new TabStudentEvaluationEvaluationService(dispatch);
    
    useEffect(()=>{
        setCurrentPageCount(
            foodReducer.evaluationSelectedConsumables.consumables.length === 0 ||
            foodReducer.evaluationSelectedConsumables.consumables.length <= consumableIndex ? 4 : 3
        );
    }, [consumableIndex, drinkIndex]);

    return (
        <TabStudentEvaluationEvaluationContainer>
            <ActionBack onClose={()=>{tabFoodStudentEvaluationService.backPage(
                consumableIndex, drinkIndex, setConsumableIndex, setDrinkIndex
            )}}/>
            <TabStudentEvaluationEvaluationTop>
                <div></div>
                <PageCount 
                    size={5} 
                    current={currentPageCount} 
                    disabled={
                        foodReducer.evaluationSelectedConsumables.consumables.length === 0 ?
                        3 :
                        foodReducer.evaluationSelectedConsumables.drinks.length === 0 ?
                        4 :
                        undefined
                    }
                />
            </TabStudentEvaluationEvaluationTop>
            {   foodReducer.evaluationSelectedConsumables.consumables.length !== 0 &&
                consumableIndex < foodReducer.evaluationSelectedConsumables.consumables.length && 
                <TabStudentEvaluationEvaluationContent>
                    
                    <img src={`${baseUrl}/${foodReducer.evaluationSelectedConsumables.consumables[consumableIndex].mealConsumable.media?.content}`} alt="Imagem do produto"/>
                    <span className='primaryBlackSemibold20-120'>{foodReducer.evaluationSelectedConsumables.consumables[consumableIndex].mealConsumable.name}</span>
                    <span className='primaryBlackMedium20-120'>Quanto você comeu de <span className='primaryBlackSemibold20-120'>{foodReducer.evaluationSelectedConsumables.consumables[consumableIndex].mealConsumable.name}</span> no <span>{foodReducer.evaluationSelectedFoodType?.name}</span> hoje?</span>
                    <TabStudentEvaluationEvaluationEmotions>
                        {
                            emotions.map((emotion : any, index : number)=>(
                                <TabStudentEvaluationEvaluationEmotionsItem 
                                    className={foodReducer.evaluationSelectedConsumables.consumables[consumableIndex].eatLevel === index+1 ? 'active' : ''}
                                    onClick={()=>tabFoodStudentEvaluationService.handleSelectConsumableEmotcion(
                                        foodReducer.evaluationSelectedConsumables,
                                        consumableIndex,
                                        index+1
                                    )}
                                >
                                    <img src={emotion.icon} alt='Icone emotions'/>
                                    <span className='primaryBlackSemibold18-120'>{emotion.descriptionConsumable}</span>
                                </TabStudentEvaluationEvaluationEmotionsItem>
                            ))
                        }
                        

                    </TabStudentEvaluationEvaluationEmotions>
                </TabStudentEvaluationEvaluationContent>
            }

            {   foodReducer.evaluationSelectedConsumables.drinks.length !== 0 &&
                foodReducer.evaluationSelectedConsumables.consumables.length === consumableIndex &&
                drinkIndex < foodReducer.evaluationSelectedConsumables.drinks.length &&
                <TabStudentEvaluationEvaluationContent>
                    <img src={`${baseUrl}/${foodReducer.evaluationSelectedConsumables.drinks[drinkIndex].mealConsumable.media?.content}`} alt="Imagem do produto"/>
                    <span className='primaryBlackSemibold20-120'>{foodReducer.evaluationSelectedConsumables.drinks[drinkIndex].mealConsumable.name}</span>
                    <span className='primaryBlackMedium20-120'>Quanto você bebeu de <span className='primaryBlackSemibold20-120'>{foodReducer.evaluationSelectedConsumables.drinks[drinkIndex].mealConsumable.name}</span> no <span>{foodReducer.evaluationSelectedFoodType?.name}</span> hoje?</span>
                    <TabStudentEvaluationEvaluationEmotions>
                        {
                            emotions.map((emotion : any, index : number)=>(
                                <TabStudentEvaluationEvaluationEmotionsItem 
                                className={foodReducer.evaluationSelectedConsumables.drinks[drinkIndex].eatLevel === index+1 ? 'active' : ''}
                                    onClick={()=>tabFoodStudentEvaluationService.handleSelectDrinkEmotcion(
                                        foodReducer.evaluationSelectedConsumables,
                                        drinkIndex,
                                        index+1
                                    )}
                                >
                                    <img src={emotion.icon} alt='Icone emotions'/>
                                    <span className='primaryBlackSemibold18-120'>{emotion.descriptionDrink}</span>
                                </TabStudentEvaluationEvaluationEmotionsItem>
                            ))
                        }
                        

                    </TabStudentEvaluationEvaluationEmotions>
                </TabStudentEvaluationEvaluationContent>
            }
            
            <TabStudentEvaluationEvaluationButtonBar>
                <ButtonPrimary 
                    disabled={
                        ( 
                            consumableIndex < foodReducer.evaluationSelectedConsumables.consumables.length && 
                            foodReducer.evaluationSelectedConsumables.consumables[consumableIndex].eatLevel === 0
                        ) || ( 
                            consumableIndex >= foodReducer.evaluationSelectedConsumables.consumables.length &&
                            drinkIndex < foodReducer.evaluationSelectedConsumables.drinks.length && 
                            foodReducer.evaluationSelectedConsumables.drinks[drinkIndex].eatLevel === 0
                        )
                    }
                    onClick={()=>tabFoodStudentEvaluationService.handleSubmit(
                        consumableIndex, 
                        drinkIndex,
                        foodReducer.evaluationSelectedConsumables.consumables.length,
                        foodReducer.evaluationSelectedConsumables.drinks.length,
                        setConsumableIndex, 
                        setDrinkIndex,
                        currentPageCount
                    )}
                >
                    Próxima pergunta
                    <img src={arrow_hight_icon} alt="Icone de seta"/>
                </ButtonPrimary>
            </TabStudentEvaluationEvaluationButtonBar>
        </TabStudentEvaluationEvaluationContainer>
    );
};