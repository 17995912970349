import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { NutritionalCategoryController } from "../../../../controllers/nutritional-category.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { MealConsumableM } from "../../../../models/meal-consumable.model";
import { foodSetConsumableForm, foodSetShowSelectCosumableModal } from "../../../../features/food-slice.feature";
import { NutritionalCategoryM } from "../../../../models/nutritional-category.model";
import { ChangeEvent } from "react";
import { MealConsumableController } from "../../../../controllers/meal-consumable.controller";


export class SelectCosumableModalService{
    dispatch : Dispatch;
    nutritionalCategoryController : NutritionalCategoryController;
    mealConsumableController : MealConsumableController;
    consumableForm : MealConsumableM;

    constructor(dispatch : Dispatch, consumableForm : MealConsumableM){
        this.dispatch = dispatch;
        this.consumableForm = consumableForm;
        this.nutritionalCategoryController = new NutritionalCategoryController();
        this.mealConsumableController = new MealConsumableController();
    }

    public handleSetShowConsumableModal(status : boolean){
        this.dispatch(foodSetShowSelectCosumableModal(status));
    }
    
    

}