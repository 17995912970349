import { StudentCardContainer } from './styles/student-card.styled';
import { TextAreaContainer } from './styles/textarea.styled';
import { StudentCardT } from './types/student-card.type';
import puppy_image from '../../assets/icons/puppy.svg';
import { baseUrl } from '../../api/api';

export const StudentCard = ({ student, id }: StudentCardT) => {


  function formatCPF(cpf?: string) {
    if (cpf) {
      const cpfStr = String(cpf);

      const formattedCPF = `${cpfStr.slice(0, 3)}.***.***-${cpfStr.slice(-2)}`;

      return formattedCPF;
    }
    return cpf;
  }

  return (
    <StudentCardContainer id={id}>
      <div className='card-container'>
        <div className='card-content'>
          <div className='content-top'>
            <div className="content-left">
              <span className="healthy-puppy">Healthy Puppy</span>
              <div className="info">
                <span className="student-name">{student.name}</span>
                <span>{formatCPF(student.user?.cpf)}</span>
                <span>{student.grade?.name}</span>
              </div>
            </div>

            <div className="content-right">
              <span>{new Date().getFullYear()}</span>
              <img src={puppy_image} alt="puppy icone" />
            </div>
          </div>

          <div className="content-bottom">
            <span className="title-small">CÓDIGO DE FRUTAS:</span>
            <div className="fruits">
              {student.fruitPassword?.map(fruit => (
                <div className='fruit-item'>
                  <img src={`${baseUrl}/${fruit.imageUrl}`} alt="fruit senha" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

    </StudentCardContainer>
  );
};
