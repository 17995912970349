import { UserSchoolWorkerM } from "../models/user-school-worker.model";
import { UserStudentM } from "../models/user-student.model";

export class UserStudentController {

    async getAllByInstitute( grade?: string,) {
        return await UserStudentM.getAllByInstitute(1, grade);
    }


   
}