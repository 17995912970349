import styled from "styled-components";

export const ButtonSwitchConteiner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 16px;

    @media screen and (max-width: 1400px) {
        padding: 0 12px;
        gap: 9px;
    }
`;

export const ButtonSwitchContent = styled.div<{checked : boolean}>`
    display: flex;
    align-items: center;
    justify-content: ${props=>props.checked ? 'flex-end' : 'flex-start'}; ;
    width: 44px;
    height: 22px;
    background-color: ${props=>props.checked ? 'var(--primary-color)' : 'var(--grey-light-2-color)'};
    border: ${props=>props.checked ? 'none' : '1px solid var(--border-color)'};
    border-radius: 11px;
    padding: 0 4px;
    cursor: pointer;
    @media screen and (max-width: 1400px) {
        width: 33px;
        height: 16px;
        border-radius: 8px;
        padding: 0 2px;
    }
`;

export const ButtonSwitchToggle = styled.div<{checked : boolean}>`
    background-color: ${props=>props.checked ? 'var(--white-color)' : 'var(--primary-color)'};
    width: 16px;
    height: 16px;
    border-radius: 50%;

    @media screen and (max-width: 1400px) {
        width: 12px;
        height: 12px;
    }
`;