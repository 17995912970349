import { format } from "date-fns";
import { handleRequest } from "../api/api-request.service";
import { GradeI } from "./interfaces/grade.interface";
import { MenuGradeI } from "./interfaces/menu-grade.interface";

export class MenuGradeM implements MenuGradeI {
    id?: number;
    name: string;
    grades?: GradeI[];
    instituteId?: number;

    constructor({ id, name, grades, instituteId }: MenuGradeI) {
        this.id = id;
        this.name = name;
        this.grades = grades;
        this.instituteId = instituteId;
    
    }

    static initial(){
        return new MenuGradeM({
            name : '',
            grades : [],
            instituteId: 1
        });
    }


    static async getAllByInstituteAndPeriod(to: Date, from: Date, instituteId: number){
        const search = `?to=${format(to, 'yyyy-MM-dd')}&from=${format(from, 'yyyy-MM-dd')}`;
        return handleRequest(
            `menu-grades/by/institute/${instituteId}${search}`,
            'GET'
        );
    }

    static async create(menuGrade : MenuGradeM){
        return handleRequest(
            `menu-grades`,
            'POST',
            menuGrade
        );
    }

    static async update(menuGrade : MenuGradeM){
        return handleRequest(
            `menu-grades`,
            'PUT',
            menuGrade
        );
    }

}