import { ActionBackContainer } from "./styles/action-back.styled";
import { ActionBackT } from "./types/action-back.type";
import arrow_left_icon from '../../assets/icons/arrow_left.svg';

export const ActionBack = ({onClose} : ActionBackT)=>{
    return(
        <ActionBackContainer type="button" onClick={onClose}>
            <img src={arrow_left_icon} alt="Seta para esquerda"/>
            <span className='primaryPrimarySemibold20-150'>Voltar</span>
        </ActionBackContainer>
    );
    
}