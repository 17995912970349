import styled from "styled-components";

export const ButtonPrimaryContainer = styled.button<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: var(--primary-color);
    border-radius: 8px;
    border: none;
    outline: none;
    padding: 16px 18px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.6 : 1)};
    border: 1px solid var(--primary-color);
    height: 52px;

    img{
        filter: brightness(0) invert(1) grayscale(100%) contrast(8);
        width: 24px;
    }

    @media screen and (max-width: 1400px) {
        padding: 12px;
        height: 39px;
        img{
            width: 18px; 
        }
    }
`;