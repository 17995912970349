import styled from 'styled-components';
import arrow_right from "../../../../assets/icons/arrow_right.svg";
import { OptionLoginT } from './types';

export const LandingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

export const LandingContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 550px;
    padding: 24px;
    align-items: center;
    justify-content: center;

    img{
        width: 146px;
        margin-bottom: 14px;
    }

    h2, span{
        margin-bottom: 38px;
    }

    button{
        margin: 16px 0 38px 0;
        width: 100%;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }

    @media screen and (max-width: 1400px) {
        width: 412px;
        padding: 18px;
        img{
            width: 110px;
            margin-bottom: 10px;
        }

        h2, span{
            margin-bottom: 28px;
        }

        button{
            margin: 12px 0 28px 0;
        }
    }

    @media screen and (max-width: 900px) {
        padding: 32px;
    }
`;
  
export const LandingOptionLogin = styled.div<OptionLoginT>`
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    border: ${(props) =>
      props.status === 'active'
        ? '1px solid var(--primary-color)'
        : '1px solid var(--border-2-color)'};
    border-radius: 4px;
    margin-bottom: 22px;
    cursor: pointer;

    &::after {
      content: url(${arrow_right});
      opacity: ${(props) =>
      props.status === 'active'
        ? '1'
        : '0.3'};
    }
  
    @media screen and (max-width: 1400px) {
      padding: 12px;
      margin-bottom: 16px;
    }
  
  `;