import { Dispatch } from "redux";
import { StudentM } from "../../../../models/student.model";
import { studentDetailsSetStudentForm, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { GradeController } from "../../../../controllers/grade.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { clearCPF, formatCPF, generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { GradeM } from "../../../../models/grade.model";
import { StudentController } from "../../../../controllers/student.controller";
import { UserStudentM } from "../../../../models/user-student.model";
import { studentContentsNames } from "../../../../features/types/student-slice.type";

export class TabAddStudentInfomationService{
    dispatch : Dispatch;
    gradeController: GradeController;
    studentController: StudentController;
    
    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;

        this.gradeController = new GradeController();
        this.studentController = new StudentController();
    }

    public handleGradesToOptions(gradesList? : GradeM[]){
        const options = gradesList?.map(grade=>(
            {value: grade.id?.toString()!, content: grade.name!}
        ));
    
        return options;
    }

    public handleMenuTypesToOptions(){
        return [
             {value: 'default', content: 'Cardápio Geral'},
             {value: 'special', content : 'Cardápio Espécial'}
         ];
    }

    public handleSexToOptions(){
        return [
             {value: 'M', content: 'Masculino'},
             {value: 'F', content : 'Feminino'}
         ];
     }

    public handleGetGrades(setGradesData : Function){
        this.dispatch(generalSetIsLoading(true));
        this.gradeController.getAllByInstitute().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setGradesData(data);
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleChangeName(value: string, userStudent: UserStudentM){
        const student = {...userStudent.student};
        student.name = value;
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student,
            name : value
        }));
    }

    public handleChangeCPF(value: string, userStudent: UserStudentM){
        const cleanedValue = value.replace(/\D/g, '');

        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            cpf : formatCPF(cleanedValue)
        }));
    }

    public handleFindGradeByValue(value: string,  userStudent: UserStudentM, gradeList? : GradeM[]){
        const grade =  gradeList?.find(grade => grade.id?.toString() === value);
        const student = {...userStudent.student};
        student.grade = grade!;
        student.gradeId = grade!.id;
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student
        }));
    }

    public handleChangePassword(value: string, userStudent: UserStudentM){
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            password : value 
        })); 
        
    }

    public handleChangeRegistrationCode(value: string, userStudent: UserStudentM){
        const student = {...userStudent.student};
        student.registrationCode = value;
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student 
        })); 
        
    }

    public handleChangeBirthDate(value: string, userStudent: UserStudentM){
        const student = {...userStudent.student};
        student.birthDate = new Date(value);
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student 
        })); 
        
    }

    public handleChangeCanteenPassword(value: string, userStudent: UserStudentM){
        const student = {...userStudent.student};
        student.canteenPassword = value;
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student
        }));
    }

    public handleChangeMenuTypeByValue(value: string, userStudent: UserStudentM){
        const specialMenu = value === 'special';
        const student = {...userStudent.student};
        student.usesSpecialMenu = specialMenu;
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student
        }));
    }


    public handleChangeSexByValue(value: string, userStudent: UserStudentM){
        const sex = value;
        const student = {...userStudent.student};
        student.sex = sex;
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student
        }));
    }

    private validateStudentForm(userStudent: UserStudentM) {
        const fieldsErrors = [];

        if (!userStudent.name && !userStudent.student.name) {
            fieldsErrors.push('nome do aluno')
        }

        if (!userStudent.cpf) {
            fieldsErrors.push('CPF do aluno')
        }

        if (!userStudent.student.registrationCode) {
            fieldsErrors.push('código de registro')
        }

        if (!userStudent.student.birthDate) {
            fieldsErrors.push('data de nascimento')
        }

        if (!userStudent.student.sex) {
            fieldsErrors.push('gênero')
        }

        if (!userStudent.student.gradeId) {
            fieldsErrors.push('ano/year')
        }

        // if (!userStudent.id && !userStudent.student.canteenPassword) {
        //     fieldsErrors.push('senha para acesso a cantina')
        // }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }

        return true;
    }

    public async handleSubmit(userStudent: UserStudentM){
        if(this.validateStudentForm(userStudent)){
            this.dispatch(generalSetIsLoading(true));
            await this.studentController.createAndUpdate({...userStudent, cpf : clearCPF(userStudent.cpf)}).then(async response=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    const student = await this.studentController.getById(data.id);
                    student.data.student.birthDate = new Date(student.data.student.birthDate+"T00:00:00.000Z"); 
                    this.dispatch(studentDetailsSetStudentForm(student.data));
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Cadastro realizado com sucesso. Continue editando as informações do aluno',
                    });
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }

}
