import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { StudentController } from "../../../../controllers/student.controller";
import { GradeM } from "../../../../models/grade.model";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { UserStudentM } from "../../../../models/user-student.model";
import { studentDetailsSetStudentForm, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { studentContents, studentContentsNames, studentDetailsContentsNames } from "../../../../features/types/student-slice.type";

export class GradeStudentsListModalService{
    dispatch : Dispatch;
    studentController : StudentController;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        
        this.studentController = new StudentController();
    }

    public handleShowStudentInfo(userStudent : UserStudentM){
        const student = {...userStudent.student};
        student.birthDate = new Date(student.birthDate+"T00:00:00.000Z"); 
        this.dispatch(studentDetailsSetStudentForm({
            ...userStudent,
            student : student
        }));
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.STUDENT_DETAILS));
    }

    public async handleSearchStudents(setStudents : Function, grade?: GradeM, search?: string){
        this.dispatch(generalSetIsLoading(true));
        await this.studentController.getAll(grade, search).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setStudents(data);
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }
}