import styled from "styled-components";

interface ToastContainerProps{
    color?: string;
    type?: string;
}


export const ToastContainer = styled.div<ToastContainerProps>`
    position: fixed;
    display: flex;
    align-items: center;
    max-width: 500px;
    gap: 10px;
    top: 20px;
    right: ${ props=>props.type ? '20px' : '-600px'};
    padding: 10px 20px;
    background-color: ${props=>props.color};
    border-radius: 4px;
    transition: right 0.5s ease;
    z-index: 100;


    img{
        width: 32px;
    }

    @media screen and (max-width : 1400px){
        padding: 8px 15px;

        img{
            width: 24px;
        }
    }
`;