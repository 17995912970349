

import styled from "styled-components"

type TabStudentEvaluationFinalSliderContentProps = {
    width: number;
}

export const TabStudentEvaluationFinalContainer = styled.div`
    
`;

export const TabStudentEvaluationFinalItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
`;
export const TabStudentEvaluationFinal = styled.div`
    display: flex;
    gap: 32px;
    margin: 20px;
`;

export const TabStudentEvaluationFinalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    img{
        width: 185px;
        height: 185px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid var(--border-color);
    }

    @media screen and (max-width: 1400px) {
        img{
            width: 138px;
            height: 138px;
        }
    }
`;

export const TabStudentEvaluationFinalSliderContainer = styled.div`
    height: 23px;
    width: 700px;
    border-radius: 8px;
    margin-bottom: 100px;
    /* border: 1px solid var(--green-color); */

    @media screen and (max-width: 1400px){
        height: 17px;
        width: 525px;
        margin-bottom: 75px;
    }
`;

export const TabStudentEvaluationFinalSliderContent = styled.div<TabStudentEvaluationFinalSliderContentProps>`
    height: 100%;
    background-color: var(--green-color);
    border-radius: 8px;
    width: ${props=>props.width}%;
`;

export const TabStudentEvaluationFinalEmotions = styled.div`
    width: 700px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;

    img{
        border: none;
        width: 48px;
        height: 48px;
    }

    .active{
        height: 65px;
        span{
            font-weight: bold !important;
        }
        img{
            width: 58px;
            height: 58px;
        }
    }

    @media screen and (max-width: 1400px){
        width: 525px;
        margin-top: 24px;
        img{
            width: 36px;
            height: 36px;
        }

        .active{           
            img{
                width: 46px;
                height: 46px;
            }
        }
    }
`;

export const TabStudentEvaluationFinalEmotionsItem = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
    cursor: pointer;
    padding: 16px;
    border-radius: 8px;
`;

export const TabStudentEvaluationFinalTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0;

    
    
    @media screen and (max-width: 1400px){
        margin: 26px 0;   
    }
`;

export const TabStudentEvaluationFinalButtonBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        width: 420px;
        justify-content: center;
        padding: 20px 0;

    }

    @media screen and (max-width: 1400px){
        button{
            width: 315px;
            padding: 15px 0;
        }  
    }
`;