import { useEffect, useRef, useState } from "react";
import { DatePicker } from "../../components/date-picker.component";
import { Dropdown } from "../../components/dropdown.component";
import { TabFoodMenuContainer, TabFoodMenuTitle, TabMenuFoodDropDownBar, TabFoodMenuSearchBar, TabMenuFoodTopBar, TabFoodMenuListProducts, TabFoodMenuProductItem, TabFoodMenuProductItemOptions, TabFoodMenuAddItem } from "./styles/tab-food.styled";
import { DatePickerMultiT } from "../../components/types/datepicker.type";
import { TabFoodService } from "./services/tab-food.service";
import { ButtonPrimary } from "../../components/button-primary.component";
import { Table } from "../../components/table.component";
import { OptionsMenu } from "../../components/options-menu.component";
import { useDispatch } from "react-redux";
import { TodaysMenuI } from "../../../models/interfaces/todays-menu.interface";
import { addDays } from "date-fns";
import { FullMealI } from "../../../models/interfaces/full-meal.interface";
import { baseUrl } from "../../../api/api";
import { SelectConsumableModalPage } from "./select-cosumable-modal.page";
import { useAppSelector } from "../../../hooks";
import { dateToString } from "../../../utils/config";
import plus_icon from '../../../assets/icons/plus.svg';
import arrow_up_icon from '../../../assets/icons/arrow-up.svg';
import arrow_down_icon from '../../../assets/icons/arrow-down.svg';
import edit_icon from '../../../assets/icons/edit.svg';
import moment from "moment";
import { TabFoodRemoveMenuItem } from "./styles/tab-food-add-menu.styled";
import trash_icon from "../../../assets/icons/trash.svg"

export const TabFoodMenuPage = () => {
    const dispatch = useDispatch();

    const [selectedTodaysMenu, setSelectedTodaysMenu] = useState<TodaysMenuI | null>();
    const [datesSearch, setDatesSearch] = useState<DatePickerMultiT>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [isEdit, setIsEdit] = useState(false);
    const theadRef = useRef<HTMLTableSectionElement>(null);

    const foodReducer = useAppSelector(state=>state.foodReducer);

    const tabFoodMenuService = new TabFoodService(dispatch, setIsEdit);
    const [selectedMenuType, setSelectedMenuType] = useState<string>();

    useEffect(()=>{
        if(!selectedMenuType)
            setSelectedMenuType(foodReducer.todaysMenusForm.rows[0]?.menuGrade?.name);
    }, [foodReducer.todaysMenusForm]);
    

    useEffect(() => {
        if (selectedDate) {
          tabFoodMenuService.handleSelectedDate(selectedDate, setDatesSearch, setSelectedMenuType);
        }
    }, [selectedDate]);

    useEffect(()=>{
        tabFoodMenuService.handleSearchTodaysMenu(setSelectedTodaysMenu, datesSearch);
    }, [datesSearch]);

    useEffect(()=>{
        if(selectedTodaysMenu){
            tabFoodMenuService.handleSearchFullMeal(selectedTodaysMenu, foodReducer.todaysMenusForm, theadRef);
        }
    }, [selectedTodaysMenu, isEdit]);

    return (
        <TabFoodMenuContainer>
            { foodReducer.showSelectCosumableModal && <SelectConsumableModalPage/> }
            <TabMenuFoodTopBar>
                <TabFoodMenuTitle>
                    <h2 className='primaryBlackSemibold28-150'>Cardápio Semanal</h2> 
                    <span className='primaryBlackNormal18-120'>{dateToString(new Date())}</span>
                </TabFoodMenuTitle>

                <TabFoodMenuSearchBar autoComplete="off">
                    <TabMenuFoodDropDownBar>
                        <DatePicker
                            dates={datesSearch}
                            setDate={setSelectedDate}
                            selectsRange={true}
                        />
                        <Dropdown
                            styleClass='input-year-style'
                            placeholder='Tipo de cardápio'
                            defaultValue={selectedMenuType}
                            options={tabFoodMenuService.handleMenuTypesToOptions(foodReducer.todaysMenusForm.rows)}
                            onChange={(value)=>setSelectedMenuType(value)}
                        />
                    </TabMenuFoodDropDownBar>
                    
                    <ButtonPrimary className="new-menu-button" onClick={()=>tabFoodMenuService.handleShowAddItemPage()}>
                        <img src={plus_icon} alt="Icone de Adicionar"/>
                        Criar novo cardápio
                    </ButtonPrimary>

                </TabFoodMenuSearchBar>
            </TabMenuFoodTopBar>

            { foodReducer.todaysMenusForm?.count === 0 ?
                <div className='empty-table'>
                    <span className='primaryBlackSemibold20-150'>Nenhum cardápio de {moment(datesSearch?.startDate).format('DD/MM/yyyy')} à {moment(datesSearch?.endDate).format('DD/MM/yyyy')}</span> 
                </div> :
                foodReducer.todaysMenusForm?.rows.map((todayMenu, todayMenuIndex)=>(
                    <>
                        {
                            todayMenu.menuGrade?.name === selectedMenuType  &&
                            <Table key={`table-${todayMenuIndex}`}>
                        
                                <thead 
                                    className='primaryBlackSemibold20-150' 
                                    ref={selectedTodaysMenu?.id === todayMenu.id ? theadRef : null} 
                                >
                                    <th>
                                        {dateToString(addDays(todayMenu.date, 1))}
                                        <span className='primaryBlackNormal18-150 tableSubTitle'>Alimentos</span>
                                    </th>
                                    <th className="options-column">
                                        <img className="arrow" onClick={selectedTodaysMenu?.id !== todayMenu.id ? ()=>setSelectedTodaysMenu(todayMenu) : ()=>setSelectedTodaysMenu(null)}  src={selectedTodaysMenu?.id === todayMenu.id ? arrow_up_icon : arrow_down_icon} alt="Icone de seta"/>
                                        <OptionsMenu
                                            options={tabFoodMenuService.handleGetOptions(
                                                foodReducer.todaysMenusForm, 
                                                todayMenuIndex
                                            )}
                                        />
                                    </th>
                                </thead>
                                { selectedTodaysMenu?.id === todayMenu.id && 
                                    todayMenu?.fullmeals?.map((fullMeal, fullMealIndex)=>(
                                        <tbody key={`fullMealContainer-${fullMealIndex}`}>
                                            <tr>
                                                <td className="full-line text-start">
                                                    <span className="primaryBlackSemibold20-150">{fullMeal.foodType?.name}</span>
                                                    <TabFoodMenuListProducts>
                                                        { 
                                                            fullMeal.consumables?.length === 0 && !isEdit ?
                                                            <div className="full-line">
                                                                <span className='primaryBlackNormal20-150'>Nenhum item neste dia</span>
                                                            </div> :
                                                            fullMeal.consumables?.map((consumable, consumableIndex)=>(
                                                                <TabFoodMenuProductItem className={`consumableContainer-${consumableIndex}`}>
                                                                    <div>
                                                                        <img src={`${baseUrl}/${consumable.media?.content}`} alt="Foto do produto"/>
                                                                        { isEdit && 
                                                                            <TabFoodRemoveMenuItem onClick={
                                                                                ()=>tabFoodMenuService.handleRemoveConsumable(
                                                                                    foodReducer.todaysMenusForm,
                                                                                    todayMenuIndex,
                                                                                    fullMealIndex, 
                                                                                    consumableIndex
                                                                                )
                                                                            }>
                                                                                <img src={trash_icon} alt="Icone de remover"/>
                                                                                <span className="primaryWhiteMedium18-150">Excluir</span>
                                                                            </TabFoodRemoveMenuItem>
                                                                        }
                                                                    </div>
                                                                    <span className="primaryBlackNormal20-150" title={consumable.name}>{consumable.name}</span>
                                                                    <span className="primaryGreyNormal18-150">{consumable.amountInGrams}g</span>

                                                                    {isEdit && 
                                                                        <TabFoodMenuProductItemOptions>
                                                                            <img src={edit_icon} alt="Icone de editar"/>
                                                                            <span 
                                                                                className="primaryPrimaryNormal18-150"
                                                                                onClick = {()=>tabFoodMenuService.handleAlterConsumable(
                                                                                    foodReducer.todaysMenusForm,
                                                                                    todayMenuIndex,
                                                                                    fullMealIndex, 
                                                                                    consumableIndex
                                                                                )}
                                                                            >
                                                                                Mudar alimento
                                                                            </span>
                                                                        </TabFoodMenuProductItemOptions>
                                                                    }
                                                                </TabFoodMenuProductItem>
                                                            ))

                                                            
                                                        }
                                                        {isEdit &&
                                                            <TabFoodMenuAddItem onClick={()=>tabFoodMenuService.handleShowSelectConsumableModal(todayMenuIndex, fullMealIndex)}>
                                                                <div>
                                                                    <img src={plus_icon} alt="Icone de Adicionar"/>
                                                                </div>
                                                                <span className="primaryGreyMedium20-150">Alimento novo</span>
                                                                <span className="primaryPrimaryMedium18-150">Escolher alimento</span>
                                                            </TabFoodMenuAddItem>
                                                        }
                                                    </TabFoodMenuListProducts>
                                                </td>                        
                                            </tr>
                                            
                                        </tbody>
                                    ))
                                }
                                { selectedTodaysMenu?.id === todayMenu.id && isEdit && 
                            
                                    <ButtonPrimary 
                                        className="button-change"
                                        onClick={()=>tabFoodMenuService.handleSubmit(foodReducer.todaysMenusForm.rows, setIsEdit)}
                                    >
                                        Salvar alterações
                                    </ButtonPrimary> 
                                    
                                }
                            </Table>  
                        }
                    </>
                ))
            }  
        </TabFoodMenuContainer>
    );
};