import styled from "styled-components"

export const TabStudentEvaluationMealContainer = styled.div`
    .active{
        border-color: var(--green-color) !important;
    }
`;

export const TabStudentEvaluationMealTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0;

    
    
    @media screen and (max-width: 1400px){
        margin: 26px 0;   
    }
`;

export const TabStudentEvaluationMealButtonBar = styled.div`
    button{
        width: 395px;
        margin-top: 80px;
        margin-left: auto;
        justify-content: center;
        padding: 20px 0;

    }

    @media screen and (max-width: 1400px){
        button{
            width: 296px;
            margin-top: 60px;
            padding: 15px 0;
        }  
    }
`;

export const TabStudentEvaluationMealItem = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 50px 20px 24px;
    border-radius: 12px;
    border: 2px solid var(--grey-color);
    margin: 18px 0;
    cursor: pointer;

    &:hover{
        border-color: var(--green-color);
    }

    @media screen and (max-width: 1400px){
        padding: 15px 37px 15px 18px;
        margin: 14px 0;   
    }
`

export const TabStudentEvaluationMealTitle = styled.div`

`

export const TabStudentEvaluationMealSearchBarInputs = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;

    .inputSearch{
        width: 100%;
    }

 
    .input-style{
        margin-top: 12px !important;
        min-height: 50px !important;
    }

    @media screen and (max-width: 1400px){
        .input-style{
            margin-top: 8px !important;
            min-height: 41px !important;

        }

    }
`;

export const TabStudentEvaluationMealSearchBar = styled.form`
    button{
       margin-left: auto;
       margin-top: 12px;
       padding: 12px 16px;
    }

    @media screen and (max-width: 1400px){
        margin-top: 9px;
        padding: 9px 12px;
    }   
`

export const TabStudentEvaluationMealAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 12px;
    background-color: var(--primary-opacity-2-color);
    border-radius: 8px;

    img{
        width: 24px;
    }

    @media screen and (max-width : 1400px){
        padding: 15px 0;
        img{
            width: 18px;
        }
    }
`;

export const TabStudentEvaluationMealContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    margin-top: 48px;

    .special{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 8px;
        background-color: var(--green-opacity-color);
        border-radius: 8px;
        max-width: 94px;
        @media screen and (max-width: 1400px){
            max-width: 76px;
        }
    
    }

    .border-none{
        border: none;
    }

    .full-padding{
        padding: 24px 27px;
    }

    td{
        justify-content: center;
    }

    @media screen and (max-width : 1400px){
        gap: 36px;
        margin-top: 36px;

        .full-padding{
            padding: 18px 20px;
        }

    }

`