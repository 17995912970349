import { handleRequest } from "../api/api-request.service";
import { FoodTypeM } from "./food-type.model";
import { FoodTypeI } from "./interfaces/food-type.interface";
import { FullMealI } from "./interfaces/full-meal.interface";
import { MealConsumableI } from "./interfaces/meal-consumable.interface";
import { MediaI } from "./interfaces/media.interface";
import { SchoolObservationI } from "./interfaces/school-observations.interface";
import { StudentMealAcceptanceI } from "./interfaces/student-meal-acceptance.interface";
import { StudentScoreI } from "./interfaces/student-score.interface";
import { MediaM } from "./media.model";

export class FullMealM implements FullMealI{
    id?: number;
    todaysMenuId?: number;
    description: string;
    media?: MediaI;
    consumables: MealConsumableI[];
    studentScores?: StudentScoreI[];
    studentsAcceptances?: StudentMealAcceptanceI[];
    observations?: SchoolObservationI[];
    foodType?: FoodTypeI;
    foodTypeId?: number;

    constructor({
        id,
        foodType,
        todaysMenuId,
        description,
        media,
        consumables,
        studentScores,
        observations,
        foodTypeId,
        studentsAcceptances
    }: FullMealI) {
        this.id = id;
        this.foodType = foodType;
        this.todaysMenuId = todaysMenuId;
        this.description = description;
        this.media = media;
        this.consumables = consumables;
        this.studentScores = studentScores;
        this.observations = observations;
        this.foodTypeId = foodTypeId;
        this.studentsAcceptances = studentsAcceptances;

    }

    public static initial(){
        return new FullMealM({
            description : '',
            foodType : FoodTypeM.initial(),
            media: MediaM.initial(),
            consumables : [],
            studentScores : [],
            studentsAcceptances: [],
            observations : [],

        })
    }

    public static getByTodaysMenuId(selectedTodaysMenuId: number){
        return handleRequest(
            `full-meal/by/todays-menu/${selectedTodaysMenuId}`,
            'GET'
        );
    }

    static async create(fullMeal : FullMealM){
        return handleRequest(
            `full-meal`,
            'POST',
            fullMeal
        );
    }

    static async update(fullMeal : FullMealM){
        return handleRequest(
            `full-meal/${fullMeal.id}`,
            'PUT',
            fullMeal
        );
    }

    static async createList(fullMeals : FullMealM[]){
        return handleRequest(
            `full-meal`,
            'POST',
            fullMeals
        );
    }

    static async updateList(fullMeals : FullMealM[]){
        return handleRequest(
            `full-meal`,
            'PUT',
            fullMeals
        );
    }
    
}