// import { AnnouncementM } from "../../models/announcements.model";
import { AnnouncementI } from "../../models/interfaces/announcements.interface";
import { TabAnnouncementsForm } from "../../views/modules/announcements/tab-announcements-form.page";
import { TabAnnouncements } from "../../views/modules/announcements/tab-announcements.page";

const tabsNames = {
    ANNOUNCEMENTS: 'Comunicados',
}

export const announcementsContentsNames = {
    TAB_ANNOUNCEMENTS: 'announcements',
    TAB_FORM_ANNOUNCEMENT: 'form-announcements'
}

export const announcementsTabToContent = {
    [tabsNames.ANNOUNCEMENTS]: announcementsContentsNames.TAB_ANNOUNCEMENTS,
}

export const announcementsTabList = Object.values(tabsNames);

export const announcementsContents = {
    [announcementsContentsNames.TAB_ANNOUNCEMENTS]: <TabAnnouncements />,
    [announcementsContentsNames.TAB_FORM_ANNOUNCEMENT]: <TabAnnouncementsForm />
}

export type AnnouncementsDataStateT = {
    count: number,
    rows: AnnouncementI[]
}

export type AnnouncementsStateT = {
    tabSelectedItem: string,
    tabSelectedContent: string,
    announcementsData?: AnnouncementsDataStateT,
    // announcementsForm: AnnouncementM
}

