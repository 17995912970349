import React, { useEffect, useState } from 'react';
import { TabGradeCard, TabGradeContainer, TabGradeContent, TabGradeSearchBar } from "./styled/tab-grade.styled";
import { Input } from "../../components/input.component";

import search_icon from "../../../assets/icons/search.svg";
import plus_icon from "../../../assets/icons/plus.svg";
import students_mult_icon from "../../../assets/icons/students_mult.svg";
import { ButtonPrimary } from '../../components/button-primary.component';
import { GradeM } from '../../../models/grade.model';
import { TabGradesService } from './services/tab-grades.service';
import { useDispatch } from 'react-redux';

export const TabGradePage = ()=>{
    const [search, setSearch] = useState('');
    const [gradesData, setGradesData] = useState<{count: number, rows : GradeM[]}>();
    const dispatch = useDispatch();

    const tabGradesService = new TabGradesService(dispatch);

    useEffect(()=>{
        tabGradesService.handleGetGrades(setGradesData, search);
    }, [search]);

    return(
        <TabGradeContainer>
            
            <TabGradeSearchBar autoComplete="off">
                <Input
                    className='inputSearch'
                    type='search'
                    name='inputSearch'
                    id='inputSearch'
                    placeholder='Busque pelo nome do item'
                    value={search}
                    onChange={setSearch}
                    prefix={
                        <img src={search_icon} alt="Icone de busca"/>
                    }
                />
                <ButtonPrimary className="addGrade" onClick={()=>tabGradesService.handleOpenAddGrade()}>
                    <img src={plus_icon} alt="Icone de adicionar"/>
                    Cadastrar nova turma
                </ButtonPrimary>
            </TabGradeSearchBar>
            { gradesData && gradesData.count > 0 ?
                <TabGradeContent>
                    {
                        gradesData?.rows.map(grade=>(
                            <TabGradeCard onClick={()=>tabGradesService.handleOpenEditGrade(grade)}>
                                <span className='primaryGreyMedium18-120'>Turma</span>
                                <span className='primaryBlackSemibold20-120'>{grade.name}</span>
                                <div className='students'>
                                    <img src={students_mult_icon} alt="Icone de alunos"/>
                                    <span className='primaryGreyMedium16-120'>{
                                        grade.studentCount && grade.studentCount > 0 ? 
                                        `${grade.studentCount} ${grade.studentCount === 1 ? 'aluno' : 'alunos'}` :
                                        'Nenhum aluno'
                                    }</span>
                                </div>
                                
                            </TabGradeCard>
                            
                        ))
                    }
                </TabGradeContent> :
                <div className='empty-table mt'>
                    <span className='primaryBlackSemibold20-150'>Nenhuma turma cadastrada</span> 
                </div>
            }
        </TabGradeContainer>
    );
}