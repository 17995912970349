import { ReactNode } from "react"
import { UserSchoolWorkerM } from "../../models/user-school-worker.model"

export const toastTypes = {
    SUCCESS : 'SUCCESS',
    ERROR : 'ERROR'
}

export type ToastValueT = {
    type : string,
    text : string
}

export type AlertDialogT = {
    isOpen : boolean,
    title : string,
    text : string,
    action : {
        text : string,
        onClick : Function
    }
}

export type GeneralStateT = {
    isLoadig : boolean,
    reload : boolean,
    toast : ToastValueT,
    alertDialog : AlertDialogT,
    userSchoolWorker?: UserSchoolWorkerM
}