import styled from "styled-components"

export const TabFoodMenuConsumableContainer = styled.div`

`

export const TabFoodMenuConsumableTitle = styled.div`

`

export const TabFoodMenuConsumableSearchBarInputs = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;

    .inputSearch{
        width: 100%;
    }


    .input-style{
        margin-top: 12px !important;
        min-height: 55px !important;
        border-radius: 4px;
    }

    @media screen and (max-width: 1400px){
        .input-style{
            margin-top: 8px !important;
            min-height: 41px !important;

        }

    }
`;

export const TabFoodMenuConsumableSearchBar = styled.form`
    button{
       margin-left: auto;
       margin-top: 12px;
       padding: 12px 16px;
    }

    @media screen and (max-width: 1400px){
        margin-top: 9px;
        padding: 9px 12px;
    }   
`


export const TabFoodMenuConsumableContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    margin-top: 48px;

    .active{
        border: 2px solid var(--primary-color);
    }

    img{
        width: 65px !important;
        height: 65px !important;
    }

    @media screen and (max-width : 1400px){
        gap: 36px;
        margin-top: 36px;
    }

`

export const TabFoodMenuConsumableItemContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 2px;

    h3{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const TabFoodMenuConsumableItem = styled.div`
    cursor: pointer;
    min-height: 252px; 
    width: 236px;
    border-radius: 8px;
    border: 1.5px solid var(--border-color);

    display: flex;
    flex-direction: column;

    img{
        width: 100% !important;
        min-height: 155px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }


    @media screen and (max-width : 1400px){
        min-height: 189px;
        width: 177px;

        img{
            min-height: 116px;
        }
    }
`

export const TabFoodMenuConsumableEmptyImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 155px;
    background-color: var(--grey-light-3-color);
    border-radius: 8px 8px 0 0;

    @media screen and (max-width: 1400px){
        min-height: 116px;
    }
`;
