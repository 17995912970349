import styled from "styled-components";

interface TabAddNewConsumableNutritionalCategoryItemProps{
    color? : string;
    className? : string
}


export const TabAddNewConsumableContainer = styled.div`
   
`;

export const TabAddNewConsumableContent = styled.div`
   
`;

export const TabAddNewConsumableTitle = styled.div`
    width: 100%;
    margin: 24px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--border-color);
`;

export const TabAddNewConsumableForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 552px;
    h2{
        margin: 0;
    }
    button{
        align-items: center;
        justify-content: center;
        padding: 16px 18px;
    }

    @media screen and (max-width: 1400px){
        width: 414px;
    }
`;

export const TabAddNewConsumableNutritionalCategoryContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`

export const TabAddNewConsumableNutritionalCategoryItem = styled.div<TabAddNewConsumableNutritionalCategoryItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${props => `1px solid ${props.color}`};
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    color: ${props => props.className?.includes('active') ? 'var(--white-color)' : 'var(--black-color)'} !important;
    background-color: ${props => props.className?.includes('active') ? props.color : 'transparent'};

    @media screen and (max-width: 1400px) {
        padding: 2px 6px;
    }
`;

export const TabAddNewConsumableNutritionalCategoryAddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    padding: 4px 8px !important;
    width: 285px;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    border: 1px solid var(--border-2-color);
    cursor: pointer;

    img{
        filter: grayscale(100%);
    }
    

    @media screen and (max-width: 1400px){
        width: 215px;
        padding: 2px 6px !important;
        
        img{
            width: 18px;
        }
    }

`
