import { AnnouncementM } from "../models/announcements.model";
import { UserSchoolWorkerM } from "../models/user-school-worker.model";

export class AnnouncementsController {


    public static getByDateRange(startDate: Date, endDate: Date) {

    }

    async getAllByInstituteAndDates(startDate: Date, endDate: Date, search?: string, status?: string) {
        return await AnnouncementM.getAllByInstituteAndDates(1, startDate, endDate, search, status);
    }

    async getAll() {
        return await AnnouncementM.getAllByInstitute(1);
    }

    async delete(userId: number) {
        return await AnnouncementM.delete(userId);
    }


    async updateDataWithoutMedia(announcement: AnnouncementM) {
        return await AnnouncementM.update(announcement);
    }

    async createAndUpdate(announcement: AnnouncementM) {
        if (announcement.id) {
            return await AnnouncementM.update({
                ...announcement,
            });
        }
        return await AnnouncementM.create({
            ...announcement,
            userId: 1,
        });
    }
}