import styled from "styled-components";

interface SelectConsumableModalNutritionalCategoryItemProps{
    color? : string;
    className? : string
}

export const SelectConsumableModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 6;
    background-color: var(--grey-opacity-color);
`;

export const SelectConsumableModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--white-color);
    width: 900px;
    height: 98vh;
    border-radius: 8px;
    padding: 48px;
    overflow-y: scroll;

    @media screen and (max-width: 1400px){
        width: 675px;
        padding: 36px;
    }

`;

export const SelectConsumableModalTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2{
        margin: 0;
    }
    
    .close-button{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: var(--grey-light-3-color);
        border-radius: 6px;
        img{
            width: 32px;
        }
    }

    @media screen and (max-width: 1400px){
        .close-button{
            border-radius: 4px;
            img{
                width: 24px;
            }
        }
    }
`;

export const SelectConsumableModalForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 552px;
    button{
        align-items: center;
        justify-content: center;
        padding: 16px;
    }

    @media screen and (max-width: 1400px){
        width: 414px;
    }
`;

export const SelectConsumableModalNutritionalCategoryContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`

export const SelectConsumableModalNutritionalCategoryItem = styled.div<SelectConsumableModalNutritionalCategoryItemProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${props => `1px solid ${props.color}`};
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    color: ${props => props.className?.includes('active') ? 'var(--white-color)' : 'var(--black-color)'} !important;
    background-color: ${props => props.className?.includes('active') ? props.color : 'transparent'};

    @media screen and (max-width: 1400px) {
        padding: 2px 6px;
    }
`;

export const SelectConsumableModalNutritionalCategoryAddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    padding: 4px 8px !important;
    width: 285px;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    border: 1px solid var(--border-2-color);
    cursor: pointer;

    img{
        filter: grayscale(100%);
    }
    

    @media screen and (max-width: 1400px){
        width: 215px;
        padding: 2px 6px !important;
        
        img{
            width: 18px;
        }
    }

`
