import { handleRequest } from "../api/api-request.service";
import { ProductCategoryI } from "./interfaces/product-category.interface";

export class ProductCategoryM implements ProductCategoryI {
    id?: number;
    name: string;

    constructor({ id, name }: ProductCategoryI) {
        this.id = id;
        this.name = name;
    }

    static initial(){
        return new ProductCategoryM({
            name : ''
        });
    }

    static async getAll(){
        return handleRequest(
            `product-category`,
            'GET'
        );
    }

    static async create(category : ProductCategoryM){
        return handleRequest(
            `product-category`,
            'POST',
            category
        );
    }

    static async update(category : ProductCategoryM){
        return handleRequest(
            `product-category`,
            'PUT',
            category
        );
    }

}