import { handleRequest } from "../api/api-request.service";
import { StudentM } from "./student.model";
import { MediaM } from "./media.model";
import { UserM } from "./user.model";
import { UserStudentT } from "./interfaces/user-student.interface";

export class UserStudentM {

    id?: number;
    name: string;
    login?: string;
    email?: string;
    cpf: string;
    password: string;
    student : StudentM;
    instituteId?: number;

    constructor({id, name, login, email, cpf, password, student} : UserStudentT){
        this.id = id;
        this.name = name;
        this.login = login;
        this.email = email;
        this.cpf = cpf;
        this.password = password;
        this.student = student;
        this.instituteId = this.instituteId;
    }
    
    static initial() {
        return new UserStudentM({
            name: '',
            cpf: '',
            email: '',
            password: '',
            instituteId: 0,
            student: StudentM.initial(),
        })
    }

    static async getAllByInstitute(instituteId: number, grade?: string) {

        const query = `?offset=0&limit=50&grade=${grade ?? ''}`;

        return handleRequest(
            `user-student/by/institute/${instituteId}${query}`,
            'GET'
        )
    }


    static async update(userStudent: UserStudentM) {
        return handleRequest(
            `user-student/${userStudent.id}`,
            'PUT',
            userStudent
        );
    }

    static async create(userStudent: UserStudentM) {
        return handleRequest(
            `user-student`,
            'POST',
            userStudent
        );
    }

    static async delete(userId: number) {
        return handleRequest(
            `user-student/${userId}`,
            'DELETE'
        );
    }
}
    
