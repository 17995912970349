import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { FoodTypeController } from "../../../../controllers/food-type.controller";
import { foodSetEvaluationSelectedFoodType, foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { FoodTypeM } from "../../../../models/food-type.model";
import { foodContentsNames } from "../../../../features/types/food-slice.type";

export class TabStudentEvaluationMealService {
    dispatch : Dispatch;
    foodTypeController : FoodTypeController;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        this.foodTypeController = new FoodTypeController();

        this.handleSelectFoodType = this.handleSelectFoodType.bind(this);
        this.handleOpenEvaluationConsumable = this.handleOpenEvaluationConsumable.bind(this);
    }

    public handleSelectFoodType(foodType : FoodTypeM){
        this.dispatch(foodSetEvaluationSelectedFoodType(foodType));
    }

    public handleOpenEvaluationConsumable(){
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_CONSUMABLE));
    }
    
    public async handleSearchFoodTypes(setFoodTypeData : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.foodTypeController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setFoodTypeData(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

}