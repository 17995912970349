import moment from "moment";
import { handleRequest } from "../api/api-request.service";
import { StudentMonitoringI } from "./interfaces/student-monitoring.interface";

export class StudentMonitoringM implements StudentMonitoringI {
    id?: number;
    age: number;
    months: number;
    completeAge: string;
    imc?: number;
    weight?: number;
    height?: number;
    weightUnit: string;
    heightUnit: string;
    studentId?: number;
    registerDate?: Date;

    constructor({
        id,
        age,
        months,
        completeAge,
        imc,
        weight,
        height,
        weightUnit,
        heightUnit,
        studentId,
        registerDate
    }: StudentMonitoringI) {
        this.id = id;
        this.age = age;
        this.months = months;
        this.completeAge = completeAge;
        this.imc = imc;
        this.weight = weight;
        this.height = height;
        this.weightUnit = weightUnit;
        this.heightUnit = heightUnit;
        this.studentId = studentId;
        this.registerDate = registerDate;
    }

    static initial(){
        return new StudentMonitoringM({
            age : 0,
            months : 0,
            completeAge: "",
            weightUnit: "",
            heightUnit: ""
        })
    }

    static async getAll(studentId: number, startDate?: Date, endDate?: Date){
        let query = '';
        if(startDate && endDate){
            query = `?from=${moment(startDate).format('yyyy-MM-DD')}&to=${moment(endDate).format('yyyy-MM-DD')}`;
        }
        return handleRequest(
            `/student-monitoring/by/student/${studentId}${query}`,
            'GET'
        );
    }

    static async create(studentMonitoring : StudentMonitoringI){
        return handleRequest(
            `student-monitoring`,
            'POST',
            studentMonitoring
        );
    }

    static async update(studentMonitoring : StudentMonitoringI){
        return handleRequest(
            `student-monitoring/${studentMonitoring.id}`,
            'PUT',
            studentMonitoring
        );
    }
}
