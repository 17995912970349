import { Dispatch } from "redux";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { ordersStatus, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { TodaysMenuController } from "../../../../controllers/todays-menu.controller";
import { FullMealController } from "../../../../controllers/full-meal.controller";
import { AnnouncementsController } from "../../../../controllers/announcements.controller";
import { AnnouncementM } from "../../../../models/announcements.model";
import { tabAnnouncementsForm } from "../../../../features/tab-announcements-slice.feature";
import { announcementsSetTabSelectedContent } from "../../../../features/announcements-slice.feature";
import { announcementsContentsNames } from "../../../../features/types/announcements-slice.type";
import { OrderM } from "../../../../models/order.model";
import { OrderController } from "../../../../controllers/order.controller";
import { DatePickerMultiT } from "../../../components/types/datepicker.type";
import { MenuGradeController } from "../../../../controllers/menu-grade.controller";
import { MenuGradeM } from "../../../../models/menu-grade.modal";
import { OrderI } from "../../../../models/interfaces/order.interface";

export class DashboardHomeService{
    dispatch : Dispatch;

    todaysMenuController : TodaysMenuController;
    fullMealController : FullMealController;
    announcementController : AnnouncementsController;
    ordersController : OrderController;
    menuGradeController : MenuGradeController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.todaysMenuController = new TodaysMenuController();
        this.fullMealController = new FullMealController();
        this.announcementController = new AnnouncementsController();
        this.ordersController = new OrderController();
        this.menuGradeController = new MenuGradeController();
    }

    public async handleSearchFullMeal(
        selectedTodaysMenu : TodaysMenuM,
        setFullMealsData: Function
    ){
        if(selectedTodaysMenu?.id){
            this.dispatch(generalSetIsLoading(true));
            await this.fullMealController.getByTodaysMenuId(selectedTodaysMenu?.id).then(response=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    setFullMealsData(data);                 
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: `Ocorreu um erro ao obter as refeições da data ${selectedTodaysMenu.date}`,
                    })
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }

    public async handleSearchMenuGrade(
        selectedDate: Date,
        setMenuGradeData: Function,
        instituteId : number,
        setSelectedMenuGrade: Function
    ){
        this.dispatch(generalSetIsLoading(true));
        await this.menuGradeController.getByInstituteIdAndPeriod(
            {startDate : selectedDate, endDate : selectedDate}, 
            instituteId
        ).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setMenuGradeData(data);    
                setSelectedMenuGrade(data.rows[0]);             
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async handleGetTodayMenu(date: Date, setTodaysMenusData : Function, setSelectedTodayMenu: Function){
        this.dispatch(generalSetIsLoading(true));
        await this.todaysMenuController.getByDateRange(date, date).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setTodaysMenusData({count: data.count, rows : [data.rows[0]]});
                setTimeout(()=>{
                    setSelectedTodayMenu(data.rows[0]);
                }, 300);
                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });

    }

    public async handleGetOrders(
        orderDateSearch : Date, 
        setOrdersData : Function, 
        ordersDataPage: number, 
        ordersData? : {count: number, rows: OrderI[]}
    ){
        this.dispatch(generalSetIsLoading(true));
        await this.ordersController.getByCanteenId(1, orderDateSearch, ordersStatus.IN_PRODUCTION, ordersDataPage).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                if(ordersDataPage === 0){
                    setOrdersData(data);
                }else{
                    const newRows = [...ordersData?.rows || [], ...data.rows];
                    setOrdersData({
                        ...ordersData,
                        rows : newRows
                    });
                }
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleViewAnnouncement(announcement: AnnouncementM, navigate: Function) {
        this.dispatch(generalSetIsLoading(true));
        this.dispatch(tabAnnouncementsForm({ ...announcement }));
        this.dispatch(announcementsSetTabSelectedContent(announcementsContentsNames.TAB_FORM_ANNOUNCEMENT))
        navigate('/comunicados');
        this.dispatch(generalSetIsLoading(false));
    }

    public async handleGetAnnouncements(setAnnouncement : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.announcementController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setAnnouncement(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });

    }

    public setSelectedTodayMenu(
        value : string, 
        todaysMenus : TodaysMenuM[],
        setSelectedTodayMenu : Function, 
        setSelectedMenuType : Function
    ){
        let selectedTodayMenu;
        todaysMenus.map(todayMenu=>{
            if(todayMenu?.menuGrade?.name === value){
                selectedTodayMenu = todayMenu;
            }
        });
        setSelectedMenuType(value);
        setSelectedTodayMenu(selectedTodayMenu);
    }

    public handleMenuGradesToOptions(menuGradesList?: MenuGradeM[]) {
        const options = menuGradesList?.map(menuGrade=>(
            {value: menuGrade.name!, content: menuGrade.name!}
        ));
    
        return options;
    }

}