

import styled from "styled-components"

type TabStudentEvaluationSuccessSliderContentProps = {
    width: number;
}

export const TabStudentEvaluationSuccessContainer = styled.div`
    
`;

export const TabStudentEvaluationSuccessContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 83px;

    img{
        width: 195px;
        height: 195px;
        object-fit: cover;
        border-radius: 8px;
    }

    @media screen and (max-width: 1400px) {
        gap: 62px;
        img{
            width: 146px;
            height: 146px;
        }
    }
`;

export const TabStudentEvaluationSuccessTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0;

    
    
    @media screen and (max-width: 1400px){
        margin: 26px 0;   
    }
`;

export const TabStudentEvaluationSuccessButtonBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 83px;
    button{
        width: 420px;
        justify-content: center;
        padding: 20px 0;

    }

    @media screen and (max-width: 1400px){
        margin-top: 62px;
        button{
            width: 315px;
            padding: 15px 0;
        }  
    }
`;