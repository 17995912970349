import styled from "styled-components";

export const StudentCardContainer = styled.div`
    width: 100%;

    .fruit-item{
        display: flex;
        width: 44px;
        height: 44px;
        padding: 10.667px 18.106px 10.667px 17.6px;
        justify-content: center;
        align-items: center;

        border-radius: 1.067px;
        background: #F7F7F7;
    }

    .card-container{
        width: 100%;
        display: inline-flex;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border: 1px dashed var(--grey-600, #332D2D);
    }

    .card-content{
        display: flex;
    
        width: 100%;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;

        border-radius: 4px;
        border: 2px solid var(--contorno-cinza-usurio-aluno, #E4E4E4);
    }

    .content-bottom{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .fruits{
            display: flex;
            align-items: flex-start;
            gap: 9px;

            img{
                width: 20px;
            }
        }

        .title-small{
            color: #45413C;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 18px */
            text-transform: uppercase;
        }
    }

    .content-top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .content-left{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            .info{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
            }
        }

        .content-right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 4px;

            span{
                color: #7B7875;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
            }

            image{
                width: 96px;
                height: 96px;
            }
        }

        .healthy-puppy{
            color: var(--primary-base, #FF6242);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 19.2px */
        }

        .student-name{
            color: #45413C;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
        }

        span{
            color: #45413C;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 27px */
        }

      
    }
`;
