import styled from "styled-components";

export const TabAddStudentHealthyContainer = styled.div`
    
`;

export const TabAddStudentHealthyContent = styled.div`
    .border-bottom{
        border-bottom: 1px solid var(--border-color);
    }
`;

export const TabAddStudentHealthyWeeklyAssessment = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 32px;
    margin-bottom: 32px;
`;

export const TabAddStudentHealthyWeeklyAssessmentDiseases = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin-top: 12px;

    .new{
        opacity: 0.8;
        cursor: pointer;
    }
`;

export const TabAddStudentHealthyWeeklyAssessmentDisease = styled.div`
    align-items: center;
    width: 80%;
    display: flex;
    gap: 18px;
    padding: 12px 16px;
    background-color: #F9F9F9;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .remove{
        margin-left: auto;
        cursor: pointer;
    }

    img{
        width: 32px;
    }

    @media screen and (max-width: 1400px){
        img{
            width: 24px;
        }    
    }

    
`;

export const TabAddStudentHealthyWeeklyAssessmentTitle = styled.div`
    display: flex;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
`
export const TabAddStudentHealthyWeeklyAssessmentColumnSpecial = styled.div`
    width: 39%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    gap: 16px;

    background: rgba(255, 98, 66, 0.05);
    border-radius: 8px;



`;

export const TabAddStudentHealthyWeeklyAssessmentColumn = styled.div`
    width: 49%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const TabAddStudentHealthyLegend = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 12px;
`;

export const TabAddStudentHealthyWeeklyAssessmentSelect = styled.div`
    display: flex;
    justify-content: flex-start;
    
    width: 80%;
    gap: 12px;
    margin-bottom: 16px;
    label{
        display: flex;
        align-items: center;
        img{
            margin-left: -30px;
        }
    }

    .react-datepicker__month-0,
    .react-datepicker__month-1,
    .react-datepicker__month-3,
    .react-datepicker__month-4,
    .react-datepicker__month-5,
    .react-datepicker__month-6,
    .react-datepicker__month-7,
    .react-datepicker__month-9,
    .react-datepicker__month-10,
    .react-datepicker__month-11 {
        display: none !important;;
    }

    .react-datepicker__month-text--keyboard-selected{
        background-color: var(--primary-opacity-color);
    }

    .react-datepicker__month-text--selected{
        background-color: var(--primary-color);
        color: var(--white-color);
    }

    .react-datepicker__month-2,
    .react-datepicker__month-8{
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 10px 40px;
    }

    .date-picker, .arrow{
        display: none;
        img{
            display: none;
        }
    }

    .react-datepicker__triangle{
        margin-left: 30px !important;
    }

    .react-datepicker{
        margin-left: -50px;
    }

   .inputDatePicker{
        max-width: 30px;
    }
`

export const TabAddStudentHealthyWeeklyAssessmentForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;

    button{
        padding: 16px 18px;
        justify-content: center;
        margin-top: 12px;
    }
`;

export const TabAddStudentHealthyLegendItem = styled.div<{color : string}>`
    display: flex;
    align-items: center;
    width: 48% !important;
    gap: 12px;

    .line-student {
        display: flex;
        align-items: center;
        
        position: relative;
        background-color: #004DE1;
        height: 6px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 15px; 
            height: 15px;
            border-radius: 50%;
            background-color: #004DE1; 
        }

        &::before {
            left: -6px; 
        }

        &::after {
            right: -6px; 
        }
    }

    div{
        background-color: ${props=>props.color};
        width: 48px;
        height: 12px;
    }
`;



