import moment from "moment";
import { handleRequest } from "../api/api-request.service";
import { FoodTypeM } from "./food-type.model";
import { GradeM } from "./grade.model";
import { StudentI } from "./interfaces/student.interface";
import { UserI } from "./interfaces/user.interface";

export class StudentM implements StudentI {
    id?: number;
    registrationCode: string;
    birthDate?: Date;
    name: string;
    parentNameMother: string;
    parentNameFather: string;
    sex: string;
    canteenPassword: string;
    grade: GradeM;
    education: string;
    userId?: number;
    user?: UserI;
    usesSpecialMenu?: boolean;
    gradeId?: number;
    scores?: any[];

    constructor({
        id,
        registrationCode,
        birthDate,
        name,
        parentNameMother,
        parentNameFather,
        sex,
        canteenPassword,
        grade,
        education,
        userId,
        user,
        usesSpecialMenu,
        gradeId
    }: StudentI) {
        this.id = id;
        this.registrationCode = registrationCode;
        this.birthDate = birthDate;
        this.name = name;
        this.parentNameMother = parentNameMother;
        this.parentNameFather = parentNameFather;
        this.sex = sex;
        this.canteenPassword = canteenPassword;
        this.grade = grade;
        this.education = education;
        this.userId = userId;
        this.user = user;
        this.usesSpecialMenu = usesSpecialMenu;
        this.gradeId = gradeId;
    }

    static initial() : StudentM {
        return new StudentM({
            registrationCode: "",
            name: "",
            parentNameMother: "",
            parentNameFather: "",
            sex: "",
            canteenPassword: "",
            education: "",
            user: undefined,
            grade: GradeM.initial(),
        });
    }

    
    static async getAll(grade? : GradeM, search?: string,  selectedFoodType?: FoodTypeM, selectedDate?: Date){
        let query = '?limit=18&offset=0';

        if(grade){
            query += `&grade=${grade.name}`;
        }
        
        if(search){
            query += `&name=${search}`;
        }

        if(selectedFoodType){
            query += `&foodType=${selectedFoodType?.name}`
        }
        
        if(selectedDate){
            query += `&foodDate=${moment(selectedDate).format('yyyy-MM-DD')}`
        }
        return handleRequest(
            `user-student${query}`,
            'GET'
        );
    }

    static async getById(id: number){
        return handleRequest(
            `user-student/${id}`,
            'GET'
        );
    }

}