import { UserM } from "../models/user.model";

export class UserController {

    async getAllByInstitute( search?: string) {
        return await UserM.getAllByInstitute(1, search);
    }

    async delete(userId: number) {
        return await UserM.delete(userId);
    }

    async getById(userId: number) {
        return await UserM.delete(userId);
    }


    async updateDataWithoutMedia(user: UserM) {
        delete user.profilePic;
        return await UserM.update(user);
    }

    async createAndUpdate(user: UserM) {
        if (user.id) {
            return await UserM.update({
                ...user,
            });
        }
        return await UserM.create({
            ...user,
            instituteId: 1,
        });
    }
}