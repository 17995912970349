import { ChangeEvent } from 'react';
import { TextAreaContainer } from './styles/textarea.styled';
import { TextAreaT } from './types/textarea.type';

export const TextArea = ({
  label,
  id,
  value,
  placeholder,
  name,
  onChange,
  className,
  prefix,
  disabled
}: TextAreaT) => {

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {

    onChange(e.target.value);
  };

  return (
    <TextAreaContainer className={className}>
      <label className="primaryBlackSemibold18-150" htmlFor={id}>
        {label}
      </label>
      {prefix && <div className='prefix-container'>{prefix}</div>}
      <textarea
        className={prefix ? 'prefix-input' : ''}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />

    </TextAreaContainer>
  );
};
