import styled from "styled-components";

export const HistoricEvaluationsContainer = styled.div`
     .empty-table {
        min-height: 150px;
    }
`;


export const HistoricEvaluationsContent = styled.div`
   

`;

export const HistoricEvaluationsAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 12px;
    background-color: var(--primary-opacity-2-color);
    border-radius: 8px;

    img{
        width: 24px;
    }

    @media screen and (max-width : 1400px){
        padding: 15px 0;
        img{
            width: 18px;
        }
    }
`;