import React, { useState } from 'react';

import * as S from './styled';

import ilustratrion_login from '../../../../assets/images/ilustratrion_login.jpg';
import logo_image from '../../../../assets/images/logo75x75.png';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Input } from '../../../components/input.component';
import { ButtonPrimary } from '../../../components/button-primary.component';
import { ResetPasswordT } from './types';
import { formatCPF } from '../../../../utils/config';
import { ResetPasswordService } from './service';

export const ResetPasswordPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const resetPasswordService = new ResetPasswordService(dispatch);

    const [resetPasswordForm, setResetPasswordForm] = useState<ResetPasswordT>({ cpf: '', password: '', confirmPassword: '' });

    return (
        <S.ResetPasswordContainer>
            <S.ResetPasswordForm>
                <form action='#' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                    <S.ResetPasswordLogoContainer>
                        <img src={logo_image} alt={"Logo Healthy Puppy"} />
                        <span className='secondaryPrimaryBold16-120'>Healthy Puppy</span>
                    </S.ResetPasswordLogoContainer>

                    <h2 className='primaryBlackBold40-120'>Cadastar nova Senha</h2>

                    <span className='primaryBlackMedium18-150'>
                        Insira a sua nova senha de acesso.
                    </span>
                    <Input
                        className="input"
                        label='CPF'
                        type='text'
                        name='cpf'
                        id='cpf'
                        placeholder='Insira seu cpf'
                        value={resetPasswordForm.cpf}
                        onChange={(value: any) => {
                            const cleanedValue = value.replace(/\D/g, '');
                            setResetPasswordForm({ ...resetPasswordForm, cpf: formatCPF(cleanedValue) })
                        }}
                    />

                    <Input
                        className="input"
                        label='Nova Senha'
                        type='password'
                        name='password'
                        id='password'
                        placeholder='Nova Senha'
                        value={resetPasswordForm.password}
                        onChange={(value: any) => {
                            setResetPasswordForm({ ...resetPasswordForm, password: value })
                        }}
                    />

                    <Input
                        className="input"
                        label='Confirme sua senha'
                        type='password'
                        name='confirmPassword'
                        id='confirmPassword'
                        placeholder='Confirmar Senha'
                        value={resetPasswordForm.confirmPassword}
                        onChange={(value: any) => {
                            setResetPasswordForm({ ...resetPasswordForm, confirmPassword: value })
                        }}
                    />

                    <ButtonPrimary className='submitButton' onClick={() =>
                        resetPasswordService.submit(resetPasswordForm, token!)
                    }>Salvar nova senha</ButtonPrimary>
                </form>
            </S.ResetPasswordForm>

            <S.ResetPasswordImage
                src={ilustratrion_login}
                alt="Imagem de fundo para a página de login"
            />
        </S.ResetPasswordContainer>
    );
};