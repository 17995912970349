import { UserStudentM } from "../models/user-student.model";
import { StudentMonitoringM } from "../models/student-monitoring.model";

export class StudentMonitoringController {
    
    async getAll(studentId: number, startDate? : Date, endDate?: Date){
        return await StudentMonitoringM.getAll(studentId, startDate, endDate);
    }

    async createAndUpdate(studentMonitoring : StudentMonitoringM){
        if(studentMonitoring.id){
            return await StudentMonitoringM.update(studentMonitoring);
        }
        return await StudentMonitoringM.create(studentMonitoring);
    }
}