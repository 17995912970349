import { handleRequest } from "../api/api-request.service";
import { NutritionalCategoryI } from "./interfaces/nutritional-category.interface";

export class NutritionalCategoryM implements NutritionalCategoryI {
    id?: number;
    name: string;
    hexColor? : string;

    constructor({ id, name, hexColor }: NutritionalCategoryI) {
        this.id = id;
        this.name = name;
        this.hexColor = hexColor;
    }

    static initial(): NutritionalCategoryM{
        return new NutritionalCategoryM({
            name: ""
        });
    }

    public static getAll(){
        return handleRequest(
            `nutritional-category`,
            'GET'
        );
    }

    static async create(nutritionalCategory : NutritionalCategoryM){
        return handleRequest(
            `nutritional-category`,
            'POST',
            nutritionalCategory
        );
    }

    static async update(nutritionalCategory : NutritionalCategoryM){
        return handleRequest(
            `nutritional-category`,
            'PUT',
            nutritionalCategory
        );
    }
}
