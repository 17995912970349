import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StudentStateT } from './types/student-slice.type';
import { StudentM } from '../models/student.model';
import { UserStudentM } from '../models/user-student.model';
import { GradeM } from '../models/grade.model';

const initialState : StudentStateT = {
    tabSelectedItem : 'Lista de Alunos',
    tabSelectedContent : 'students-list',
    tabDetailsSelectedContent: 'student_information',
    tabDetailsSelectedItem: 'Informações do aluno',
    studentForm : UserStudentM.initial(),
    gradeForm: GradeM.initial(),
    showGradeListStudent : false
} 

export const studentSlice = createSlice({
   name: 'students',
   initialState,
   reducers: {
        studentSetTabSelectdItem : (state, action : PayloadAction<string>)=>{
            state.tabSelectedItem = action.payload;
        },
        studentSetTabSelectedContent: (state, action : PayloadAction<string>)=>{
            state.tabSelectedContent = action.payload;
        },       
        studentDetailsSetTabSelectdItem : (state, action : PayloadAction<string>)=>{
            state.tabDetailsSelectedItem = action.payload;
        },
        studentDetailsSetTabSelectedContent: (state, action : PayloadAction<string>)=>{
            state.tabDetailsSelectedContent = action.payload;
        }, 
        studentDetailsSetStudentForm: (state, action : PayloadAction<UserStudentM>)=>{
            state.studentForm = action.payload;
        },
        studentDetailsSetGradeForm: (state, action : PayloadAction<GradeM>)=>{
            state.gradeForm = action.payload;
        },
        studentDetailsSetShowGradeListStudent: (state, action : PayloadAction<boolean>)=>{
            state.showGradeListStudent = action.payload;
        },
   } 
});

export const { 
    studentSetTabSelectdItem, 
    studentSetTabSelectedContent,
    studentDetailsSetStudentForm,
    studentDetailsSetTabSelectdItem,
    studentDetailsSetTabSelectedContent,
    studentDetailsSetGradeForm,
    studentDetailsSetShowGradeListStudent
} = studentSlice.actions;
export const studentReducer = studentSlice.reducer;