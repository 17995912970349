import { useEffect, useState } from "react";
import { Input } from "../../components/input.component";
import { TabSelectConsumableButtonBar, TabSelectConsumableContainer, TabSelectConsumableContent, TabSelectConsumableEmptyImage, TabSelectConsumableItem, TabSelectConsumableItemContent, TabSelectConsumableSearchBar } from "./styles/tab-select-consumable.styled";
import { Dropdown } from "../../components/dropdown.component";
import { MealConsumableM } from "../../../models/meal-consumable.model";
import { TabSelectConsumableService } from "./services/tab-select-cosumable.service";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../api/api";
import search_icon from '../../../assets/icons/search.svg';
import image_product_icon from '../../../assets/icons/image-product.svg';
import { ButtonPrimary } from "../../components/button-primary.component";
import { FoodTypeM } from "../../../models/food-type.model";
import { useAppSelector } from "../../../hooks";

export const TabSelectConsumablePage = ()=>{
    const [search, setSearch] = useState('');
    const [foodTypesData, setFoodTypesData] = useState<{count : number, rows : FoodTypeM[]}>();
    const [consumablesData, setConsumablesData] = useState<{count : number, rows : MealConsumableM[]}>();
    const [selectedConsumable, setSelectedConsumable] = useState<MealConsumableM>();
    const [selectedFoodType, setSelectedFoodType] = useState<FoodTypeM>();
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);

    const tabSelectConsumableService = new TabSelectConsumableService(dispatch);

    useEffect(()=>{
        setSelectedFoodType(
            foodReducer.todaysMenusForm.rows[foodReducer.todaysMenusFormData?.todaysMenuIndex!]
            .fullmeals?.[foodReducer.todaysMenusFormData?.fullMealIndex!].foodType
        );
    }, []);

    useEffect(()=>{
        tabSelectConsumableService.handleSearchFoodTypes(setFoodTypesData);
    }, []);

    useEffect(()=>{
        tabSelectConsumableService.handleSearchConsumables(setConsumablesData, selectedFoodType, search);
    }, [selectedFoodType, search])

    return (
        <TabSelectConsumableContainer>
            
            <TabSelectConsumableSearchBar autoComplete="off">
                <Input
                    className='inputSearch'
                    type='search'
                    name='inputSearch'
                    id='inputSearch'
                    placeholder='Busque pelo nome do item'
                    value={search}
                    onChange={setSearch}
                    prefix={
                        <img src={search_icon} alt="Icone de busca"/>
                    }
                />
                <Dropdown
                    styleClass='input-style'
                    defaultValue={selectedFoodType?.id?.toString()}
                    placeholder='Categorias'
                    options={tabSelectConsumableService.handleFoodTypesToOptions(foodTypesData?.rows)}
                    onChange={(value)=>setSelectedFoodType(tabSelectConsumableService.handleFindFoodTypeByValue(value, foodTypesData?.rows))}
                />
            </TabSelectConsumableSearchBar>
            <TabSelectConsumableContent>
                {consumablesData?.rows.map(consumable=>(
                    <TabSelectConsumableItem className={consumable === selectedConsumable ? 'active' : ''} onClick={()=>setSelectedConsumable(consumable)}>
                        {consumable.media?.content ?
                            <img src={`${baseUrl}/${consumable.media?.content}`} alt="Imagem do produto"/> :
                            <TabSelectConsumableEmptyImage>
                                <img src={image_product_icon} alt="Imagem do produto"/>
                            </TabSelectConsumableEmptyImage>
                        }
                        <TabSelectConsumableItemContent>
                            <h3 className="primaryBlackSemibold18-150">{consumable.name}</h3>
                            <span className="primaryBlackNormal16-150">{consumable.amountInGrams}g</span>
                            <span className="primaryBlackNormal16-150">{consumable.foodType.name}</span>
                        </TabSelectConsumableItemContent>
                    </TabSelectConsumableItem> 
                ))}
                        
            </TabSelectConsumableContent>

            <TabSelectConsumableButtonBar>
                <ButtonPrimary 
                    disabled={!selectedConsumable}
                    onClick={selectedConsumable ? ()=>tabSelectConsumableService.handleSubmit(
                        foodReducer.todaysMenusForm, selectedConsumable, foodReducer.todaysMenusFormData
                    ) : ()=>{}}
                >
                    Salvar escolha de alimento
                </ButtonPrimary> 
            </TabSelectConsumableButtonBar>
            
        </TabSelectConsumableContainer>
    );
}