import React, { useEffect, useState } from 'react';
import search_icon from '../../../assets/icons/search.svg';
import { Input } from '../../components/input.component';
import { Dropdown } from '../../components/dropdown.component';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { GradeM } from '../../../models/grade.model';
import { TabFoodStudentEvaluationAction, TabFoodStudentEvaluationContainer, TabFoodStudentEvaluationContent, TabFoodStudentEvaluationSearchBar, TabFoodStudentEvaluationSearchBarInputs, TabFoodStudentEvaluationTitle } from './styles/tab-food-student-evaluation.styled';
import { TabFoodStudentEvaluationService } from './services/tab-food-student-evaluation.service';
import { Table } from '../../components/table.component';

import arrow_right_icon from '../../../assets/icons/arrow_right.svg';
import arrow_right_green_icon from '../../../assets/icons/arrow_right_green.svg';
import { StudentM } from '../../../models/student.model';
import { FoodTypeM } from '../../../models/food-type.model';
import { DatePicker } from '../../components/date-picker.component';
import { StudentScoreM } from '../../../models/student-score.model';
import { StudentScoreModal } from '../students/student-score-modal.page';


export const TabFoodStudentEvaluationPage = () => {
    const [search, setSearch] = useState<string>();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedGrade, setSelectedGrade] = useState<GradeM>();
    const [gradesData, setGradesData] = useState<{ count: number, rows: GradeM[] }>();
    const [foodTypesData, setFoodTypesData] = useState<{ count: number, rows: FoodTypeM[] }>();
    const [studentsData, setStudentsData] = useState<{ count: number, rows: StudentM[] }>();
    const [selectedFoodType, setSelectedFoodType] = useState<FoodTypeM>();
    const [showModal, setShowModal] = useState<StudentScoreM | null>(null);
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);
    const tabFoodStudentEvaluationService = new TabFoodStudentEvaluationService(dispatch);

    useEffect(() => {
        tabFoodStudentEvaluationService.handleSearchStudents(setStudentsData, selectedGrade, search, selectedFoodType, selectedDate);
    }, [selectedGrade, search, selectedFoodType, selectedDate]);

    useEffect(()=>{
        if(foodTypesData)
            setSelectedFoodType(foodTypesData?.rows[0]);
    }, [foodTypesData]);

    useEffect(() => {
        tabFoodStudentEvaluationService.handleSearchGrades(setGradesData);
        tabFoodStudentEvaluationService.handleSearchFoodTypes(setFoodTypesData);
    }, []);

    return (
        <TabFoodStudentEvaluationContainer>
            <TabFoodStudentEvaluationSearchBar autoComplete="off">
                <TabFoodStudentEvaluationSearchBarInputs>
                    <Input
                        className='inputSearch'
                        type='search'
                        name='inputSearch'
                        id='inputSearch'
                        placeholder='Busque pelo nome do aluno'
                        value={search}
                        onChange={setSearch}
                        prefix={
                            <img src={search_icon} alt="Icone de busca" />
                        }
                    />

                    <Dropdown
                        styleClass='input-style'
                        defaultValue={selectedFoodType?.id?.toString() || 'all'}
                        placeholder='Tipo de alimento'
                        options={tabFoodStudentEvaluationService.handleFoodTypesToOptions(foodTypesData?.rows)}
                        onChange={(value) => setSelectedFoodType(tabFoodStudentEvaluationService.handleFindFoodTypeByValue(value, foodTypesData?.rows))}
                    />

                    <DatePicker
                        className='date-picker-filter'
                        date={selectedDate}
                        setDate={setSelectedDate}
                    />

                    <Dropdown
                        styleClass='input-style'
                        defaultValue={selectedGrade?.id?.toString() || 'all'}
                        placeholder='Turmas'
                        options={tabFoodStudentEvaluationService.handleGradesToOptions(gradesData?.rows)}
                        onChange={(value) => setSelectedGrade(tabFoodStudentEvaluationService.handleFindGradeByValue(value, gradesData?.rows))}
                    />
                </TabFoodStudentEvaluationSearchBarInputs>
            </TabFoodStudentEvaluationSearchBar>

            <TabFoodStudentEvaluationContent>
                <Table className='border-none'>
                    <thead className='primaryBlackSemibold20-150'>
                        <th>Aluno</th>
                        <th>Turma</th>
                        <th>Categoria</th>
                        <th></th>
                    </thead>
                    {studentsData && studentsData.count > 0 ?
                        <tbody className='primaryBlackMedium18-150'>
                            {studentsData?.rows.map((student: StudentM) => (
                                <tr className='full-padding'>
                                    <td>{student.name}</td>
                                    <td>{student.grade.name}</td>
                                    <td>
                                        <span>Cardápio</span>
                                        {student.usesSpecialMenu ?
                                            <span className='special'>Especial</span> :
                                            <span>Geral</span>
                                        }
                                    </td>
                                    <td>
                                        <TabFoodStudentEvaluationAction
                                            className={`primaryPrimarySemibold16-150 ${student.scores?.find(score => score.studentId === student.id) && 'active'}`}
                                            onClick={student.scores?.find(score => score.studentId === student.id) ? ()=>{} : () => tabFoodStudentEvaluationService.handleOpenEvaluation(student)}
                                        >
                                            {student.scores?.find(score => score.studentId === student.id) ? 'Já avaliou' : 'Avaliar refeição'}
                                            <img src={student.scores?.find(score => score.studentId === student.id) ? arrow_right_green_icon : arrow_right_icon} alt='Icone seta para direita' />
                                        </TabFoodStudentEvaluationAction>
                                    </td>
                                </tr>
                            ))}
                        </tbody> :
                        <tbody>
                            <div className='empty-table mt'>
                                <span className='primaryBlackSemibold20-150'>Nenhum aluno cadastrado</span>
                            </div>
                        </tbody>
                    }
                </Table>

                {showModal && <StudentScoreModal studentScore={showModal} onClose={()=>setShowModal(null)}/> }
            </TabFoodStudentEvaluationContent>

        </TabFoodStudentEvaluationContainer>
    );
};