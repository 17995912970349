import styled from "styled-components";

export const DatePikerContent = styled.div`
    position: relative;
    width: 250px;

`;

export const DatePikerContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;

    input{
        padding: 13px !important;
    }

    .new-button{
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        width: 100%;
        cursor: pointer;
    }

    .date-picker{
        width: 250px;
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        outline: none;

        &:focus{
            border: 1px solid var(--primary-color);
        }
    }

    .arrow{
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;

    }

    .react-datepicker__header{
        background-color: var(--white-color);
        border: none !important;
    }

    .react-datepicker__day-names{
        background-color: #F5F7FA;
        margin: 15px 5px -7px 5px;
    }

    .react-datepicker__day--today{
        background-color: transparent;
        position: relative;
        color: var(--primary-color); ;
        &::after{
            content: '.';
            position: absolute;
            bottom: 5%;
            right: 42%;
            border-radius: 50%;
            height: 16%!important;
            width: 16%!important;
            z-index: 1000;
            background-color: var(--primary-color);
        }
    }

    .react-datepicker__day--in-range{
        background-color: #FFE8E3;
        color : var(--black-color);
    }

    .react-datepicker__day{
        margin: 0;
        width: 35px;
        height: 33px;
        border-radius: 0 !important;
    }

    .react-datepicker__day--range-start, .react-datepicker__day--range-end{
        background-color: var(--primary-color);
        color: var(--white-color);
    }

    .react-datepicker__day--range-start{
        border-radius: 5px 0 0 5px !important;
    }

    .react-datepicker__day--range-end{
        border-radius: 0 5px 5px 0 !important;
    }

    .react-datepicker__day--selected{
        background-color: var(--primary-color);
        color: var(--white-color);
    }

    @media screen and (max-width: 1400px){
        gap: 9px;
        input{
            padding: 9px !important;
        }
    }

`;