import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { StudentMonitoringM } from '../../models/student-monitoring.model';

export const LineChart = ({data, sex} : {data? : StudentMonitoringM[], sex: string}) => {
  const [chartData, setChartData] = useState<any>();
  const chartDataBoy = [
    ['Idade', 'Abaixo do Peso Ideal', 'Peso Ideal', 'Acima do Peso Ideal', 'Obesidade', 'Obesidade Grave', 'Peso do aluno'],
    [ 5,     13.1,   14,   15.3, 16.7, 18.1,  data && data.length > 0 ? null : 0],
    [ 5.5,   13.1,   14,   15.3, 16.7, 18.1,  null],
    [ 6,     13.2,   14,   15.3, 16.8, 18.3,  null],
    [ 6.5,   13.2,   14.1, 15.4, 16.9, 18.5,  null],
    [ 7,     13.3,   14.2, 15.5, 17.1, 18.8,  null],
    [ 7.5,   13.3,   14.3, 15.6, 17.3, 19,    null],
    [ 8,     13.4,   14.4, 15.7, 17.5, 19.4,  null],
    [ 8.5,   13.5,   14.5, 15.9, 17.7, 19.7,  null],
    [ 9,     13.6,   14.6, 16,   18,   20.1,  null],
    [ 9.5,   13.8,   14.7, 16.2, 18.3, 20.5,  null],
    [10,     13.9,   14.9, 16.4, 18.6, 21,    null],
    [10.5,   14,     15.1, 16.7, 18.9, 21.5,  null],
    [11,     14.2,   15.3, 16.9, 19.3, 22,    null],
    [11.5,   14.4,   15.5, 17.2, 19.6, 22.5,  null],
    [12,     14.6,   15.7, 17.5, 20.1, 23.1,  null],
    [12.5,   14.8,   16,   17.9, 20.5, 23.6,  null],
    [13,     15.1,   16.3, 18.2, 20.9, 24.2,  null],
    [13.5,   15.4,   16.6, 18.6, 21.4, 24.8,  null],
    [14,     15.6,   16.9, 19,   21.9, 25.3,  null],
    [14.5,   15.9,   17.3, 19.4, 22.4, 25.8,  null],
    [15,     16.2,   17.6, 19.8, 22.8, 26.4,  null],
    [15.5,   16.4,   17.9, 20.1, 23.2, 26.8,  null],
    [16,     16.7,   18.2, 20.5, 23.7, 27.3,  null],
    [16.5,   16.9,   18.5, 20.8, 24,   27.7,  null],
    [17,     17.1,   18.7, 21.1, 24.4, 28,    null],
    [17.5,   17.3,   18.9, 21.4, 24.7, 28.4,  null],
    [18,     17.5,   19.2, 21.7, 25,   28.6,  null],
    [18.5,   17.6,   19.4, 22,   25.3, 28.9,  null],
    [19,     17.8,   19.5, 22.2, 25.6, 29.1,  null]
  ];

  const chartDataGirl = [
    ['Idade', 'Abaixo do  Ideal', 'Peso Ideal', 'Acima do Peso Ideal', 'Obesidade', 'Obesidade Grave', 'Peso do aluno'],
    [ 5,    12.9, 13.8, 15.2, 16.9, 18.6, data && data.length > 0 ? null : 0],
    [ 5.5,  12.8, 13.8, 15.2, 17.0, 18.7, null],
    [ 6,    12.8, 13.8, 15.3, 17.1, 18.9, null],
    [ 6.5,  12.8, 13.8, 15.3, 17.2, 19.2, null],
    [ 7,    12.9, 13.9, 15.4, 17.4, 19.4, null],
    [ 7.5,  12.9, 14.0, 15.5, 17.6, 19.8, null],
    [ 8,    13.0, 14.1, 15.7, 17.8, 20.2, null],
    [ 8.5,  13.1, 14.2, 15.9, 18.1, 20.6, null],
    [ 9,    13.3, 14.4, 16.1, 18.4, 21.1, null],
    [ 9.5,  13.4, 14.6, 16.3, 18.8, 21.6, null],
    [10,    13.6, 14.8, 16.6, 19.1, 22.1, null],
    [10.5,  13.8, 15.0, 16.9, 19.5, 22.6, null],
    [11,    14.0, 15.3, 17.2, 20.0, 23.2, null],
    [11.5,  14.3, 15.6, 17.6, 20.4, 23.8, null],
    [12,    14.6, 15.9, 18.0, 20.9, 24.4, null],
    [12.5,  14.8, 16.2, 18.4, 21.4, 25.0, null],
    [13,    15.1, 16.5, 18.8, 21.9, 25.6, null],
    [13.5,  15.4, 16.9, 19.2, 22.4, 26.1, null],
    [14,    15.6, 17.2, 19.6, 22.9, 26.7, null],
    [14.5,  15.9, 17.4, 19.9, 23.3, 27.1, null],
    [15,    16.1, 17.7, 20.2, 23.7, 27.6, null],
    [15.5,  16.2, 17.9, 20.5, 24.0, 27.9, null],
    [16,    16.4, 18.1, 20.7, 24.2, 28.2, null],
    [16.5,  16.5, 18.1, 20.9, 24.5, 28.4, null],
    [17,    16.6, 18.2, 21.0, 24.6, 28.6, null],
    [17.5,  16.6, 18.3, 21.2, 24.8, 28.8, null],
    [18,    16.7, 18.4, 21.3, 24.9, 28.9, null],
    [18.5,  16.7, 18.5, 21.3, 25.0, 29.0, null],
    [19,    16.7, 18.6, 21.4, 25.1, 29.0, null],
  ];

    useEffect(()=>{
        const fixedData = sex === 'M' ? [...chartDataBoy] : [...chartDataGirl];
        fixedData.map((fixedItem : any, index : number)=>{
            if(index !== 0){
                data?.map(studentMonitoring=>{ 
                    let ageMonth = studentMonitoring.age;
                    if(studentMonitoring.months > 5){
                        ageMonth+=0.5
                    }

                    if(ageMonth === fixedItem[0]){
                        fixedItem[fixedItem.length-1] = studentMonitoring.imc;
                    }
                });
            }
        });

   
        setChartData(fixedData);
        
    }, [data]);
  

    return (chartData ? 
                <Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={chartData}
                    options={{
                        title: '',
                        
                        hAxis: {
                            title: 'Idade (em anos)',
                            ticks: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
                        },
                        vAxis: {
                            title: '', 
                            ticks: [15, 20, 25, 30, 35],
                            viewWindow: {
                                min: 12,
                            },
                        },
                        legend: { position: 'none'},
                        chartArea: { width: '80%', height: '70%' },
                        lineWidth: 1.5,
                        colors: ["#B5AFFE", "#A5E4AC", "#F4E47F", "#FEB0A0", "#A19F9D", "#004DE1" ],
                        series: {
                            5: { pointSize: 10, type: 'points', color: '#004DE1' } 
                        },

                    }}
                /> : <></>
                
    );
};
