import { addDays, endOfWeek, format, startOfWeek } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { TodaysMenuController } from "../../../../controllers/todays-menu.controller";
import { DatePickerMultiT } from "../../../components/types/datepicker.type";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { FullMealController } from "../../../../controllers/full-meal.controller";
import { TodaysMenuI } from "../../../../models/interfaces/todays-menu.interface";
import { foodSetConsumableForm, foodSetTabSelectedContent, foodSetShowSelectCosumableModal, foodSetTodaysMenusFormData, foodSetTodaysMenusForm } from "../../../../features/food-slice.feature";
import { FullMealM } from "../../../../models/full-meal.model";
import { foodContents, foodContentsNames } from "../../../../features/types/food-slice.type";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { MealConsumableM } from "../../../../models/meal-consumable.model";

export class TabFoodService {

    dispatch : Dispatch;
    setIsEdit : Function;
    todaysMenuController : TodaysMenuController;
    fullMealController : FullMealController;

    constructor(dispatch : Dispatch, setIsEdit : Function){
        this.dispatch = dispatch;
        this.setIsEdit = setIsEdit;
        this.todaysMenuController = new TodaysMenuController();
        this.fullMealController = new FullMealController();

        this.handleMenuTypesToOptions = this.handleMenuTypesToOptions.bind(this);
        this.handleSearchTodaysMenu = this.handleSearchTodaysMenu.bind(this);
        this.handleGetOptions = this.handleGetOptions.bind(this);
        this.handleClearMenu = this.handleClearMenu.bind(this);
    }

    public handleShowSelectConsumableModal(todaysMenuIndex : number, fullMealIndex: number ){
        this.dispatch(foodSetShowSelectCosumableModal(true));
        this.dispatch(foodSetTodaysMenusFormData({
            todaysMenuIndex,
            fullMealIndex
        }));
    }

    public handleMenuTypesToOptions(todaysMenus: TodaysMenuM[]) {
        const uniqueMenuGrades = new Set<string>();
    
        const filteredMenus = todaysMenus.reduce((result, todaysMenu) => {
            const menuGradeName = todaysMenu.menuGrade?.name;
    
            if (menuGradeName && !uniqueMenuGrades.has(menuGradeName)) {
                uniqueMenuGrades.add(menuGradeName);
    
                result.push({
                    value: menuGradeName,
                    content: menuGradeName
                });
            }
    
            return result;
        }, [] as { value: string; content: string }[]);
    
        return filteredMenus;
    }

    public handleRemoveConsumable(
        todaysMenusForm: { count: number, rows: TodaysMenuM[] },
        todaysMenuIndex: number,
        fullMealIndex: number,
        consumableIndex: number
    ) {
        const updatedForm = JSON.parse(JSON.stringify(todaysMenusForm));
        let consumablesUpdated = [...updatedForm.rows[todaysMenuIndex].fullmeals[fullMealIndex].consumables];
        
        consumablesUpdated.splice(consumableIndex, 1);
        updatedForm.rows[todaysMenuIndex].fullmeals[fullMealIndex].consumables = consumablesUpdated;

        this.dispatch(foodSetTodaysMenusForm(updatedForm));
    }

    public handleAlterConsumable(
        todaysMenusForm: { count: number, rows: TodaysMenuM[] },
        todaysMenuIndex: number,
        fullMealIndex: number,
        consumableIndex: number
    ) {
        this.dispatch(foodSetTodaysMenusFormData({
            todaysMenuIndex,
            fullMealIndex,
            consumableIndex
        }));
        this.dispatch(foodSetShowSelectCosumableModal(true));
    }

    public handleShowAddItemPage(){
        this.dispatch(foodSetTodaysMenusForm({count: 0, rows: []}));
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.MENU_ADD_FULL_MEAL));       
    }

    public handleSetShowSelectCosumable(status : boolean){
        this.dispatch(foodSetShowSelectCosumableModal(status));
    }

    public handleSelectedDate(selectedDate : Date, setDatesSearch : Function, setSelectedMenuType: Function){
        let startDate = startOfWeek(new Date(selectedDate));
        startDate = addDays(startDate, 1);
        let endDate = endOfWeek(new Date(selectedDate));
        endDate = addDays(endDate, -1);
        setDatesSearch({ startDate, endDate });
        setSelectedMenuType(null);
    }

    public async handleSearchTodaysMenu(setSelectedTodaysMenu : Function, dateRange? : DatePickerMultiT){
        if(dateRange){
            this.dispatch(generalSetIsLoading(true));
            await this.todaysMenuController.getByDateRange(addDays(dateRange.startDate, -1) , addDays(dateRange.endDate, 1)).then(response=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    data.rows = data.rows.map((todayMenu : TodaysMenuM)=>{return {...todayMenu, fullmeals : []}})
                    this.dispatch(foodSetTodaysMenusForm(data));
                    if(data.count > 0){
                        setSelectedTodaysMenu(data.rows[0]);
                    }
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    })
                }
            }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
            });
        }

    }

    public async handleSearchFullMeal(
        selectedTodaysMenu : TodaysMenuI,
        todaysMenuForm: {count : number, rows :TodaysMenuM[]},
        theadRef : any
    ){
        if(selectedTodaysMenu.id){
            this.dispatch(generalSetIsLoading(true));
            await this.fullMealController.getByTodaysMenuId(selectedTodaysMenu.id).then(response=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    var todayMenuIndex = todaysMenuForm.rows?.indexOf(selectedTodaysMenu);
                    const updatedRows = [...todaysMenuForm.rows];
                    updatedRows[todayMenuIndex] = { ...updatedRows[todayMenuIndex], fullmeals: data.rows };

                    this.dispatch(foodSetTodaysMenusForm({
                        count: todaysMenuForm.count,
                        rows: updatedRows,
                    }));
                    
                    setTimeout(()=>{
                        if(theadRef.current !== null){
                            theadRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest",
                            });
                        }
                    }, 200);
                   
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: `Ocorreu um erro ao obter as refeições da data ${selectedTodaysMenu.date}`,
                    })
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }

    private handleClearMenu(
        todaysMenusForm : {count: number, rows : TodaysMenuM[]}, 
        todaysMenuIndex : number,
    ){
        this.setIsEdit(true);
        const updatedForm = JSON.parse(JSON.stringify(todaysMenusForm));
        
        Array.from({ length: updatedForm.rows[todaysMenuIndex].fullmeals.length }).map((_, index) => {
            updatedForm.rows[todaysMenuIndex].fullmeals[index].consumables = [];
        });

        this.dispatch(foodSetTodaysMenusForm(updatedForm));
       
    }

    public handleGetOptions(
        todaysMenuForm : {count: number, rows : TodaysMenuM[]},
        todaysMenuIndex : number
    ){
        return [
            {
                value : 'Editar cardápio',
                onClick : ()=>{
                    this.setIsEdit(true);
                }
            },
            {
                value : 'Limpar cardápio',
                onClick : ()=>{
                    this.handleClearMenu(todaysMenuForm, todaysMenuIndex);
                }
            }
        ]
    }

    public async handleSubmit(
        todaysMenuList : TodaysMenuM[],
        setIsEdit : Function
    ){
        this.dispatch(generalSetIsLoading(true));
            await this.todaysMenuController.createAndUpdateList(todaysMenuList).then(response=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    setIsEdit(false);
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: `cardápio alterado com sucesso`,
                    })              
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    })
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            });
    }


}
