import React, { useState } from 'react';
import * as S from './styled';

import ilustratrion_login from '../../../../assets/images/ilustratrion_login.jpg';
import logo_image from '../../../../assets/images/logo75x75.png';

import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks';
import { ForgoutPasswordService } from './service';
import { ForgoutPasswordT } from './types';
import { Input } from '../../../components/input.component';
import { ButtonPrimary } from '../../../components/button-primary.component';
import { formatCPF } from '../../../../utils/config';

export const ForgoutPasswordPage = () => {
    const authReducer = useAppSelector((state) => state.authReducer);
    const dispatch = useDispatch();

    const forgoutPasswordService = new ForgoutPasswordService(dispatch);

    const [loginForgoutPasswordForm, setLoginForgoutPassordForm] = useState<ForgoutPasswordT>({ cpf: '' });

    return (
        <S.ForgoutPasswordContainer>
            <S.ForgoutPasswordForm>
                <form action='#' onSubmit={(e) => e.preventDefault()}>
                    <S.ForgoutPasswordLogoContainer>
                        <img src={logo_image} alt={"Logo Healthy Puppy"} />
                        <span className='secondaryPrimaryBold16-120'>Healthy Puppy</span>
                    </S.ForgoutPasswordLogoContainer>

                    <h2 className='primaryBlackBold40-120'>Esqueci a Senha</h2>

                    <span className='primaryBlackMedium18-150'>
                        Receba um email para recuperar a sua senha de acesso.
                    </span>
                    <Input
                        className="input"
                        label='CPF'
                        type='text'
                        name='cpf'
                        id='cpf'
                        placeholder='Insira seu cpf'
                        value={loginForgoutPasswordForm.cpf}
                        onChange={(value: any) => {
                            const cleanedValue = value.replace(/\D/g, '');
                            setLoginForgoutPassordForm({ ...loginForgoutPasswordForm, cpf: formatCPF(cleanedValue) })
                        }}
                    />

                    <ButtonPrimary className='submitButton' onClick={() =>
                        forgoutPasswordService.submit(loginForgoutPasswordForm)
                    }>Recuperar senha</ButtonPrimary>
                </form>
            </S.ForgoutPasswordForm>

            <S.ForgoutPasswordImage
                src={ilustratrion_login}
                alt="Imagem de fundo para a página de login"
            />
        </S.ForgoutPasswordContainer>
    );
};