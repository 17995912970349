import { handleRequest } from "../api/api-request.service";
import { UserResponsibleT } from "./interfaces/user-responsible.interface";
import { ResponsibleM } from "./responsible.model";
import { StudentM } from "./student.model";

export class UserResponsibleM{
    id?: number;
    name: string;
    login?: string;
    email?: string;
    cpf: string;
    password: string;
    responsible : ResponsibleM;

    constructor({id, name, login, email, cpf, password, responsible} : UserResponsibleT){
        this.id = id;
        this.name = name;
        this.login = login;
        this.email = email;
        this.cpf = cpf;
        this.password = password;
        this.responsible = responsible;
    }

    static initial(){
        return new UserResponsibleM({
            name: "",
            login: "",
            email: "",
            cpf: "",
            password: "",
            responsible: ResponsibleM.initial(),
        })
    }

    static async getAll(studentId: number){
        return handleRequest(
            `/user-responsible/by/student/${studentId}`,
            'GET'
        );
    }

    static async getByCPF(cpf: string){
        return handleRequest(
            `/user-responsible?cpf=${cpf}`,
            'GET'
        );
    }

    static async create(userResponsible : UserResponsibleM){
        return handleRequest(
            `user-responsible`,
            'POST',
            userResponsible
        );
    }

    static async update(userResponsible : UserResponsibleM){
        return handleRequest(
            `user-responsible/${userResponsible.id}`,
            'PUT',
            userResponsible
        );
    }


}