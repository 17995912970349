import { TabAnnouncementsButtonBar, TabAnnouncementsContainer, TabAnnouncementsGrid, TabAnnouncementsGridItem, TabAnnouncementsGridItemBody, TabAnnouncementsGridItemSection, TabAnnouncementsSearchBar } from "./styles/tab-announcements.styled";
import { Input } from "../../components/input.component";


import search_icon from '../../../assets/icons/search.svg';
import calendar_icon from '../../../assets/icons/calendar_day.svg';

import plus_icon from '../../../assets/icons/plus.svg';
import { Dropdown } from "../../components/dropdown.component";
import { ButtonPrimary } from "../../components/button-primary.component";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabAnnouncementsService } from "./services/tab-announcements-service";
import { useAppSelector } from "../../../hooks";
import { baseUrl } from "../../../api/api";
import { DatePicker } from "../../components/date-picker.component";
import { DatePickerMultiT } from "../../components/types/datepicker.type";
import { format } from "date-fns";

export const TabAnnouncements = () => {

    const tabAnnouncementsReducer = useAppSelector(state => state.tabAnnouncementsReducer);

    const [search, setSearch] = useState('');

    const [searchStatus, setSearchStatus] = useState('active');

    const dispatch = useDispatch();

    const tabAnnouncementsService = TabAnnouncementsService.getInstance(dispatch);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [datesSearch, setDatesSearch] = useState<DatePickerMultiT>();

    useEffect(() => {
        if (selectedDate) {
            tabAnnouncementsService.handleSelectedDate(selectedDate, setDatesSearch);
        }
    }, [selectedDate]);

    useEffect(() => {
        tabAnnouncementsService.handleGetAnnouncements(datesSearch, search, searchStatus);
    }, [datesSearch, searchStatus]);


    return (
        <TabAnnouncementsContainer>
            <h2 className='primaryBlackSemibold28-150'>Comunicados</h2>
            <TabAnnouncementsSearchBar>
                <Input
                    className='inputSearch'
                    type='search'
                    name='search'
                    id='searchAnnouncement'
                    placeholder='Busque por um comunicado'
                    value={search}
                    onChange={(value) => {
                        setSearch(value)
                        tabAnnouncementsService.handleGetAnnouncements(datesSearch, search, searchStatus)
                    }}
                    prefix={
                        <img src={search_icon} alt="Icone de busca" />
                    }
                />
                <div className="inputDropsContainer">
                    <DatePicker
                        className="inputDatePicker"
                        dates={datesSearch}
                        setDate={setSelectedDate}
                        selectsRange={true}
                    />

                    <Dropdown
                        styleClass='input-style'
                        containerClassName='dropDownStyle'
                        optionsClassName={'dropOptionsStyle'}
                        defaultValue={searchStatus}
                        placeholder='Status'
                        options={[{ value: 'active', content: 'Ativo' }, { value: 'no-active', content: "Inativo" }]}
                        onChange={(value) => {
                            setSearchStatus(value)
                        }}
                    />
                </div>
            </TabAnnouncementsSearchBar>

            <TabAnnouncementsButtonBar>
                <ButtonPrimary onClick={() => tabAnnouncementsService.handleShowAddItemPage()}>
                    <img src={plus_icon} alt="Icone de Adicionar" />
                    Cadastrar novo comunicado
                </ButtonPrimary>
            </TabAnnouncementsButtonBar>
            {tabAnnouncementsReducer.announcementsData && tabAnnouncementsReducer.announcementsData.count > 0 ? 
                <TabAnnouncementsGrid>

                    {
                        tabAnnouncementsReducer.announcementsData?.rows.map((row) => {

                            const announcement = row;

                            var date = new Date(announcement.createdAt!);
                            var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                            var mm = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                            var yyyy = date.getFullYear()

                            var strDate = `${dd}/${mm}/${yyyy}`;

                            return (
                                <TabAnnouncementsGridItem>
                                    <figure className='figure card-figure'>
                                        <img className="card-figure-image"
                                            src={`${baseUrl}/${announcement.media?.content ?? 'public/images/logoHP.png'}`} alt={`Imagem do comunicado ${announcement.title}`} />
                                    </figure>

                                    <TabAnnouncementsGridItemBody>

                                        <TabAnnouncementsGridItemSection>
                                            <div className="card-title-label">
                                                {announcement.title}
                                            </div>
                                            <div className="card-date-label">
                                                <img src={calendar_icon} alt="Icone de calendário" />
                                                {strDate}
                                            </div>
                                            <div className="card-author-label">
                                                Por {announcement.author?.schoolWorker?.name}
                                            </div>
                                        </TabAnnouncementsGridItemSection>

                                        <TabAnnouncementsGridItemSection>
                                            <div className="card-description">
                                                {
                                                    announcement.content
                                                }
                                            </div>
                                        </TabAnnouncementsGridItemSection>

                                        <TabAnnouncementsGridItemSection>
                                            <ButtonPrimary onClick={() => tabAnnouncementsService.handleEdit(announcement)}>
                                                Ver comunicado
                                            </ButtonPrimary>
                                        </TabAnnouncementsGridItemSection>

                                    </TabAnnouncementsGridItemBody>
                                </TabAnnouncementsGridItem>
                            )
                        })
                       
                    }
                </TabAnnouncementsGrid> :
                <div className="empty-table">
                    <span className='primaryBlackSemibold20-150'>Nenhum comunicado {searchStatus === 'active' ? 'Ativo' : 'Inativo'} cadastrado no dia {format(selectedDate, 'dd/MM/yyyy')}</span>
                </div>
            }


        </TabAnnouncementsContainer>
    )
}