import React, { useState } from 'react';
import { AddDiseaseModalActions, AddDiseaseModalContainer, AddDiseaseModalContent, AddDiseaseModalDescription, AddDiseaseModalTitle } from './styled/add-disease-modal.styled';
import { useDispatch } from 'react-redux';
import { ButtonPrimary } from '../../components/button-primary.component';
import { useAppSelector } from '../../../hooks';
import { Input } from '../../components/input.component';
import { StudentDiseaseM } from '../../../models/student-disease.model';
import { StudentHealthObservationI } from '../../../models/interfaces/student-health-observation.interface';
import { StudentHealthObservationM } from '../../../models/student-health-observation.model';
import { AddDiseaseModalService } from './services/add-disease-modal.service';
// import { AddDiseaseModalService } from './services/add-disease-modal.service';


export const AddDiseaseModal = (
    {setShowAddDiseaseModal, studentHealhtyObservation } : {setShowAddDiseaseModal : Function, studentHealhtyObservation? : StudentHealthObservationM}
) => {
    const dispatch = useDispatch();
    const [disease, setDisease] = useState<StudentDiseaseM>(StudentDiseaseM.initial());
    const addDiseaseModalService = new AddDiseaseModalService(dispatch);

    return (
        <AddDiseaseModalContainer>
            <AddDiseaseModalContent>
                <AddDiseaseModalTitle>
                    <span className='primaryBlackSemibold18-150'>Adicionar nova Doença</span>
                </AddDiseaseModalTitle>
                <AddDiseaseModalDescription>
                    <Input
                        id='name'
                        name='name'
                        type='text'
                        label='Doença'
                        placeholder='Doença'
                        value={disease?.disease}
                        onChange={(value)=>{setDisease({...disease, disease : value})}}
                    />
                </AddDiseaseModalDescription>
                <AddDiseaseModalActions>
                    <ButtonPrimary onClick={()=>{addDiseaseModalService.handleSubmit(disease, setShowAddDiseaseModal, studentHealhtyObservation)}}>
                        Salvar Doença
                    </ButtonPrimary>
                    <span className='primaryBlackNormal20-150' onClick={()=>setShowAddDiseaseModal(false)}>Cancelar</span>
                </AddDiseaseModalActions>
            </AddDiseaseModalContent>
        </AddDiseaseModalContainer>
    );
};
