import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserSchoolWorkerDataT, TabAdminStateT } from "./types/user-school-worker-slice.type";
import { UserSchoolWorkerM } from "../models/user-school-worker.model";

const initialState: TabAdminStateT = {
    userForm: UserSchoolWorkerM.initial()
}

export const tabAdminSlice = createSlice({
    name: 'tab-admin',
    initialState,
    reducers: {
        
        tabAdminSetSchoolWorkersData(state, action: PayloadAction<UserSchoolWorkerDataT>) {
            state.usersData = action.payload;
        },
        tabAdminSetSchoolWorkerForm(state, action: PayloadAction<UserSchoolWorkerM>) {
            state.userForm = action.payload;
        }
    }
});

export const {
    tabAdminSetSchoolWorkersData,
    tabAdminSetSchoolWorkerForm
} = tabAdminSlice.actions;
export const tabAdminReducer = tabAdminSlice.reducer;