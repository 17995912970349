import styled from "styled-components";

export const CardUser = styled.div`

    border: 1px solid #B5B5B5;
    border-radius: 12px;
    height: 86px;
    width: 324px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.selected {
        border: 2px solid #FF6242;
        }

    &.selected > div svg {
        color: #FF6242;
    }

    & > img {
        height: 52px;
        width: 52px;
        border-radius: 50%;
        margin-left: 17px;
    }

`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 17px;
    width: 170px;
    font-family: Inter;


    & > span:nth-child(1) {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0px;
        text-align: left;
        color: #45413C;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & > span:nth-child(2){
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #686868;
    }

`;

export const SVGIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 17px;

    & > svg {
        stroke-width: 2px;
        stroke: 2px;
    }
`;


export const UserInfoContainer = styled.div`

    min-height: 100vh;
    margin-left: 64px;
    display: flex;
    flex-direction: column;


    .input-form {
        width: 521px !important;
        margin-bottom: 20px;
    }

    #cargoNameSuggestions {
        width: 521px !important;
        margin-bottom: 30px;
    }
    
    .input-form > input{
        height: 54px !important;
        border: 2px solid #E4E4E4 !important;
        color: rgba(69, 65, 60, 0.80);
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px; 
    }
    


    .input-form > input:disabled {
        border: 2px solid #E4E4E4 !important;
        color: rgba(69, 65, 60, 0.80) !important;
    }

    h3 {
        margin-bottom: 40px;
    }

    .admin-edit-button, .admin-save-button {
        margin-top: 20px;
        width: 521px;
        height: 56px;
        padding: 20px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }


`;


export const TabSubtitle = styled.h3`
    /* width: 193px; */
    height: 36px;
    /* top: 261px; */
    /* left: 308px; */
    /* ma: 30px; */
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    color: #45413C;
`;



export const TabListUserSection = styled.div`

    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* height: 100vh; */
`;

export const TabListContainer = styled.div`

    display: flex;
    flex-direction: column;

`;

export const SectionSeparator = styled.div`
    background: #CACACA;
    width: 1px;
    /* height: 100vh; */
    position: relative;
    display: block;
    transform: translateY(-25px);
    height: auto;
    margin-left: 30px;
`;

export const TabMainContainer = styled.div`

    display: flex;
    
`;


export const ProfilePic = styled.label`
    width: 83px;
    height: 83px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;

    /* border: 1px solid black; */
    margin-bottom: 20px;

    #image_file {
        display: block;
        width: 83px;
        height:83px;
        border-radius: 999px;
    }


    & input[type=file] {
        display: none;
        visibility: hidden;
    }

    & .icon {
        opacity: 80%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        
        /* border: 1px solid black; */
        background: #FEFEFE;
        color: #CACACA;
    }

    & .icon:hover {
        opacity: 100%;
    }

`;



export const TabSettingsButtonBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    justify-content: flex-end;
    
    button{
        width: auto;
        /* height: 48px !important; */
        display: inline-flex !important;
        margin: 0px 0;
        padding: 10px 12px;
        gap: 5px;
    }

    @media screen and (max-width: 1400px){

        button{
            margin: 0px 0;
        }
        
        .add-item{
            width: 180px;
             margin: 0;
        }

        button{
            /* max-height: 42px; */
            padding: 8px;
        }

        .info-more{
            margin: 0 0 0 21px !important;
        }
    }
`;



export const TabSettingsInfoContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
`;