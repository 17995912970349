import { handleRequest } from "../api/api-request.service";
import { FoodTypeI } from "./interfaces/food-type.interface";

export class FoodTypeM implements FoodTypeI {
    id?: number;
    name: string;

    constructor({ id, name }: FoodTypeI) {
        this.id = id;
        this.name = name;
    }

    static initial(){
        return new FoodTypeM({
            name : ''
        });
    }

    static async getAll(){
        return handleRequest(
            `food-type`,
            'GET'
        );
    }

    static async create(foodType : FoodTypeM){
        return handleRequest(
            `food-type`,
            'POST',
            foodType
        );
    }

    static async update(foodType : FoodTypeM){
        return handleRequest(
            `food-type`,
            'PUT',
            foodType
        );
    }

}