import { handleRequest } from "../api/api-request.service";
import { StudentAllergiesI } from "./interfaces/student-allergies.interface";
import { StudentDiseaseI } from "./interfaces/student-disease.interface";
import { StudentEspecialCareI } from "./interfaces/student-especial-care.interface";
import { StudentHealthObservationI } from "./interfaces/student-health-observation.interface";
import { StudentDiseaseM } from "./student-disease.model";

export class StudentHealthObservationM implements StudentHealthObservationI {
    id?: number;
    diseases?: StudentDiseaseI[];
    allergies?: StudentAllergiesI[];
    especialCares?: StudentEspecialCareI[];
    studentId?: number;

    constructor({
        id,
        diseases,
        allergies,
        especialCares,
        studentId,
    }: StudentHealthObservationI) {
        this.id = id;
        this.diseases = diseases;
        this.allergies = allergies;
        this.especialCares = especialCares;
        this.studentId = studentId;
    }

    public static initial(studentId: number){
        return new StudentHealthObservationM({
            diseases: [],
            allergies: [],
            especialCares: [],
            studentId
        });
    }

    static async getAll(studentId: number){
        return handleRequest(
            `/student-health-observation/by/student/${studentId}`,
            'GET'
        );
    }

    static async update(studentHealthObservation : StudentHealthObservationM){
        return handleRequest(
            `student-health-observation/${studentHealthObservation.id}`,
            'PUT',
            studentHealthObservation
        );
    }

    static async create(studentHealthObservation : StudentHealthObservationM){
        return handleRequest(
            `student-health-observation/`,
            'POST',
            studentHealthObservation
        );
    }
}