import styled from "styled-components"

export const TabSelectConsumableContainer = styled.div`
    position: relative;
    button{
        justify-content: center;
        margin-top: 20px;
        padding: 16px 18px;
    }

`

export const TabSelectConsumableContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    margin-top: 48px;
    padding-bottom: 90px;

    .active{
        border: 2px solid var(--primary-color);
    }

    img{
        width: 65px !important;
        height: 65px !important;
    }



    @media screen and (max-width : 1400px){
        gap: 36px;
        margin-top: 36px;
    }

`


export const TabSelectConsumableButtonBar = styled.div`
    position: fixed;
    bottom: 8px;
    height: 90px;
    background-color: var(--white-color);
    width: 898px;
    margin-left: -50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 8px 8px;

    button{
        margin: 0 !important;
        width: 60%;
    }

    @media screen and (max-width: 1400px){
        width: 673px;
        margin-left: -36px;
        
    }
`;

export const TabSelectConsumableItemContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 2px;

    h3{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const TabSelectConsumableItem = styled.div`
    cursor: pointer;
    min-height: 252px; 
    width: 234px;
    border-radius: 8px;
    border: 1.5px solid var(--border-color);

    display: flex;
    flex-direction: column;

    img{
        width: 100% !important;
        min-height: 155px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }

    &:hover{
        border: 2px solid var(--primary-color);
    }

    @media screen and (max-width : 1400px){
        min-height: 189px;
        width: 175px;

        img{
            min-height: 116px;
        }
    }
`

export const TabSelectConsumableEmptyImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 155px;
    background-color: var(--grey-light-3-color);
    border-radius: 8px 8px 0 0;

    @media screen and (max-width: 1400px){
        min-height: 116px;
    }
`;

export const TabSelectConsumableSearchBar = styled.form`
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .inputSearch{
        width: 100%;
    }



    .input-style{
        margin-top: 12px !important;
        min-height: 50px !important;
    }

    @media screen and (max-width: 1400px){
        .input-style{
            margin-top: 8px !important;
            min-height: 41px !important;

        }

    }

`