import { Dispatch } from "redux";
import { StudentDiseaseM } from "../../../../models/student-disease.model";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { StudentHealthyObservationController } from "../../../../controllers/student-healthy-observation.controller";
import { StudentHealthObservationM } from "../../../../models/student-health-observation.model";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";

export class AddDiseaseModalService{
    dispatch : Dispatch;
    studentHealthyObservationController : StudentHealthyObservationController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;

        this.studentHealthyObservationController = new StudentHealthyObservationController();
    }
    
      
    public async handleSubmit(
        disease : StudentDiseaseM,
        setShowDiaseModal: Function,
        studentHealthyObservation?: StudentHealthObservationM,
        
    ){
        
        if(studentHealthyObservation){
            this.dispatch(generalSetIsLoading(true));
            studentHealthyObservation.diseases?.push(disease);
            await this.studentHealthyObservationController.createAndUpdate(studentHealthyObservation).then(response=>{
                const {status, data} = response;
                if(status === responseStatus.SUCCESS){
                    setShowDiaseModal(false);
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    })
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            });
        }
    };
}
