export class LocalToken {


    static storeToken(access: string, userType: string) {
        localStorage.setItem('access', access);
        localStorage.setItem('userType', userType);
    }

    static clearStoredToken() {
        localStorage.removeItem('access');
        localStorage.removeItem('userType');
    }

    static getStoredToken() {
        return localStorage.getItem('access') ?? '';
    }

    static getStoredUserType() {
        return localStorage.getItem('userType') ?? '';
    }

}