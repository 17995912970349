import { handleRequest } from "../api/api-request.service";
import { ResponsibleI } from "./interfaces/responsible.interface";
import { StudentI } from "./interfaces/student.interface";
import { UserI } from "./interfaces/user.interface";
import { UserM } from "./user.model";

export class ResponsibleM implements ResponsibleI {
    id?: number;
    isCanteenSubscriber: boolean;
    isNotificationsAllowed: boolean;
    name: string;
    firstLogin?: boolean;
    sex?: string;
    userId?: number;
    user?: UserI;
    students?: StudentI[];

    constructor({
        id,
        isCanteenSubscriber,
        isNotificationsAllowed,
        name,
        firstLogin,
        sex,
        userId,
        user,
        students,
    }: ResponsibleI) {
        this.id = id;
        this.isCanteenSubscriber = isCanteenSubscriber;
        this.isNotificationsAllowed = isNotificationsAllowed;
        this.name = name;
        this.firstLogin = firstLogin;
        this.sex = sex;
        this.userId = userId;
        this.user = user;
        this.students = students;
    }

    static initial(): ResponsibleM {
        return new ResponsibleM({
            isCanteenSubscriber: false,
            isNotificationsAllowed: false,
            name: "",
            students: [],
            firstLogin: false
        });
    }


}