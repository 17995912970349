import { StudentAllergiesI } from "./interfaces/student-allergies.interface";

export class StudentAllergiesM implements StudentAllergiesI {
    id?: number;
    allergie: string;
    studentNoteId?: number;

    constructor({ id, allergie, studentNoteId }: StudentAllergiesI) {
        this.id = id;
        this.allergie = allergie;
        this.studentNoteId = studentNoteId;
    }

    static initial(){
        return new StudentAllergiesM({
            allergie : ""
        });
    }
}