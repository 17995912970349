import { FullMealM } from "../models/full-meal.model";

export class FullMealController {

    async createAndUpdate(fullMeal : FullMealM){
        if(fullMeal.id){
            return await FullMealM.update(fullMeal);
        }
        return await FullMealM.create(fullMeal);
    }

    async createAndUpdateList(fullMeals : FullMealM[]){
        if(fullMeals[0].id){
            return await FullMealM.updateList(fullMeals);
        }
        return await FullMealM.createList(fullMeals);
    }
    
    async getByTodaysMenuId(selectedTodaysMenuId: number){
        return await FullMealM.getByTodaysMenuId(selectedTodaysMenuId);
    }
}