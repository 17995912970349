import { Dispatch } from "redux";
import { canteenSetTabSelectdItem, canteenSetTabSelectedContent } from "../../../../features/canteen-slice.feature";
import { canteenTabToContent } from "../../../../features/types/canteen-slice.type";

export class HomeService{
    dispatch : Dispatch;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
    }
    
    public handleSetSelectedTab(tabSelectedItem : string){
        this.dispatch(canteenSetTabSelectdItem(tabSelectedItem));
        this.dispatch(canteenSetTabSelectedContent(canteenTabToContent[tabSelectedItem]))
    }
}
