import { useDispatch } from "react-redux";
import { TabBar } from "../../components/tab-bar.component";
import { HomeContainer } from "../settings/styles/home.styled";
import { useAppSelector } from "../../../hooks";
import { HomeService } from "./services/home.service";
import { settingsContents, settingsTabList } from "../../../features/types/settings-slice.type";

export const SettingsPage = () => {

    const settingsReducer = useAppSelector((state) => state.settingsReducer);
    const dispatch = useDispatch();

    const homeService = new HomeService(dispatch);


    return (
        <HomeContainer>
           <h2 className='primaryBlackSemibold28-120'>Configurações</h2> 
           <span className='primaryBlackNormal20-120'>Gerencie permissões de acesso e outras configurações</span>

           <TabBar 
                setSelectedTab={(tabSelectedItem)=>homeService.handleSetSelectedTab(tabSelectedItem)} 
                selected={settingsReducer.tabSelectedItem} 
                items={settingsTabList}
            />
        
           { settingsContents[settingsReducer.tabSelectedContent] }
           
        </HomeContainer>
    );
};