import styled from "styled-components";

export const ButtonSecondaryContainer = styled.button<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 16px;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
    background-color: transparent;
    border-radius: 8px;
    border: none;
    width: 100%;
    outline: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? 0.3 : 1)};

    @media screen and (max-width: 1400px) {
        padding: 12px;
    }
`;