import { handleRequest } from "../api/api-request.service";
import { GradeI } from "./interfaces/grade.interface";
import { SchoolWorkerI } from "./interfaces/school-worker.interface";

export class GradeM implements GradeI {
    id?: number;
    name: string;
    education: string;
    instituteId?: number;
    studentCount?: number;
    schoolWorkers?: SchoolWorkerI[];

    constructor({ id, name, education, studentCount, schoolWorkers }: GradeI) {
        this.id = id;
        this.name = name;
        this.education = education;
        this.studentCount = studentCount;
        this.schoolWorkers = schoolWorkers;
    
    }

    static initial(){
        return new GradeM({
            name : '',
            education : '',
            schoolWorkers: []
        });
    }

    static async getAll(){
        return handleRequest(
            `grades`,
            'GET'
        );
    }

    static async getById(id?: number){
        return handleRequest(
            `grades/${id}`,
            'GET'
        );
    }

    static async getAllByInstitute(instituteId: number, search?: string){
        return handleRequest(
            `grades/by/institute/${instituteId}?name=${search}`,
            'GET'
        );
    }


    static async create(grade : GradeM){
        return handleRequest(
            `grades`,
            'POST',
            grade
        );
    }

    static async update(grade : GradeM){
        return handleRequest(
            `grades/${grade.id}`,
            'PUT',
            grade
        );
    }

}