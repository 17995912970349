import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductCategoriesDataT, ProductsDataT, TabMenuStateT } from "./types/tab-menu-slice.type";
import { ProductM } from "../models/product.model";

const initialState : TabMenuStateT = {
    productForm : ProductM.initial()
} 

export const tabMenuSlice = createSlice({
   name: 'tab-menu',
   initialState,
   reducers: {
        tabMenuSetProductCategoriesData(state, action : PayloadAction<ProductCategoriesDataT>){
            state.productCategoriesData = action.payload;
        },
        tabMenuSetProductsData(state, action : PayloadAction<ProductsDataT>){
            state.productsData = action.payload;
        },
        tabMenuSetProductForm(state, action : PayloadAction<ProductM>){
            state.productForm = action.payload;
        }
   } 
});

export const { 
    tabMenuSetProductCategoriesData,
    tabMenuSetProductsData,
    tabMenuSetProductForm
} = tabMenuSlice.actions;
export const tabMenuReducer = tabMenuSlice.reducer;