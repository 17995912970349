import { GradeM } from "../../models/grade.model";
import { StudentM } from "../../models/student.model";
import { UserStudentM } from "../../models/user-student.model";
import { TabOrdersCompletedPage } from "../../views/modules/canteen/tab-orders-completed.page";
import { HistoricEvaluations } from "../../views/modules/students/historic-evaluation.page";
import { StudentTabCanteen } from "../../views/modules/students/student-tab-canteen.page";
import { StudentDetailsPage } from "../../views/modules/students/students-details.page";
import { TabAddGradePage } from "../../views/modules/students/tab-add-grade.page";
import { TabAddStudentHealthyPage } from "../../views/modules/students/tab-add-student-healthy.page";
import { TabAddStudentInformationPage } from "../../views/modules/students/tab-add-student-information.page";
import { TabAddStudentResponsiblePage } from "../../views/modules/students/tab-add-student-responsibles.page";
import { TabGradePage } from "../../views/modules/students/tab-grade.page";
import { TabStudentsPage } from "../../views/modules/students/tab-students.page";

const tabsNames = {
    STUDENTS_LIST : 'Lista de Alunos', 
    GRADES : 'Turmas',
    
}

const tabsDetailsNames = {
    STUDENT_INFORMATION : 'Informações do aluno', 
    STUDENT_HEALTH : 'Saúde do aluno',
    RESPONSIBLE_INFORMATION : 'Informações dos responsáveis', 
    CANTEEN : 'Cantina',
    HISTORIC_EVALUATION: 'Histórico de avaliações das refeições'
}

export const studentContentsNames = {
    STUDENTS_LIST : 'students-list', 
    GRADES : 'grades',
    STUDENT_DETAILS : 'student_details',
    ADD_GRADE : 'add_grade',
    
}

export const studentDetailsContentsNames = {
    STUDENT_INFORMATION : 'student_information', 
    STUDENT_HEALTH : 'student_health',
    RESPONSIBLE_INFORMATION : 'responsible_information', 
    CANTEEN : 'canteen',
    HISTORIC_EVALUATION: 'historic_evaluation'
}

export const studentTabToContent = {
    [tabsNames.STUDENTS_LIST] : studentContentsNames.STUDENTS_LIST,
    [tabsNames.GRADES] : studentContentsNames.GRADES,
    
}

export const studentDetailsTabToContent = {
    [tabsDetailsNames.STUDENT_INFORMATION] : studentDetailsContentsNames.STUDENT_INFORMATION,
    [tabsDetailsNames.STUDENT_HEALTH] : studentDetailsContentsNames.STUDENT_HEALTH,
    [tabsDetailsNames.RESPONSIBLE_INFORMATION] : studentDetailsContentsNames.RESPONSIBLE_INFORMATION,
    [tabsDetailsNames.CANTEEN] : studentDetailsContentsNames.CANTEEN,
    [tabsDetailsNames.HISTORIC_EVALUATION] : studentDetailsContentsNames.HISTORIC_EVALUATION
}

export const studentTabList = Object.values(tabsNames);
export const studentDetialsTabList = Object.values(tabsDetailsNames);

export const studentContents = {
    [studentContentsNames.STUDENTS_LIST] : <TabStudentsPage/>,
    [studentContentsNames.GRADES] : <TabGradePage/>,
    [studentContentsNames.STUDENT_DETAILS] : <StudentDetailsPage/>,
    [studentContentsNames.ADD_GRADE] : <TabAddGradePage/>
}

export const studentDetailsContents = {
    [studentDetailsContentsNames.STUDENT_INFORMATION] : <TabAddStudentInformationPage/>,
    [studentDetailsContentsNames.STUDENT_HEALTH] : <TabAddStudentHealthyPage/>,
    [studentDetailsContentsNames.RESPONSIBLE_INFORMATION] : <TabAddStudentResponsiblePage/>,
    [studentDetailsContentsNames.CANTEEN] : <StudentTabCanteen/>,
    [studentDetailsContentsNames.HISTORIC_EVALUATION] : <HistoricEvaluations/>,
}

export type StudentStateT = {
    tabSelectedItem : string,
    tabSelectedContent : string,
    tabDetailsSelectedItem : string,
    tabDetailsSelectedContent : string,
    studentForm: UserStudentM,
    gradeForm : GradeM,
    showGradeListStudent : boolean
    
}