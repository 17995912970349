import styled from "styled-components"

export const TabStudentEvaluationEvaluationContainer = styled.div`
    
`;

export const TabStudentEvaluationEvaluationContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    img{
        width: 268px;
        height: 268px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid var(--border-color);
    }

    @media screen and (max-width: 1400px) {
        img{
            width: 200px;
            height: 200px;
        }
    }
`;

export const TabStudentEvaluationEvaluationEmotions = styled.div`
    width: 420px;
    display: flex;
    flex-wrap: wrap;

    img{
        border: none;
        width: 80px;
        height: 80px;
    }

    .active{
        border: 2px solid var(--green-color);
        background-color: var(--green-opacity-color);
    }

    @media screen and (max-width: 1400px){
        width: 315px;
        img{
            width: 60px;
            height: 60px;
        }
    }
`;

export const TabStudentEvaluationEvaluationEmotionsItem = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
    cursor: pointer;
    padding: 16px;
    border-radius: 8px;
`;

export const TabStudentEvaluationFinalConsumables = styled.div`
    display: flex;
    gap: 32px;
    margin-bottom: 12px;
`;

export const TabStudentEvaluationFinalConsumablesItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
`;

export const TabStudentEvaluationEvaluationTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 35px 0;

    
    
    @media screen and (max-width: 1400px){
        margin: 26px 0;   
    }
`;

export const TabStudentEvaluationEvaluationButtonBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
        width: 420px;
        justify-content: center;
        padding: 20px 0;

    }

    @media screen and (max-width: 1400px){
        button{
            width: 315px;
            padding: 15px 0;
        }  
    }
`;