import styled from "styled-components";

export const TabAddStudentInformationContainer = styled.div`
    
`;

export const TabAddStudentInformationContent = styled.form`
    display: flex;
    flex-direction: column;
    width: 520px;
    gap: 24px;

    .title-card{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .print{
            color: var(--primary-base, #FF6242);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 24px */
            cursor: pointer;
        }
    }

    .birthDate{
        width: 520px;
        input{
            width: 520px;
        }
    }

    button{
        align-items: center;
        justify-content: center;
        padding: 16px 18px;
    }

    @media screen and (max-width: 1400px) {
        width: 390px;  
        gap: 18px;    
        .birthDate{
            width: 390px;
            input{
                width: 390px;
            }
        } 
    }
`;

