import { useDispatch } from "react-redux";
import { TabBar } from "../../components/tab-bar.component";
import { HomeContainer } from "../settings/styles/home.styled";
import { useAppSelector } from "../../../hooks";
import { HomeService } from "./services/home.service";
import { announcementsContents, announcementsTabList } from "../../../features/types/announcements-slice.type";

export const AnnouncementsPage = () => {

    const announcementsReducer = useAppSelector((state) => state.announcementsReducer);
    const dispatch = useDispatch();

    const homeService = new HomeService(dispatch);


    return (
        <HomeContainer>
           <h2 className='primaryBlackSemibold28-120'>Comunicados</h2> 
           <span className='primaryBlackNormal20-120'>Crie e gerencie comunicados importantes para os pais</span>

           <TabBar 
                setSelectedTab={(tabSelectedItem)=>{}} 
                selected={announcementsReducer.tabSelectedItem} 
                items={[]}
            />
        
           { announcementsContents[announcementsReducer.tabSelectedContent] }
           
        </HomeContainer>
    );
};