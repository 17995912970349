import React from 'react';
import { ToastContainer } from './styles/toast.styled';
import { useAppSelector } from '../../hooks';
import alert_success_icon from '../../assets/icons/alert-success.svg';
import alert_warning_icon from '../../assets/icons/alert-warning.svg';
import { toastTypes } from '../../features/types/general-slice.type';

export const Toast = () => {
  const generalReducer = useAppSelector(state => state.generalReducer);
  return (
    <ToastContainer type={generalReducer.toast.type} color={generalReducer.toast.type === toastTypes.ERROR ? '#E54926' : '#44AD5C'}>
        <img src={generalReducer.toast.type === toastTypes.ERROR ? alert_warning_icon : alert_success_icon } alt='Icone de alerta'/>
        {generalReducer.toast.text.includes('<br/>') ? 
            <span dangerouslySetInnerHTML={{ __html: generalReducer.toast.text }}  className='primaryWhiteSemibold18-150'></span>:
            <span className='primaryWhiteSemibold18-150'>{generalReducer.toast.text}</span>
        }
    </ToastContainer>
  );
};