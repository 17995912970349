import { Action, Dispatch } from "redux";
import { NutritionalCategoryM } from "../../../../models/nutritional-category.model";
import { SetStateAction } from "react";
import { generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { NutritionalCategoryController } from "../../../../controllers/nutritional-category.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { foodSetShowAddNutritionalCategoryModal } from "../../../../features/food-slice.feature";

export class NutritionalCategoryModalService{
    dispatch : Dispatch;
    nutritionalCategory : NutritionalCategoryM;
    setNutritionalCategory : Function;
    handleGetNutritionalCategories : Function;
    nutritionalCategoryController : NutritionalCategoryController;

    constructor(
        dispatch : Dispatch,
        nutritionalCategory : NutritionalCategoryM,
        setNutritionalCategory : Function,
        handleGetNutritionalCategories : Function,
    ){
        this.dispatch = dispatch;
        this.nutritionalCategory = nutritionalCategory;
        this.setNutritionalCategory = setNutritionalCategory;
        this.handleGetNutritionalCategories = handleGetNutritionalCategories;
        this.nutritionalCategoryController = new NutritionalCategoryController();

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateNutritionalCategory = this.validateNutritionalCategory.bind(this);
    }

    public handleChangeName(value : string){
        this.setNutritionalCategory({
            ...this.nutritionalCategory,
            name : value
        });
    }

    public handleSetShowAddNutritionalCategoryModal(status : boolean){
        this.dispatch(foodSetShowAddNutritionalCategoryModal(status));
    }

    private validateNutritionalCategory(){
        const fieldsErrors = [];
        if(!this.nutritionalCategory.name){
            fieldsErrors.push('Nome');
        }
        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        return true;
    }

    public handleSubmit(){
        if(this.validateNutritionalCategory()){
            this.dispatch(generalSetIsLoading(true));
            this.nutritionalCategoryController.createAndUpdate(this.nutritionalCategory).then(response=>{
                const { status, data } = response;
                if(status === responseStatus.SUCCESS){
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Categoria cadastrada com sucesso',
                    });
                    this.dispatch(foodSetShowAddNutritionalCategoryModal(false));
                    this.handleGetNutritionalCategories();
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            })
        }

    }


}