import { Dispatch } from "redux";
import { foodTabToContentAdm, foodTabToContentStudent, foodTabToContentTeacher } from "../../../../features/types/food-slice.type";
import { foodSetTabSelectdItem, foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { LocalToken } from "../../../../utils/local-token";
import { LoginUserType } from "../../../../utils/config";
import { UserSchoolWorkerM } from "../../../../models/user-school-worker.model";

export class HomeService{
    dispatch : Dispatch;
    userSchoolWorker? : UserSchoolWorkerM

    constructor(dispatch : Dispatch, userSchoolWorker? : UserSchoolWorkerM){
        this.dispatch = dispatch;
        this.userSchoolWorker = userSchoolWorker;
    }
    
    public handleSetSelectedTab(tabSelectedItem : string){
        this.dispatch(foodSetTabSelectdItem(tabSelectedItem));
        if(LocalToken.getStoredUserType() === LoginUserType.TEACHER){
            if(this.userSchoolWorker?.groups?.find(group=>group.name === "SCHOOL_TEACHER")){
                this.dispatch(foodSetTabSelectedContent(foodTabToContentTeacher[tabSelectedItem]));
            }else{
                this.dispatch(foodSetTabSelectedContent(foodTabToContentAdm[tabSelectedItem]));
            }
            
        }else{
            this.dispatch(foodSetTabSelectedContent(foodTabToContentStudent[tabSelectedItem]));
        }
    }
}
