import { UserI } from "../../models/interfaces/user.interface";
import { TabAdmin } from "../../views/modules/settings/tab-admin.page";
import { TabClasses } from "../../views/modules/settings/tab-classes.page";

const tabsNames = {
    ADMIN: 'Administradores',
    // CLASSES: 'Turmas'
}

export const settingsContentsNames = {
    WORKERS: 'school-workers',
    // CLASSES: 'classes'
}

export const settingsTabToContent = {
    [tabsNames.ADMIN]: settingsContentsNames.WORKERS,
    // [tabsNames.CLASSES]: settingsContentsNames.CLASSES,
}

export const settingsTabList = Object.values(tabsNames);

export const settingsContents = {
    [settingsContentsNames.WORKERS]: <TabAdmin />,
    // [settingsContentsNames.CLASSES]: <TabClasses />,
}

export type UserSchoolWorkersDataStateT = {
    count: number,
    rows: UserI[]
}

export type UserSchoolWorkersStateT = {
    tabSelectedItem: string,
    tabSelectedContent: string,
    schoolWorkersData?: UserSchoolWorkersDataStateT
}