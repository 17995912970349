import { useDispatch } from "react-redux";
import { TabAddStudentInformationContainer, TabAddStudentInformationContent } from "./styled/tab-add-student-information.styled";
import { Input } from "../../components/input.component";
import { useAppSelector } from "../../../hooks";
import { TabAddStudentInfomationService } from "./services/tab-add-student-information.service";
import { Select } from "../../components/select.component";
import { useEffect, useState } from "react";
import { GradeM } from "../../../models/grade.model";
import { ButtonPrimary } from "../../components/button-primary.component";
import { StudentCard } from "../../components/student-card.component";
import { UserI } from "../../../models/interfaces/user.interface";
import puppyImage from '../../../assets/icons/puppy.svg';
import { DatePicker } from "../../components/date-picker.component";
import { formatCPF } from "../../../utils/config";


export const TabAddStudentInformationPage = () => {
    const dispatch = useDispatch();
    const studentReducer = useAppSelector(state => state.studentReducer);
    const [gradesData, setGradesData] = useState<{ count: number, rows: GradeM[] }>();
    const tabAddStudentInfomationService = new TabAddStudentInfomationService(dispatch);

    useEffect(() => {
        tabAddStudentInfomationService.handleGetGrades(setGradesData);
    }, []);
 


    function handlePrint() {
        const cardId = `card-${studentReducer.studentForm.student.id}`;
        const elementToPrint = document.getElementById(cardId);

        if (elementToPrint) {
            const printWindow = window.open('', '_blank');

            if (printWindow) {
                const styleElement = printWindow.document.createElement('style');
                styleElement.textContent = `
                body {
                    .fruit-item{
                        display: flex;
                        width: 50px;
                        height: 50px !important;
                        padding: 0;
                        justify-content: center;
                        align-items: center;

                        border-radius: 2px;
                        background: #F7F7F7;

                        img{
                            margin: 0;
                            width: 30px;
                            height: 30px;
                        }
                    }
                            
                    .card-container {
                        width: 400px;
                        display: inline-flex;
                        padding: 8px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border: 1px dashed var(--grey-600, #332D2D);
                    }
                    .card-content {
                        display: flex;
                        width: 360px;
                        padding: 12px 16px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 16px;
                        border-radius: 4px;
                        border: 2px solid var(--contorno-cinza-usurio-aluno, #E4E4E4);
                    }
                    .content-bottom {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 8px;
                        .fruits {
                            display: flex;
                            align-items: flex-start;
                            gap: 9px;
                            img {
                                width: 20px;
                            }
                        }
                        .title-small {
                            color: #45413C;
                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: Inter;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%;
                            text-transform: uppercase;
                        }
                    }
                    .content-top {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-self: stretch;
                        .content-left {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 16px;
                            .info {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 4px;
                            }
                        }

                        

                        .content-right {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-end;
                            gap: 4px;
                            span {
                                color: #7B7875;
                                font-family: Inter;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 150%;
                            }
                            img {
                                width: 96px;
                                height: 96px;
                            }
                        }
                        .healthy-puppy {
                            color: var(--primary-base, #FF6242);
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                        }
                        .student-name {
                            color: #45413C;
                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: Inter;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 120%;
                        }
                        span {
                            color: #45413C;
                            font-feature-settings: 'clig' off, 'liga' off;
                            font-family: Inter;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%;
                        }
                    }
                }
            `;

                // Adiciona o elemento <style> ao <head> do documento de impressão
                printWindow.document.head.appendChild(styleElement);

                // Copia o HTML do elemento a ser impresso
                printWindow.document.body.innerHTML = `
                ${elementToPrint.outerHTML}
                <img id="puppy-image" src="${puppyImage}" alt="puppy icon" style="display: none;" />
            `;

                const imgElement = printWindow.document.getElementById('puppy-image') as any;
                if (imgElement) {
                    const image = new Image();
                    image.src = puppyImage;
                    image.onload = () => {
                        imgElement.src = image.src;
                        printWindow.document.close();
                        printWindow.focus();
                        printWindow.print();
                    };
                }
            }
        }
    }


    return (
        <TabAddStudentInformationContainer>

            <h2 className='primaryBlackSemibold28-120'>Informações do aluno</h2>
            <TabAddStudentInformationContent autoComplete="off">
                <Input
                    className='input-form'
                    id='name'
                    name='name'
                    type='text'
                    label='Nome do aluno'
                    placeholder='Nome do aluno'
                    value={studentReducer.studentForm.student.name}
                    onChange={(value) => tabAddStudentInfomationService.handleChangeName(value, studentReducer.studentForm)}
                />

                <Input
                    className='input-form'
                    id='cpf'
                    name='cpf'
                    type='text'
                    label='CPF do aluno'
                    placeholder='CPF do aluno'
                    value={formatCPF(studentReducer.studentForm?.cpf)}
                    onChange={(value) => tabAddStudentInfomationService.handleChangeCPF(value, studentReducer.studentForm)}
                />

                <Input
                    className='input-form'
                    id='registrationCode'
                    name='registrationCode'
                    type='registrationCode'
                    label='Código de registro'
                    placeholder='Código de registro'
                    value={studentReducer.studentForm?.student.registrationCode}
                    onChange={(value) => tabAddStudentInfomationService.handleChangeRegistrationCode(value, studentReducer.studentForm)}
                />

                <DatePicker
                    label="Data de nascimento"
                    className="birthDate"
                    placeholder="Data de nascimento"
                    acceptWeekend={true}
                    date={studentReducer.studentForm?.student?.birthDate}
                    setDate={(value: any) => tabAddStudentInfomationService.handleChangeBirthDate(value, studentReducer.studentForm)}
                />

                <Select
                    className='input-form'
                    label='Gênero'
                    name='sex'
                    id='sex'
                    placeholder='Gênero'
                    defaultValue={studentReducer.studentForm?.student.sex}
                    options={tabAddStudentInfomationService.handleSexToOptions()}
                    onChange={(value) => tabAddStudentInfomationService.handleChangeSexByValue(value, studentReducer.studentForm)}
                />

                <Select
                    className='input-form'
                    label='Ano/Year'
                    name='turma'
                    id='turma'
                    placeholder='Selecione uma turma'
                    defaultValue={studentReducer.studentForm?.student.grade?.id?.toString()}
                    options={tabAddStudentInfomationService.handleGradesToOptions(gradesData?.rows)}
                    onChange={(value) => tabAddStudentInfomationService.handleFindGradeByValue(value, studentReducer.studentForm, gradesData?.rows)}
                />

                {/* <Input
                    className='input-form'
                    id='canteen-password'
                    name='canteen-password'
                    type='text'
                    label='Senha para acesso à cantina'
                    placeholder='Senha para acesso à cantina'
                    value={studentReducer.studentForm.student.canteenPassword}
                    onChange={(value) => tabAddStudentInfomationService.handleChangeCanteenPassword(value, studentReducer.studentForm)}
                /> */}

                <Select
                    className='input-form'
                    label='Tipo de cardápio'
                    name='menu-type'
                    id='menu-type'
                    placeholder='Tipo de Cardápio'
                    defaultValue={studentReducer.studentForm?.student.usesSpecialMenu ? 'special' : 'default'}
                    options={tabAddStudentInfomationService.handleMenuTypesToOptions()}
                    onChange={(value) => tabAddStudentInfomationService.handleChangeMenuTypeByValue(value, studentReducer.studentForm)}
                />

                {studentReducer.studentForm.student.id &&
                    <>
                        <div className="title-card">
                            <label className='label primaryBlackSemibold18-150'>Cartão do aluno</label>
                            <span className="print" onClick={handlePrint}>Imprimir</span>
                        </div>

                    
                        <StudentCard id={`card-${studentReducer.studentForm.student.id}`} student={{ ...studentReducer.studentForm?.student, user: studentReducer.studentForm as UserI }} />
                    </>
                }

                <ButtonPrimary
                    onClick={() => { tabAddStudentInfomationService.handleSubmit(studentReducer.studentForm) }}
                >
                    Salvar informações
                </ButtonPrimary>
            </TabAddStudentInformationContent>
        </TabAddStudentInformationContainer>
    );
}