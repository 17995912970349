import { handleRequest } from "../api/api-request.service";
import { OrderM } from "../models/order.model";
import { OrderI } from "../models/interfaces/order.interface";

export class OrderController {

    async getByCanteenId(
        canteenId : number, 
        date : Date | null, 
        status : string,
        page : number
    ){
        const limit = 10;
        return await OrderM.getByCanteenId(canteenId, date, limit, page*limit, status);
    }

    async update(order : OrderI){
        return await OrderM.update(order);
    }
}