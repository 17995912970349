import { Dispatch } from "redux";
import { responseStatus } from "../../../../api/api-request.service";
import { AnnouncementsController } from "../../../../controllers/announcements.controller";
import { announcementsSetTabSelectedContent } from "../../../../features/announcements-slice.feature";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { tabAnnouncementsForm, tabAnnouncementsSetAnnouncementsData } from "../../../../features/tab-announcements-slice.feature";
import { announcementsContentsNames } from "../../../../features/types/announcements-slice.type";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { AnnouncementM } from "../../../../models/announcements.model";
import { showToast } from "../../../../utils/config";
import { addDays, endOfWeek, startOfWeek } from "date-fns";
import { DatePickerMultiT } from "../../../components/types/datepicker.type";

export class TabAnnouncementsService {
    private static instance: TabAnnouncementsService | null = null;

    private dispatch: Dispatch;
    private announcementsController: AnnouncementsController;
    // private userSchoolWorkersController: UserSchoolWorkerController;

    private constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.announcementsController = new AnnouncementsController();
        // this.userSchoolWorkersController = new UserSchoolWorkerController();
    }

    public static getInstance(dispatch: Dispatch) {
        if (!TabAnnouncementsService.instance) {
            TabAnnouncementsService.instance = new TabAnnouncementsService(dispatch);
        }
        return TabAnnouncementsService.instance;
    }

    public handleGetAnnouncements(dateRange? : DatePickerMultiT, search?: string, status?: string) {
        
        if(dateRange){
            this.dispatch(generalSetIsLoading(true));
    
            this.announcementsController.getAllByInstituteAndDates(dateRange!.startDate, dateRange!.endDate, search, status).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    this.dispatch(tabAnnouncementsSetAnnouncementsData(data));
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }
        
    }

    public handleSelectedDate(selectedDate : Date, setDatesSearch : Function){
        let startDate = startOfWeek(new Date(selectedDate));
        startDate = addDays(startDate, 1);
        let endDate = endOfWeek(new Date(selectedDate));
        endDate = addDays(endDate, -1);
        setDatesSearch({ startDate, endDate });
    }

    public handleShowAddItemPage(){
        this.resetForm();
        this.dispatch(announcementsSetTabSelectedContent(announcementsContentsNames.TAB_FORM_ANNOUNCEMENT));       
    }

    public handleEdit(announcement: AnnouncementM) {
        this.dispatch(generalSetIsLoading(true));
        this.dispatch(tabAnnouncementsForm({ ...announcement }));
        this.dispatch(announcementsSetTabSelectedContent(announcementsContentsNames.TAB_FORM_ANNOUNCEMENT))
        this.dispatch(generalSetIsLoading(false));
    }

    public resetForm() {
        this.dispatch(tabAnnouncementsForm(AnnouncementM.initial()));
    }

}