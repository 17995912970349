import styled from "styled-components";

export const ResetPasswordContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;

    .fruitsSequence{
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        width: 320px;
        margin: 24px 0;
        
        .fruitsSequenceItem{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px ;
            width: 60px ;

            border-radius: 2px;
            background: #F7F7F7;

            cursor: pointer;

            &:hover{
                border: 1px solid #50A822;
            }

            img{
                width: 30px;
            }
        }

    }

    .fruitsSequenceSelecteds{
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        width: 450px;
        margin: 24px 0;
        
        .fruitsSequenceSelectedsItem{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px ;
            width: 60px ;
            position: relative;

            border-radius: 2px;
            border: 1px solid #9E9393;

            cursor: pointer;
            

            &:hover{
                border: 1px solid #50A822;

                .delete{
                    display: flex;
                }
                
            }

            img{
                width: 30px;
            }

            .delete{
                height: 100% ;
                width: 100% ;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #ffffffaa;
                align-items: center;
                justify-content: center;
                display: none;
            }
        }

    }
`;

export const ResetPasswordLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 75px;

    img{
        width: 75px;
    }

    @media screen and (max-width: 1400px) {
        margin-bottom: 56px;
        img{
            width: 56px;
        }
    }
`;

export const ResetPasswordForm = styled.div`
    width: 50%;
    background-color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;

    .submitButton{
        width: 100%;
        border-radius: 4px;
        text-align: center;
        justify-content: center;
    }

    form{
        max-width: 70%;

        h2{
            margin-bottom: 20px;
        }

        .inputEmail, .inputClasse{
            margin-top: 32px;
        }

        .inputPassword, .input{
            margin-top: 24px;
        }

        button{
            margin-top: 32px;
        }
    
    }

    @media screen and (max-width: 1400px) {
        width: 60%;
        
        form{
            h2{
                margin-bottom: 15px;
            }

            .inputEmail, .inputClasse{
                margin-top: 24px;
            }

            .inputPassword, .input{
                margin-top: 18px;
            }

            button{
                margin-top: 24px;   
            }
        }
    }

    @media screen and (max-width: 900px) {
        width: 100%;
        form{
            max-width: 100%;
            padding: 32px;
        }
    }
`;

export const ResetPasswordImage = styled.img`
    width: 50%;
    object-fit: cover;

    @media screen and (max-width: 1400px) {
        width: 40%;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }

`;

export const ResetPasswordForgotPassword = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    
    @media screen and (max-width: 1400px) {
        margin-top: 12px;
    }
`;  