import { ProductM } from "../models/product.model";

export class ProductController {

    async getByCategoryName(categoryName : string, search?: string){
        return await ProductM.getByCategoryName(categoryName, search);
    }

    async delete(productId : number){
        return await ProductM.delete(productId);
    }

    async updateDataWithoutMedia(product : ProductM){
        delete product.media;
        delete product.type;
        return await ProductM.update(product);
    }

    async createAndUpdate(product : ProductM){
        if(product.id){
            return await ProductM.update({
                ...product,
                price : product.price!/100
            });
        }
        return await ProductM.create({
            ...product, 
            canteenId : 1, 
            price : product.price!/100, 
            availableCode : true
        });
    }
}