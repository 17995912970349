import React, { useState } from 'react';
import { AddAllergyModalActions, AddAllergyModalContainer, AddAllergyModalContent, AddAllergyModalDescription, AddAllergyModalTitle } from './styled/add-allergy-modal.styled';
import { useDispatch } from 'react-redux';
import { ButtonPrimary } from '../../components/button-primary.component';
import { Input } from '../../components/input.component';
import { StudentAllergiesM } from '../../../models/student-allergies.model';
import { StudentHealthObservationM } from '../../../models/student-health-observation.model';
import { AddAllergyModalService } from './services/add-allergy-modal.service';


export const AddAllergyModal = (
    {setShowAddAllergyModal, studentHealhtyObservation } : {setShowAddAllergyModal : Function, studentHealhtyObservation? : StudentHealthObservationM}
) => {
    const dispatch = useDispatch();
    const [allergy, setAllergy] = useState<StudentAllergiesM>(StudentAllergiesM.initial());
    const addAllergyModalService = new AddAllergyModalService(dispatch);

    return (
        <AddAllergyModalContainer>
            <AddAllergyModalContent>
                <AddAllergyModalTitle>
                    <span className='primaryBlackSemibold18-150'>Adicionar nova Alergia</span>
                </AddAllergyModalTitle>
                <AddAllergyModalDescription>
                    <Input
                        id='name'
                        name='name'
                        type='text'
                        label='Alergia'
                        placeholder='Alergia'
                        value={allergy?.allergie}
                        onChange={(value)=>{setAllergy({...allergy, allergie : value})}}
                    />
                </AddAllergyModalDescription>
                <AddAllergyModalActions>
                    <ButtonPrimary onClick={()=>{addAllergyModalService.handleSubmit(allergy, setShowAddAllergyModal, studentHealhtyObservation)}}>
                        Salvar Alergia
                    </ButtonPrimary>
                    <span className='primaryBlackNormal20-150' onClick={()=>setShowAddAllergyModal(false)}>Cancelar</span>
                </AddAllergyModalActions>
            </AddAllergyModalContent>
        </AddAllergyModalContainer>
    );
};
