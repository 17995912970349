import { handleRequest } from "../api/api-request.service";
import { PaymentRequestI } from "./interfaces/payment-request.interface";
import { MediaM } from "./media.model";
import { StudentM } from "./student.model";

export class PaymentRequestM implements PaymentRequestI {
    id?: number;
    periodFrom: Date;
    periodTo: Date;
    isConfirmedPayment: boolean;
    isOverdue: boolean;
    isDownloaded: boolean;
    media: MediaM;
    students: StudentM[];
    createdAt?: Date | undefined;

    constructor({
        id,
        periodFrom,
        periodTo,
        isConfirmedPayment,
        isOverdue,
        isDownloaded,
        media,
        students
    }: PaymentRequestI) {
        this.id = id;
        this.periodFrom = periodFrom;
        this.periodTo = periodTo;
        this.isConfirmedPayment = isConfirmedPayment;
        this.isOverdue = isOverdue;
        this.isDownloaded = isDownloaded;
        this.media = media;
        this.students = students;
    }    

    static initial(){
        return new PaymentRequestM({
            periodFrom : new Date(),
            periodTo : new Date(),
            isConfirmedPayment : false,
            isOverdue : false,
            isDownloaded: false,
            media: MediaM.initial(),
            students: []
        });
    }

    static async getAllByStudent(studentId: number) {

        const query = `?offset=0&limit=50`;

        return handleRequest(
            `payment-request/by/student/${studentId}${query}`,
            'GET'
        )
    }
    
    static async update(paymentRequest : PaymentRequestM){
        return handleRequest(
            `payment-request/${paymentRequest.id}`,
            'PUT',
            paymentRequest
        );
    }

    static async create(paymentRequest : PaymentRequestM){
        return handleRequest(
            `payment-request`,
            'POST',
            paymentRequest
        );
    }
}