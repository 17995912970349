import styled from "styled-components";

export const PageCountContainer = styled.div`
    display: flex;
    gap: 12px;

    .active{
        background-color: var(--primary-color);
        color: var(--white-color);
    }

    .disabled{
        color: var(--grey-color);
    }

    @media screen and (max-width: 1400px){
        gap: 9px;        
    }
`;

export const PageCountItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-light-4-color);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    
    @media screen and (max-width: 1400px){
        height: 18px;
        width: 18px;
    }
`