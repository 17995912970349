import styled from "styled-components";

export const StudentTabCanteenContainer = styled.div`
    
`;

export const StudentTabCanteenPaymentContent = styled.div`
    display: flex;
    gap: 24px;
    width: 380px;
    align-content: center;
    justify-content: center;
    padding: 12px;
    border: 1.5px solid var(--black-opacity-color);
    border-radius: 8px;
    span{
        opacity: 0.8;
        display: flex ;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 1400px){
        gap: 18px;
        width: 285px;
        padding: 9px;
    }
`;  

export const StudentTabCanteenPaymentItem = styled.a`
    cursor: pointer;
    text-decoration: none;
`;

export const StudentTabCanteenContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    .active{
        border: 1px dashed var(--black-opacity-color);
    }

    @media screen and (max-width: 1400px){
        margin-top: 18px;
        gap: 12px;
    }
`;

export const StudentTabCanteenHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width: 1400px) {
        padding-bottom: 18px;
    }
`;

export const StudentTabCanteenHeaderButtons = styled.div`
    display: flex;
    gap: 62px;
    @media screen and (max-width: 1400px) {
        gap: 42px;        
    }
`

export const StudentTabCanteenHeaderButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 9px;

    button{
        padding: 9px 12px;
        min-width: 327px;
        align-items: center;
        justify-content: center;

        img{
            width: 24px;
        }
    }

    @media screen and (max-width: 1400px){
        button{
            padding: 8px 9px;
            min-width: 245px;

            img{
                width: 18px;
            }
        }
    }
`