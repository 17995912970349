import React, { useEffect, useState } from 'react';
import { DasboardHomeContainer, DasboardHomeContent, DasboardHomeContentAnnouncement, DasboardHomeContentMenu, DasboardHomeContentOrders, DasboardHomeContentRow, DasboardHomeHeader, DashboardHomeGridItem, DashboardHomeGridItemBody, DashboardHomeGridItemContent, DashboardHomeGridItemSection, TableOrdersHead } from './styles/dashboard-home.styled';
import { Table } from '../../components/table.component';
import { Dropdown } from '../../components/dropdown.component';
import { TodaysMenuM } from '../../../models/todays-menu.model';
import { useDispatch } from 'react-redux';
import { DashboardHomeService } from './services/dashboard-home.service';
import { DatePicker } from '../../components/date-picker.component';
import { baseUrl } from '../../../api/api';
import { TabFoodMenuListProducts, TabFoodMenuProductItem } from '../food/styles/tab-food.styled';
import { FullMealM } from '../../../models/full-meal.model';
import { AnnouncementM } from '../../../models/announcements.model';
import { ButtonPrimary } from '../../components/button-primary.component';
import calendar_icon from '../../../assets/icons/calendar_day.svg';
import { useNavigate } from 'react-router-dom';
import { OrderM } from '../../../models/order.model';
import moment from 'moment';
import { formattedPrice } from '../../../utils/config';
import { useAppSelector } from '../../../hooks';
import { MenuGradeM } from '../../../models/menu-grade.modal';
import { format } from 'date-fns';

export const DashboardHomePage = ()=>{
    const generalReducer = useAppSelector(state=>state.generalReducer);
    const [selectedMenuGrade, setSelectedMenuGrade] = useState<MenuGradeM>();
    const [selectedTodayMenu, setSelectedTodayMenu] = useState<TodaysMenuM>();
    const [fullMealsData, setFullMealsData] = useState<{count : number, rows : FullMealM[]}>();
    const [menuGradeData, setMenuGradeData] = useState<{count : number, rows : MenuGradeM[]}>();
    const [todaysMenuData, setTodaysMenuData] = useState<{count : number, rows : TodaysMenuM[]}>();
    const [announcementsData, setAnnouncementsData] = useState<{count : number, rows : AnnouncementM[]}>();
    const [ordersData, setOrdersData] = useState<{count : number, rows : OrderM[]}>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [orderDateSearch, setOrderDateSearch] = useState(new Date());
    const [ordersDataPage, setOrdersDataPage] = useState(0);
    const navigate = useNavigate();
    
    const dispatch = useDispatch();

    const dashboardHomeService = new DashboardHomeService(dispatch);

    useEffect(()=>{
        dashboardHomeService.handleGetAnnouncements(setAnnouncementsData);
    }, []);

    useEffect(()=>{
        if(orderDateSearch)
            dashboardHomeService.handleGetOrders(
                orderDateSearch, setOrdersData, ordersDataPage, ordersData
        );
    }, [orderDateSearch, ordersDataPage]);

    useEffect(()=>{
        if(orderDateSearch)
            setOrdersDataPage(0)
    }, [orderDateSearch]);

    useEffect(()=>{
        if(selectedMenuGrade?.id){
            dashboardHomeService.handleGetTodayMenu(selectedDate, setTodaysMenuData, setSelectedTodayMenu);
        }
    }, [selectedMenuGrade]);

    useEffect(()=>{
        if(selectedTodayMenu){
            dashboardHomeService.handleSearchFullMeal(selectedTodayMenu, setFullMealsData);
        }
    }, [selectedTodayMenu]);

    useEffect(()=>{
       
        if(selectedDate && generalReducer.userSchoolWorker?.instituteId){
            dashboardHomeService.handleSearchMenuGrade(
                selectedDate, setMenuGradeData, generalReducer.userSchoolWorker.instituteId, setSelectedMenuGrade
            );
        }
    }, [selectedDate, generalReducer.userSchoolWorker?.instituteId]);

    return(
        <DasboardHomeContainer>
            <DasboardHomeHeader>
                <h2 className='primaryBlackSemibold28-120'>Dashboard</h2>
                <span className='primaryBlackNormal20-120'>Acompanhe a visão geral do aplicativo e acesse atalhos de forma rápida</span>
            </DasboardHomeHeader>
            <DasboardHomeContent>
                <DasboardHomeContentRow>
                    <DasboardHomeContentMenu>
                        <Table key={'table'} className='table'>
                            <thead className='primaryBlackSemibold20-150 tableHead' >
                                <th className="title-column">
                                    <span className='primaryBlackSemibold20-150'>Cardápio do dia</span>
                                </th>
                                <th className="filter-column">
                                    <Dropdown
                                        styleClass='input-year-style'
                                        placeholder='Tipo de cardápio'
                                        defaultValue={selectedMenuGrade?.name}
                                        options={dashboardHomeService.handleMenuGradesToOptions(menuGradeData?.rows)}
                                        // onChange={(value)=>dashboardHomeService.setSelectedTodayMenu(value, todaysMenuData!.rows, setSelectedTodayMenu, setSelectedMenuType)}
                                    /> 
                                    <DatePicker
                                        date={selectedDate}
                                        setDate={setSelectedDate}
                                    />                
                                </th>
                            </thead>
                            
                            { todaysMenuData?.rows && todaysMenuData?.rows.length > 0 ?
                                todaysMenuData?.rows.map(todayMenu=>(
                                todayMenu?.menuGrade?.name === selectedMenuGrade?.name &&
                                <tbody key={`todayMenuContainer-${todayMenu?.id}`}>
                                    {
                                        fullMealsData?.rows?.map((fullMeal, fullMealIndex)=>(
                                                
                                                <tr key={`fullMealContainer-${todayMenu?.id}`}>
                                                    <td className="full-line text-start">
                                                        <span className="primaryBlackSemibold20-150">{fullMeal.foodType?.name}</span>
                                                        <TabFoodMenuListProducts>
                                                            { 
                                                                fullMeal.consumables?.length === 0 ?
                                                                <div className="full-line">
                                                                    <span className='primaryBlackNormal20-150'>Nenhum item neste dia</span>
                                                                </div> :
                                                                fullMeal.consumables?.map((consumable, consumableIndex)=>(
                                                                    <TabFoodMenuProductItem className={`consumableContainer-${consumableIndex}`}>
                                                                        <div>
                                                                            <img src={`${baseUrl}/${consumable.media?.content}`} alt="Foto do produto"/>
                                                                        </div>
                                                                        <span className="primaryBlackNormal20-150" title={consumable.name}>{consumable.name}</span>
                                                                        <span className="primaryGreyNormal18-150">{consumable.amountInGrams}g</span>
                                                                    </TabFoodMenuProductItem>
                                                                ))

                                                                
                                                            }
                                                        </TabFoodMenuListProducts>
                                                    </td>                        
                                                </tr>
                                                
                                        
                                        ))
                                    }
                                </tbody>  
                            )) :
                            <tbody>
                                <div className='empty-table'>
                                    <span className='primaryBlackSemibold20-150'>Nenhum cardapio no dia {moment(selectedDate).format('DD/MM/yyyy')}</span> 
                                </div> 
                            </tbody>
                            }
                        </Table>
                    </DasboardHomeContentMenu>
                    {   announcementsData && announcementsData.count > 0 &&
                        <DasboardHomeContentAnnouncement>
                            {
                                
                                announcementsData?.rows.map((announcement) => {
                                    var date = new Date(announcement.createdAt!);
                                    var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                                    var mm = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                                    var yyyy = date.getFullYear()

                                    var strDate = `${dd}/${mm}/${yyyy}`;

                                    return (
                                        <DashboardHomeGridItem>
                                            <span className='primaryBlackSemibold20-120 title'>Comunicado mais recente</span>
                                        
                                            <img src={`${baseUrl}/${announcement.media?.content ?? 'public/images/logoHP.png'}`} alt={`Imagem do comunicado ${announcement.title}`} />
                                        

                                            <DashboardHomeGridItemBody>

                                                <DashboardHomeGridItemSection>
                                                    <span  className='primaryBlackSemibold20-120'>
                                                        {announcement.title}
                                                    </span>
                                                    <div className="card-date-label">
                                                        <img src={calendar_icon} alt="Icone de calendário" />
                                                        <span className='primaryGreyMedium16-120'>{strDate}</span>
                                                    </div>
                                                    <span className='primaryPrimaryMedium16-120'>
                                                        Por {announcement.author?.schoolWorker?.name}
                                                    </span>
                                                </DashboardHomeGridItemSection>

                                                <DashboardHomeGridItemContent>
                                                    <div className="primaryBlackMedium16-120">
                                                        {
                                                            announcement.content
                                                        }
                                                    </div>
                                                </DashboardHomeGridItemContent>

                                                <ButtonPrimary onClick={() => {dashboardHomeService.handleViewAnnouncement(announcement ,navigate)}}>
                                                    Ver comunicado
                                                </ButtonPrimary>

                                            </DashboardHomeGridItemBody>
                                        </DashboardHomeGridItem>
                                    )
                                })
                            }
                        </DasboardHomeContentAnnouncement>
                    }
                </DasboardHomeContentRow>
                <DasboardHomeContentRow>
                    <DasboardHomeContentOrders>
                        <Table className='table'>
                            <TableOrdersHead className='filter-column'>
                                <span className='primaryBlackSemibold20-150'>Pedidos ativos na Cantina</span>
                                <DatePicker
                                    id='datepiker-activeorders'
                                    date={orderDateSearch}
                                    setDate={setOrderDateSearch}
                                />
                            </TableOrdersHead>
                            <thead className='primaryBlackSemibold20-150'>
                                <th className='hidden-mobile'>Pedido</th>
                                <th>Aluno</th>
                                <th>Decrição de Valor</th>
                                <th className='hidden-mobile'>Status</th>
                            </thead>
                            {ordersData?.count === 0 ?
                                <tbody>
                                    <div className='empty-table'>
                                        <span className='primaryBlackSemibold20-150'>Nenhum pedido em produção no dia {moment(orderDateSearch).format('DD/MM/yyyy')}</span> 
                                    </div> 
                                </tbody> :
                                <tbody>
                                    {ordersData?.rows?.map((order) => {
                                        let amountPrice = 0;
                                        return (
                                            <tr key={order?.id}>
                                                <td className='info-product hidden-mobile'>
                                                    {order.orderItems?.map((orderItem, index) => {
                                                        amountPrice += orderItem.unitPrice * orderItem.quantity;
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <a href='#' className='primaryBlackNormal18-150'>
                                                                    {orderItem.product?.name}
                                                                </a>
                                                                {index !== (order.orderItems!.length - 1) && <br />}
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    <span className='primaryGreyNormal16-150 overflow'>
                                                        Você pode clicar no nome do produto para visualizá-lo
                                                    </span>
                                                </td>

                                                <td>
                                                    <span className='item-column primaryGreyNormal18-150'>
                                                        Nome do aluno{' '}
                                                        <span className='primaryBlackNormal18-150'>{order.student?.name}</span>
                                                    </span>
                                                    <span className='item-column primaryGreyNormal18-150'>
                                                        Ano/Year<span className='primaryBlackNormal18-150'>{order.student?.grade?.name}</span>
                                                    </span>
                                                </td>

                                                <td>
                                                    <span className='item-column primaryBlackNormal18-150'>
                                                        {order.orderItems?.map((orderItem, index) => (
                                                            <span>{orderItem.quantity}x {formattedPrice(orderItem.unitPrice)}</span>
                                                        ))}
                                                    </span>
                                                    <span className='item-column primaryPrimarySemibold18-150'>
                                                        Valor Total <span className='primaryBlackSemibold20-150'>{formattedPrice(amountPrice)}</span>{' '}
                                                    </span>
                                                </td>
                                                <td className='hidden-mobile'>
                                                    <div className='column-status'>
                                                        <Dropdown
                                                            options={[{value : 'Finalizado', content: 'Finalizar'}]}
                                                            className='dropdown-status'
                                                            placeholder={order.orderStatus}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })} 
                                </tbody>
            
                            }
                            {
                                ordersData && ordersData.rows.length < ordersData.count &&
                                <div className='full-line' onClick={()=>{setOrdersDataPage(ordersDataPage+1)}}>
                                    <span className='primaryPrimarySemibold18-150'>Ver mais</span>
                                </div>
                            }
                        </Table>
                    </DasboardHomeContentOrders>
                </DasboardHomeContentRow>
            </DasboardHomeContent>
        </DasboardHomeContainer>
    );
};