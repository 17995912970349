import React, { useEffect, useState } from 'react';
import search_icon from '../../../assets/icons/search.svg';
import { TabFoodMenuConsumableContainer, TabFoodMenuConsumableContent, TabFoodMenuConsumableEmptyImage, TabFoodMenuConsumableItem, TabFoodMenuConsumableItemContent, TabFoodMenuConsumableSearchBar, TabFoodMenuConsumableSearchBarInputs, TabFoodMenuConsumableTitle } from './styles/tab-food-menu-consumable.styled';
import { Input } from '../../components/input.component';
import { Dropdown } from '../../components/dropdown.component';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { FoodTypeM } from '../../../models/food-type.model';
import { TabFoodMenuConsumableService } from './services/tab-food-menu-consumable.service';
import { ButtonPrimary } from '../../components/button-primary.component';
import { MealConsumableM } from '../../../models/meal-consumable.model';
import { baseUrl } from '../../../api/api';

import plus_icon from '../../../assets/icons/plus.svg';
import image_product_icon from '../../../assets/icons/image-product.svg';

export const TabFoodMenuConsumablePage = () => {
    const [search, setSearch] = useState<string>();
    const [foodTypesData, setFoodTypesData] = useState<{count : Number, rows: FoodTypeM[]}>();
    const [consumablesData, setConsumablesData] = useState<{count : number, rows : MealConsumableM[]}>();
    const [selectedFoodType, setSelectedFoodType] = useState<FoodTypeM>();
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);

    const tabFoodMenuConsumableService = new TabFoodMenuConsumableService(dispatch);

    useEffect(()=>{
        tabFoodMenuConsumableService.handleSearchFoodTypes(setFoodTypesData);
    }, []);

    useEffect(()=>{
        tabFoodMenuConsumableService.handleSearchConsumables(setConsumablesData, selectedFoodType, search);
    }, [selectedFoodType, search]);

    return (
        <TabFoodMenuConsumableContainer>
            <TabFoodMenuConsumableTitle>
                <h2 className='primaryBlackSemibold28-150'>Catálogo de Alimentos</h2>
            </TabFoodMenuConsumableTitle>

            <TabFoodMenuConsumableSearchBar autoComplete="off">
                <TabFoodMenuConsumableSearchBarInputs>
                    <Input
                        className='inputSearch'
                        type='search'
                        name='inputSearch'
                        id='inputSearch'
                        placeholder='Busque pelo nome do item'
                        value={search}
                        onChange={setSearch}
                        prefix={
                            <img src={search_icon} alt="Icone de busca"/>
                        }
                    />
                    <Dropdown
                        styleClass='input-style'
                        defaultValue={selectedFoodType?.id?.toString()}
                        placeholder='Categorias'
                        options={tabFoodMenuConsumableService.handleFoodTypesToOptions(foodTypesData?.rows)}
                        onChange={(value)=>setSelectedFoodType(tabFoodMenuConsumableService.handleFindFoodTypeByValue(value, foodTypesData?.rows))}
                    />
                </TabFoodMenuConsumableSearchBarInputs>

                <ButtonPrimary className="new-menu-button" onClick={tabFoodMenuConsumableService.handleShowAddConsumableTab}>
                    <img src={plus_icon} alt="Icone de Adicionar"/>
                    Cadastrar novo alimento
                </ButtonPrimary>
            </TabFoodMenuConsumableSearchBar>
            {consumablesData && consumablesData.count > 0 ?
                <TabFoodMenuConsumableContent>
                    {consumablesData?.rows.map(consumable=>(
                        <TabFoodMenuConsumableItem onClick={()=>tabFoodMenuConsumableService.handleOpenConsumable(consumable)}>
                            {consumable.media?.content ?
                                <img src={`${baseUrl}/${consumable.media?.content}`} alt="Imagem do produto"/> :
                                <TabFoodMenuConsumableEmptyImage>
                                    <img src={image_product_icon} alt="Imagem do produto"/>
                                </TabFoodMenuConsumableEmptyImage>
                            }
                            <TabFoodMenuConsumableItemContent>
                                <h3 className="primaryBlackSemibold18-150">{consumable.name}</h3>
                                <span className="primaryBlackNormal16-150">{consumable.amountInGrams}g</span>
                                <span className="primaryBlackNormal16-150">{consumable.foodType.name}</span>
                            </TabFoodMenuConsumableItemContent>
                        </TabFoodMenuConsumableItem> 
                    ))}
                            
                </TabFoodMenuConsumableContent> :
                <div className='empty-table mt'>
                    <span className='primaryBlackSemibold20-150'>Nenhum alimento cadastrado</span> 
                </div> 
            }
            
        </TabFoodMenuConsumableContainer>
    );
};