import { GradeStudentsListModalContainer, GradeStudentsListModalContent, GradeStudentsListModalList, GradeStudentsListModalListItem, GradeStudentsListModalListItemInfo, GradeStudentsListModalTopBar } from "./styled/grades-students-list-modal.styled"
import close_icon from '../../../assets/icons/close.svg';
import search_icon from '../../../assets/icons/search.svg';
import student_icon from '../../../assets/icons/student.svg';
import arrow_right_icon from '../../../assets/icons/arrow_right.svg';
import { Input } from "../../components/input.component";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GradeStudentsListModalService } from "./services/grades-students-list-modal.service";
import { useAppSelector } from "../../../hooks";
import { UserStudentDataT } from "../../../models/interfaces/user-student.interface";

export const GradeStudentsListModal = ({onClose}: {onClose : Function})=>{
    const [search, setSearch] = useState('');
    const [studentsData, setStudentsData] = useState<UserStudentDataT>();
    const dispatch = useDispatch();
    const studentReducer = useAppSelector(state => state.studentReducer);

    const gradeStudentsListModalService = new GradeStudentsListModalService(dispatch);

    useEffect(()=>{
        gradeStudentsListModalService.handleSearchStudents(setStudentsData, studentReducer.gradeForm, search);
    }, [search]);

    return(
        <GradeStudentsListModalContainer>
            <GradeStudentsListModalContent>
                <GradeStudentsListModalTopBar>
                    <span className="primaryBlackSemibold20-150">Lista de alunos</span>
                    <div className="close-button" onClick={()=>{onClose()}}>
                        <img src={close_icon} alt="Icone de fechar"/>
                    </div>
                </GradeStudentsListModalTopBar>

                <GradeStudentsListModalList>
                    <Input
                        className='inputSearch'
                        type='search'
                        name='inputSearch'
                        id='inputSearch'
                        placeholder='Busque por um aluno'
                        value={search}
                        onChange={setSearch}
                        prefix={
                            <img src={search_icon} alt="Icone de busca"/>
                        }
                    />
                    {studentsData && studentsData.count > 0 ?
                        <>
                            {studentsData?.rows?.map(userStudent=>(
                                <GradeStudentsListModalListItem>
                                    <div>
                                        <img src={student_icon} alt="Icone de estudante"/>
                                        <span className="primaryBlackNormal18-120">{userStudent.student.name}</span>
                                    </div>
                                    <GradeStudentsListModalListItemInfo onClick={()=>{gradeStudentsListModalService.handleShowStudentInfo(userStudent)}}>
                                        <span className="primaryPrimarySemibold16-150">Informações do aluno</span>
                                        <img src={arrow_right_icon} alt="Icone de seta"/>
                                    </GradeStudentsListModalListItemInfo>
                                </GradeStudentsListModalListItem>
                            ))}
                        </> :
                        <div className="empty-table">
                            <span className='primaryBlackSemibold20-150 mt'>Nenhum aluno nesta turma</span>
                        </div>
                    }
                </GradeStudentsListModalList>
            </GradeStudentsListModalContent>
        </GradeStudentsListModalContainer>
    )
}   