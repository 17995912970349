import styled from "styled-components";

interface ContentProps{
    color?: string;
}

export const DropDownOption = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: start;

    &:hover{
        background-color: var(--primary-opacity-color);
    }
`;

export const DropDownOptions = styled.div`
    position: absolute;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    margin-top: 8px;
    z-index: 3 !important;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 0 !important;
    
    @media screen and (max-width: 1400px){
        margin-top: 6px;
    }
`;

export const DropdownContainer = styled.div`
    position: relative;
    .input-style{
        border: 1px solid var(--border-color);
        background-color: var(--white-color);
        width: 210px;

        span{
            color: var(--black-opacity-color) !important;
        }

        img{
            filter: hue-rotate(18deg);
        }
   }
`;

export const DropDownContent = styled.div<ContentProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${props=>props.color? props.color :  'var(--grey-light-2-color)'};
    max-height: 48px;
    padding: 8px 12px;
    cursor: pointer;

    @media screen and (max-width: 1400px){
        padding: 6px 9px;
        max-height: 36px;
    }
`;