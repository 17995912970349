import styled from "styled-components";

export const InputContainer = styled.div`
    input{
        font-family: 'Inter';
    }

    label{
        position: relative;
        display: flex;
        gap: 8px;
        align-items: center;

        img{
            width: 24px;
        }

        cursor: pointer;

        .infoCard{
            position: absolute;
            left: 132px;
            min-width: calc(100% + 20px) !important;
            background-color: #F4F4F4;
            padding: 10px;
            display: none;
        }

        &:hover{
            .infoCard{
                display: block;
            }
        }
    }
    
    input{
        width: 100%;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        
        &:focus{
            outline: 1px solid var(--primary-color);
        }
    }

    .prefix-container{
        display: flex;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: 40px;
    }

    .prefix-input{
        padding-left: 38px !important;
    }

    

    @media screen and (max-width: 1400px) {
        input{ 
            padding: 12px;
        }

        label{
            img{
                width: 18px;
            }
        }

    }
`;

export const InputContent = styled.div`
    position: relative;
    margin-top: 12px;

    @media screen and (max-width: 1400px) {
        margin-top: 9px;
    }
`;

export const InputEyeButton = styled.button`
    padding: 0 !important;
    margin: 0 !important;
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    height: 100%;
    width: 80px;
    top: 0;
    right: 0;
    
    @media screen and (max-width: 1400px) {
        width: 60px;
        img{
            width: 18px;
        }

    }
`;