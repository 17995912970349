import styled from "styled-components";

export const DetailsContainer = styled.div`
    width: 100%;
    h2{
        margin-bottom: 24px;
    }

    @media screen and (max-width: 1400px){
        h2{
            margin-bottom: 18px;
        }
    }
`;