import { GradeM } from "../models/grade.model";
import { UserStudentM } from "../models/user-student.model";
import { StudentM } from "../models/student.model";
import { FoodTypeM } from "../models/food-type.model";

export class StudentController {
    
    async getAll(grade? : GradeM, search?: string,  selectedFoodType?: FoodTypeM, selectedDate?: Date){
        return await StudentM.getAll(grade, search, selectedFoodType, selectedDate);
    }

    async getById(id: number){
        return await StudentM.getById(id);
    }

    async createAndUpdate(userStudent : UserStudentM){
        if(userStudent.student.id){
            return await UserStudentM.update(userStudent);
        }
        return await UserStudentM.create(userStudent);
    }
}