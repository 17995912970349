import { handleRequest } from "../api/api-request.service";
import { LocalTokenM } from "../features/types/auth-slice.type";
import { ResetPasswordT } from "../views/modules/auth/ResetPassword/types";

export class AuthController {

    async authenticateSchooWorker(email: string, password: string) {
        return handleRequest(
            `auth/user-school-worker/authenticate`,
            `POST`,
            {
                email: email,
                password: password
            }
        )
    }

    async authenticateStudent(password: string) {
        return handleRequest(
            `auth/user-student/authenticate`,
            `POST`,
            {
                password: password
            }
        )
    }

    async getFruitsSequence() {
        return handleRequest(
            `user-student/utils/fruits-sequence`,
            `GET`
        )
    }


    async verifyToken(token: LocalTokenM) {
        return handleRequest(
            `auth/verify`,
            `POST`,
            token
        )
    }



    async passwordResetSchooWorker(cpf: string) {
        return handleRequest(
            `/auth/request-password-reset`,
            `POST`,
            {
                cpf: cpf,
            }
        )
    }

    async passwordSaveResetSchooWorker(payload: ResetPasswordT, token: string) {
        return handleRequest(
            `/auth/password-reset?token=${token}`,
            `POST`,
            payload
        )
    }

}