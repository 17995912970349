import { GradeI } from "../../models/interfaces/grade.interface";
import { UserI } from "../../models/interfaces/user.interface";
import { UserM } from "../../models/user.model";
import { LocalToken } from "../../utils/local-token";


export class LocalTokenM {
    access?: string;
    userType?: string;

    constructor({ access, userType }: { access?: string, userType: string }) {
        this.access = access;
        this.userType = userType;
    }

    static initial() {
        const token = LocalTokenM.getStoredToken();
        const userType = LocalTokenM.getStoredUserType();

        return new LocalTokenM({
            access: token,
            userType
        });
    }

    storeToken() {
        if(this.access && this.userType){
            LocalToken.storeToken(this.access, this.userType)
        }
    }

    static clearStoredToken() {
        LocalToken.clearStoredToken()
    }

    static getStoredToken() {
        return LocalToken.getStoredToken()
    }

    static getStoredUserType() {
        return LocalToken.getStoredUserType()
    }

}


export class LoginFormM {
    grade?: string;
    name?: string;
    email?: string;
    cpf?: string;
    login?: string;
    password?: string;


    constructor({ grade, name, email, cpf, login, password }: {
        grade?: string;
        name?: string;
        email?: string;
        cpf?: string;
        login?: string;
        password?: string;
    }) {
        this.grade = grade;
        this.name = name;
        this.email = email;
        this.cpf = cpf;
        this.login = login;
        this.password = password;
    }

    static initial() {
        return new LoginFormM({
            grade: "",
            name: "",
            email: "",
            cpf: "",
            login: "",
            password: "",
        });
    }
}


export type StudentsDataT = {
    count: number,
    rows: UserI[]
}

export type GradeDataT = {
    count: number,
    rows: GradeI[]
}


export type LoginStateT = {
    studentsData?: StudentsDataT,
    gradesData?: GradeDataT,
    loginForm: LoginFormM,
    token?: LocalTokenM,
    user?: UserI,
    userId?: number,
}
