import { Dispatch } from "redux";
import { FoodTypeController } from "../../../../controllers/food-type.controller";
import { FoodTypeM } from "../../../../models/food-type.model";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { MealConsumableController } from "../../../../controllers/meal-consumable.controller";
import { foodSetConsumableForm, foodSetTabSelectedContent, foodSetTodaysMenusForm, foodSetTodaysMenusFormData } from "../../../../features/food-slice.feature";
import { foodContentsNames } from "../../../../features/types/food-slice.type";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { MealConsumableM } from "../../../../models/meal-consumable.model";

export class TabFoodMenuConsumableService{
    dispatch : Dispatch;
    foodTypeController : FoodTypeController;
    mealConsumableController : MealConsumableController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.foodTypeController = new FoodTypeController();
        this.mealConsumableController = new MealConsumableController();

        this.handleShowAddConsumableTab = this.handleShowAddConsumableTab.bind(this);
    }

    public handleOpenConsumable(consumable : MealConsumableM){
        this.dispatch(foodSetConsumableForm(consumable));
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.MENU_ADD_CONSUMABLE));
    }

    public handleShowAddConsumableTab(){
        this.dispatch(foodSetConsumableForm(MealConsumableM.initial()));
        this.dispatch(foodSetTodaysMenusForm({count : 1, rows : [TodaysMenuM.initial()]}));
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.MENU_ADD_CONSUMABLE));
    }

    public handleFoodTypesToOptions(foodTypesList? : FoodTypeM[]){
        const options = foodTypesList?.map(foodType=>(
            {value: foodType.id?.toString()!, content: foodType.name!}
        ));
        
        options?.push({value : '', content : 'Nenhuma'});
        return options;
    }

    public handleFindFoodTypeByValue(value: string, foodTypesList? : FoodTypeM[]){
        return foodTypesList?.find(foodType => foodType.id?.toString() === value);
    }

    public async handleSearchConsumables( setConsumables : Function, foodType?: FoodTypeM, search?: string){
        this.dispatch(generalSetIsLoading(true));
        await this.mealConsumableController.getAll(foodType, search).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setConsumables(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async handleSearchFoodTypes(setFoodTypes : Function){
        this.dispatch(generalSetIsLoading(true));
        await this.foodTypeController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setFoodTypes(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }
}