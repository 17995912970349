import styled from "styled-components";

export const InputPhotoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--grey-color);
    width: 552px;
    height: 412px;
    margin-top: 18px;
    margin-bottom: 36px;
    border-radius: 8px;
    cursor: pointer;

    img{
        max-width: 250px;
        height: 250px;
        object-fit: cover;
    }

    @media screen and (max-width: 1400px){
        width: 414px;
        height: 309px;
        margin-top: 14px;
        margin-bottom: 27px;
    }
`;