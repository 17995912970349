import styled from "styled-components";

export const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--grey-opacity-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
`;

export const LoadingContent = styled.div`
   
`;