import React, { ChangeEvent, useState } from 'react';
import openEyeIcon from '../../assets/icons/open_eye.svg';
import closedEyeIcon from '../../assets/icons/closed_eye.svg';
import { InputContainer, InputContent, InputEyeButton } from './styles/input.styled';
import { InputT } from './types/input.type';
import { formattedPrice } from '../../utils/config';
import { Timeout } from 'react-number-format/types/types';

import info_icon from '../../assets/icons/info.svg'

export const Input = ({
  label,
  type,
  id,
  value,
  placeholder,
  name,
  onChange,
  className,
  prefix,
  info,
  disabled
}: InputT) => {
  const [showPassword, setShowPassword] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<Timeout | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(type === 'search'){
      setSearchText(e.target.value);
      
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      const newTimeout = setTimeout(() => {
        onChange(e.target.value);
      }, 1000);

      setTypingTimeout(newTimeout);
    }else{
      let inputValue = type === 'currency' || type === 'decimal' ? 
        e.target.value.replace(/[^\d]/g, '').replace('R$', '').replaceAll('.', '').replaceAll(',', '') : 
        e.target.value;
        
      onChange(inputValue);
    }
  };

  return (
    <InputContainer className={className}>
      <label className="primaryBlackSemibold18-150" htmlFor={id}>
        {label}
        {info &&
          <>
              <img src={info_icon} alt="Icone de Informação" />
              <span className='infoCard primaryBlackNormal16-120'>
                {info}
              </span>
          </>
        }
      </label>

      <InputContent>
        {prefix && <div className='prefix-container'>{prefix}</div>}
        <input
          autoComplete={'off'}
          className={prefix ? 'prefix-input' : ''}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          id={id}
          name={name}
          value={(type === 'currency' || type === 'decimal') && value ? formattedPrice(value ? parseFloat(value!)/100 : 0, type) : type === 'search' ? searchText :  value}
          placeholder={placeholder}
          onChange={handleChange}
        />

        {(id === 'password' || id === 'confirmPassword') && (
          <InputEyeButton type="button" onClick={() => setShowPassword(!showPassword)}>
            <img src={showPassword ? openEyeIcon : closedEyeIcon} alt="Mostrar / Esconder senha" />
          </InputEyeButton>
        )}
      </InputContent>
    </InputContainer>
  );
};
