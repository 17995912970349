import React, { useEffect, useState } from 'react';

import *as S from './styled';

import { Link, useNavigate, useParams } from 'react-router-dom';
import ilustratrion_login from '../../../../assets/images/ilustratrion_login.jpg';
import logo_image from '../../../../assets/images/logo75x75.png';
import delete_icon from '../../../../assets/icons/delete.svg';
import { useAppSelector } from '../../../../hooks';
import { useDispatch } from 'react-redux';
import { LoginFormService } from './service';
import { FruitSequencyI } from '../../../../models/interfaces/fruit-sequency.interface copy';
import { LoginUserType } from '../../../../utils/config';
import { Input } from '../../../components/input.component';
import { baseUrl } from '../../../../api/api';
import { ButtonPrimary } from '../../../components/button-primary.component';

export const LoginPage = () => {
    const { userType } = useParams();

    const authReducer = useAppSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginService = LoginFormService.getInstance(dispatch);

    const [fruitsSequence, setFruitsSequence] = useState<FruitSequencyI[]>([]);
    const [fruitsSelecteds, setFruitsSelecteds] = useState<FruitSequencyI[]>([]);

    useEffect(() => {
        loginService.getFruitsSequence(setFruitsSequence);
    }, [authReducer.gradesData, loginService]);

    return (
        <S.LoginContainer>
            <S.LoginForm>
                <form action='#' onSubmit={(e) => e.preventDefault()}>
                    <S.LoginLogoContainer>
                        <img src={logo_image} alt={"Logo Healthy Puppy"} />
                        <span className='secondaryPrimaryBold16-120'>Healthy Puppy</span>
                    </S.LoginLogoContainer>

                    <h2 className='primaryBlackBold40-120'>{LoginUserType.TEACHER === userType ? "Login" : "Login do aluno"} </h2>

                    <span className='primaryBlackMedium18-150'>
                        {LoginUserType.TEACHER === userType ?
                            "Por aqui você gerencia todas as informações e funcionalidades do aplicativo Healthy Puppy." :
                            "Preencha a sequência de frutas de acordo com o seu cartão para entrar no Healthy Puppy."
                        }
                    </span>
                    {LoginUserType.TEACHER === userType ?
                        <>
                            <Input
                                className="inputEmail"
                                label='E-mail'
                                type='text'
                                name='email'
                                id='email'
                                placeholder='Insira seu email'
                                onChange={(value: any) => { loginService.handleChangeEmail(value, authReducer.loginForm) }}
                            />

                            <Input
                                className="inputPassword"
                                label='Senha'
                                type='password'
                                name='password'
                                id='password'
                                placeholder='Insira sua senha'
                                onChange={(value: any) => { loginService.handleChangePassword(value, authReducer.loginForm) }}
                            />

                            <S.LoginForgotPassword >
                                <Link to="/esqueci-minha-senha" className='primaryBlackSemibold18-150'>
                                    Esqueci minha senha
                                </Link>
                            </S.LoginForgotPassword>
                        </> :
                        <>
                            <div className='fruitsSequence'>
                                {fruitsSequence?.map(fruitSequence => (
                                    <div className='fruitsSequenceItem' onClick={() => {
                                        if (fruitsSelecteds.length < 6)
                                            setFruitsSelecteds([...fruitsSelecteds, fruitSequence])
                                    }}>
                                        <img src={`${baseUrl}/${fruitSequence.imageUrl}`} />
                                    </div>
                                ))}
                            </div>

                            <h2 className='primaryBlackBold22-120'>Sequência de frutas</h2>

                            <div className='fruitsSequenceSelecteds'>
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <div className='fruitsSequenceSelectedsItem'>
                                        {fruitsSelecteds[index] && <img src={`${baseUrl}/${fruitsSelecteds[index].imageUrl}`} />}
                                        {index === fruitsSelecteds.length - 1 &&
                                            <div className="delete" onClick={() => {
                                                const newFruits = [...fruitsSelecteds];
                                                newFruits.pop();
                                                setFruitsSelecteds(newFruits);
                                            }}>
                                                <img src={delete_icon} />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </>
                    }

                    <ButtonPrimary className='submitButton' onClick={() => {
                        if (LoginUserType.TEACHER === userType) {
                            loginService.handleLogin(authReducer.loginForm, navigate)
                        }
                        else {
                            loginService.handleLoginStudent(fruitsSelecteds)
                        }
                    }}>Entrar</ButtonPrimary>
                </form>
            </S.LoginForm>

            <S.LoginImage
                src={ilustratrion_login}
                alt="Imagem de fundo para a página de login"
            />
        </S.LoginContainer>
    );
};