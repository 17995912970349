import { handleRequest } from "../api/api-request.service";
import { AnnouncementI } from "./interfaces/announcements.interface";
import { MediaI } from "./interfaces/media.interface";
import { UserI } from "./interfaces/user.interface";
import { MediaM } from "./media.model";
import { UserM } from "./user.model";
import { UserSchoolWorkerM } from "./user-school-worker.model";
import moment from "moment";

export class AnnouncementM implements AnnouncementI {
    id?: number;
    title: string;
    content: string;
    status: string;
    userId: number;
    media?: MediaI;
    author?: UserI;
    viewCount?: number;
    createdAt?: Date;

    constructor({
        id,
        title,
        content,
        status,
        userId,
        media,
        author,
        viewCount,
        createdAt
    }: AnnouncementI) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.status = status;
        this.userId = userId;
        this.media = media;
        this.author = author;
        this.viewCount = viewCount ?? 0;
        this.createdAt = createdAt ? new Date(createdAt) : new Date();
    }


    static initial() {
        return new AnnouncementM({
            title: '',
            content: '',
            status: 'active',
            userId: 0,
            author: UserSchoolWorkerM.initial(),
            media: MediaM.initial(),
            viewCount: 0,
            createdAt: new Date()
        })
    }




    static async getAllByInstituteAndDates(instituteId: number, startDate: Date, endDate: Date, search?: string, status?: string) {

        const query = `?from=${moment(startDate).format('yyyy-MM-DD')}&to=${moment(endDate).format('yyyy-MM-DD')}&title=${search ?? ''}&status=${status ?? ''}`;

        return handleRequest(
            `announcements/by/institute/${instituteId}${query}`,
            'GET'
        )
    }

    static async getAllByInstitute(instituteId: number) {

        return handleRequest(
            `announcements/by/institute/${instituteId}?limit=1&offset=0`,
            'GET'
        )
    }


    static async update(announcement: AnnouncementM) {
        return handleRequest(
            `announcements/${announcement.id}`,
            'PUT',
            announcement
        );
    }

    static async create(schoolWorker: AnnouncementM) {
        return handleRequest(
            `announcements`,
            'POST',
            schoolWorker
        );
    }

    static async delete(userId: number) {
        return handleRequest(
            `announcements/${userId}`,
            'DELETE'
        );
    }
}


