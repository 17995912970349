import styled from "styled-components";

export const SelectOption = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 8px 16px;

    &:hover{
        background-color: var(--primary-opacity-color);
    }
`;

export const SelectOptions = styled.div`
    position: absolute;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    width: 100%;
    margin-top: 8px;
    z-index: 3 !important;
    border: 1px solid var(--border-color);

    @media screen and (max-width: 1400px){
        margin-top: 6px;
    }
`;

export const SelectContainer = styled.div`
    position: relative;
    width: 100%;

    .focus{
        outline: 1px solid var(--primary-color);
    }
`;

export const SelectContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    width: 100%;
    height: 54px;
    padding: 12px 16px;
    margin-top: 8px;
    cursor: pointer;

    span{
        margin: 0 !important;
    }

    @media screen and (max-width: 1400px){
        padding: 8px 12px;
        height: 40px;
    }
`;