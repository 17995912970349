import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { foodSetEvaluationSelectedConsumables, foodSetEvaluationSelectedFoodType, foodSetEvaluationSelectedFullMeal, foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { FoodTypeM } from "../../../../models/food-type.model";
import { foodContentsNames } from "../../../../features/types/food-slice.type";
import { StudentM } from "../../../../models/student.model";
import { TodaysMenuController } from "../../../../controllers/todays-menu.controller";
import { MealConsumableM } from "../../../../models/meal-consumable.model";
import { TabSelectConsumableContainer } from "../styles/tab-select-consumable.styled";
import { TodaysMenuM } from "../../../../models/todays-menu.model";
import { FullMealM } from "../../../../models/full-meal.model";
import { StudentMealAcceptanceI } from "../../../../models/interfaces/student-meal-acceptance.interface";
import { StudentMealAcceptanceM } from "../../../../models/student-meal-acceptance.model";

export class TabStudentEvaluationConsumableService {
    dispatch : Dispatch;
    todaysMenuController : TodaysMenuController;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        this.todaysMenuController = new TodaysMenuController();

        this.handleSelectConsumable = this.handleSelectConsumable.bind(this);
        this.handleOpenEvaluationEvaluation = this.handleOpenEvaluationEvaluation.bind(this);
        
    }

    public handleBack(){
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION));
    }

    public handleOpenEvaluationEvaluation(){
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_EVALUATION))
    }

    public handleSelectConsumable(
        studentId: number,
        consumable: MealConsumableM, 
        consumables: { consumables : StudentMealAcceptanceM[] , drinks : StudentMealAcceptanceM[]}
    ) {
        const index = consumables.consumables.findIndex((c) => c.mealConsumable.id === consumable.id);
        let newConsumables;
        if (index !== -1) {
            newConsumables = [...consumables.consumables];
            newConsumables.splice(index, 1);           
        } else {
            let consumableAcceptance = new StudentMealAcceptanceM({
                mealConsumable : consumable,
                studentId: studentId,
                mealConsumableId: consumable.id!,
                eatLevel: 0
            });
            newConsumables = [...consumables.consumables, consumableAcceptance];
        }

        this.dispatch(foodSetEvaluationSelectedConsumables({
            ...consumables,
            consumables : newConsumables                    
        }));
    }

    public handleSelectDrink(
        studentId: number,
        drink: MealConsumableM, 
        consumables: { consumables : StudentMealAcceptanceM[] , drinks : StudentMealAcceptanceM[]}
    ) {
        const index = consumables.drinks.findIndex((c) => c.mealConsumable.id === drink.id);
        let newDrinks;
        if (index !== -1) {
            newDrinks = [...consumables.drinks];
            newDrinks.splice(index, 1);           
        } else {
            let drinkAcceptance = new StudentMealAcceptanceM({
                mealConsumable : drink,
                studentId: studentId,
                mealConsumableId: drink.id!,
                eatLevel: 0
            });
            newDrinks = [...consumables.drinks, drinkAcceptance];
        }

        this.dispatch(foodSetEvaluationSelectedConsumables({
            ...consumables,
            drinks : newDrinks                    
        }));
    }
    
    public handleGetConsumables(
        todayMenu : TodaysMenuM, 
        setConsumables : Function, 
        setDrinks : Function,
        selectedFoodType?: FoodTypeM
    ){
        const consumables : MealConsumableM[] = [];
        const drinks : MealConsumableM[] = [];
        todayMenu.fullmeals.map(fullMeal=>{
            if(fullMeal.foodTypeId === selectedFoodType?.id){
                fullMeal.consumables.map(consumable=>{
                    if(consumable.mealType === 'drink'){
                        drinks.push(consumable);
                    }else{
                        consumables.push(consumable);
                    }
                });
            }
        });
        setConsumables(consumables);
        setDrinks(drinks);
       
    }
   
    
    public async handleGetTodayMenu(setTodayMenu : Function, selectedFoodType?: FoodTypeM, selectedStudent? : StudentM ){
        this.dispatch(generalSetIsLoading(true));
        await this.todaysMenuController.getByStudent(selectedStudent!).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setTodayMenu(data);
                data.fullmeals.map((fullMeal : FullMealM)=>{
                    if(fullMeal.foodTypeId === selectedFoodType?.id){
                        this.dispatch(foodSetEvaluationSelectedFullMeal(fullMeal));
                    }
                });

            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

}