import styled from "styled-components";

export const TableContainer = styled.div`
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-bottom: 32px;
    
    .full-line{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
    }

    .text-start{
        align-items: flex-start !important;
    }

    @media screen and (max-width: 900px){
        th{
            width: auto !important;
        }
        .hidden-mobile{
            display: none !important;
        }        
    }
`;

export const TableContent = styled.table`
    width:100% ;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .tableSubTitle{
        margin-top: 20px;
    }


    .overflow{
        position: relative;
        min-width: 600px;
    }

    .item-column{
        display: flex;
        flex-direction: column;
    }

    .column-status{
        position: relative;
        align-items: center;
        justify-content: center;        
        display: flex;
        padding-right: 0 !important ;
        width: 300px !important;
        align-self: flex-end;
        height: 100%;

        .dropdown-status{
            width: auto !important;
            gap: 10px;
        }
    }

    .info-more{
        position: absolute;
        top: 0;
        right: 0;
    }

    .info-product{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 160px;
    }

    thead{
        /* min-width: 800px !important; */
        display: flex;
        justify-content: space-between;
        padding: 17px 24px;
        border-bottom: 1px solid var(--border-color);

        th{
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 25%;
        }
    }

    tbody{
        /* min-width: 800px !important; */
        tr{
            display: flex;
            justify-content: space-between;
            padding: 17px 24px;
            border-bottom: 1px solid var(--border-color);
        }

        td{
            display: flex;
            flex-direction: column;
            width: 25%;
            gap: 16px;
        }
    }

    @media screen and (max-width: 1400px){

        .tableSubTitle{
            margin-top: 15px;
        }

        .info-product{
            min-height: 120px;
        }

        thead, tbody tr{
            padding: 12px 18px;
        }

        tbody{
            td{
                gap: 12px;
            }

        }
    }
    
`;