import React from 'react';
import { useDispatch } from 'react-redux';
import { TabBar } from '../../components/tab-bar.component';
import { HomeContainer } from './styled/students-home.styled';
import { useAppSelector } from '../../../hooks';
import { studentContents, studentContentsNames, studentDetailsContents, studentDetialsTabList, studentTabList } from '../../../features/types/student-slice.type';
import { StudentDetailsService } from './services/students-details.service';
import { DetailsContainer } from './styled/students-details.styled';
import { ActionBack } from '../../components/action-back.component';


export const StudentDetailsPage = () => {
    const dispatch = useDispatch();
    const studentReducer = useAppSelector(state => state.studentReducer);
    const studentDetailsService = new StudentDetailsService(dispatch);

    return (
        <DetailsContainer>
            <ActionBack onClose={()=>{
                studentReducer.tabSelectedItem === "Turmas" ? 
                studentDetailsService.handleCloseToGrades() : 
                studentDetailsService.handleCloseToStudents()
            }}/>
            
            <TabBar 
                setSelectedTab={(tabSelectedItem)=>studentDetailsService.handleSetSelectedTab(tabSelectedItem)} 
                selected={studentReducer.tabDetailsSelectedItem} 
                items={studentReducer.studentForm.id ? studentDetialsTabList : [studentDetialsTabList[0]]}
            />        
           { studentDetailsContents[studentReducer.tabDetailsSelectedContent] }
           
        </DetailsContainer>
    );
};