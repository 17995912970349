import { Dispatch } from "redux";
import { ProductCategoryController } from "../../../../controllers/produc-category.controller";
import { alertInitialValue, generalSetAlertDialog, generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { ProductCategoryI } from "../../../../models/interfaces/product-category.interface";
import { ProductI } from "../../../../models/interfaces/product.interface";
import { ProductController } from "../../../../controllers/product.controller";
import { tabMenuSetProductCategoriesData, tabMenuSetProductForm, tabMenuSetProductsData } from "../../../../features/tab-menu-slice.feature";
import { ProductsDataT } from "../../../../features/types/tab-menu-slice.type";
import { ProductM } from "../../../../models/product.model";
import { OptionsMenuOptionT } from "../../../components/types/options-menu.type";
import { canteenSetTabSelectdItem, canteenSetTabSelectedContent } from "../../../../features/canteen-slice.feature";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { canteenContentsNames } from "../../../../features/types/canteen-slice.type";
import { ProductCategoryM } from "../../../../models/product-category.model";

export class TabMenuService {
    private static instance: TabMenuService | null = null;

    private dispatch: Dispatch;
    private productCategoryController: ProductCategoryController;
    private productController: ProductController;

    private constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.productCategoryController = new ProductCategoryController();
        this.productController = new ProductController();
    }

    public static getInstance(dispatch: Dispatch){
        if (!TabMenuService.instance) {
            TabMenuService.instance = new TabMenuService(dispatch);
        }
        return TabMenuService.instance;
    }

    public handleShowAddItemPage(){
        this.dispatch(tabMenuSetProductForm(ProductM.initial()))
        this.dispatch(canteenSetTabSelectedContent('menu-add-item'));       
    }
    
    public handleGetCategories(){
        this.dispatch(generalSetIsLoading(true));
        this.productCategoryController.getAll().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                this.dispatch(tabMenuSetProductCategoriesData(data));
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public async handleGetProcductsByCategoryList(productsCategoriesList: ProductCategoryI[], search?: string) {
        if (productsCategoriesList) {
            const allProducts: ProductsDataT = [];
    
            this.dispatch(generalSetIsLoading(true));
    
            try {
                await Promise.all(
                    productsCategoriesList.map(async (productCategory) => {
                        
                        const response = await this.productController.getByCategoryName(productCategory.name!, search);
                        const { status, data } = response;
                        
                        if (status === responseStatus.SUCCESS) {
                            allProducts.push({ category: productCategory, productsList: data });
                        }else{
                            showToast(this.dispatch, {
                                type: toastTypes.ERROR,
                                text: data,
                            });
                        }
                    })
                );
    
                this.dispatch(tabMenuSetProductsData(allProducts));
            } finally {
                this.dispatch(generalSetIsLoading(false));
            }
        }
    }

    public async handleGetProcductsByCategory(productCategory: ProductCategoryI, search?: string) {
        if (productCategory) {    
            this.dispatch(generalSetIsLoading(true));
            await this.productController.getByCategoryName(productCategory.name!, search).then(response=>{
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    this.dispatch(tabMenuSetProductsData([{ category: productCategory, productsList: data }]));
                }else{
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(()=>{
                this.dispatch(generalSetIsLoading(false));
            });
        }
    }
    
    public handleCategoriesToOptions(productsCategoriesList? : ProductCategoryI[]){
        const options = productsCategoriesList?.map(productCategory=>(
            {value: productCategory.id?.toString()!, content: productCategory.name!}
        ));
        
        options?.push({value : '', content : 'Nenhuma'});
        return options;
    }
    
    public handleFindCategoryByValue(value: string, productsCategoriesList? : ProductCategoryI[]){
        return productsCategoriesList?.find(productCategory => productCategory.id?.toString() === value);
    }

    public handleSwitchMenu(product : ProductI, productsCategoriesList: ProductCategoryI[]){
        this.dispatch(generalSetIsLoading(true));
        this.productController.updateDataWithoutMedia({...product, available : !product.available}).then(async response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                await this.handleGetProcductsByCategoryList(productsCategoriesList)
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleSwitchBarCode(product : ProductI, productsCategoriesList: ProductCategoryI[]){
        this.dispatch(generalSetIsLoading(true));
        this.productController.updateDataWithoutMedia({...product, availableCode : !product.availableCode}).then(async response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                await this.handleGetProcductsByCategoryList(productsCategoriesList)
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    private handleDeleteItem(productId : number, productCategoryList : ProductCategoryI[]){
        this.dispatch(generalSetIsLoading(true));
        this.productController.delete(productId).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                showToast(this.dispatch, {
                    type: toastTypes.SUCCESS,
                    text: 'Produto excluído com sucesso.',
                });
                this.handleGetProcductsByCategoryList(productCategoryList)
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }

        }).finally(()=>{
            this.dispatch(generalSetAlertDialog(alertInitialValue));
            this.dispatch(generalSetIsLoading(false));
        });
    }

    private handleEditarItem(product : ProductM){
        this.dispatch(tabMenuSetProductForm({...product, price : product.price! * 100}));
        this.dispatch(canteenSetTabSelectedContent(canteenContentsNames.MENU_ADD_ITEM));
    }

    private handleDuplicateItem(product : ProductM){
        this.dispatch(tabMenuSetProductForm({...product, price : product.price! * 100, id: -1}));
        this.dispatch(canteenSetTabSelectedContent(canteenContentsNames.MENU_ADD_ITEM));
    }

    private handleShowAlertExcluirItem(product : ProductM, productCategoryList : ProductCategoryI[]){
        this.dispatch(generalSetAlertDialog({
            title : 'Excluir Item',
            text : `O item “${product.name}” será excluído do cardápio. Tem certeza que deseja continuar?`,
            isOpen : true,
            action : {
                text : 'Excluir item',
                onClick : ()=>{
                    this.handleDeleteItem(product.id!, productCategoryList);
                }
            }
        }));
    }

    public handleGetOptionsProductItem(product : ProductM, productCategoryList : ProductCategoryI[]){
        const options : OptionsMenuOptionT[] =  [
            {value: "Editar Item", onClick : ()=>this.handleEditarItem(product)},
            {value: "Excluir Item", onClick : ()=>this.handleShowAlertExcluirItem(product, productCategoryList)},
            {value: "Duplicar Item", onClick : ()=>{this.handleDuplicateItem(product)}},
        ];

        return options;
    }

    
    
}
