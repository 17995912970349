import styled from "styled-components";

export const TabFoodMenuContainer = styled.div`
    width: 100%;
    thead{
        .arrow{
            cursor: pointer;
        }
    }
    .button-change{
        width: 200px;
        justify-content: center;
        margin: 10px 20px;
        padding: 16px 18px;
    }

    .options-column{
        display: flex !important;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 32px;

    }

    @media screen and (max-width: 1400px){
        .tableSubTitle{
            margin-top: 15px;
        }
        .options-column{
            gap: 24px
        }

        .button-change{
            width: 150px;
            margin: 8px 15px;
        }
    }
`;

export const TabFoodMenuTitle = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;

    h2{
        margin-right: auto;
        margin-bottom: 8px;
    }

    @media screen and (max-width: 1400px){
        h2{
            margin-right: auto;
            margin-bottom: 6px;
        }
    }
  
`;

export const TabMenuFoodDropDownBar = styled.div`
    display: flex;
    gap: 24px;
    align-items: flex-end;

    .input-year-style{
        width: 220px;
        padding: 14px 12px;
        border-radius: 4px;
    }

    @media screen and (max-width : 1400px){
        gap: 18px;
        .input-year-style{
            width: 180px;
            padding: 14px 12px;
        }
    }
`;

export const TabFoodMenuSearchBar = styled.form`
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    gap: 32px;

    .input-year-style{
        border: 1px solid var(--border-color);
        background-color: transparent !important;
    }

    .new-menu-button{
        padding: 12px 20px;
    }

    button{
        max-height: 48px !important;
        height: 36px !important;
    }

   
    @media screen and (max-width : 1400px){
        gap: 24px;

        .new-menu-button{
            padding: 9px 15px;
        }

        button{
            max-height: 36px !important;
            height: 36px !important;
        }

    }
`;

export const TabMenuFoodTopBar = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 12px;
    margin-bottom: 32px;

    @media screen and (max-width : 1400px){
        margin-bottom: 24px;
    }
`;

export const TabFoodMenuListProducts = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 60px;
    
    @media screen and (max-width : 1400px){
        gap: 45px;
        margin-bottom: 15px;
    }

    @media screen and (max-width : 900px){
        gap: 2%;
    }
`;

export const TabFoodMenuProductItemOptions = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    img{
        width: 20px !important;
        height: 20px !important;
    }

    @media screen and (max-width: 1400px){
        gap: 4px;
        img{
            width: 15px !important;
            height: 15px !important;
        }   
    }
`;

export const TabFoodMenuAddItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;

    div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border-radius: 8px;
        border: 1px dashed var(--border-color);

        img{
            width: 52px;
            height: 52px;
            filter: grayscale(100%);
        }
    }

    @media screen and (max-width : 1400px){
        div{
            width: 90px;
            height: 90px;

            img{
                width: 39px;
                height: 39px;
            }
        }
        
    }
`;

export const TabFoodMenuProductItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 160px !important;

    span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    div{

        img{
            width: 120px;
            height: 120px;
            border-radius: 8px;
            object-fit: cover;
        }

        &:hover{
            div{
                display: flex;
            }
        }
    }

   

    @media screen and (max-width : 1400px){
        gap: 2px;
        width: 120px;

        div{
            img{
                width: 90px;
                height: 90px;
            }
        }        
    }

    
    @media screen and (max-width : 900px){
        min-width: 49% !important;
        margin-bottom: 24px;
    }

`;