import styled from "styled-components";

export const TabStudentsContainer = styled.div`
    
`;

export const TabStudentsSearchBar = styled.form`
   display: flex;
    justify-content: space-between;
    gap: 12px;

    .inputSearch{
        width: 100%;
    }


    .input-style{
        min-height: 55px !important;
        margin-top: 12px;
        border-radius: 4px;
    }

    

    @media screen and (max-width: 1400px){
        .input-style{
            margin-top: 8px !important;
            min-height: 41px !important;

        }

    }

`;

export const TabStudentsCanteenPeriod = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    gap: 12px;
    margin-top: 12px;
    width: auto !important;
    max-height: 40px;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .canteenPeriod{
        width: auto;
        gap: 0 !important;
    }

    input{
        border: none !important;
        padding: 0 !important;
        max-width: 200px !important;
    }
    
    img, label{
        display: none;
    }

    @media screen and (max-width: 1400px) {
        input{
            max-width: 150px !important;
        }        
    }
`;

export const TabStudentsContent = styled.div`
    margin-top: 20px;
    .border-none{
        border: none;
        td{
            justify-content: center;
        }
    }
`;

export const TabStudentsAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 12px;
    background-color: var(--primary-opacity-2-color);
    border-radius: 8px;

    img{
        width: 24px;
    }

    @media screen and (max-width : 1400px){
        padding: 15px 0;
        img{
            width: 18px;
        }
    }
`;

export const TabStudentsButtonBar = styled.div`
    display: flex;
    button{
        margin-left: auto;
        margin-top: 12px;
        min-height: 40px;
        padding: 0 16px;
    }

    label{
        margin-left: 8px;
        position: relative;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;

        img{
            width: 24px;
        }

        cursor: pointer;

        .infoCard{
            position: absolute;
            left: 132px;
            min-width: calc(100% + 20px) !important;
            background-color: #F4F4F4;
            padding: 10px;
            display: none;
        }

        &:hover{
            .infoCard{
                display: block;
            }
        }
    }

    @media screen and (max-width: 1400px){
        button{
            padding: 0 12px;
            min-height: 40px;
        }

        
    }
`;