import { SchoolWorkerI } from "./interfaces/school-worker.interface";
import { UserI } from "./interfaces/user.interface";

export class SchoolWorkerM implements SchoolWorkerI {
    id?: number;
    name: string;
    registrationCode?: string;
    office: string;
    userId?: number;
    user?: UserI;

    constructor({ id, name, registrationCode, office, userId, user }: SchoolWorkerI) {
        this.id = id;
        this.name = name;
        this.registrationCode = registrationCode;
        this.office = office;
        this.userId = userId;
        this.user = user;
    }

    static initial(): SchoolWorkerM {
        return new SchoolWorkerM({
            name: "",
            office: "",
            // user: UserM.initial(),
        });
    }

}
