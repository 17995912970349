import { Dispatch } from "redux";
import { responseStatus } from "../../../../api/api-request.service";
import { UserSchoolWorkerController } from "../../../../controllers/user-school-worker.controller";
import { UserStudentController } from "../../../../controllers/user-student.controller";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { tabAnnouncementsSetAuthorsData } from "../../../../features/tab-announcements-slice.feature";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { LoginUserType, generateErrorMessage, showToast } from "../../../../utils/config";
import { AuthController } from "../../../../controllers/auth.controller";
import { LocalTokenM, LoginFormM } from "../../../../features/types/auth-slice.type";
import { authForm, authSetGradesData, authSetStudentsData, tokenData, userData, userId } from "../../../../features/auth-slice.feature";
import { LocalToken } from "../../../../utils/local-token";
import { UserController } from "../../../../controllers/user.controller";
import { GradeController } from "../../../../controllers/grade.controller";
import { StudentM } from "../../../../models/student.model";
import { foodSetEvaluationSelectedStudent } from "../../../../features/food-slice.feature";
import { FruitSequencyI } from "../../../../models/interfaces/fruit-sequency.interface copy";

export class LoginFormService {
    private static instance: LoginFormService | null = null;

    private dispatch: Dispatch;
    private authController: AuthController;
    private userController: UserController;
    private gradeController: GradeController;
    private userStudentController: UserStudentController;
    private userSchoolWorkersController: UserSchoolWorkerController;

    private constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.authController = new AuthController();
        this.userController = new UserController();
        this.gradeController = new GradeController();
        this.userStudentController = new UserStudentController();
        this.userSchoolWorkersController = new UserSchoolWorkerController();
    }

    public static getInstance(dispatch: Dispatch) {
        if (!LoginFormService.instance) {
            LoginFormService.instance = new LoginFormService(dispatch);
        }
        return LoginFormService.instance;
    }

    public handleChangeName(value: string, loginForm: LoginFormM) {
        this.dispatch(authForm({
            ...loginForm,
            name: value
        }));
    }

    public handleChangeGrade(value: string, loginForm: LoginFormM) {
        this.dispatch(authForm({
            ...loginForm,
            grade: value
        }));
    }


    public handleChangeEmail(value: string, loginForm: LoginFormM) {
        this.dispatch(authForm({
            ...loginForm,
            email: value
        }));
    }


    public handleChangePassword(value: string, loginForm: LoginFormM) {
        this.dispatch(authForm({
            ...loginForm,
            password: value
        }));
    }














    private validateLoginStudent(loginForm: LoginFormM) {
        const fieldsErrors = [];


        if (!loginForm.grade) {
            fieldsErrors.push('Ano/Série')
        }


        if (!loginForm.name) {
            fieldsErrors.push('Nome')
        }


        if (!loginForm.password) {
            fieldsErrors.push('Senha')
        }


        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }

        return true;
    }

    private validateLogin(loginForm: LoginFormM) {
        const fieldsErrors = [];

        if (!loginForm.email) {
            fieldsErrors.push('Email')
        }

        if (!loginForm.password) {
            fieldsErrors.push('Senha')
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }

        return true;
    }


    public getFruitsSequence(setFruitsSequence: Function) {
        this.dispatch(generalSetIsLoading(true));
        this.authController.getFruitsSequence().then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                setFruitsSequence(data)
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: status === responseStatus.NOT_FOUND ?
                        'Ocorreu um erro ao buscar as frutas.' :
                        data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }


    public handleLoginStudent(fruitsSequence: FruitSequencyI[]) {
        this.dispatch(generalSetIsLoading(true));
        this.authController.authenticateStudent(fruitsSequence.map(fs=>fs.n.toString()).join('')).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                LocalToken.storeToken(data.access, LoginUserType.STUDENT);
                this.dispatch(tokenData(data));
                this.dispatch(foodSetEvaluationSelectedStudent(data.data.student!));
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: status === responseStatus.NOT_FOUND ?
                        'Usuário ou senha incorretos.' :
                        data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleLogin(loginForm: LoginFormM, navigate: Function) {
        if (this.validateLogin(loginForm)) {
            this.dispatch(generalSetIsLoading(true));
            this.authController.authenticateSchooWorker(loginForm.email!, loginForm.password!).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    LocalToken.storeToken(data.access, LoginUserType.TEACHER);
                    this.dispatch(tokenData(new LocalTokenM(data)));
                    console.log('LOGIN',data)
                    if(data.data.groups.find((group:any)=>group.name === "SCHOOL_TEACHER")){
                        window.location.href = '/alimentacao';
                    }else{
                        window.location.href = '/home';
                    }
                    

                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: status === responseStatus.NOT_FOUND ?
                            'Usuário ou senha incorretos.' :
                            data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }

    }

    public resetForm() {
        this.dispatch(authForm(LoginFormM.initial()));
    }

    public handleGetSchoolWorkers(instituteId: number) {
        this.dispatch(generalSetIsLoading(true));
        this.userSchoolWorkersController.getAllByInstitute(instituteId).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(tabAnnouncementsSetAuthorsData(data));
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }


    public handleGetCurrentUserData(user_id: number) {
        this.dispatch(generalSetIsLoading(true));

        this.userController.getById(user_id).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(userData(data));
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleVerifyToken(token: LocalTokenM) {
        this.dispatch(generalSetIsLoading(true));

        this.authController.verifyToken(token).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                this.dispatch(userId(data.user.user_id));
            } else {
                LocalToken.clearStoredToken()
                this.dispatch(tokenData(new LocalTokenM({ access: '', userType: '' })));
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: status === responseStatus.NOT_FOUND ?
                        'Usuário ou senha incorretos.' :
                        data,
                });
                // Se acontecer algum erro ao verificar o token, então vamos redirecionar para o landingpage
                window.location.replace(window.location.origin + '/landing');
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });

    }


}