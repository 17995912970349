import { handleRequest } from "../api/api-request.service";
import { MediaM } from "./media.model";
import { SchoolWorkerM } from "./school-worker.model";
import { UserM } from "./user.model";

export class UserSchoolWorkerM extends UserM {


    static initial(){
        return new UserSchoolWorkerM({
            email: '',
            password: '',
            instituteId: 0,
            schoolWorker : SchoolWorkerM.initial(),
            profilePic : MediaM.initial()
        })
    }


    static async getAllByInstitute(instituteId: number, search?: string) {

        const query = `?offset=0&limit=20`;

        return handleRequest(
            `user-school-worker/by/institute/${instituteId}${query}`,
            'GET'
        )
    }

    static async getById(userId: string) {
        return handleRequest(
            `user-school-worker/${userId}`,
            'GET'
        )
    }


    static async update(schoolWorker: UserM) {
        return handleRequest(
            `user-school-worker/${schoolWorker.id}`,
            'PUT',
            schoolWorker
        );
    }

    static async create(schoolWorker: UserM) {
        return handleRequest(
            `user-school-worker`,
            'POST',
            schoolWorker
        );
    }

    static async delete(userId: number) {
        return handleRequest(
            `user-school-worker/${userId}`,
            'DELETE'
        );
    }

}

