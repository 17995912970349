import React from 'react';
import { useDispatch } from 'react-redux';
import { TabBar } from '../../components/tab-bar.component';
import { HomeContainer } from './styled/students-home.styled';
import { StudentHomeService } from './services/students-home.service';
import { useAppSelector } from '../../../hooks';
import { studentContents, studentTabList } from '../../../features/types/student-slice.type';


export const StudentHomePage = () => {
    const dispatch = useDispatch();
    const studentReducer = useAppSelector(state => state.studentReducer);
    const studentHomeService = new StudentHomeService(dispatch);


    return (
        <HomeContainer>
           <h2 className='primaryBlackSemibold28-120'>Alunos</h2> 
           <span className='primaryBlackNormal20-120'>Gerencie e visualize os alunos e pais cadastrados, assim como outras informações dos alunos.</span>

           <TabBar 
                setSelectedTab={(tabSelectedItem)=>studentHomeService.handleSetSelectedTab(tabSelectedItem)} 
                selected={studentReducer.tabSelectedItem} 
                items={studentTabList}
            />
        
           { studentContents[studentReducer.tabSelectedContent] }
           
        </HomeContainer>
    );
};