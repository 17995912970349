import { Dispatch } from "redux";
import { clearCPF, generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { AuthController } from "../../../../controllers/auth.controller";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { ResetPasswordT } from "./types";

export class ResetPasswordService{
    dispatch : Dispatch;
    authController : AuthController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
        this.authController = new AuthController();
    }

    private validateResetPassword(resetPassword: ResetPasswordT) {
        const fieldsErrors = [];

        if (!resetPassword.cpf) {
            fieldsErrors.push('cpf')
        }

        if (!resetPassword.password) {
            fieldsErrors.push('password')
        }

        if (!resetPassword.confirmPassword) {
            fieldsErrors.push('confirmPassword')
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }

        return true;
    }


    public async submit(resetPassword : ResetPasswordT, token: string){
        if (this.validateResetPassword(resetPassword)) {
            this.dispatch(generalSetIsLoading(true));
            this.authController.passwordSaveResetSchooWorker({...resetPassword, cpf : clearCPF(resetPassword.cpf!)}, token).then(response => {
                const { status, data } = response;
                if (status === responseStatus.SUCCESS) {
                    showToast(this.dispatch, {
                        type: toastTypes.SUCCESS,
                        text: 'Senha alterada com sucesso',
                    });
                    window.location.href = '/login/TEACHER'
                } else {
                    showToast(this.dispatch, {
                        type: toastTypes.ERROR,
                        text: data,
                    });
                }
            }).finally(() => {
                this.dispatch(generalSetIsLoading(false));
            });
        }

    }
}