import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AlertDialogT, GeneralStateT, ToastValueT } from "./types/general-slice.type";
import { UserSchoolWorkerM } from "../models/user-school-worker.model";

export const alertInitialValue = {
    isOpen : false,
    title : '',
    text : '',
    action : {
        text : '',
        onClick : ()=>{}
    }
}

const initialState : GeneralStateT = {
    isLoadig : false,
    reload : false,
    toast : {text : '', type : ''},
    alertDialog : alertInitialValue
} 

export const generalSlice = createSlice({
   name: 'general',
   initialState,
   reducers: {
        generalSetIsLoading : (state, action : PayloadAction<boolean>)=>{
            state.isLoadig = action.payload;
        },       

        generalReload : (state)=>{
            state.reload = !state.reload;
        },     

        generalSetToast : (state, action : PayloadAction<ToastValueT>)=>{
            state.toast = action.payload;
        },

        generalSetAlertDialog : (state, action : PayloadAction<AlertDialogT>)=>{
            state.alertDialog = action.payload;
        },

        generalSetUserSchoolWorker : (state, action : PayloadAction<UserSchoolWorkerM>)=>{
            state.userSchoolWorker = action.payload
        }
   } 
});

export const { 
    generalSetIsLoading,
    generalReload,
    generalSetToast,
    generalSetAlertDialog,
    generalSetUserSchoolWorker
} = generalSlice.actions;
export const generalReducer = generalSlice.reducer;