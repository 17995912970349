import { TabAddNewConsumableContainer, TabAddNewConsumableContent, TabAddNewConsumableForm, TabAddNewConsumableNutritionalCategoryAddButton, TabAddNewConsumableNutritionalCategoryContainer, TabAddNewConsumableNutritionalCategoryItem, TabAddNewConsumableTitle } from "./styles/tab-add-new-consumable.styled";
import plus_icon from '../../../assets/icons/plus.svg';
import { useEffect, useState } from "react";
import { InputPhoto } from "../../components/input-photo.component";
import { useAppSelector } from "../../../hooks";
import { baseUrl } from "../../../api/api";
import { Select } from "../../components/select.component";
import { Input } from "../../components/input.component";
import { ButtonPrimary } from "../../components/button-primary.component";
import { useDispatch } from "react-redux";
import { NutritionalCategoryM } from "../../../models/nutritional-category.model";
import { AddNutritionalCategoryModal } from "./add-nutritional-category-modal.page";
import { TabAddNewConsumableService } from "./services/tab-add-new-consumable.service";
import { FoodTypeM } from "../../../models/food-type.model";
import { ActionBack } from "../../components/action-back.component";
import { ButtonSecondary } from "../../components/button-secondary.component";


export const TabAddNewCosumablePage = () => {
    const [nutritionalCategoriesData, setNutritionalCategoriesData] = useState<{count : number , rows : NutritionalCategoryM[]}>();
    const [foodTypesData, setFoodTypesData] = useState<{count : number, rows : FoodTypeM[]}>();
    const foodReducer = useAppSelector(state=>state.foodReducer);
    const dispatch = useDispatch();
    const tabAddNewConsumableService = new TabAddNewConsumableService(
        dispatch, foodReducer.consumableForm
    );

    useEffect(()=>{
        tabAddNewConsumableService.handleGetNutritionalCategories(setNutritionalCategoriesData);
        tabAddNewConsumableService.handleSearchFoodTypes(setFoodTypesData);
    }, []);


    useEffect(()=>{
        if(foodTypesData){
            if(foodReducer.todaysMenusFormData?.todaysMenuIndex && foodReducer.todaysMenusFormData?.fullMealIndex){
                const value = foodReducer.todaysMenusForm.rows[foodReducer.todaysMenusFormData?.todaysMenuIndex]
                    .fullmeals![foodReducer.todaysMenusFormData?.fullMealIndex]!.foodType!.id;

                tabAddNewConsumableService.handleChangeCategoryByValue(value!.toString(), foodReducer.consumableForm, foodTypesData?.rows);
            }
        }
    }, [foodTypesData]);

    return (
        <TabAddNewConsumableContainer>
            { !foodReducer.todaysMenusFormData &&
                <>
                    <ActionBack onClose={tabAddNewConsumableService.handleClose}/>
                    <TabAddNewConsumableTitle className="primaryBlackSemibold24-150">Detalhes do alimento</TabAddNewConsumableTitle>
                </>
            }
            { foodReducer.showAddNutritionalCategoryModal && 
                <AddNutritionalCategoryModal 
                    handleGetNutritionalCategories={()=>tabAddNewConsumableService.handleGetNutritionalCategories(setNutritionalCategoriesData)}
                /> 
            }
            <TabAddNewConsumableContent>
                <TabAddNewConsumableForm autoComplete="off">
                    <h2 className='primaryBlackSemibold24-150'>Foto do alimento</h2>
                    <InputPhoto
                        label="Aparece na listagem e no detalhe do item."
                        srcImage={
                            foodReducer.consumableForm.media?.type === 'external_url' ?
                            `${baseUrl}/${foodReducer.consumableForm.media?.content}` :
                            foodReducer.consumableForm.media?.content
                        } 
                        onChange={(e)=>{tabAddNewConsumableService.handleChangeImage(e)}}
                    />

                    <Select
                        className='input-form'
                        label='Categoria'
                        name='category'
                        id='category'
                        placeholder='Selecione uma categoria'
                        defaultValue={foodReducer.consumableForm?.foodType?.id?.toString()}
                        options={tabAddNewConsumableService.handleFoodTypesToOptions(foodTypesData?.rows)}                    
                        onChange={(value)=>tabAddNewConsumableService.handleChangeCategoryByValue(value, foodReducer.consumableForm, foodTypesData?.rows)}
                    />

                    <Input
                        className='input-form'
                        id='name'
                        name='name'
                        type='text'
                        label='Nome do Item'
                        placeholder='Nome do Item'
                        value={foodReducer.consumableForm.name}                    
                        onChange={tabAddNewConsumableService.handleChangeName}
                    />

                    <Input
                        className='input-form'
                        id='amountInGrams'
                        name='amountInGrams'
                        type='number'
                        placeholder='Peso em Gramas'
                        label='Peso em Gramas'
                        value={foodReducer.consumableForm.amountInGrams?.toString()}                    
                        onChange={tabAddNewConsumableService.handleChangeAmountInGrams}
                    />

                    <Select
                        className='input-form'
                        label='Tipo de Alimento'
                        name='mealType'
                        id='mealType'
                        placeholder='Selecione um tipo de alimento'
                        defaultValue={foodReducer.consumableForm?.mealType}
                        options={tabAddNewConsumableService.handleMealTypesToOptions()}                    
                        onChange={(value)=>tabAddNewConsumableService.handleChangeMealTypeByValue(value, foodReducer.consumableForm)}
                    />

                    <label className="primaryBlackSemibold20-120">Categorias nutricionais</label>
                    <TabAddNewConsumableNutritionalCategoryContainer>
                        {nutritionalCategoriesData?.rows.map(nutritionalCategory => (
                            <TabAddNewConsumableNutritionalCategoryItem 
                                className={`primaryBlackSemibold18-120 ${foodReducer.consumableForm.nutritionalCategories.find(nutritionalCategoryFind=> nutritionalCategoryFind.id === nutritionalCategory.id) ? 'active' : ''}`}
                                color={nutritionalCategory.hexColor}
                                onClick={() => tabAddNewConsumableService.selectNutritionalCategories(nutritionalCategory)}
                            >
                                {nutritionalCategory.name}
                            </TabAddNewConsumableNutritionalCategoryItem>
                        ))}
                    </TabAddNewConsumableNutritionalCategoryContainer>

                    <TabAddNewConsumableNutritionalCategoryAddButton 
                        type="button"
                        className="primaryBlackSemibold18-120"
                        onClick={()=>tabAddNewConsumableService.handleSetShowAddNutritionalCategoryModal(true)}
                    >
                        <img src={plus_icon} alt="Icone de adicionar"/>
                        Nova categoria nutricional
                    </TabAddNewConsumableNutritionalCategoryAddButton>

                    <ButtonPrimary 
                        onClick={()=>tabAddNewConsumableService.handleSubmit(
                            foodReducer.todaysMenusForm, foodReducer.todaysMenusFormData
                        )}
                    >
                        {foodReducer.todaysMenusFormData ? "Salvar escolha de alimento" : "Salvar Item"}
                    </ButtonPrimary>

                    { foodReducer.consumableForm.id &&
                        <ButtonSecondary onClick={()=>tabAddNewConsumableService.handleShowAlertExcluirItem(foodReducer.consumableForm)}>
                            Excluir Item
                        </ButtonSecondary>
                    }
                </TabAddNewConsumableForm>
            </TabAddNewConsumableContent>
        </TabAddNewConsumableContainer>
    );
};