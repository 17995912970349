import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { FoodTypeM } from '../../../models/food-type.model';
import { TabStudentEvaluationMealButtonBar, TabStudentEvaluationMealContainer, TabStudentEvaluationMealItem, TabStudentEvaluationMealTop } from './styles/tab-student-evaluation-meal.styled';
import { TabStudentEvaluationMealService } from './services/tab-student-evaluation-meal.service';
import { ActionBack } from '../../components/action-back.component';
import { PageCount } from '../../components/page-count.component';
import arrow_right_icon from '../../../assets/icons/arrow_right.svg';
import { ButtonPrimary } from '../../components/button-primary.component';
import { LoginUserType } from '../../../utils/config';
import { LocalToken } from '../../../utils/local-token';

export const TabStudentEvaluationMealPage = () => {

    const [foodTypesData, setFoodTypesData] = useState<{count : number, rows: FoodTypeM[]}>();
    const userType = LocalToken.getStoredUserType();
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);
    const tabFoodStudentEvaluationService = new TabStudentEvaluationMealService(dispatch);
    
    useEffect(()=>{
        tabFoodStudentEvaluationService.handleSearchFoodTypes(setFoodTypesData);
    }, []);

    return (
        <TabStudentEvaluationMealContainer>
            { userType === LoginUserType.TEACHER && <ActionBack onClose={()=>{}}/> }
            <TabStudentEvaluationMealTop>
                <span className='primaryBlackSemibold24-120'>Qual refeição iremos avaliar agora?</span>
                <PageCount size={5} current={1}/>
            </TabStudentEvaluationMealTop>

            {foodTypesData?.rows.map(foodtype=>(
                <TabStudentEvaluationMealItem 
                    className={`primaryBlackSemibold20-150 ${foodtype.id === foodReducer.evaluationSelectedFoodType?.id ? 'active' : ''}`}
                    onClick={()=>tabFoodStudentEvaluationService.handleSelectFoodType(foodtype)}
                >
                    <span>{foodtype.name}</span>
                    <img src={arrow_right_icon} alt="Icone de Seta"/>
                </TabStudentEvaluationMealItem>
                
            ))}
            <TabStudentEvaluationMealButtonBar>
                <ButtonPrimary 
                    disabled={!foodReducer.evaluationSelectedFoodType}
                    onClick={tabFoodStudentEvaluationService.handleOpenEvaluationConsumable}
                >
                    Continuar
                </ButtonPrimary>
            </TabStudentEvaluationMealButtonBar>
        </TabStudentEvaluationMealContainer>
    );
};