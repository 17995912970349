import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnnouncementsDataStateT, AnnouncementsStateT } from './types/announcements-slice.type';
// import { AnnouncementM } from '../models/announcements.model';

const initialState : AnnouncementsStateT = {
    tabSelectedItem : 'Comunicados',
    tabSelectedContent : 'announcements',

    // announcementsForm: AnnouncementM.initial()
} 

export const announcementsSlice = createSlice({
   name: 'announcements',
   initialState,
   reducers: {
        announcementsSetTabSelectdItem : (state, action : PayloadAction<string>)=>{
            state.tabSelectedItem = action.payload;
        },
        announcementsSetTabSelectedContent: (state, action : PayloadAction<string>)=>{
            state.tabSelectedContent = action.payload;
        },
        tabAnnouncementsSetAnnouncementsData: (state, action : PayloadAction<AnnouncementsDataStateT>)=>{
            state.announcementsData = action.payload;
        },

        // tabAnnouncementsForm(state, action: PayloadAction<AnnouncementM>) {
        //     state.announcementsForm = action.payload;
        // }
       
   } 
});

export const { 
    announcementsSetTabSelectdItem, 
    announcementsSetTabSelectedContent,
    tabAnnouncementsSetAnnouncementsData,
    // tabAnnouncementsForm
} = announcementsSlice.actions;
export const announcementsReducer = announcementsSlice.reducer;