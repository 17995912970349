import { FoodTypeM } from "../../models/food-type.model";
import { MealConsumableM } from "../../models/meal-consumable.model";
import { StudentM } from "../../models/student.model";
import { TodaysMenuM } from "../../models/todays-menu.model";
import { TabAddNewCosumablePage } from "../../views/modules/food/tab-add-new-consumable.page";
import { TabFoodAddMenuPage } from "../../views/modules/food/tab-food-add-menu.page";
import { TabFoodMenuConsumablePage } from "../../views/modules/food/tab-food-menu-consumable.page";
import { TabFoodMenuPage } from "../../views/modules/food/tab-food-menu.page";
import { TabFoodStudentEvaluationPage } from "../../views/modules/food/tab-food-student-evaluation.page";
import { TabStudentEvaluationEvaluationPage } from "../../views/modules/food/tab-student-evaluation-evaluation.page";
import { TabStudentEvaluationConsumablePage } from "../../views/modules/food/tab-student-evaluation-consumable.page";
import { TabStudentEvaluationMealPage } from "../../views/modules/food/tab-student-evaluation-meal.page";
import { StudentMealAcceptanceI } from "../../models/interfaces/student-meal-acceptance.interface";
import { TabStudentEvaluationFinalPage } from "../../views/modules/food/tab-student-evaluation-final.page";
import { FullMealM } from "../../models/full-meal.model";
import { TabStudentEvaluationSuccessPage } from "../../views/modules/food/tab-student-evaluation-success.page";
import { LocalToken } from "../../utils/local-token";
import { LoginUserType } from "../../utils/config";


const tabsNamesAdm = {
    MENU : "Cardápio",
    STUDENT_EVALUATION : "Avaliação dos alunos",
    FOOD_CATALOG :  "Catálogo de alimentos",
};

const tabsNamesTeacher = {
    STUDENT_EVALUATION : "Avaliação dos alunos",
};

const tabsNamesStudent = {
    STUDENT_EVALUATION : "Avaliação do alimento",
};

export const foodContentsNames = {
    MENU : "menu",
    STUDENT_EVALUATION : 'student-evaluation',
    STUDENT_EVALUATION_MEAL : 'student-evaluation-meal',
    STUDENT_EVALUATION_CONSUMABLE : 'student-evaluation-consumable',
    STUDENT_EVALUATION_EVALUATION : 'student-evaluation-evaluation',
    STUDENT_EVALUATION_FINAL : 'student-evaluation-final',
    STUDENT_EVALUATION_SUCCESS : 'student-evaluation-success',
    FOOD_CATALOG : 'food-catalog',  
    MENU_ADD_FULL_MEAL: 'menu-add-full-meal',
    MENU_ADD_CONSUMABLE: 'menu-add-consumable'

}

export const foodTabToContentTeacher = {
    [tabsNamesTeacher.STUDENT_EVALUATION] : foodContentsNames.STUDENT_EVALUATION,
};

export const foodTabToContentAdm = {
    [tabsNamesAdm.MENU || ''] : foodContentsNames.MENU,
    [tabsNamesAdm.STUDENT_EVALUATION] : foodContentsNames.STUDENT_EVALUATION,
    [tabsNamesAdm.FOOD_CATALOG || ''] : foodContentsNames.FOOD_CATALOG,
};

export const foodTabToContentStudent = {
    [tabsNamesStudent.STUDENT_EVALUATION] : foodContentsNames.STUDENT_EVALUATION,
};

export const foodContents = {
    [foodContentsNames.MENU] : <TabFoodMenuPage/>,
    [foodContentsNames.STUDENT_EVALUATION] : <TabFoodStudentEvaluationPage/>,
    [foodContentsNames.STUDENT_EVALUATION_MEAL] : <TabStudentEvaluationMealPage/>,
    [foodContentsNames.STUDENT_EVALUATION_CONSUMABLE] : <TabStudentEvaluationConsumablePage/>,
    [foodContentsNames.STUDENT_EVALUATION_EVALUATION] : <TabStudentEvaluationEvaluationPage/>,
    [foodContentsNames.STUDENT_EVALUATION_FINAL] : <TabStudentEvaluationFinalPage/>,
    [foodContentsNames.STUDENT_EVALUATION_SUCCESS] : <TabStudentEvaluationSuccessPage/>,
    [foodContentsNames.FOOD_CATALOG] : <TabFoodMenuConsumablePage/>,
    [foodContentsNames.MENU_ADD_FULL_MEAL] : <TabFoodAddMenuPage/>,
    [foodContentsNames.MENU_ADD_CONSUMABLE] : <TabAddNewCosumablePage/>
    
}

export const foodTabStudentList = Object.values(tabsNamesStudent);
export const foodTabTeatcherList = Object.values(tabsNamesTeacher);
export const foodTabAdmList = Object.values(tabsNamesAdm);

export type FoodTodaysMenusFormDataT = {
    todaysMenuIndex : number,
    fullMealIndex : number,
    consumableIndex? : number
} | undefined;

export type FoodStateT = {
    tabSelectedItem : string,
    tabSelectedContent : string,
    consumableForm : MealConsumableM,
    todaysMenusForm: {count : number, rows : TodaysMenuM[]},
    showAddNutritionalCategoryModal : boolean,
    showSelectCosumableModal : boolean,
    todaysMenusFormData?: FoodTodaysMenusFormDataT,
    evaluationSelectedFoodType? : FoodTypeM,
    evaluationSelectedFullMeal? : FullMealM,
    evaluationSelectedStudent? : StudentM,
    evaluationSelectedConsumables : {
        consumables : StudentMealAcceptanceI[],
        drinks : StudentMealAcceptanceI[],
    }

}