import { useDispatch } from "react-redux";
import { TabAddStudentHealthyContainer, TabAddStudentHealthyContent, TabAddStudentHealthyLegend, TabAddStudentHealthyLegendItem, TabAddStudentHealthyWeeklyAssessment, TabAddStudentHealthyWeeklyAssessmentColumn, TabAddStudentHealthyWeeklyAssessmentColumnSpecial, TabAddStudentHealthyWeeklyAssessmentDisease, TabAddStudentHealthyWeeklyAssessmentDiseases, TabAddStudentHealthyWeeklyAssessmentForm, TabAddStudentHealthyWeeklyAssessmentSelect, TabAddStudentHealthyWeeklyAssessmentTitle } from "./styled/tab-add-student-healthy.styled";
import { Input } from "../../components/input.component";
import { useAppSelector } from "../../../hooks";
import { TabAddStudentHealthyService } from "./services/tab-add-student-healthy.service";
import { useEffect, useState } from "react";
import { LineChart } from "../../components/line-chart.component";
import { StudentMonitoringM } from "../../../models/student-monitoring.model";
import dropdown_arrow_icon from "../../../assets/icons/dropdown_arrow_primary.svg";
import pill_icon from "../../../assets/icons/pill.svg";
import plus_border_icon from "../../../assets/icons/plus_border.svg";
import minus_icon from "../../../assets/icons/minus.svg";
import { ButtonPrimary } from "../../components/button-primary.component";
import { StudentHealthObservationM } from "../../../models/student-health-observation.model";
import { AddDiseaseModal } from "./add-disease-modal.page";
import { AddAllergyModal } from "./add-allergy-modal.page";
import { DatePicker } from "../../components/date-picker.component";
import { format } from "date-fns";
import { pt } from 'date-fns/locale';

export const TabAddStudentHealthyPage = () => {
    const dispatch = useDispatch();
    const studentReducer = useAppSelector(state => state.studentReducer);
    const [studentMonitoringData, setStudentMonitoringData ] = useState<{count: number, rows: StudentMonitoringM[]}>();
    const [studentHealhtyObservationsData, setStudentHealhtyObservationsData ] = useState<{count: number, rows: StudentHealthObservationM[]}>();
    const [studentMonitoring, setStudentMonitoring] = useState<StudentMonitoringM>(StudentMonitoringM.initial());
    const tabAddStudentHealthyService = new TabAddStudentHealthyService(dispatch);
    const [showDiaseModal, setShowDiaseModal] = useState(false);
    const [showAllergyModal, setShowAllergyModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>();


    useEffect(()=>{
        tabAddStudentHealthyService.handleGetStudentMonitoringData(
            studentReducer.studentForm.student.id!, setStudentMonitoringData
        );

        tabAddStudentHealthyService.handleGetStudentHealhtyObservationsData(
            studentReducer.studentForm.student.id!, setStudentHealhtyObservationsData
        );

    }, [refresh]);

    useEffect(()=>{
        // busca o semestre atual e converte para o mes de captura das avaliações
        tabAddStudentHealthyService.handleGetStudentMonitoringDate(setSelectedDate);
    }, []);

    useEffect(()=>{
        if(selectedDate){
            tabAddStudentHealthyService.handleGetStudentMonitoringByDate(
                studentReducer.studentForm.student.id!, setStudentMonitoring, selectedDate
            );
        }
    }, [selectedDate, refresh]);

    useEffect(()=>{
        tabAddStudentHealthyService.handleCalcIMC(studentMonitoring, setStudentMonitoring)
    }, [studentMonitoring.height, studentMonitoring.weight, refresh]);

    return(
        <TabAddStudentHealthyContainer>
            {showDiaseModal && <AddDiseaseModal setShowAddDiseaseModal={setShowDiaseModal} studentHealhtyObservation={studentHealhtyObservationsData?.rows[0]}/>}
            {showAllergyModal && <AddAllergyModal setShowAddAllergyModal={setShowAllergyModal} studentHealhtyObservation={studentHealhtyObservationsData?.rows[0]}/>}
            <h2 className='primaryBlackSemibold28-120'>Informações sobre a saúde do aluno</h2> 
            <TabAddStudentHealthyContent>
                <TabAddStudentHealthyWeeklyAssessment className="border-bottom">
                    <TabAddStudentHealthyWeeklyAssessmentColumn>
                        <span className="primaryBlackSemibold20-150">Curva de crescimento</span>
                        <LineChart data={studentMonitoringData?.rows} sex={studentReducer.studentForm.student.sex}/>
                        <TabAddStudentHealthyLegend>
                            <TabAddStudentHealthyLegendItem color="#B5AFFE">
                                <div></div>
                                <span className="primaryBlackMedium18-120">Abaixo do peso ideal</span>
                            </TabAddStudentHealthyLegendItem>
                            <TabAddStudentHealthyLegendItem color="#FEB0A0">
                                <div></div>
                                <span className="primaryBlackMedium18-120">Obesidade</span>
                            </TabAddStudentHealthyLegendItem>
                            <TabAddStudentHealthyLegendItem color="#A5E4AC">
                                <div></div>
                                <span className="primaryBlackMedium18-120"> Peso ideal</span>
                            </TabAddStudentHealthyLegendItem>
                            <TabAddStudentHealthyLegendItem color="#A19F9D">
                                <div></div>
                                <span className="primaryBlackMedium18-120"> Obesidade grave</span>
                            </TabAddStudentHealthyLegendItem>
                            <TabAddStudentHealthyLegendItem color="#F4E47F">
                                <div></div>
                                <span className="primaryBlackMedium18-120">Acima do peso ideal</span>
                            </TabAddStudentHealthyLegendItem>
                            <TabAddStudentHealthyLegendItem color="">
                                <div className="line-student"></div>
                                <span className="primaryBlackMedium18-120">Aluno</span>
                            </TabAddStudentHealthyLegendItem>
                        </TabAddStudentHealthyLegend>
                    </TabAddStudentHealthyWeeklyAssessmentColumn>
                    <TabAddStudentHealthyWeeklyAssessmentColumn>
                        <TabAddStudentHealthyWeeklyAssessmentSelect>
                            <label htmlFor="date-picker">
                                <span className="primaryBlackSemibold20-150">Avaliação semestral ({selectedDate ? format(selectedDate, 'LLL/yy', { locale: pt }) : ''})</span>
                                {/* Para ocultar os meses que não serão ulizados foi feito no css */}
                                <DatePicker
                                    className="inputDatePicker"
                                    date={selectedDate}
                                    setDate={setSelectedDate}
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker={true}
                                />
                                <img src={dropdown_arrow_icon} alt="Icone seta para baixo"/>
                            </label>
                            
                        </TabAddStudentHealthyWeeklyAssessmentSelect>
                            
                        <TabAddStudentHealthyWeeklyAssessmentForm autoComplete="off">
                            <Input
                                className='input-form'
                                id='weight'
                                name='weight'
                                type='decimal'
                                label='Peso (kg)'
                                placeholder='Peso (kg)'
                                value={studentMonitoring.weight ? studentMonitoring.weight?.toString() : ''}
                                onChange={(value) => tabAddStudentHealthyService.changeWeight(value, studentMonitoring, setStudentMonitoring)}
                            />

                            <Input
                                className='input-form'
                                id='height'
                                name='height'
                                type='text'
                                label='Altura (cm)'
                                placeholder='Altura (cm)'
                                value={studentMonitoring.height ? studentMonitoring.height?.toString() : ''}
                                onChange={(value) => tabAddStudentHealthyService.changeHeight(value, studentMonitoring, setStudentMonitoring)}
                            />

                            <Input
                                className='input-form'
                                id='idade'
                                name='idade'
                                disabled
                                type='text'
                                label='Idade'
                                placeholder='Idade'
                                value={studentMonitoring.completeAge || tabAddStudentHealthyService.handleCalcAge(selectedDate!, studentReducer.studentForm, studentMonitoring, setStudentMonitoring)}
                                onChange={(value) => {}}
                            />

                            <Input
                                className='input-form'
                                id='imc'
                                name='imc'
                                disabled
                                type='decimal'
                                label='IMC'
                                placeholder='IMC'
                                value={studentMonitoring.imc ? studentMonitoring.imc.toString().replace('.', '') : ''}
                                onChange={(value) => {}}
                            />

                            <ButtonPrimary onClick={()=>tabAddStudentHealthyService.submit(refresh, setRefresh, studentMonitoring, selectedDate!, studentReducer.studentForm.student.id!)}>
                                {studentMonitoring.id ? 'Editar ' : 'Registrar nova '} avaliação semestral
                            </ButtonPrimary>
                        </TabAddStudentHealthyWeeklyAssessmentForm>
                    </TabAddStudentHealthyWeeklyAssessmentColumn>
                  
                </TabAddStudentHealthyWeeklyAssessment>

                <h2 className='primaryBlackSemibold28-120'>Doenças e alergias</h2> 
                <TabAddStudentHealthyWeeklyAssessment>
                <TabAddStudentHealthyWeeklyAssessmentColumn>
                        <TabAddStudentHealthyWeeklyAssessmentTitle>
                            <span className="primaryBlackSemibold20-150">Doenças registradas</span>
                        </TabAddStudentHealthyWeeklyAssessmentTitle>

                        <TabAddStudentHealthyWeeklyAssessmentDiseases>
                            { 
                                studentHealhtyObservationsData?.rows[0]?.diseases?.map((disease, index)=>(
                                    <TabAddStudentHealthyWeeklyAssessmentDisease>
                                        <img src={pill_icon} alt={"Icone de pilula"}/>
                                        <span className="primaryBlackSemibold18-120">{disease.disease}</span>
                                        <img 
                                            className="remove" 
                                            src={minus_icon} 
                                            alt={"Icone de remover"} 
                                            onClick={()=>{tabAddStudentHealthyService.handleShowAlertExcluirDesease(index, studentHealhtyObservationsData?.rows[0], ()=>setRefresh(!refresh))}}
                                        />
                                    </TabAddStudentHealthyWeeklyAssessmentDisease>
                                ))
                            }

                            <TabAddStudentHealthyWeeklyAssessmentDisease className="new" onClick={()=>setShowDiaseModal(true)}>
                                <img src={plus_border_icon} alt={"Icone de adicionar"}/>
                                <span className="primaryBlackSemibold18-120">Nova doença</span>
                            </TabAddStudentHealthyWeeklyAssessmentDisease>
                        </TabAddStudentHealthyWeeklyAssessmentDiseases>

                    </TabAddStudentHealthyWeeklyAssessmentColumn>

                    <TabAddStudentHealthyWeeklyAssessmentColumn>
                        <TabAddStudentHealthyWeeklyAssessmentTitle>
                            <span className="primaryBlackSemibold20-150">Alergias registradas</span>
                        </TabAddStudentHealthyWeeklyAssessmentTitle>

                        <TabAddStudentHealthyWeeklyAssessmentDiseases>
                            { 
                                studentHealhtyObservationsData?.rows[0]?.allergies?.map((allergie, index)=>(
                                    <TabAddStudentHealthyWeeklyAssessmentDisease>
                                        <img src={pill_icon} alt={"Icone de pilula"}/>
                                        <span className="primaryBlackSemibold18-120">{allergie.allergie}</span>
                                        <img 
                                            className="remove" 
                                            src={minus_icon} 
                                            alt={"Icone de remover"} 
                                            onClick={()=>{tabAddStudentHealthyService.handleShowAlertExcluirAllergy(index, studentHealhtyObservationsData?.rows[0], ()=>setRefresh(!refresh))}}
                                        />
                                    </TabAddStudentHealthyWeeklyAssessmentDisease>
                                ))
                            }

                            <TabAddStudentHealthyWeeklyAssessmentDisease className="new" onClick={()=>{setShowAllergyModal(true)}}>
                                <img src={plus_border_icon} alt={"Icone de adicionar"}/>
                                <span className="primaryBlackSemibold18-120">Nova alergia</span>
                            </TabAddStudentHealthyWeeklyAssessmentDisease>
                        </TabAddStudentHealthyWeeklyAssessmentDiseases>

                    </TabAddStudentHealthyWeeklyAssessmentColumn>

                    
                </TabAddStudentHealthyWeeklyAssessment>

                <TabAddStudentHealthyWeeklyAssessmentColumnSpecial>
                    <TabAddStudentHealthyWeeklyAssessmentTitle>
                        <span className="primaryBlackSemibold20-150">Cuidados especiais</span>
                    </TabAddStudentHealthyWeeklyAssessmentTitle>
                        { 
                            studentHealhtyObservationsData?.rows[0]?.especialCares?.map(especialCare=>(
                                <span className="primaryBlackSemibold18-150">{especialCare.especialCare}</span>
                            ))
                        }
                </TabAddStudentHealthyWeeklyAssessmentColumnSpecial>
            </TabAddStudentHealthyContent>
        </TabAddStudentHealthyContainer>
    );
}