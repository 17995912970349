import styled from "styled-components";

export const OptionsMenuModalContainer = styled.div`
    position: absolute;
    right: 10px;
    width: 180px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--white-color);
    z-index: 3;
    padding: 12px 24px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);

    @media screen and (max-width: 1400px){
        width: 135px;
        gap: 6px;
        padding: 8px 18px;
    }
`;

export const OptionsMenuModalOptionItem = styled.li`
    display: flex;
    align-items: center;
    padding: 6px 0;
    text-decoration: none;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid var(--border-color);
    }
`;