import styled from "styled-components";

export const TextAreaContainer = styled.div`
    position: relative;

    label{
        margin-bottom: 16px;
    }
    
    textarea{
        width: 100%;
        margin-top: 12px;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        color: #45413C;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */ 

        &:focus{
            outline: 1px solid var(--primary-color);
        }
    }

    .prefix-container{
        position: absolute;
        top: 12px;
        left: 10px;
    }

    .prefix-input{
        padding-left: 38px !important;
    }

    

    @media screen and (max-width: 1400px) {
        textarea{ 
            margin-top: 9px;
            padding: 12px;
            font-size: 14px;
        }

        .prefix-container{
            top: 5px;
        }
    }
`;
