import { GradeM } from "../models/grade.model";

export class GradeController {
    
    async createAndUpdate(grade : GradeM){
        if(grade.id){
            return await GradeM.update(grade);
        }
        return await GradeM.create(grade);
    }

    async getAllByInstitute(search?: string){
        return await GradeM.getAllByInstitute(1, search);
    }

    async getById(id?: number){
        return await GradeM.getById(id);
    }
}