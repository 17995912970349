import { UserStudentM } from "../models/user-student.model";
import { StudentHealthObservationM } from "../models/student-health-observation.model";

export class StudentHealthyObservationController {
    
    async getAll(studentId: number){
        return await StudentHealthObservationM.getAll(studentId);
    }

    async createAndUpdate(studentHealthObservation : StudentHealthObservationM){
        if(studentHealthObservation.id){
            return await StudentHealthObservationM.update(studentHealthObservation);
        }
        return await StudentHealthObservationM.create(studentHealthObservation);
    }
}