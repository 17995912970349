import { Dispatch } from "redux";
import { studentDetailsSetStudentForm, studentSetTabSelectdItem, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { studentContentsNames, studentDetailsContents, studentTabToContent } from "../../../../features/types/student-slice.type";
import { GradeM } from "../../../../models/grade.model";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { GradeController } from "../../../../controllers/grade.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { StudentController } from "../../../../controllers/student.controller";
import { StudentM } from "../../../../models/student.model";
import { addMonths } from "date-fns";
import { UserStudentM } from "../../../../models/user-student.model";

export class TabStudentsService{
    dispatch : Dispatch;
    gradeController : GradeController;
    studentController : StudentController;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;

        this.gradeController = new GradeController();
        this.studentController = new StudentController();
    }

    public handleOpenDetailsPage(student? : UserStudentM){
        if(student){
            this.dispatch(studentDetailsSetStudentForm(student));
        }
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.STUDENT_DETAILS));
    }
    
    public handleSetSelectedTab(tabSelectedItem : string){
        this.dispatch(studentSetTabSelectdItem(tabSelectedItem));
        this.dispatch(studentSetTabSelectedContent(studentTabToContent[tabSelectedItem]))
    }

    public handleFindGradeByValue(value: string, gradeList? : GradeM[]){
        return gradeList?.find(grade => grade.id?.toString() === value);
    }

    public handleSelectedDate(selectedDate : Date | Date[], setDatesSearch : Function){
        if(Array.isArray(selectedDate)){
            selectedDate = selectedDate[0];
        }
        let endDate = new Date(selectedDate);
        endDate = addMonths(endDate, 1);
        setDatesSearch({ startDate : selectedDate, endDate });
    }

    public async handleSearchStudents(setStudentsData : Function, selectedGrade? : GradeM, search?: string){
        this.dispatch(generalSetIsLoading(true));
        await this.studentController.getAll(selectedGrade, search).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                data.rows.map((row : any)=>{
                    row.student.birthDate = new Date(row.student.birthDate+"T00:00:00.000Z");
                })
                setStudentsData(data);                
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleGetGrades(setGradesData : Function){
        this.dispatch(generalSetIsLoading(true));
        this.gradeController.getAllByInstitute().then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setGradesData(data);
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.SUCCESS,
                    text: 'Ocorreu um erro ao obter as turmas.',
                });
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleGradesToOptions(gradesList? : GradeM[]){
        const options = gradesList?.map(grade=>(
            {value: grade.id?.toString()!, content: grade.name!}
        ));

        options?.push({
            value: ' ', content : 'Todas'
        })
    
        return options;
    }

}
