import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { foodSetEvaluationSelectedConsumables, foodSetEvaluationSelectedFoodType, foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { FoodTypeM } from "../../../../models/food-type.model";
import { foodContentsNames } from "../../../../features/types/food-slice.type";
import { StudentM } from "../../../../models/student.model";
import { TodaysMenuController } from "../../../../controllers/todays-menu.controller";
import { MealConsumableM } from "../../../../models/meal-consumable.model";
import { StudentMealAcceptanceI } from "../../../../models/interfaces/student-meal-acceptance.interface";

export class TabStudentEvaluationEvaluationService {
    dispatch : Dispatch;
    todaysMenuController : TodaysMenuController;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        this.todaysMenuController = new TodaysMenuController();
        
    }

    public handleSelectConsumableEmotcion(
        consumables: {
            consumables : StudentMealAcceptanceI[],
            drinks : StudentMealAcceptanceI[]
        }, 
        consumableIndex : number,
        eatLevel : number,
    ){
        const newConsumable = [...consumables.consumables];
        newConsumable[consumableIndex].eatLevel = eatLevel;

        this.dispatch(foodSetEvaluationSelectedConsumables({
            ...consumables,
            consumables : newConsumable
        }));

    }

    public handleSelectDrinkEmotcion(
        consumables: {
            consumables : StudentMealAcceptanceI[],
            drinks : StudentMealAcceptanceI[]
        }, 
        drinkIndex : number,
        eatLevel : number,
    ){
        const newDrinks = [...consumables.drinks];
        newDrinks[drinkIndex].eatLevel = eatLevel;

        this.dispatch(foodSetEvaluationSelectedConsumables({
            ...consumables,
            drinks : newDrinks
        }));

    }

    public async backPage(
        consumableIndex : number,
        drinkIndex : number,
        setConsumableIndex : Function, 
        setDrinkIndex : Function,
    ){
        if(drinkIndex !== 0){
            setDrinkIndex(drinkIndex-1);
        }else if(consumableIndex !== 0){
            setConsumableIndex(consumableIndex-1);
        }else{
            this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_CONSUMABLE));
        }
    }
   
    public async handleSubmit(
        consumableIndex : number,
        drinkIndex : number,
        countConsumables : number,
        countDrinks : number,
        setConsumableIndex : Function, 
        setDrinkIndex : Function,
        currentPageCount : number
    ){
        if(consumableIndex < countConsumables)
            setConsumableIndex(consumableIndex+1);
        else if(drinkIndex < countDrinks){
            setDrinkIndex(drinkIndex+1);
        }

        if((currentPageCount === 4 && drinkIndex+1 === countDrinks) || (consumableIndex+1 >= countConsumables && countDrinks === 0)){
            this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_FINAL));
        }
    }
    
    public async handleGetTodayMenu(setTodayMenu : Function, selectedStudent? : StudentM ){
        this.dispatch(generalSetIsLoading(true));
        await this.todaysMenuController.getByStudent(selectedStudent!).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){
                setTodayMenu(data);           
            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
    }

}