import { Dispatch } from "redux";
import { studentDetailsSetStudentForm, studentDetailsSetTabSelectdItem, studentDetailsSetTabSelectedContent, studentSetTabSelectdItem, studentSetTabSelectedContent } from "../../../../features/student-slice.feature";
import { studentContentsNames, studentDetailsTabToContent, studentTabToContent } from "../../../../features/types/student-slice.type";
import { UserStudentM } from "../../../../models/user-student.model";

export class StudentDetailsService{
    dispatch : Dispatch;

    constructor(dispatch : Dispatch){
        this.dispatch = dispatch;
    }
    
    public handleSetSelectedTab(tabSelectedItem : string){
        this.dispatch(studentDetailsSetTabSelectdItem(tabSelectedItem));
        this.dispatch(studentDetailsSetTabSelectedContent(studentDetailsTabToContent[tabSelectedItem]))
    }

    public handleCloseToGrades(){
        this.dispatch(studentDetailsSetStudentForm(UserStudentM.initial()));
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.ADD_GRADE));
    }

    public handleCloseToStudents(){
        this.dispatch(studentDetailsSetStudentForm(UserStudentM.initial()));
        this.dispatch(studentSetTabSelectedContent(studentContentsNames.STUDENTS_LIST));
    }
}
