import styled from "styled-components";

export const TabAnnouncementsContainer = styled.section`
    width: 100%;

   
    @media screen and (max-width: 1400px){
        
    }
`;



export const TabAnnouncementsButtonBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    
    button{
        width: auto;
        display: inline-flex !important;
        margin: 24px 0;
        padding: 10px 12px;
        gap: 5px;
    }

    @media screen and (max-width: 1400px){

        button{
            margin: 18px 0;
        }
        
        .add-item{
            width: 180px;
             margin: 0;
        }

        button{
            max-height: 42px;
            font-size: 14px !important;
            padding: 8px;
        }

        .info-more{
            margin: 0 0 0 21px !important;
        }
    }
`;

export const InputPhoto = styled.div`
    margin-top: 16px;
    width: 794px;
`;

export const TabAnnouncementsSearchBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 0;

    .inputSearch{
        flex: 5;
    }

    .inputDropsContainer{
        flex: 1;
        display: flex;
        gap: 24px;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    .date-picker{
        height: 55px;
    }

    .input-style{
        max-height: 56px !important;
        height: 54px;
        margin-top: 10px;
        border-radius: 4px;

    }

    .dropOptionsStyle{
        max-height: 120px !important;
        height: auto !important;
    }
    

    @media screen and (max-width : 1400px){
        .date-picker{
            height: 40px;
        }

        .input-style{
            margin-top: 9px;
            max-height: 42px;
            height: 42px;

        }

        .dropOptionsStyle{
            max-height: 120px !important;
            height: auto !important;
        }

    }
`;

export const TabAnnouncementsGrid = styled.div`
    width: 100%;
    min-height: 400px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0px;
    justify-content: left;
    
    

    @media screen and (max-width : 1400px){
       
    
    }

    @media screen and (max-width : 1024px){ 
        grid-template-columns: auto auto;
    }
`;


export const TabAnnouncementsGridItem = styled.div`
    border-radius: 8px;
    width: 349px;
    height: 484px; 
    border-radius: 8px;
    border: 1.5px solid #E0E0E0;
    background: #FFF;
    margin : 10px ;

    figure{
        margin: 0em;
        position: relative;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 100%;
        height: 172px;
    }
        
    figure img {
        position: absolute;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        left: 50%;
        top: 50%;
        object-fit: cover;
        transform: translate(-50%, -50%);
        /* border-radius: 0%; */
    }



    
    @media screen and (max-width : 1400px){
        width: 314px;
        figure{
            height: 172px;
        }
    }

    
 

`;


export const TabAnnouncementsGridItemBody = styled.div`

    & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(484px - 172px);
    }

    @media screen and (max-width : 1400px){


    }
`;

export const TabAnnouncementsGridItemSection = styled.div`

    & {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;
    }
    
    & .card-title-label {
        color: #45413C;
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        height: 27px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .card-date-label {
        display: flex;
        align-items: center;
        color: #6F6A65;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        margin-top: 8px;
        img {
            margin-right: 7px;
        }
    }
    
    & .card-author-label {
        margin-top: 12px;
        color: #FF6242;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
    }

    & .card-description{
        color: #45413C;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        height: 95px;
        /* white-space: nowrap; */
        display: -webkit-box;
        overflow : hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;  
    }
    
    & button {
        display: flex;
        height: 50px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        margin-bottom: 16px;
    }

`
