import { PaymentRequestM } from "../models/payment-request.model";
import { StudentM } from "../models/student.model";

export class PaymentRequestController {

    async getByStudent(studentId : number){
        return await PaymentRequestM.getAllByStudent(studentId);
    }

    async createAndUpdate(paymentRequest : PaymentRequestM){
        if(paymentRequest.id){
            return await PaymentRequestM.update(paymentRequest);
        }
        return await PaymentRequestM.create(paymentRequest);
    }
    
}