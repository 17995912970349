import { MutableRefObject } from "react";

export class SelectService{
    
    handleClickOutside(
        event : MouseEvent, 
        selectRef : any, 
        setShowOptions : (isShow: boolean) => void
    ){
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setShowOptions(false);
        }
    };
    
    handleAddEventListner(
        selectRef : MutableRefObject<null>, 
        setShowOptions : (isShow: boolean) => void
    ){
        document.addEventListener('click', (event)=>this.handleClickOutside(event, selectRef, setShowOptions));
    };
    
    handleRemoveEventListner(
        selectRef : MutableRefObject<null>, 
        setShowOptions : (isShow: boolean) => void
    ){
        document.removeEventListener('click', (event)=>this.handleClickOutside(event, selectRef, setShowOptions));
    };

}
