import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserSchoolWorkersDataStateT, UserSchoolWorkersStateT, settingsContentsNames, settingsTabToContent } from './types/settings-slice.type';

const initialState : UserSchoolWorkersStateT = {
    tabSelectedItem : 'Administradores',
    tabSelectedContent : 'school-workers',
} 

export const settingsSlice = createSlice({
   name: 'settings',
   initialState,
   reducers: {
        settingsSetTabSelectdItem : (state, action : PayloadAction<string>)=>{
            state.tabSelectedItem = action.payload;
        },
        settingsSetTabSelectedContent: (state, action : PayloadAction<string>)=>{
            state.tabSelectedContent = action.payload;
        },
        settingsSetUsersSchoolWorkersData: (state, action : PayloadAction<UserSchoolWorkersDataStateT>)=>{
            state.schoolWorkersData = action.payload;
        },
       
   } 
});

export const { 
    settingsSetTabSelectdItem, 
    settingsSetTabSelectedContent,
    settingsSetUsersSchoolWorkersData,
} = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;