import React from 'react';
import { OptionsMenuModalContainer, OptionsMenuModalOptionItem } from './styles/options-menu-modal.styled';
import { OptionsMenuModalT } from './types/options-menu-modal.type';

export const OptionsMenuModal = ({options} : OptionsMenuModalT) => {
  return (
    <OptionsMenuModalContainer >
        <ul>
            {options?.map(option=>(
                <OptionsMenuModalOptionItem>
                    <a href="javascript:void(0);" onClick={option.onClick} className='primaryBlackNormal16-150'>{option.value}</a>
                </OptionsMenuModalOptionItem>
            ))}
        </ul>
    </OptionsMenuModalContainer>
  );
};