import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { ResponsibleController } from "../../../../controllers/responsible.controller";
import { responseStatus } from "../../../../api/api-request.service";
import { clearCPF, formatCPF, generateErrorMessage, showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { UserResponsibleM } from "../../../../models/user-responsible.model";
import { ResponsibleI } from "../../../../models/interfaces/responsible.interface";
import { StudentM } from "../../../../models/student.model";

export class TabAddStudentResponsiblesService {
    dispatch: Dispatch;
    responsibleController: ResponsibleController;
    constructor(dispatch: Dispatch) {
        this.dispatch = dispatch;
        this.responsibleController = new ResponsibleController();
    }

    private async handleSearchResponsibleByCPF(
        index: number,
        setResponsibleData: Function,
        responsiblesData?: { count: number, rows: UserResponsibleM[] },

    ) {
        this.dispatch(generalSetIsLoading(true));
        await this.responsibleController.getByCPF(clearCPF(responsiblesData?.rows[index].cpf!)).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                if (data.count > 0) {
                    const responsibles = [...responsiblesData!.rows];
                    responsibles[index] = data.rows[0];
                    setResponsibleData({
                        ...responsiblesData,
                        rows: responsibles
                    });
                }


            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        });
    }

    public handleChangeCPF(
        value: string,
        index: number,
        setResponsiblesData: Function,
        setInputDisables: Function,
        responsiblesData?: { count: number, rows: UserResponsibleM[] },
        inputDisableds?: boolean[]
    ) {
        const responsibles = [...responsiblesData!.rows];
        responsibles[index].cpf = formatCPF(value);

        if (inputDisableds) {
            if (responsibles[index].cpf.length === 14) {
                inputDisableds[index] = false;
                this.handleSearchResponsibleByCPF(index, setResponsiblesData, responsiblesData);
            } else {
                inputDisableds[index] = true;
            }
        }

        setInputDisables(inputDisableds);

        setResponsiblesData({
            ...responsiblesData,
            rows: responsibles
        });
    }

    public handleChangeName(
        value: string,
        index: number,
        setResponsiblesData: Function,
        responsiblesData?: { count: number, rows: UserResponsibleM[] },
    ) {
        const responsibles = [...responsiblesData!.rows];
        responsibles[index].responsible.name = value;
        responsibles[index].name = value;
        setResponsiblesData({
            ...responsiblesData,
            rows: responsibles
        });
    }

    private validateResponsibleForm(index: number, responsibleData: UserResponsibleM) {
        const fieldsErrors = [];

        if (!responsibleData.name && !responsibleData.responsible.name) {
            fieldsErrors.push('Nome');
        }

        if (!responsibleData.cpf) {
            fieldsErrors.push('CPF');
        }

        if (!responsibleData.email) {
            fieldsErrors.push('Email');
        }

        if (!responsibleData.id && !responsibleData.password) {
            fieldsErrors.push('Senha');
        }

        if (fieldsErrors.length !== 0) {
            const errorMessage = generateErrorMessage(fieldsErrors, `no Responsável ${index + 1}`);
            showToast(this.dispatch, {
                type: toastTypes.ERROR,
                text: errorMessage,
            });
            return false;
        }
        return true;
    }

    public handleSaveIsDisables(responsibles?: UserResponsibleM[]) {
        let isDisabled = true;
        if (responsibles)
            for (const responsible of responsibles) {
                if (responsible.cpf || responsible.email || responsible.name) {
                    isDisabled = false;
                }
            }
        return isDisabled;

    }

    public async handleSubmit(student: StudentM, responsibles: UserResponsibleM[], passwords: string[], setPasswords: Function, reload: boolean, setReload: Function) {
        let errors: string[] = [];
        let index = 0;
        for (const responsible of responsibles) {

            if (responsible.cpf || responsible.email || responsible.name) {
                responsible.password = passwords[index] || responsible.password;
                responsible.cpf = clearCPF(responsible.cpf);

                if (this.validateResponsibleForm(index, responsible)) {
                    this.dispatch(generalSetIsLoading(true));
                    if (!responsible.responsible.students?.find(studentFind => studentFind.id === student.id)) {
                        responsible.responsible.students?.push(student);
                    }
                    await this.responsibleController.createAndUpdate(responsible).then(response => {
                        const { status, data } = response;
                        console.log(data)
                        if (status !== responseStatus.SUCCESS) {
                            errors.push(`Responsável ${index + 1}: ${data?.response?.data?.message || data}` );
                        }
                    }).finally(() => {
                        this.dispatch(generalSetIsLoading(false));
                        
                        if (index === 1) {
                            if (errors.length > 0) {
                                showToast(this.dispatch, {
                                    type: toastTypes.ERROR,
                                    text: errors.join('<br/>'),
                                });
                            } else {
                                showToast(this.dispatch, {
                                    type: toastTypes.SUCCESS,
                                    text: 'Responsáveis salvos com sucesso.',
                                });
                                setPasswords([]);
                                setReload(!reload);
                            }
                        }

                    });
                }
            }
            index++;
        }

    }

    public generateRandomPassword() {
        const length = 7;
        const lettersLowerCase = "abcdefghijklmnopqrstuvwxyz";
        const lettersUpperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        let password = "@";

        // Adiciona uma letra minúscula aleatória
        const randomLetterIndex = Math.floor(Math.random() * lettersLowerCase.length);
        password += lettersLowerCase.charAt(randomLetterIndex);

        // Adiciona uma letra maiúscula aleatória
        const randomUpperCaseIndex = Math.floor(Math.random() * lettersUpperCase.length);
        password += lettersUpperCase.charAt(randomUpperCaseIndex);

        // Adiciona um número aleatório
        const randomNumberIndex = Math.floor(Math.random() * numbers.length);
        password += numbers.charAt(randomNumberIndex);

        // Preenche o restante da senha com caracteres aleatórios
        for (let i = 3; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * (lettersLowerCase.length + lettersUpperCase.length + numbers.length));
            if (randomIndex < lettersLowerCase.length) {
                password += lettersLowerCase.charAt(randomIndex);
            } else if (randomIndex < lettersLowerCase.length + lettersUpperCase.length) {
                password += lettersUpperCase.charAt(randomIndex - lettersLowerCase.length);
            } else {
                password += numbers.charAt(randomIndex - lettersLowerCase.length - lettersUpperCase.length);
            }
        }

        // Embaralha a senha
        password = this.shuffleString(password);

        return password;
    }

    private shuffleString(password: string) {
        const array = password.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    }

    public handleGeneratePassword(index: number, passwords: string[], setPasswords: Function) {
        const newPasswords = [...passwords];
        newPasswords[index] = this.generateRandomPassword();
        setPasswords(newPasswords);
    }

    public handleChangeEmail(
        value: string,
        index: number,
        setResponsiblesData: Function,
        responsiblesData?: { count: number, rows: UserResponsibleM[] },
    ) {
        const responsibles = [...responsiblesData!.rows];
        responsibles[index].email = value;
        setResponsiblesData({
            ...responsiblesData,
            rows: responsibles
        });
    }

    public handleChangePassword(
        value: string,
        index: number,
        setPasswords: Function,
        passwords?: string[],
    ) {
        passwords![index] = value.replaceAll('******', '');
        setPasswords(passwords);
    }

    public async handleGetResponsibles(studentId: number, setResponsiblesData: Function, setInputDisables: Function) {
        this.dispatch(generalSetIsLoading(true));
        await this.responsibleController.getAll(studentId).then(response => {
            const { status, data } = response;
            if (status === responseStatus.SUCCESS) {
                if (data.count !== 0) {
                    setResponsiblesData(data);
                    if (data.count === 1) {
                        setInputDisables([false, true]);
                        const responsibles = data.rows;
                        responsibles.push(UserResponsibleM.initial());
                        setResponsiblesData({ ...data, rows: responsibles });
                    } else if (data.count === 2) {
                        setInputDisables([false, false]);
                        setResponsiblesData(data);
                    }
                } else {
                    setResponsiblesData({ count: 0, rows: [UserResponsibleM.initial(), UserResponsibleM.initial()] });
                }
            } else {
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                });
            }
        }).finally(() => {
            this.dispatch(generalSetIsLoading(false));
        })
    }
}