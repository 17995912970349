import styled from "styled-components";

export const StudentScoreModalContainer = styled.div`
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-opacity-color);
   
`;

export const StudentScoreModalContent = styled.div`
    background-color: var(--white-color);
    width: 700px;
    border: 1px solid var(--grey-light-2-color);
    border-radius: 8px;

    @media screen and (max-width : 1400px){
        width: 600px;
    }
`;

export const StudentScoreModalTitle = styled.div`
    padding: 32px 0 16px 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);

    @media screen and (max-width : 1400px){
        padding: 24px 0 12px 0;
    }

`;

export const StudentScoreModalDescription = styled.div`
    margin: 32px 47px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .food-item-left{
        display: flex;
        flex-direction: column;
        gap: 8px;
        span{
            color: #45413C;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 19.2px */
        }
    }

    .title{
        font-weight: bold;
        color: #45413C;
    }

    .food-list{
            margin-top: 16px;
            display: flex;
            padding: 16px 12px;
            align-items: flex-end;
            align-content: flex-end;
            gap: 16px 61px;
            width: 270px;
            flex-wrap: wrap;

            border-radius: 16px;
            border: 1px solid var(--contorno-cinza-usurio-aluno, #E4E4E4);

            .food-item{
                display: flex;
                width: 270px;
                justify-content: space-between;

                .food-item-left{
                    img{
                        width: 90px;
                        height: 90px;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }

                .food-item-right{
                    span{
                        color: #45413C;
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 120%; /* 19.2px */
                    }
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    
                    img{
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 8px;
                    }

                    .active{
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        height: 90px;
                        border-radius: 6px;
                        border: 2px solid #46C964;
                        background: rgba(77, 202, 89, 0.08);
                    }
                }
            }
       }

    

    .container-top{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .info-item{
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        span{
            font-family: Inter;
        }

        
    }

    @media screen and (max-width : 1400px){
        margin: 24px 35px;
    }
    
`;

export const StudentScoreModalActions = styled.div`
    margin: 0 47px 20px 47px !important;
    button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding:16px;
    }

    @media screen and (max-width : 1400px){
        margin: 0 35px;

        span{
            padding:12px;
        }
    }

`;