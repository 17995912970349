import React from 'react';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { ButtonPrimary } from '../../components/button-primary.component';
import { PageCount } from '../../components/page-count.component';
import { TabStudentEvaluationSuccessService } from './services/tab-student-evaluation-success.service';
import { TabStudentEvaluationSuccessButtonBar, TabStudentEvaluationSuccessContainer, TabStudentEvaluationSuccessContent, TabStudentEvaluationSuccessTop } from './styles/tab-student-evaluation-success.styled';

import checkmark_circle_icon from '../../../assets/icons/checkmark_circle.svg';
import { LocalToken } from '../../../utils/local-token';
import { useNavigate } from 'react-router-dom';
export const TabStudentEvaluationSuccessPage = () => {
    const dispatch = useDispatch();
    const userType = LocalToken.getStoredUserType();

    const foodReducer = useAppSelector(state => state.foodReducer);
    const tabFoodStudentEvaluationFinalService = new TabStudentEvaluationSuccessService(dispatch);

    return (
        <TabStudentEvaluationSuccessContainer>
            <TabStudentEvaluationSuccessTop>
                <div></div>
                <PageCount 
                    size={5} 
                    current={5} 
                    disabled={
                        foodReducer.evaluationSelectedConsumables.consumables.length === 0 ?
                        3 :
                        foodReducer.evaluationSelectedConsumables.drinks.length === 0 ?
                        4 :
                        undefined
                    }
                />
            </TabStudentEvaluationSuccessTop>

            <TabStudentEvaluationSuccessContent>
                <img src={checkmark_circle_icon} alt={"Icone de sucesso"}/>
                <span className='primaryBlackBold32-150'>Muito obrigado por sua avaliação!</span>
            </TabStudentEvaluationSuccessContent>
            
            
            <TabStudentEvaluationSuccessButtonBar>
                <ButtonPrimary 
                    onClick={()=>{tabFoodStudentEvaluationFinalService.handleSubmit(userType)}}
                >
                    Fechar
                </ButtonPrimary>
            </TabStudentEvaluationSuccessButtonBar>
        </TabStudentEvaluationSuccessContainer>
    );
};