import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../hooks";
import { StudentScoreService } from "./services/students-score.service";
import { HistoricEvaluationsAction, HistoricEvaluationsContainer } from "./styled/historic-evaluations.styled";
import { useEffect, useState } from "react";
import { Table } from "../../components/table.component";
import { StudentScoreM } from "../../../models/student-score.model";
import arrow_right_icon from '../../../assets/icons/arrow_right.svg';
import { StudentScoreModal } from "./student-score-modal.page";
import moment from "moment";

export const HistoricEvaluations = ()=>{
    const dispatch = useDispatch();
    const [avaliationData, setAvaliationData] = useState<{count: number, rows: StudentScoreM[]}>()
    const studentScoreService = new StudentScoreService(dispatch);
    const studentReducer = useAppSelector(state => state.studentReducer);
    const [showModal, setShowModal] = useState<StudentScoreM | null>(null);

    useEffect(()=>{
        studentScoreService.handleGetByStudentId(studentReducer.studentForm.student.id, setAvaliationData)
    }, []);

    return(
        <HistoricEvaluationsContainer>
           <HistoricEvaluationsContainer>
                <Table className='border-none'>
                    <thead className='primaryBlackSemibold20-150'>
                        <th>Refeição</th>
                        <th>Data</th>
                        <th>Aluno</th>
                        <th></th>
                    </thead>
                    {avaliationData && avaliationData.count > 0 ?
                        <tbody className='primaryBlackMedium18-150'>
                            {avaliationData?.rows.map((studentScore : StudentScoreM)=>(
                                <tr className='full-padding'>
                                    <td>{studentScore.fullMeal?.foodType?.name}</td>
                                    <td>{moment(studentScore.createdAt).format('DD/MM/yyyy')}</td>
                                    <td>{studentScore.student?.name}</td>
                                    <td>
                                        <HistoricEvaluationsAction 
                                            className='primaryPrimarySemibold16-150'
                                            onClick={()=>{setShowModal(studentScore)}}
                                        >
                                            Ver avaliação
                                            <img src={arrow_right_icon} alt='Icone seta para direita'/>
                                        </HistoricEvaluationsAction>
                                    </td>
                                </tr>
                            ))}
                            
                        </tbody> :
                        <tbody>
                            <div className='empty-table mt'>
                                <span className='primaryBlackSemibold20-150'>Nenhuma avaliação cadastrada</span> 
                            </div>
                        </tbody>
                    }
                </Table>

                {showModal && <StudentScoreModal studentScore={showModal} onClose={()=>setShowModal(null)}/> }
            </HistoricEvaluationsContainer>
        </HistoricEvaluationsContainer>
    );
}