import styled from "styled-components";



export const ShowModal = styled.div`

    margin: 24px;
    display: flex;
    flex-direction: column;

    #cargoNameSuggestions {
        width: 521px !important;
        margin-bottom: 30px;
    }

    .input-form {
        width: 521px !important;
        margin-bottom: 20px;
    }
    
    .input-form > input{
        height: 54px !important;
        border: 2px solid #E4E4E4 !important;
        color: rgba(69, 65, 60, 0.80);
        font-family: 'Inter';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px; 
    }
    
    .input-form > input:disabled {
        border: 2px solid #E4E4E4 !important;
        color: rgba(69, 65, 60, 0.80) !important;
    }

    h3 {
        margin-bottom: 40px;
    }

    .admin-save-button {
        margin-top: 20px;
        width: 521px;
        height: 56px;
        padding: 20px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }


`;


export const SectionSeparator = styled.div`
    background: #E4E4E4;
    /* width: 1px; */
    height: 1px;
    position: relative;
    display: block;
    /* transform: translateY(-25px); */
    width: auto;
    /* margin-left: 30px; */
`;

export const ModalBackground = styled.div`

        position: fixed;
        background: rgb(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow-y: scroll;

`;

export const ModalBox = styled.div`
        padding-top: 5vh;
        padding-bottom: 5vh;
        /* height: 100%; */
        display: flex;
        align-items: center;
        justify-content: center;


        @media screen and (max-width : 1400px){ 
            
            margin-top: 20vh;
            margin-bottom: 0vh;
        }
`




export const ProfilePic = styled.label`
    width: 83px;
    height: 83px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    cursor: pointer;

    /* border: 1px solid black; */
    margin-bottom: 20px;

    #image_file {
        display: block;
        width: 83px;
        height:83px;
        border-radius: 999px;
    }


    & input[type=file] {
        display: none;
        visibility: hidden;
    }

    & .icon {
        opacity: 80%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        
        /* border: 1px solid black; */
        background: #FEFEFE;
        color: #CACACA;
    }

    & .icon:hover {
        opacity: 100%;
    }

`;



export const ModalHeader = styled.div`
    color: #45413C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */ 

    margin-top: 32px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

`

export const ShowModalContainer = styled.div`
   border: 1px solid #E4E4E4;
        border-radius: 16px;
        min-height: 300px;
        height: fit-content;
        width: fit-content;
        background: white;
        display: flex;
        justify-content: center;
        
        `


export const TabSettingsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;

    height: fit-content;
`;