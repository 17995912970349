import React, { useEffect, useState } from 'react';
import { StudentTabCanteenContainer, StudentTabCanteenContent, StudentTabCanteenHeader, StudentTabCanteenHeaderButton, StudentTabCanteenHeaderButtons, StudentTabCanteenPaymentContent, StudentTabCanteenPaymentItem } from "./styled/student-tab-canteen.styled";

import { useDispatch } from 'react-redux';
import { DatePicker } from '../../components/date-picker.component';
import { DatePickerMultiT } from '../../components/types/datepicker.type';
import { StudentTabCanteenService } from './services/student-tab-canteen.service';
import { ButtonSecondary } from '../../components/button-secondary.component';

import upload_icon from '../../../assets/icons/upload.svg';
import check_white_icon from '../../../assets/icons/check_white.svg';
import document_icon from '../../../assets/icons/document.svg';
import { ButtonPrimary } from '../../components/button-primary.component';
import { useAppSelector } from '../../../hooks';
import { PaymentRequestM } from '../../../models/payment-request.model';
import { AlertDialog } from '../../components/alert-dialog.component';
import { format } from 'date-fns';
import { baseUrl } from '../../../api/api';

export const StudentTabCanteen = ()=>{
    const [datesSearch, setDatesSearch] = useState<DatePickerMultiT>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [paymentRequestsData, setPaymentRequestsData] = useState<{count: number, rows: PaymentRequestM[]}>();

    const studentReducer = useAppSelector(state=>state.studentReducer);
    const generalReducer = useAppSelector(state=>state.generalReducer);

    const dispatch = useDispatch();
    const studentTabCanteenService = new StudentTabCanteenService(dispatch);

    useEffect(()=>{
        studentTabCanteenService.handleSelectedDate(selectedDate, setDatesSearch);
    }, []);

    useEffect(()=>{
        studentTabCanteenService.handleGetPaymentRequestByStudentId(studentReducer.studentForm.id!, setPaymentRequestsData);
    }, []);

    return(
        <StudentTabCanteenContainer>
            { generalReducer.alertDialog.isOpen && <AlertDialog/> }
            <h2 className='primaryBlackSemibold28-120'>Informações Relacionadas a Cantina</h2>
            <StudentTabCanteenHeader>
                <DatePicker
                    label='Periodo Atual'
                    dates={datesSearch}
                    setDate={setSelectedDate}
                    selectsRange={true}
                    disabled
                />

                <StudentTabCanteenHeaderButtons>

                    <StudentTabCanteenHeaderButton>
                        <span className='primaryBlackSemibold18-150'>Boleto de pagamento</span>
                    
                        <input id="document" hidden type="file" accept=".pdf" onChange={(e)=>{studentTabCanteenService.hanldeFileOnChange(e, studentReducer.studentForm.student, setPaymentRequestsData, datesSearch)}}/>
                        <ButtonSecondary onClick={()=>studentTabCanteenService.handleClickInputFile()}>
                            <img src={upload_icon} alt="Upload de boleto"/>
                            Anexar boleto de pagamento
                        </ButtonSecondary>
                    </StudentTabCanteenHeaderButton>

                    <StudentTabCanteenHeaderButton>
                        <span className='primaryBlackSemibold18-150'>Pagamento liberado</span>
                        <ButtonPrimary 
                            disabled={!paymentRequestsData?.rows[0]?.isOverdue}
                            onClick={()=>studentTabCanteenService.handleChangePayment(
                                {
                                    ...paymentRequestsData!.rows[0],
                                    isOverdue: false
                                },
                                studentReducer.studentForm.student,
                                setPaymentRequestsData
                            )}
                        
                        >
                            <img src={check_white_icon} alt="Upload de boleto"/>
                            Pagamento liberado
                        </ButtonPrimary>
                    </StudentTabCanteenHeaderButton>
                </StudentTabCanteenHeaderButtons>
            </StudentTabCanteenHeader>
            { paymentRequestsData && paymentRequestsData?.rows?.length > 0 &&
                (
                <StudentTabCanteenContent>
                    <span className='primaryBlackSemibold20-120'>Histórico de boletos enviados</span>
                    {paymentRequestsData?.rows?.map(paymentRequest=>(
                        <StudentTabCanteenPaymentItem target='blank' href={`${baseUrl}/${paymentRequest.media.content}`}>
                            <StudentTabCanteenPaymentContent  className={paymentRequest.isOverdue ? 'active' : ''} >
                                <img src={document_icon} alt="Icone de documento"/>
                                <span className='primaryBlackSemibold18-120'>{paymentRequest.media.name}.pdf</span>
                            </StudentTabCanteenPaymentContent>
                            <span className='primaryBlackMedium16-120'>Enviado {paymentRequest.createdAt ? format(paymentRequest.createdAt, 'dd/MM/yyyy'): ''}</span>
                        </StudentTabCanteenPaymentItem>
                    ))}

                    <span 
                        className='primaryPrimarySemibold20-150-underline'
                        onClick={()=>studentTabCanteenService.handleChangePayment(
                            {
                                ...paymentRequestsData.rows[0],
                                isOverdue: true
                            },
                            studentReducer.studentForm.student,
                            setPaymentRequestsData
                        )}
                    >
                        Enviar aviso sobre inadimplência na Cantina
                    </span>
                </StudentTabCanteenContent>
            )}
        </StudentTabCanteenContainer>
    );
}