import styled from "styled-components";

export const TabAddStudentResponsibleContainer = styled.div`

`;



export const TabAddStudentResponsibleContent = styled.div`
    .submitButton{
        margin-top: 16px;
        padding: 16px 32px;
    }
`;  

export const TabAddStudentResponsibleResponsibleContainer = styled.div`
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    padding: 32px 0;
`;

export const TabAddStudentResponsibleResponsible = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 552px;
    padding-bottom: 16px;

    button{
        width: 100%;
        padding: 16px;
        margin: 16px 0;
        justify-content: center;
    }

    @media screen and (max-width: 1400px){
        width: 414px ;
    }
`;

