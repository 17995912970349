import { ResponsibleM } from "../models/responsible.model";
import { UserResponsibleM } from "../models/user-responsible.model";

export class ResponsibleController {
    
    async getAll(studentId : number){
        return await UserResponsibleM.getAll(studentId);
    }
    
    async getByCPF(cpf: string){
        return await UserResponsibleM.getByCPF(cpf);
    }

    async createAndUpdate(responsible : UserResponsibleM){
        if(responsible.responsible.id){
            return await UserResponsibleM.update(responsible);
        }
        return await UserResponsibleM.create(responsible);
    }
}