import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { ActionBack } from '../../components/action-back.component';
import { ButtonPrimary } from '../../components/button-primary.component';
import { TodaysMenuM } from '../../../models/todays-menu.model';
import { baseUrl } from '../../../api/api';
import { PageCount } from '../../components/page-count.component';

import face_confused_icon from '../../../assets/icons/face_confused.svg';
import face_happy_icon from '../../../assets/icons/face_happy.svg';
import face_sad_icon from '../../../assets/icons/face_sad.svg';
import face_smiling_icon from '../../../assets/icons/face_smiling.svg';
import arrow_hight_icon from '../../../assets/icons/arrow_right.svg';
import { TabStudentEvaluationFinalService } from './services/tab-student-evaluation-final.service';
import { TabStudentEvaluationFinalButtonBar, TabStudentEvaluationFinalContainer, TabStudentEvaluationFinalContent, TabStudentEvaluationFinalEmotions, TabStudentEvaluationFinalEmotionsItem, TabStudentEvaluationFinalSliderContainer, TabStudentEvaluationFinalSliderContent } from './styles/tab-student-evaluation-final.styled';
import { TabStudentEvaluationConsumableTop } from './styles/tab-student-evaluation-consumable.styled';
import { TabStudentEvaluationFinalConsumables, TabStudentEvaluationFinalConsumablesItem } from './styles/tab-student-evaluation-evaluation.styled';

export const TabStudentEvaluationFinalPage = () => {

    const [selectedEmotion, setSelectedEmotion] = useState<any>();

    const emotions = [
        {
            description : "Não gostei",
            icon : face_confused_icon,
            width : 12,
            score : 1
        },
        {
            description : "Gostei pouco",
            icon : face_sad_icon,
            width : 37,
            score : 2
        },
        {
            description : "Gostei",
            icon : face_smiling_icon,
            width : 62,
            score: 3
        },
        {
            description : "Gostei Muito!",
            icon : face_happy_icon,
            width : 100,
            score : 4
        },
    ];
   
    const dispatch = useDispatch();

    const foodReducer = useAppSelector(state => state.foodReducer);
    const tabFoodStudentEvaluationFinalService = new TabStudentEvaluationFinalService(dispatch);
    
    useEffect(()=>{
        
    }, []);

    return (
        <TabStudentEvaluationFinalContainer>
            <ActionBack onClose={tabFoodStudentEvaluationFinalService.backPage}/>
            <TabStudentEvaluationConsumableTop>
                <div></div>
                <PageCount 
                    size={5} 
                    current={5} 
                    disabled={
                        foodReducer.evaluationSelectedConsumables.consumables.length === 0 ?
                        3 :
                        foodReducer.evaluationSelectedConsumables.drinks.length === 0 ?
                        4 :
                        undefined
                    }
                />
            </TabStudentEvaluationConsumableTop>

            <TabStudentEvaluationFinalContent>
                <TabStudentEvaluationFinalConsumables>
                    {foodReducer.evaluationSelectedConsumables.consumables.map(consumable=>(
                        <TabStudentEvaluationFinalConsumablesItem>
                            <img src={`${baseUrl}/${consumable.mealConsumable.media?.content}`} alt="Imagem do produto"/>
                            <span className='primaryBlackSemibold20-120'>{consumable.mealConsumable.name}</span>
                        </TabStudentEvaluationFinalConsumablesItem>
                    ))}

                    {foodReducer.evaluationSelectedConsumables.drinks.map(drink=>(
                        <TabStudentEvaluationFinalConsumablesItem>
                            <img src={`${baseUrl}/${drink.mealConsumable.media?.content}`} alt="Imagem do produto"/>
                            <span className='primaryBlackSemibold20-120'>{drink.mealConsumable.name}</span>
                        </TabStudentEvaluationFinalConsumablesItem>
                    ))}
                </TabStudentEvaluationFinalConsumables>
                
                <span className='primaryBlackSemibold24-120'>Você gostou do {foodReducer.evaluationSelectedFoodType?.name} hoje?</span>
            
                <TabStudentEvaluationFinalEmotions>
                    {
                        emotions.map((emotion : any, index : number)=>(
                            <TabStudentEvaluationFinalEmotionsItem
                                className={selectedEmotion?.icon === emotion.icon ? 'active' : ''}
                                onClick={()=>setSelectedEmotion(emotion)}
                            >
                                <img src={emotion.icon} alt='Icone emotions'/>
                                <span className='primaryBlackSemibold18-120'>{emotion.description}</span>
                            </TabStudentEvaluationFinalEmotionsItem>
                        ))
                    }
                </TabStudentEvaluationFinalEmotions>

                <TabStudentEvaluationFinalSliderContainer>
                    <TabStudentEvaluationFinalSliderContent width={selectedEmotion?.width || 0}/>
                </TabStudentEvaluationFinalSliderContainer>

            </TabStudentEvaluationFinalContent>
            
            
            <TabStudentEvaluationFinalButtonBar>
                <ButtonPrimary 
                    disabled={!selectedEmotion}
                    onClick={()=>{tabFoodStudentEvaluationFinalService.handleSubmit(
                        foodReducer.evaluationSelectedStudent!,
                        foodReducer.evaluationSelectedFullMeal!,
                        selectedEmotion?.score,
                        foodReducer.evaluationSelectedConsumables!
                    )}}
                >
                    Finalizar
                    <img src={arrow_hight_icon} alt="Icone de seta"/>
                </ButtonPrimary>
            </TabStudentEvaluationFinalButtonBar>
        </TabStudentEvaluationFinalContainer>
    );
};