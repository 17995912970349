import React, { useState } from 'react';
import { AddNutritionalCategoryModalActions, AddNutritionalCategoryModalContainer, AddNutritionalCategoryModalContent, AddNutritionalCategoryModalDescription, AddNutritionalCategoryModalTitle } from './styles/add-nutritional-category-modal.styled';
import { useDispatch } from 'react-redux';
import { ButtonPrimary } from '../../components/button-primary.component';
import { Input } from '../../components/input.component';
import { ProductCategoryM } from '../../../models/product-category.model';
import { NutritionalCategoryM } from '../../../models/nutritional-category.model';
import { NutritionalCategoryModalService } from './services/add-nutritional-category-modal.service';
import { AddNutritionalCategoryModalT } from './types/add-nutritional-category-modal.type';


export const AddNutritionalCategoryModal = ({
    handleGetNutritionalCategories
} : AddNutritionalCategoryModalT) => {
    const dispatch = useDispatch();
    const [nutritionalCategory, setNutritionalCategory] = useState(NutritionalCategoryM.initial());

    const addNutritionalCategoryModalService = new NutritionalCategoryModalService(
        dispatch,
        nutritionalCategory, 
        setNutritionalCategory,
        handleGetNutritionalCategories
    );
    
    
    return (
        <AddNutritionalCategoryModalContainer>
            <AddNutritionalCategoryModalContent>
                <AddNutritionalCategoryModalTitle>
                    <span className='primaryBlackSemibold18-150'>Adicionar nova Catégoria Nutricional</span>
                </AddNutritionalCategoryModalTitle>
                <AddNutritionalCategoryModalDescription>
                    <Input
                        id='name'
                        name='name'
                        type='text'
                        label='Nome da categoria nutricional'
                        placeholder='Nome da categoria nutricional'
                        value={nutritionalCategory.name}
                        onChange={addNutritionalCategoryModalService.handleChangeName}
                    />
                </AddNutritionalCategoryModalDescription>
                <AddNutritionalCategoryModalActions>
                    <ButtonPrimary onClick={addNutritionalCategoryModalService.handleSubmit}>
                        Salvar categoria nutricional
                    </ButtonPrimary>
                    <span className='primaryBlackNormal20-150' onClick={()=>addNutritionalCategoryModalService.handleSetShowAddNutritionalCategoryModal(false)}>Cancelar</span>
                </AddNutritionalCategoryModalActions>
            </AddNutritionalCategoryModalContent>
        </AddNutritionalCategoryModalContainer>
    );
};
