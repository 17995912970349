import { Dispatch } from "redux";
import { generalSetIsLoading } from "../../../../features/general-slice.feature";
import { responseStatus } from "../../../../api/api-request.service";
import { showToast } from "../../../../utils/config";
import { toastTypes } from "../../../../features/types/general-slice.type";
import { foodSetEvaluationSelectedConsumables, foodSetEvaluationSelectedFoodType, foodSetEvaluationSelectedFullMeal, foodSetTabSelectedContent } from "../../../../features/food-slice.feature";
import { foodContentsNames } from "../../../../features/types/food-slice.type";
import { StudentM } from "../../../../models/student.model";
import { TodaysMenuController } from "../../../../controllers/todays-menu.controller";
import { StudentMealAcceptanceI } from "../../../../models/interfaces/student-meal-acceptance.interface";
import { FoodTypeM } from "../../../../models/food-type.model";
import { FullMealM } from "../../../../models/full-meal.model";
import { StudentScoreM } from "../../../../models/student-score.model";
import { StudentMealAcceptanceM } from "../../../../models/student-meal-acceptance.model";
import { FullMealController } from "../../../../controllers/full-meal.controller";

export class TabStudentEvaluationFinalService {
    dispatch : Dispatch;
    fullMealController : FullMealController;

    constructor(dispatch: Dispatch){
        this.dispatch = dispatch;
        this.fullMealController = new FullMealController();

        this.backPage = this.backPage.bind(this);
        
    }

    public async backPage(){
        this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_EVALUATION));
    }
   
    public async handleSubmit(
        selectedStudent : StudentM,
        selectedFullMeal : FullMealM,
        selectedScore : number,
        selectedConsumables : {
            consumables : StudentMealAcceptanceI[],
            drinks : StudentMealAcceptanceI[],
        }
    ){
        this.dispatch(generalSetIsLoading(true));
        const fullMeal = {...selectedFullMeal};
        const studentsAcceptances : StudentMealAcceptanceM[] = [];

        selectedConsumables.consumables.map(consumable=>{
            studentsAcceptances.push(consumable);
        });

        selectedConsumables.drinks.map(drink=>{
            studentsAcceptances.push(drink);
        });

        fullMeal.studentsAcceptances = studentsAcceptances;

        fullMeal.studentScores = [
            new StudentScoreM({
                fullMealId: fullMeal.id!,
                score : selectedScore,
                studentId : selectedStudent.id!
            })
        ];

        await this.fullMealController.createAndUpdate(fullMeal!).then(response=>{
            const {status, data} = response;
            if(status === responseStatus.SUCCESS){    
                this.dispatch(foodSetEvaluationSelectedConsumables({consumables: [], drinks: []}));
                this.dispatch(foodSetEvaluationSelectedFoodType(FoodTypeM.initial()));
                this.dispatch(foodSetEvaluationSelectedFullMeal(FullMealM.initial()));
                this.dispatch(foodSetTabSelectedContent(foodContentsNames.STUDENT_EVALUATION_SUCCESS));

            }else{
                showToast(this.dispatch, {
                    type: toastTypes.ERROR,
                    text: data,
                })
            }
        }).finally(()=>{
            this.dispatch(generalSetIsLoading(false));
        });
        
    }
    
}