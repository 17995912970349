import { ProductCategoryM } from "../models/product-category.model";

export class ProductCategoryController {

    async getAll(){
        return await ProductCategoryM.getAll();
    }

    async createAndUpdate(category : ProductCategoryM){
        if(category.id){
            return await ProductCategoryM.update(category);
        }
        return await ProductCategoryM.create(category);
    }
    
}